import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/container'
import ContactForm from '../components/ContactForm'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeadset } from '@fortawesome/free-solid-svg-icons'

const Contact = () => {
  const { t } = useTranslation('contact')

  return (
    <div className="relative pt-10 pb-16">
      {/* Decorative elements */}
      <div className="absolute left-0 top-0 w-40 h-40 bg-cyan-500 opacity-10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
      <div className="absolute right-0 bottom-0 w-56 h-56 bg-cyan-600 opacity-10 rounded-full translate-x-1/4 translate-y-1/4"></div>
      
      <Container className="relative z-10">
        {/* Page header */}
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-cyan-500 to-cyan-600 mb-6">
            <FontAwesomeIcon icon={faHeadset} className="text-white text-2xl" />
          </div>
          <h1 className="text-4xl font-bold text-white mb-4">
            {t('title', 'Kontakt')}
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto">
            {t('description', 'Sie haben Fragen oder Anregungen? Schreiben Sie uns!')}
          </p>
        </div>
        
        {/* Contact form component */}
        <ContactForm />
      </Container>
    </div>
  )
}

export default Contact