import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/container'
import joey_koenig from '../assets/images/pages/about/joey_koenig.png'
import moritz_busch from '../assets/images/pages/about/moritz_busch.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserTie, faGraduationCap } from '@fortawesome/free-solid-svg-icons'

/**
 * About component renders the About page with various sections.
 * It uses the `useTranslation` hook to fetch translations for the content.
 * Timeline has been converted to horizontal layout to reduce scrolling.
 *
 * @component
 * @example
 * return (
 * <About />
 * )
 *
 * @returns {JSX.Element} The rendered About page component.
 */
const About = () => {
  const { t } = useTranslation('about')
  
  // Fetch the timeline items
  const timelineItems = t('c1', { returnObjects: true });
  
  return (
    <>
      {/* Company Timeline Section - Modified to horizontal layout */}
      <section className="py-16">
        <Container>
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-cyan-400 mb-3">
              Unsere Geschichte
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Der Weg von PathConnect
            </p>
          </div>
          
          {/* Horizontal Timeline */}
          <div className="relative">
            {/* Timeline center line - now horizontal */}
            <div className="absolute top-8 left-0 right-0 h-1 bg-cyan-800"></div>
            
            {/* Timeline events in horizontal layout */}
            <div className="flex flex-row items-start justify-between overflow-x-auto pb-6">
              {timelineItems.map((item, index) => (
                <div key={index} className="flex flex-col items-center min-w-64 px-4 relative">
                  {/* Year circle */}
                  <div className="z-10 mb-12">
                    <div className="w-16 h-16 rounded-full bg-gray-900 border-2 border-cyan-500 flex items-center justify-center">
                      <div className="text-cyan-400 font-bold text-lg">
                        {item.title}
                      </div>
                    </div>
                  </div>
                  
                  {/* Connection dot */}
                  <div className="absolute top-8 left-1/2 transform -translate-x-1/2">
                    <div className="w-4 h-4 rounded-full bg-cyan-400"></div>
                  </div>
                  
                  {/* Content card - now below the timeline */}
                  <div className="w-full max-w-xs">
                    <div className="bg-gray-800 p-6 rounded-lg border border-gray-700 hover:border-cyan-500 transition-colors duration-300 shadow-lg transform hover:-translate-y-1 transition-transform duration-300 h-full">
                      <p className="text-gray-300">{item.cardDetailedText}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </section>
      
      {/* Company Values Section */}
      <section className="py-16 bg-gray-900/30">
        <Container>
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-cyan-400 mb-3">
              Unsere Werte
            </h2>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {t('c2', { returnObjects: true }).map((object, index) => (
              <div 
                key={index} 
                className="bg-gray-800 p-6 rounded-lg border border-gray-700 hover:border-cyan-500 transition-colors duration-300 shadow-md"
              >
                <h3 className="text-xl font-bold text-cyan-400 mb-4">{object.title}</h3>
                <p className="text-gray-300">{object.description}</p>
              </div>
            ))}
          </div>
        </Container>
      </section>
      
      {/* Team Members Section */}
      <section className="py-16">
        <Container>
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-cyan-400 mb-3">
              Unser Team
            </h2>
            <p className="text-gray-300 max-w-2xl mx-auto">
              Die Menschen hinter PathConnect
            </p>
          </div>
          
          {t('c3.list', { returnObjects: true }).map((person, index) => (
            <div 
              key={index} 
              className="mb-16 last:mb-0"
            >
              {/* Team Member Header */}
              <div className="bg-gradient-to-r from-gray-900 to-gray-800 p-6 rounded-t-lg border border-gray-700 flex flex-col md:flex-row items-center md:items-start">
                <img
                  src={person.photo === 'joey_koenig' ? joey_koenig : moritz_busch}
                  alt={person.title}
                  className="w-32 h-32 object-cover rounded-full border-4 border-cyan-800"
                />
                <div className="md:ml-8 mt-4 md:mt-0 text-center md:text-left">
                  <h3 className="text-2xl font-bold text-cyan-400">{person.title}</h3>
                  <p className="text-gray-300 mt-2">{person.catch}</p>
                </div>
              </div>
              
              {/* Strengths and Competencies */}
              <div className="bg-gray-800/50 p-6 border-x border-gray-700">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon icon={faUserTie} className="text-cyan-400 mr-3" />
                  <h4 className="text-xl font-semibold text-white">{t('c3.strAndComp')}</h4>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {person.strAndComp.map((item, idx) => (
                    <div 
                      key={idx} 
                      className="bg-gray-900/60 p-4 rounded-lg border border-gray-700 hover:border-cyan-500 transition-colors duration-300"
                    >
                      <h5 className="text-lg font-medium text-cyan-400 mb-2">
                        {item.title}
                      </h5>
                      <p className="text-gray-300">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Professional Background */}
              <div className="bg-gray-800/30 p-6 rounded-b-lg border-x border-b border-gray-700">
                <div className="flex items-center mb-4">
                  <FontAwesomeIcon icon={faGraduationCap} className="text-cyan-400 mr-3" />
                  <h4 className="text-xl font-semibold text-white">{t('c3.profBack')}</h4>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {person.profBack.map((item, idx) => (
                    <div 
                      key={idx} 
                      className="bg-gray-900/60 p-4 rounded-lg border border-gray-700 hover:border-cyan-500 transition-colors duration-300"
                    >
                      <h5 className="text-lg font-medium text-cyan-400 mb-2">
                        {item.title}
                      </h5>
                      <p className="text-gray-300">{item.text}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </Container>
      </section>
    </>
  )
}

export default About