import React, { useState } from 'react'
import PropTypes from 'prop-types'
import i18n, { languages } from '../i18n'
import Dropdown from './dropdown'

const LanguageSwitcher = ({ className }) => {
  // Get last used language from local storage or default to "de_DE"
  const lastLanguage = localStorage.getItem('i18nextLng') || 'de_DE'

  // Set the current language to the last used language or default
  const [currentLanguage, setCurrentLanguage] = useState(lastLanguage)

  const handleOptionClick = language => {
    setCurrentLanguage(language.code)
    localStorage.setItem('i18nextLng', language.code)
    i18n.changeLanguage(language.code)
  }

  const options = languages.map(language => ({
    name: `${language.flag} ${language.name}`,
    link: '#',
    code: language.code
  }))

  return (
    <Dropdown
      prefix=""
      options={options}
      placeholder={
        languages.find(language => language.code === currentLanguage)?.flag ||
        ''
      }
      onOptionClick={handleOptionClick}
      className={className}
    />
  )
}

LanguageSwitcher.propTypes = {
  className: PropTypes.string
}

export default LanguageSwitcher
