import React from 'react'
import { Routes, Route } from 'react-router-dom'
import PageTitle from './components/pageTitle'
import Layout from './components/layout'
import { ThemeProvider } from './components/themeProvider'
import Home from './pages/home'
import About from './pages/about'
import Background from './components/layout/background'
import Contact from './pages/contact'
import Portal from './pages/portal'
import Impressum from './pages/impressum'
import AGB from './pages/agb'
import Privacy from './pages/privacy'
import Blog from './pages/blog'
import BlogPost from './pages/blogPost'


const App = () => {
  return (
    <ThemeProvider>
      <Background>
        <Layout>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <PageTitle titleKey="home" />
                  <Home />
                </>
              }
            />
            <Route
              path="/portal"
              element={
                <>
                  <PageTitle titleKey="portal" />
                  <Portal />
                </>
              }
            />
            <Route
              path="/about"
              element={
                <>
                  <PageTitle titleKey="about" />
                  <About />
                </>
              }
            />
            <Route
              path="/contact"
              element={
                <>
                  <PageTitle titleKey="contact" />
                  <Contact />
                </>
              }
            />
            {/* Blog Routes */}
            <Route
              path="/blog"
              element={
                <>
                  <PageTitle titleKey="blog.blog-title" />
                  <Blog />
                </>
              }
            />
            <Route
              path="/blog/:slug"
              element={
                <>
                  <PageTitle titleKey="blog.post-title" />
                  <BlogPost />
                </>
              }
            />
            <Route
              path="/impressum"
              element={
                <>
                  <PageTitle titleKey="impressum" />
                  <Impressum />
                </>
              }
            />
            <Route
              path="/agb"
              element={
                <>
                  <PageTitle titleKey="agb" />
                  <AGB />
                </>
              }
            />
            <Route
              path="/privacy"
              element={
                <>
                  <PageTitle titleKey="privacy" />
                  <Privacy />
                </>
              }
            />
          </Routes>
        </Layout>
      </Background>
    </ThemeProvider>
  )
}

export default App