// src/data/blogPosts/nextcloud-office-mit-collabora.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-office-mit-collabora-featured.png';
import collaboraOnlineImage from '../../assets/images/blog/2022/09/collabora-online.png';
import nextcloudHubLogo from '../../assets/images/blog/2022/01/Nextcloud-Hub-logo.png';
import dsgvoImage from '../../assets/images/blog/2022/01/dsgvo-3583628_1920-1024x683.jpg';
import webImage from '../../assets/images/blog/2021/05/web-3967926_1920-1024x594.jpg';
import dsgvoAnalysisImage from '../../assets/images/blog/2020/06/DSGVO-Analyse-1024x723.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Office with Collabora
 */
const post = {
  id: '9000',
  slug: 'nextcloud-office-mit-collabora',
  
  // Base data - same regardless of language
  date: "2022-09-08T15:38:43.000Z",
  readTime: 8,
  categories: ["Informationen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 5,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Office mit Collabora",
      formattedDate: "8. September 2022",
      excerpt: "Nextcloud Office mit Collabora. \"Könnte Collabora in Zukunft MS Office ersetzen?\" Eine steile Einstiegsfrage zum Beginn unseres Blogartikels, doch nicht ganz so unwahrscheinlich. In einem kürzlich erschienen heise Artikel wurde das Thema diskutiert, wie mittels Nextcloud Hub eine vollständige Umgebung für sämtliche Office Arbeitsplätze geschaffen werden kann.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Office mit Collabora</h1>
          
          <p>"Könnte Collabora in Zukunft MS Office ersetzen?" Eine steile Einstiegsfrage zum Beginn unseres Blogartikels, doch nicht ganz so unwahrscheinlich. In einem kürzlich erschienen heise Artikel ("Nextcloud: Initiative für eine souveräne europäische MS-Office Alternative", von Andre von Raison) wurde das Thema diskutiert, wie mittels Nextcloud Hub eine vollständige Umgebung für sämtliche Office Arbeitsplätze geschaffen werden kann.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={collaboraOnlineImage} alt="Collabora Online" size="large" />
            </div>
            
          </div>
          
          <BlogHeading>Nextcloud Hub</BlogHeading>
          
          <p>Mit Nextcloud Hub lassen sich Dokumente gemeinsam bearbeiten, E-Mails können versendet und empfangen werden oder Kalender und Videochats können ganz ohne Datenlecks verwendet werden. Als vollständige lokale Lösung bietet Nextcloud Hub die Vorteile der Online Zusammenarbeit ohne Compliance und Datenlecks.</p>
          
          <BlogImage src={nextcloudHubLogo} alt="Nextcloud Hub Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Europaische Behören für souveräne Alternative</BlogHeading>
          
          <p>Mehrere europäische Behörden aus dem deutschen, schwedischen und schweizer Raum, setzen sich seit längerem für digitale Sicherheit und Privatsphäre am Arbeitsplatz ein und erhoffen sich mit Nextcloud Hub eine souveräne Alternative zu schaffen. Nicht nur für die Umsetzung der Pläne sind die Behörden zuständig, sondern stehen auch als Vermittler zwischen Nutzern und Entwicklern dar, um eine unabhängige und vertrauenswürdige Zusammenarbeit voranzutreiben. Da seit einigen Jahren sich Microsoft bei den meisten Unternehmen als Kollaborationsplattform durchgesetzt hat, ist es umso wichtiger sich weiterhin an den MS-Fonts wie auch dem gängigen Nutzerinterface zu orientieren, um sich in Zukunft durchsetzen zu können.</p>
          
          <BlogImage src={dsgvoImage} alt="DSGVO Konzept" size="large" className="my-6" />
          
          <BlogHeading level={3}>Usability</BlogHeading>
          
          <p>Wie wird es nun in der Zukunft aussehen und was erhoffen sich genau die europäischen Behörden ? Zum einen möchte man Umfragen zu bestimmten Nutzermustern durchführen. Dafür zuständig ist das Nextcloud Usability Team in Kombination mit dem Advisory Board, welches bei der weiteren Entwicklung der Funktionen helfen sollte wie auch an neuen und praktischen Features arbeiten sollte. So sollte man beispielsweise in der Lage sein, zwischen bestimmten Office Paketen zu switchen. Insbesondere dann, wenn das Produkt eine bestimmte Funktionslücke lokal aufweist und diese aber online wieder abrufbar ist.</p>
          
          <BlogImage src={webImage} alt="Webentwicklung" size="large" className="my-6" />
          
          <BlogHeading level={3}>Mehr Datenschutz</BlogHeading>
          
          <p>Natürlich ist das alles darauf zurückzuführen, dass in den letzten Jahren die Machtstellung in bestimmten Bereichen durch vereinzelnde US amerikanische Firmen zugenommen hat und es anderen Marktteilnehmern schlichtweg nicht ermöglicht auf derselben Erfolgswelle mitzuschwimmen. Doch damit könnte Schluss sein. Denn viele US amerikanische Cloud Plattformen entsprechen nicht der DSGVO bzw dem Cloud Act, weshalb man für die Zukunft nach souveränen Anbietern sucht und den gesamten Bereich transparenter gestaltet.</p>
          
          <BlogImage src={dsgvoAnalysisImage} alt="DSGVO Analyse" size="large" className="my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Office mit Collabora",
      formattedDate: "8. September 2022",
      excerpt: "Nextcloud Office mit Collabora. \"Könnte Collabora in Zukunft MS Office ersetzen?\" Eine steile Einstiegsfrage zum Beginn unseres Blogartikels, doch nicht ganz so unwahrscheinlich. In einem kürzlich erschienen heise Artikel wurde das Thema diskutiert, wie mittels Nextcloud Hub eine vollständige Umgebung für sämtliche Office Arbeitsplätze geschaffen werden kann.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Office with Collabora",
      formattedDate: "September 8, 2022",
      excerpt: "Nextcloud Office with Collabora. \"Could Collabora replace MS Office in the future?\" A steep question to start our blog article, but not entirely unlikely. In a recently published heise article, the topic was discussed how Nextcloud Hub can create a complete environment for all office workplaces.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Office with Collabora</h1>
          
          <p>"Could Collabora replace MS Office in the future?" A steep question to start our blog article, but not entirely unlikely. In a recently published heise article ("Nextcloud: Initiative for a sovereign European MS Office alternative", by Andre von Raison), the topic was discussed how Nextcloud Hub can create a complete environment for all office workplaces.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Office with Collabora",
      formattedDate: "8 September 2022",
      excerpt: "Nextcloud Office with Collabora. \"Could Collabora replace MS Office in the future?\" A steep question to start our blog article, but not entirely unlikely. In a recently published heise article, the topic was discussed how Nextcloud Hub can create a complete environment for all office workplaces.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;