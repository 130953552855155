// src/data/blogPosts/kimai-elektronische-zeiterfassung.js

// Import images
import kimaiLogo from '../../assets/images/blog/2024/01/kimai.webp';
import thumbnailImage from '../../assets/images/blog/kimai-elektronische-zeiterfassung-featured.webp';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Kimai electronic time tracking
 */
const post = {
  id: '15700',
  slug: 'kimai-elektronische-zeiterfassung',
  
  // Base data - same regardless of language
  date: "2024-01-26T14:02:39.000Z",
  readTime: 13,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 10,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Kimai – Elektronische Zeiterfassung",
      formattedDate: "26. Januar 2024",
      excerpt: "Kimai ist eine Software zur elektronischen Zeiterfassung, mit der du und dein Team im Handumdrehen Arbeitszeiten erfassen können. In diesem Artikel zeigen wir dir die Anwendung und gehen auf die wichtigsten Funktionen ein.",
      content: () => (
        <BlogContent>
          <p>Kimai ist eine Software zur elektronischen Zeiterfassung, mit der du und dein Team im Handumdrehen Arbeitszeiten erfassen können. In diesem Artikel zeigen wir dir die Anwendung und gehen auf die wichtigsten Funktionen ein.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={kimaiLogo} alt="Kimai Logo" size="medium" />
          </div>
          
          <BlogHeading>Elektronische Zeiterfassung</BlogHeading>
          
          <p>Bei der elektronischen Zeiterfassung, die übrigens seit September 2022 verpflichtend ist, geht es darum, die Arbeitszeiten der Arbeitnehmer aufzuzeichnen. Was zunächst etwas abschreckend klingt, hat auch viele Vorteile. So können Arbeitnehmer ihre geleisteten Arbeitsstunden genau abrechnen und nachweisen. <br />Durch die genaue Zuordnung der Stunden zu Kunde, Projekt und Tätigkeit kann der Arbeitgeber genau nachvollziehen, wie viel Aufwand ein Auftrag gekostet hat.</p>
          
          <div className="my-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" className="mx-auto text-gray-600"><path d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 10 4.477 10 10z"></path></svg>
          </div>
          
          <BlogHeading>Funktionen</BlogHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <BlogHeading level={3}>Kunden</BlogHeading>
              <p>Kunden können mit einem Klick importiert oder auch einzeln angelegt werden. Bei der Arbeitszeiterfassung kann dann der entsprechende Kunde ausgewählt werden. Für den Kunden können zusätzlich Kontaktdaten, Kundennummer und Budget festgelegt werden.</p>
              
              <BlogHeading level={3}>Projekte</BlogHeading>
              <p>Durch das Hinzufügen von Projekten können verschiedene Arbeiten pro Kunde definiert werden. Bei einem Malerbetrieb könnte dies z.B. Renovierung, Innen und Außen, Wasserschaden, Isolierung usw. sein. Jedes Projekt muss einem Kunden zugeordnet werden.</p>
              
              <BlogHeading level={3}>Tätigkeiten</BlogHeading>
              <p>Hier wird für jedes Projekt die spezifische Tätigkeit festgelegt. Bei einem Malerbetrieb könnte dies z.B. Abkleben, Streichen, Lackieren, Schleifen usw. sein. Jede Tätigkeit wird wiederum einem Projekt zugeordnet. So entsteht eine direkte Verbindung zwischen Kunde – Projekt und Tätigkeit.</p>
              
              <BlogHeading level={3}>Mitarbeiter & Teams</BlogHeading>
              <p>Mitarbeiter können entweder einzeln angelegt werden oder sich direkt über LDAP anmelden. Jeder Mitarbeiter kann einem Team zugeordnet, ein Vorgesetzter definiert und eine Personalnummer vergeben werden. Außerdem kann der Stundensatz, d.h. welcher Stundenlohn dem jeweiligen Mitarbeiter beim Kunden in Rechnung gestellt wird, und ein interner Preis festgelegt werden. So kann im Nachhinein genau nachvollzogen werden, wie viel ein bestimmter Kunde, ein bestimmtes Projekt und eine bestimmte Tätigkeit gekostet hat.</p>
            </div>
            
            <div>
              <BlogHeading level={3}>Zeiterfassung</BlogHeading>
              <p>Die eigentliche Hauptfunktion ist die Zeiterfassung, die über das Smartphone, das Webinterface oder den Computer erfolgen kann. Bei der Zeiterfassung muss die Start- und Endzeit eingegeben werden. Im nächsten Schritt wird ein Kunde, ein Projekt und eine Tätigkeit ausgewählt.</p>
              
              <BlogHeading level={3}>Zeiten einsehen</BlogHeading>
              <p>Zur Auswertung können die Arbeitszeiten aller Mitarbeiter eingesehen werden. Über einen Filter kann nach einzelnen Zeiträumen, Mitarbeitern, Kunden, Projekten und Tätigkeiten gefiltert werden. Für eine eventuelle Weitergabe an ein Lohnbüro können die Arbeitszeiten einfach exportiert werden.</p>
              
              <BlogHeading level={3}>Berichte</BlogHeading>
              <p>Kimai bietet eine Vielzahl von Berichten, mit denen Personalkosten detailliert ausgewertet werden können.</p>
              
              <BlogHeading level={3}>Soll/Ist Arbeitszeiten</BlogHeading>
              <p>Für jeden Mitarbeiter kann festgelegt werden, wie viele Stunden er pro Tag arbeiten soll. Durch eine einfache Soll-Ist-Anzeige kann festgestellt werden, ob der Mitarbeiter zu viel oder zu wenig arbeitet.</p>
              
              <BlogHeading level={3}>Weitere Funktionen</BlogHeading>
              <p>Neben den oben genannten Funktionen gibt es noch einige weitere kostenlose und kostenpflichtige Erweiterungen. Eine genaue Übersicht findest du hier:<br />
              <a href="https://www.kimai.org/de/store/" target="_blank" rel="noopener">https://www.kimai.org/de/store/</a></p>
            </div>
          </div>
          
          <div className="my-10">
            <p className="text-center italic text-gray-600">
              [Benutzeroberfläche von Kimai mit verschiedenen Funktionen wie Dashboard, Kunden, Projekte, Tätigkeiten, Zeiterfassung, und Berichte]
            </p>
          </div>
          
          <BlogHeading>Smartphone App</BlogHeading>
          
          <p>Ein klarer Vorteil für die einfache und bequeme Zeiterfassung ist die App, die die Mitarbeiter auf ihrem Smartphone installieren können.</p>
          
          <p>Android:</p>
          <p><a href="https://play.google.com/store/apps/details?id=de.cloudrizon.kimaimobile&hl=de&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=de.cloudrizon.kimaimobile&hl=de&gl=US</a></p>
          <p><a href="https://f-droid.org/de/packages/de.live.gdev.timetracker/" target="_blank" rel="noopener">https://f-droid.org/de/packages/de.live.gdev.timetracker/</a></p>
          
          <p>iOS:</p>
          <p><a href="https://apps.apple.com/de/app/kimai-mobile/id1563933145" target="_blank" rel="noopener">https://apps.apple.com/de/app/kimai-mobile/id1563933145</a></p>
          
          <p>Um sich in die App einloggen zu können, muss zunächst ein API-Zugang mit Passwort eingerichtet werden. Klicke dazu in deinem Profil auf das Icon oben rechts und dann auf API-Zugang. Dort kannst du ein Passwort vergeben. <br />Danach kannst du in der App die Serveradresse, den Benutzernamen und das eben vergebene API Passwort eingeben und schon bist du eingeloggt.</p>
          
          <BlogHeading>Hosting von Kimai - Elektronische Zeiterfassung</BlogHeading>
          
          <p>Mit Kimai Hosting, deiner Lösung ohne technischen Aufwand, kannst du deine Zeiterfassung einfach und sicher gestalten. Du bekommst Kimai auf deiner eigenen Domain, was Professionalität ausstrahlt und Vertrauen schafft. Wir sorgen für höchste Datensicherheit durch regelmäßige Backups und schützen deine Daten vor Verlust und unbefugtem Zugriff.</p>
          
          <p>Wir stehen dir von der Einrichtung bis zum täglichen Betrieb zur Seite, damit du immer das Beste aus Kimai herausholen kannst.</p>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Kimai – Elektronische Zeiterfassung",
      formattedDate: "26. Januar 2024",
      excerpt: "Kimai ist eine Software zur elektronischen Zeiterfassung, mit der du und dein Team im Handumdrehen Arbeitszeiten erfassen können. In diesem Artikel zeigen wir dir die Anwendung und gehen auf die wichtigsten Funktionen ein.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Kimai – Electronic Time Tracking",
      formattedDate: "January 26, 2024",
      excerpt: "Kimai is an electronic time tracking software that allows you and your team to record working hours in no time. In this article, we show you the application and explain its key features.",
      content: () => (
        <BlogContent>
          <p>Kimai is an electronic time tracking software that allows you and your team to record working hours in no time. In this article, we show you the application and explain its key features.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={kimaiLogo} alt="Kimai Logo" size="medium" />
          </div>
          
          <BlogHeading>Electronic Time Tracking</BlogHeading>
          
          <p>Electronic time tracking, which has been mandatory since September 2022, is about recording employees' working hours. What initially sounds somewhat intimidating also has many advantages. Employees can accurately calculate and verify their worked hours. <br />By precisely assigning hours to clients, projects, and activities, employers can track exactly how much effort a job has cost.</p>
          
          <div className="my-8">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" className="mx-auto text-gray-600"><path d="M15.91 13.34l2.636-4.026-.454-.406-3.673 3.099c-.675-.138-1.402.068-1.894.618-.736.823-.665 2.088.159 2.824.824.736 2.088.665 2.824-.159.492-.55.615-1.295.402-1.95zm-3.91-10.646v-2.694h4v2.694c-1.439-.243-2.592-.238-4 0zm8.851 2.064l1.407-1.407 1.414 1.414-1.321 1.321c-.462-.484-.964-.927-1.5-1.328zm-18.851 4.242h8v2h-8v-2zm-2 4h8v2h-8v-2zm3 4h7v2h-7v-2zm21-3c0 5.523-4.477 10-10 10-2.79 0-5.3-1.155-7.111-3h3.28c1.138.631 2.439 1 3.831 1 4.411 0 8-3.589 8-8s-3.589-8-8-8c-1.392 0-2.693.369-3.831 1h-3.28c1.811-1.845 4.321-3 7.111-3 5.523 0 10 4.477 10 10z"></path></svg>
          </div>
          
          <BlogHeading>Features</BlogHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <BlogHeading level={3}>Customers</BlogHeading>
              <p>Customers can be imported with one click or created individually. During time tracking, the corresponding customer can be selected. Contact information, customer number, and budget can also be defined for each customer.</p>
              
              <BlogHeading level={3}>Projects</BlogHeading>
              <p>By adding projects, different types of work can be defined for each customer. For a painting company, this could be renovation, interior and exterior work, water damage repair, insulation, etc. Each project must be assigned to a customer.</p>
              
              <BlogHeading level={3}>Activities</BlogHeading>
              <p>Here, the specific activity is defined for each project. For a painting company, this could be taping, painting, varnishing, sanding, etc. Each activity is in turn assigned to a project. This creates a direct connection between customer, project, and activity.</p>
              
              <BlogHeading level={3}>Employees & Teams</BlogHeading>
              <p>Employees can either be created individually or log in directly via LDAP. Each employee can be assigned to a team, a supervisor can be defined, and a personnel number can be assigned. Additionally, the hourly rate (i.e., which hourly wage is charged to the client for the respective employee) and an internal price can be set. This allows for precise tracking of how much a specific customer, project, and activity cost.</p>
            </div>
            
            <div>
              <BlogHeading level={3}>Time Tracking</BlogHeading>
              <p>The main function is time tracking, which can be done via smartphone, web interface, or computer. When tracking time, the start and end time must be entered. In the next step, a customer, project, and activity are selected.</p>
              
              <BlogHeading level={3}>Viewing Times</BlogHeading>
              <p>For evaluation purposes, the working hours of all employees can be viewed. A filter allows filtering by specific time periods, employees, customers, projects, and activities. For potential transfer to a payroll office, working hours can be easily exported.</p>
              
              <BlogHeading level={3}>Reports</BlogHeading>
              <p>Kimai offers a variety of reports that can be used to analyze personnel costs in detail.</p>
              
              <BlogHeading level={3}>Target/Actual Working Hours</BlogHeading>
              <p>For each employee, you can define how many hours they should work per day. A simple target-actual display can determine whether the employee is working too much or too little.</p>
              
              <BlogHeading level={3}>Additional Features</BlogHeading>
              <p>In addition to the features mentioned above, there are several other free and paid extensions. A detailed overview can be found here:<br />
              <a href="https://www.kimai.org/store/" target="_blank" rel="noopener">https://www.kimai.org/store/</a></p>
            </div>
          </div>
          
          <div className="my-10">
            <p className="text-center italic text-gray-600">
              [Interface of Kimai showing various features like dashboard, customers, projects, activities, time tracking, and reports]
            </p>
          </div>
          
          <BlogHeading>Smartphone App</BlogHeading>
          
          <p>A clear advantage for easy and convenient time tracking is the app that employees can install on their smartphones.</p>
          
          <p>Android:</p>
          <p><a href="https://play.google.com/store/apps/details?id=de.cloudrizon.kimaimobile&hl=de&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=de.cloudrizon.kimaimobile&hl=de&gl=US</a></p>
          <p><a href="https://f-droid.org/de/packages/de.live.gdev.timetracker/" target="_blank" rel="noopener">https://f-droid.org/de/packages/de.live.gdev.timetracker/</a></p>
          
          <p>iOS:</p>
          <p><a href="https://apps.apple.com/de/app/kimai-mobile/id1563933145" target="_blank" rel="noopener">https://apps.apple.com/de/app/kimai-mobile/id1563933145</a></p>
          
          <p>To log in to the app, you must first set up an API access with a password. To do this, click on the icon in the top right of your profile and then on API access. There you can assign a password. <br />After that, you can enter the server address, username, and the API password you just assigned in the app, and you'll be logged in.</p>
          
          <BlogHeading>Hosting Kimai - Electronic Time Tracking</BlogHeading>
          
          <p>With Kimai hosting, your solution without technical effort, you can design your time tracking simply and securely. You get Kimai on your own domain, which projects professionalism and builds trust. We ensure the highest data security through regular backups and protect your data from loss and unauthorized access.</p>
          
          <p>We're there for you from setup to daily operation, ensuring you always get the most out of Kimai.</p>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Kimai – Electronic Time Tracking",
      formattedDate: "26 January 2024",
      excerpt: "Kimai is an electronic time tracking software that allows you and your team to record working hours in no time. In this article, we show you the application and explain its key features.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;