import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faExternalLinkAlt,
  faPhone
} from '@fortawesome/free-solid-svg-icons'

/**
 * Renders a React element based on the provided key and value.
 *
 * @param {string} key - The key indicating the type of element to render. The key may contain an underscore, in which case only the part before the underscore is used.
 * @param {any} value - The value to be rendered. This can be a string, an array, or an object depending on the key.
 * @returns {JSX.Element} The rendered React element.
 *
 * @example
 * // Renders an h1 element with the text "Hello World"
 * renderElement('h1', 'Hello World')
 *
 * @example
 * // Renders a container with nested elements
 * renderElement('container', [{ h1: 'Title', p: 'Paragraph' }])
 *
 * @example
 * // Renders a link element
 * renderElement('link', { url: 'https://example.com', text: 'Example' })
 */
const renderElement = (key, value) => {
  key = key.split('_')[0]
  switch (key) {
    case 'h1':
      return <h1 className="text-center">{value}</h1>
    case 'h2':
      return <h2 className="mt-20 break-words">{value}</h2>
    case 'h3':
      return <h3 className="mt-10">{value}</h3>
    case 'container':
      return value.map((item, index) => (
        <div className="" key={index}>
          <Container className="">
            {Object.entries(item).map(([childKey, childValue]) =>
              renderElement(childKey, childValue)
            )}
          </Container>
        </div>
      ))
    case 'p':
      if (Array.isArray(value)) {
        return value.map((item, index) => (
          <>
            <p key={index}>{item}</p>
            <br />
          </>
        ))
      } else {
        return <p>{value}</p>
      }
    case 'link':
      return (
        <a
          href={value.url}
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
        >
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            className="mr-2 no-underline"
          />
          {value.text}
        </a>
      )
    case 'mail':
      return (
        <a href={`mailto:${value}`} className="underline">
          <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
          <span className="underline">{value}</span>
        </a>
      )
    case 'phone':
      return (
        <a href={`tel:${value}`} className="underline">
          <FontAwesomeIcon icon={faPhone} />
          <span className="ml-2 underline">{value}</span>
        </a>
      )
    default:
      return <p>{value}</p>
  }
}

/**
 * Impressum component that displays content from the 'impressum' translation namespace.
 * It uses the `useTranslation` hook to fetch the translated content and renders it using the `renderElement` function.
 *
 * @component
 * @returns {JSX.Element} The rendered Impressum component.
 */
const Impressum = () => {
  const { t } = useTranslation('impressum')

  return (
    <div className="w-full">
      {Object.entries(t('content', { returnObjects: true })).map(
        ([key, value]) => renderElement(key, value)
      )}
    </div>
  )
}

export default Impressum
