// src/data/blogPosts/e-mail-account-in-paperless-ngx-einbinden.js

// Import images
import thumbnailImage from '../../assets/images/blog/e-mail-account-in-paperless-ngx-einbinden-featured.png';
import paperlessLogo from '../../assets/images/blog/2024/05/logo_full_black.png';
import paperPlaneIcon from '../../assets/images/blog/2023/03/iconmonstr-paper-plane-8-240.png';
import emailAccountAddImage from '../../assets/images/blog/2024/09/01E-Mail-Account-hinzufuegen.png';
import newEmailAccountImage from '../../assets/images/blog/2024/09/02Neues-E-Mail-Konto-hinzufuegen.png';
import appPasswordMailcowImage from '../../assets/images/blog/2024/09/03App-Passwort-Mailcow.png';
import appPasswordGmailImage from '../../assets/images/blog/2024/09/04App-Passwort-G-Mail.png';
import newEmailRuleImage from '../../assets/images/blog/2024/09/05-Neue-E-Mail-Regel-erstellen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about integrating email accounts with Paperless-ngx
 */
const post = {
  id: '21730',
  slug: 'e-mail-account-in-paperless-ngx-einbinden',
  
  // Base data - same regardless of language
  date: "2024-09-19T13:33:32.000Z",
  readTime: 10,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 36,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "E-Mail Account in Paperless-ngx einbinden",
      formattedDate: "19. September 2024",
      excerpt: "E-Mail Account in Paperless-ngx einbinden Mit der digitalen Dokumentenverwaltung Paperless-ngx verwandelst du dein Papierchaos in eine geordnete und durchsuchbare Struktur. Aber auch digitale Dokumente, die per E-Mail eintreffen, sollten am besten gleich richtig abgelegt werden.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">E-Mail Account in Paperless-ngx einbinden</h1>
          
          <p>Mit der digitalen Dokumentenverwaltung Paperless-ngx verwandelst du dein Papierchaos in eine geordnete und durchsuchbare Struktur. Aber auch digitale Dokumente, die per E-Mail eintreffen, sollten am besten gleich richtig abgelegt werden. In diesem Artikel zeigen wir dir, wie du dein E-Mail Account in Paperless-ngx einbinden kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={paperlessLogo} alt="Paperless-ngx Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Dokumente per E-Mail zu Paperless-ngx</BlogHeading>
          
          <p>Heutzutage können viele Dokumente per E-Mail verschickt werden. Das Problem dabei ist jedoch, dass diese meist in der Vielzahl der E-Mails untergehen und die Suche oft mühsam ist.</p>
          <p>Mit Paperless-ngx können die E-Mails als Ganzes oder nur der Anhang direkt übernommen und mit den entsprechenden Tags und Dokumententyp versehen werden. Mit speziellen Filterregeln werden die E-Mail-Anhänge z.B. nur von bestimmten Empfängern oder mit einem bestimmten Betreff in Paperless-ngx geladen.</p>
          
          <BlogImage src={paperPlaneIcon} alt="Papierflieger Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>E-Mail Account verbinden</BlogHeading>
          
          <p>Klicke auf der linken Seite auf „E-Mail" und dann auf „Konto hinzufügen".</p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Name:</strong> <br />Der Name für dieses E-Mail-Konto (dient nur der Übersicht)</li>
            <li><strong>IMAP-Server:</strong> <br />Die Adresse des IMAP-Servers deines E-Mail-Providers</li>
            <li><strong>IMAP-Port:</strong> <br />Standard 993</li>
            <li><strong>IMAP Sicherheit:</strong> <br />Standard SSL</li>
            <li><strong>Benutzername:</strong> <br />Deine E-Mail Adresse, mit der du dich anmelden möchtest</li>
            <li><strong>Kennwort:</strong> <br />Dein Kennwort für die E-Mail Adresse</li>
          </ul>
          
          <p>Um zu überprüfen, ob die Verbindung funktioniert, klicke auf „Testen". Wenn du die Meldung „Verbindung zum Mailserver erfolgreich hergestellt" erhältst, klicke auf „Speichern".</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={emailAccountAddImage} alt="E-Mail Account hinzufügen" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={newEmailAccountImage} alt="Neues E-Mail Konto hinzufügen" size="medium" />
            </div>
          </div>
          
          <p>Für das Kennwort solltest du ein App-Passwort verwenden. Wir zeigen dir anhand von Mailcow und G-Mail wie du ein App-Passwort erstellst.</p>
          
          <p><strong>Mailcow:</strong> <br />Melde dich mit deiner E-Mail-Adresse und deinem Passwort bei Mailcow an, klicke auf den Reiter „App-Passwörter" und dann auf „App-Passwort erstellen".</p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>App-Name:</strong> <br />Gib dem App-Passwort einen Namen</li>
            <li><strong>Passwort:</strong> <br />Lass es generieren</li>
            <li><strong>Erlaubte Protokolle:</strong> <br />Für Paperless ist das IMAP-Protokoll ausreichend.</li>
          </ul>
          
          <p>Kopiere das Passwort in die Zwischenablage und klicke auf „Hinzufügen".</p>
          
          <p><strong>G-Mail:</strong><br />Melde dich mit deiner E-Mail-Adresse und deinem Passwort bei G-Mail an und öffne diesen Link:<br /><a href="https://myaccount.google.com/apppasswords" target="_blank" rel="noopener">https://myaccount.google.com/apppasswords</a></p>
          
          <p>Gib dem App-Passwort wieder einen Namen und klicke auf „Erstellen".</p>
          
          <p>Das App Passwort kannst du dann anstelle deines richtigen Passworts bei Paperless-ngx einfügen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={appPasswordMailcowImage} alt="App Passwort Mailcow" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={appPasswordGmailImage} alt="App Passwort G-Mail" size="medium" />
            </div>
          </div>
          
          <BlogHeading>E-Mail Regeln erstellen</BlogHeading>
          
          <p>Damit die Dokumente aus deiner E-Mail auch in Paperless-ngx hochgeladen werden, müssen E-Mail-Regeln erstellt werden.<br />Klicke dazu auf „Regel hinzufügen".</p>
          <ul className="list-disc pl-6 mb-4">
            <li><strong>Name:</strong> <br />Name für diese Regel</li>
            <li><strong>Konto:</strong> <br />Auswahl des hinzugefügten E-Mail Kontos</li>
            <li><strong>Ordner:</strong> <br />E-Mail Ordner im Posteingang, wähle „INBOX" für den gesamten Posteingang</li>
            <li><strong>Maximales Alter:</strong> <br />Wie alt dürfen E-Mails maximal sein, um noch verarbeitet zu werden.</li>
            <li><strong>Dateianhangstyp:</strong> <br />Auswahl, ob nur Anhänge oder auch in der Nachricht enthaltene Dokumente verarbeitet werden sollen.</li>
            <li><strong>Verarbeitungsumfang:</strong><br />Auswahl, ob nur Anhänge oder die gesamte E-Mail verarbeitet werden soll</li>
            <li><strong>Absender/Empfänger filtern:</strong> <br />Hier kann ein bestimmter Absender/Empfänger eingegeben werden.</li>
            <li><strong>Betreff filtern:</strong> <br />Nur E-Mails, die diesem Betreff entsprechen, werden verarbeitet.</li>
            <li><strong>Nachrichteninhalt filtern:</strong> <br />Suche nach bestimmten Wörtern in der Nachricht</li>
            <li><strong>Filter Anhang Dateiname enthält:</strong> <br />Suche nach bestimmten Wörtern im Dateinamen des Anhangs</li>
            <li><strong>Aktion:</strong> <br />Hier kann ausgewählt werden, was mit der E-Mail geschehen soll, nachdem sie von Paperless verarbeitet wurde.</li>
            <li><strong>Titelzuweisung:</strong> <br />Welchen Namen soll das Dokument in Paperless erhalten?</li>
            <li><strong>Tags:</strong> <br />Ob dem Dokument direkt Tags zugewiesen werden sollen.</li>
            <li><strong>Dokumenttyp zuweisen:</strong> <br />Falls dem Dokument direkt ein Typ zugewiesen werden soll</li>
            <li><strong>Korrespondentenzuweisung:</strong> <br />Falls das Dokument direkt einem Korrespondenten zugeordnet werden soll</li>
          </ul>
          
          <BlogImage src={newEmailRuleImage} alt="Neue E-Mail Regel erstellen" size="large" className="my-6" />
          
          <p>In diesem Beispiel werden alle E-Mails im Posteingang mit dem Betreff „Rechnung" verarbeitet. Dazu wird folgender Regex-Ausdruck verwendet: .*\\bRechnung([a-zA-ZäöüÄÖÜß]*|\\s?\\d+)?\\b.*</p>
          
          <p>Die E-Mail wird mit dem Tag „papierlos" gekennzeichnet.<br />Der Dateiname des Anhangs wird als Titel für das Dokument in Paperless-ngx verwendet und dem Dokumenttyp „Rechnung" zugeordnet. Als Korrespondent wird der Name des Absenders verwendet.</p>
          
          <p>Von nun an werden deine E-Mails automatisch in Paperless-ngx geladen.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "E-Mail Account in Paperless-ngx einbinden",
      formattedDate: "19. September 2024",
      excerpt: "E-Mail Account in Paperless-ngx einbinden Mit der digitalen Dokumentenverwaltung Paperless-ngx verwandelst du dein Papierchaos in eine geordnete und durchsuchbare Struktur. Aber auch digitale Dokumente, die per E-Mail eintreffen, sollten am besten gleich richtig abgelegt werden.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Integrating Email Accounts in Paperless-ngx",
      formattedDate: "September 19, 2024",
      excerpt: "Integrating Email Accounts in Paperless-ngx With the digital document management system Paperless-ngx, you can transform your paper chaos into an organized and searchable structure. But digital documents arriving via email should also be properly stored right away.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Integrating Email Accounts in Paperless-ngx</h1>
          
          <p>With the digital document management system Paperless-ngx, you can transform your paper chaos into an organized and searchable structure. But digital documents arriving via email should also be properly stored right away. In this article, we show you how to integrate your email account with Paperless-ngx.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Integrating Email Accounts in Paperless-ngx",
      formattedDate: "19 September 2024",
      excerpt: "Integrating Email Accounts in Paperless-ngx With the digital document management system Paperless-ngx, you can transform your paper chaos into an organized and searchable structure. But digital documents arriving via email should also be properly stored right away.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;