// src/data/blogPosts/draw-io.js

// Import images
import thumbnailImage from '../../assets/images/blog/draw-io-featured.png';
import diagramsLogo from '../../assets/images/blog/2024/03/Diagrams.net_Logo.svg';
import circleIcon from '../../assets/images/blog/2023/05/iconmonstr-circle-5-240.png';
import newDiagramWhiteboardImage from '../../assets/images/blog/2024/03/Neues-Diagramm-oder-Whiteboard-erstellen.png';
import whiteboardImage from '../../assets/images/blog/2024/03/Whiteboard.png';
import orgachartImage from '../../assets/images/blog/2024/03/Orgachart.png';
import exportImage from '../../assets/images/blog/2024/03/Export.png';
import teilenImage from '../../assets/images/blog/2024/03/Teilen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Draw.io
 */
const post = {
  id: '17816',
  slug: 'draw-io',
  
  // Base data - same regardless of language
  date: "2024-03-04T17:36:41.000Z",
  readTime: 8,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 5,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Draw.io",
      formattedDate: "4. März 2024",
      excerpt: "Draw.io – Diagramme und Whiteboard Mit der Anwendung Draw.io können Diagramme und Whiteboards erstellt werden. Dieser Artikel zeigt, wie das Tool verwendet und in Nextcloud integriert werden kann.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Draw.io - Diagramme und Whiteboard</h1>
          
          <p>Mit der Anwendung Draw.io können Diagramme und Whiteboards erstellt werden. Dieser Artikel zeigt, wie das Tool verwendet und in Nextcloud integriert werden kann.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={diagramsLogo} alt="Diagrams.net Logo" size="small" />
          </div>
          
          <BlogHeading>Nutzungsmöglichkeiten</BlogHeading>
          
          <p>Draw.io kann in viele andere Anwendungen wie z.B. Nextcloud integriert werden. Die Anwendung kann direkt im Browser unter <a href="https://app.diagrams.net/" target="_blank" rel="noopener">https://app.diagrams.net/</a> aufgerufen werden. In Nextcloud kann die Anwendung einfach installiert werden. Für die Nutzung ist hier der öffentliche Server <a href="https://embed.diagrams.net" target="_blank" rel="noopener">https://embed.diagrams.net</a> voreingestellt. Die Software kann aber auch selbst gehostet werden.</p>
          
          <BlogImage src={circleIcon} alt="Kreis Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Draw.io in Nextcloud integrieren</BlogHeading>
          
          <p>Zur Installation loggst du dich mit einem Administrator-Account in deine Nextcloud ein, klickst auf das runde Icon oben rechts und dann auf „+Apps".<br />In der Suche kannst du einfach „Draw.io" eingeben und dann auf „Herunterladen und aktivieren" klicken.</p>
          <p>Um ein neues Diagramm oder Whiteboard zu erstellen, klicke auf „+ Neu" in einer Nextcloud.</p>
          
          <BlogImage src={newDiagramWhiteboardImage} alt="Neues Diagramm oder Whiteboard erstellen" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Draw.io - Whiteboard und Diagramme</BlogHeading>
          
          <p>Das Whiteboard eignet sich gut, um Ideen zu sammeln oder verschiedene Themen übersichtlich darzustellen. Es können verschiedene Formen, Pfeile und Texte in beliebiger Größe und Farbe eingefügt werden.</p>
          
          <BlogImage src={whiteboardImage} alt="Draw.io Whiteboard" size="large" className="my-6" />
          
          <p>Es können Diagramme aller Art erstellt werden. Es gibt zahlreiche Vorschläge, die direkt eingefügt und dann bearbeitet werden können.</p>
          
          <BlogImage src={orgachartImage} alt="Draw.io Orgachart" size="large" className="my-6" />
          
          <BlogHeading>Export und Teilen</BlogHeading>
          
          <p>Sowohl das Whiteboard als auch die Diagramme können in fast alle Formate exportiert werden, wie z.B. png, webp, pdf, svg, etc.</p>
          
          <BlogImage src={exportImage} alt="Export" size="medium" className="mx-auto my-6" />
          
          <p>In Nextcloud kannst du die Draw.io Datei aber auch über die normale „Teilen-Funktion" mit anderen Leuten teilen.</p>
          
          <BlogImage src={teilenImage} alt="Teilen" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Zusammenfassung</BlogHeading>
          
          <p>Mit Draw.io kannst du Whiteboards und Diagramme direkt in deiner Nextcloud erstellen. Dank der vielfältigen Gestaltungs- und Exportmöglichkeiten hast du große Freiheiten bei der Umsetzung deiner Projekte.</p>
          
          <BlogSection title="Open Source Software im Onlineshop">
            <p>Entdecke unsere große Auswahl an Open Source Software in unsrem Onlineshop. Du kannst fertige Pakete auswählen oder dein ganz individuelles Paket selbst zusammenstellen.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Draw.io",
      formattedDate: "4. März 2024",
      excerpt: "Draw.io – Diagramme und Whiteboard Mit der Anwendung Draw.io können Diagramme und Whiteboards erstellt werden. Dieser Artikel zeigt, wie das Tool verwendet und in Nextcloud integriert werden kann.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Draw.io",
      formattedDate: "March 4, 2024",
      excerpt: "Draw.io – Diagrams and Whiteboard. With the Draw.io application, diagrams and whiteboards can be created. This article shows how the tool can be used and integrated into Nextcloud.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Draw.io - Diagrams and Whiteboard</h1>
          
          <p>With the Draw.io application, diagrams and whiteboards can be created. This article shows how the tool can be used and integrated into Nextcloud.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Draw.io",
      formattedDate: "4 March 2024",
      excerpt: "Draw.io – Diagrams and Whiteboard. With the Draw.io application, diagrams and whiteboards can be created. This article shows how the tool can be used and integrated into Nextcloud.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;