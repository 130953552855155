// src/data/blogPosts/index.js
import nextcloud_whiteboard_kreatives_arbeiten_in_der_cloud from './nextcloud-whiteboard-kreatives-arbeiten-in-der-cloud';
import paperless_ngx_oauth2_fur_microsoft365_einrichten from './paperless-ngx-oauth2-fur-microsoft365-einrichten';
import mailcow_dns_einstellungen from './mailcow-dns-einstellungen';
import mailcow_postfach_migrieren from './mailcow-postfach-migrieren';
import nextcloud_kalender_und_aufgaben_in_thunderbird_integrieren from './nextcloud-kalender-und-aufgaben-in-thunderbird-integrieren';
import e_mail_account_in_paperless_ngx_einbinden from './e-mail-account-in-paperless-ngx-einbinden';
import nextcloud_benutzer_hinzufugen from './nextcloud-benutzer-hinzufugen';
import wechsel_von_einer_nextcloud_instanz_zu_einer_anderen from './wechsel-von-einer-nextcloud-instanz-zu-einer-anderen';
import gitlab_all_in_one_devops_plattform from './gitlab-all-in-one-devops-plattform';
import netbox_das_tool_zur_verwaltung_von_netzwerkinfrastruktur from './netbox-das-tool-zur-verwaltung-von-netzwerkinfrastruktur';
import thunderbird_e_mail_verschlusseln_und_signieren from './thunderbird-e-mail-verschlusseln-und-signieren';
import cname_record from './cname-record';
import civicrm_das_open_source_crm_fur_organisationen from './civicrm-das-open-source-crm-fur-organisationen';
import heliboard from './heliboard';
import nextcloud_recognize from './nextcloud-recognize';
import mediadc_duplikate_von_bildern_und_videos_finden from './mediadc-duplikate-von-bildern-und-videos-finden';
import libresign_elektronische_signatur from './libresign-elektronische-signatur';
import nextcloud_und_paperless_ngx_im_multiuser_betrieb from './nextcloud-und-paperless-ngx-im-multiuser-betrieb';
import paperless_ngx from './paperless-ngx';
import nextcloud_deck_kanban_board from './nextcloud-deck-kanban-board';
import organic_maps from './organic-maps';
import open_source_software from './open-source-software';
import nextcloud_memories from './nextcloud-memories';
import nextcloud_kalender_in_ubuntu_und_windows_einbinden from './nextcloud-kalender-in-ubuntu-und-windows-einbinden';
import prime_mover_wordpress_einfach_umziehen from './prime-mover-wordpress-einfach-umziehen';
import draw_io from './draw-io';
import nextcloud from './nextcloud';
import mailcow_e_mail_server from './mailcow-e-mail-server';
import kimai_elektronische_zeiterfassung from './kimai-elektronische-zeiterfassung';
import keepassdx_android_app from './keepassdx-android-app';
import stirling_pdf from './stirling-pdf';
import nextcloud_und_openproject_integration from './nextcloud-und-openproject-integration';
import openproject_2 from './openproject-2';
import was_bedeutet_hosting from './was-bedeutet-hosting';
import nextcloud_forms_umfragen_sicher_leicht_erstellen from './nextcloud-forms-umfragen-sicher-leicht-erstellen';
import nextcloud_e_mail_signatur from './nextcloud-e-mail-signatur';
import wordpress from './wordpress';
import nextcloud_account_absichern from './nextcloud-account-absichern';
import nextcloud_keeweb from './nextcloud-keeweb';
import libreoffice from './libreoffice';
import wechsel_zu_pathconnect from './wechsel-zu-pathconnect';
import nextcloud_user_migration_app from './nextcloud-user-migration-app';
import nextcloud_umfragen_polls from './nextcloud-umfragen-polls';
import nextcloud_kollektiv from './nextcloud-kollektiv';
import nextcloud_office_einrichten from './nextcloud-office-einrichten';
import nextcloud_ai from './nextcloud-ai';
import buchempfehlung_nextcloud_schnelleinstieg from './buchempfehlung-nextcloud-schnelleinstieg';
import nextcloud_federation_share from './nextcloud-federation-share';
import baume_pflanzen_klimaschutz from './baume-pflanzen-klimaschutz';
import user_story_handwerkersbetrieb from './user-story-handwerkersbetrieb';
import nextcloud_notes from './nextcloud-notes';
import fairemail from './fairemail';
import zwei_faktor_authentifizierung from './zwei_faktor_authentifizierung';
import android_app_newpipe from './android-app-newpipe';
import nextcloud_photos_app_fuer_android from './nextcloud-photos-app-fuer-android';
import die_ersten_schritte_mit_nextcloud from './die-ersten-schritte-mit-nextcloud';
import nextcloud_e_mail from './nextcloud-e-mail';
import nextcloud_office from './nextcloud-office';
import user_story_viktor_geht_auf_reisen from './user-story-viktor-geht-auf-reisen';
import thunderbird from './thunderbird';
import pathconnect_und_nextcloud from './pathconnect-und-nextcloud';
import user_story_anna_studentin from './user-story-anna-studentin';
import privates_surfen_im_internet from './privates-surfen-im-internet';
import keepassxc from './keepassxc';
import nextcloud_kalender from './nextcloud-kalender';
import nextcloud_talk from './nextcloud-talk';
import nextcloud_android from './nextcloud-android';
import mastodon from './mastodon';
import nextcloud_office_mit_collabora from './nextcloud-office-mit-collabora';
import cryptomator from './cryptomator';
import cloud_act_was_steckt_dahinter from './cloud-act-was-steckt-dahinter';
import buchempfehlung_dann_haben_die_halt_meine_daten_na_un from './buchempfehlung-dann-haben-die-halt-meine-daten-na-un';
import kalender_und_kontakt_mit_android_synchronisieren from './kalender-und-kontakt-mit-android-synchronisieren';
import kalender_und_kontakt_synchronisieren from './kalender-und-kontakt-synchronisieren';
import dsgvo from './dsgvo';
import nextcloud_news from './nextcloud-news';

// Array of all blog posts, sorted by date (newest first)
const allBlogPosts = [
  nextcloud_whiteboard_kreatives_arbeiten_in_der_cloud,
  paperless_ngx_oauth2_fur_microsoft365_einrichten,
  mailcow_dns_einstellungen,
  mailcow_postfach_migrieren,
  nextcloud_kalender_und_aufgaben_in_thunderbird_integrieren,
  e_mail_account_in_paperless_ngx_einbinden,
  nextcloud_benutzer_hinzufugen,
  wechsel_von_einer_nextcloud_instanz_zu_einer_anderen,
  gitlab_all_in_one_devops_plattform,
  netbox_das_tool_zur_verwaltung_von_netzwerkinfrastruktur,
  thunderbird_e_mail_verschlusseln_und_signieren,
  cname_record,
  civicrm_das_open_source_crm_fur_organisationen,
  heliboard,
  nextcloud_recognize,
  mediadc_duplikate_von_bildern_und_videos_finden,
  libresign_elektronische_signatur,
  nextcloud_und_paperless_ngx_im_multiuser_betrieb,
  paperless_ngx,
  nextcloud_deck_kanban_board,
  organic_maps,
  open_source_software,
  nextcloud_memories,
  nextcloud_kalender_in_ubuntu_und_windows_einbinden,
  prime_mover_wordpress_einfach_umziehen,
  draw_io,
  nextcloud,
  mailcow_e_mail_server,
  kimai_elektronische_zeiterfassung,
  keepassdx_android_app,
  stirling_pdf,
  nextcloud_und_openproject_integration,
  openproject_2,
  was_bedeutet_hosting,
  nextcloud_forms_umfragen_sicher_leicht_erstellen,
  nextcloud_e_mail_signatur,
  wordpress,
  nextcloud_account_absichern,
  nextcloud_keeweb,
  libreoffice,
  wechsel_zu_pathconnect,
  nextcloud_user_migration_app,
  nextcloud_umfragen_polls,
  nextcloud_kollektiv,
  nextcloud_office_einrichten,
  nextcloud_ai,
  buchempfehlung_nextcloud_schnelleinstieg,
  nextcloud_federation_share,
  baume_pflanzen_klimaschutz,
  user_story_handwerkersbetrieb,
  nextcloud_notes,
  fairemail,
  zwei_faktor_authentifizierung,
  android_app_newpipe,
  nextcloud_photos_app_fuer_android,
  die_ersten_schritte_mit_nextcloud,
  nextcloud_e_mail,
  nextcloud_office,
  user_story_viktor_geht_auf_reisen,
  thunderbird,
  pathconnect_und_nextcloud,
  user_story_anna_studentin,
  privates_surfen_im_internet,
  keepassxc,
  nextcloud_kalender,
  nextcloud_talk,
  nextcloud_android,
  mastodon,
  nextcloud_office_mit_collabora,
  cryptomator,
  cloud_act_was_steckt_dahinter,
  buchempfehlung_dann_haben_die_halt_meine_daten_na_un,
  kalender_und_kontakt_mit_android_synchronisieren,
  kalender_und_kontakt_synchronisieren,
  dsgvo,
  nextcloud_news
].sort((a, b) => new Date(b.date) - new Date(a.date));

export default allBlogPosts;