// src/data/blogPosts/nextcloud-deck-kanban-board.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-deck-kanban-board-featured.png';
import deckLogo from '../../assets/images/blog/2024/04/Nextcloud-Deck.png';
import kanbanImage from '../../assets/images/blog/2024/04/Kanban.png';
import deckInstallImage from '../../assets/images/blog/2024/04/deck-installieren.png';
import boardAddImage from '../../assets/images/blog/2024/04/Board-hinzufuegen.png';
import listAddImage from '../../assets/images/blog/2024/04/Liste-hinzufuegen.png';
import projektKanbanImage from '../../assets/images/blog/2024/04/Projekt-Kanban.png';
import cardOptionsImage from '../../assets/images/blog/2024/04/Kartenoptionen.png';
import cardDetailsImage from '../../assets/images/blog/2024/04/Kartendetails.png';
import boardShareImage from '../../assets/images/blog/2024/04/Board-teilen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Deck Kanban Board
 */
const post = {
  id: '19842',
  slug: 'nextcloud-deck-kanban-board',
  
  // Base data - same regardless of language
  date: "2024-04-23T06:56:08.000Z",
  readTime: 12,
  categories: ["Empfehlungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 37,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Deck – Kanban Board",
      formattedDate: "23. April 2024",
      excerpt: "Nextcloud Deck ist ein Kanban Board, mit dem du sowohl geschäftliche als auch private Projekte planen und umsetzen kannst. In diesem Artikel erklären wir dir, was Kanban ist und wie du die Anwendung in Nextcloud nutzen kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Deck - Kanban Board</h1>
          
          <p>Nextcloud Deck ist ein Kanban Board, mit dem du sowohl geschäftliche als auch private Projekte planen und umsetzen kannst. In diesem Artikel erklären wir dir, was Kanban ist und wie du die Anwendung in Nextcloud nutzen kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={deckLogo} alt="Nextcloud Deck Logo" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Kanaban</BlogHeading>
          
          <p>Kanban ist eine agile Methode zur Umsetzung von Projekten. Das Kanban Board eignet sich aber auch sehr gut für alltägliche Aufgaben, die keinem spezifischen Projekt zugeordnet sind. Ähnlich einer To-Do-Liste werden Karten mit Aufgaben erstellt. Ein Kanban Board besteht aus mehreren Listen, die üblicherweise Backlog, To-Do, In-Progress und Done genannt werden. Im Backlog werden alle zu erledigenden Aufgabenkarten abgelegt. In To-Do kommen die Karten, die aktuell zu erledigen sind. Werden Aufgaben gerade bearbeitet, werden sie nach „In Progress" verschoben. Sobald die Aufgabe erledigt ist, kann die Karte in die Spalte „Done" verschoben werden.</p>
          
          <BlogImage src={kanbanImage} alt="Nextcloud Deck Kanban" size="large" className="my-6" />
          
          <BlogHeading>Installation von Nextcloud Deck</BlogHeading>
          
          <p>Um die App in deiner Nextcloud zu installieren, melde dich als Administrator an, klicke auf das Icon in der rechten oberen Ecke und wähle „Apps".<br />In der Suche kannst du nun „Deck" eingeben und die App wird dir direkt angezeigt. Klicke dann auf „Herunterladen & aktivieren".</p>
          
          <BlogImage src={deckInstallImage} alt="Nextcloud Deck installieren" size="large" className="my-6" />
          
          <BlogHeading>Einrichtung des Kanban Boards</BlogHeading>
          
          <p>Zuerst wird ein neues Kanaban-Board erstellt. Klicke dazu auf „Board hinzufügen" und gib einen Namen ein.</p>
          
          <BlogImage src={boardAddImage} alt="Nextcloud Deck Board hinzufügen" size="medium" className="mx-auto my-6" />
          
          <p>Nachdem das Board erstellt wurde, werden die oben erwähnten Listen erstellt. Hier wird der Name der ersten Liste eingegeben.</p>
          
          <BlogImage src={listAddImage} alt="Nextcloud Deck - Liste hinzufügen" size="medium" className="mx-auto my-6" />
          
          <p>Um weitere Listen hinzuzufügen, klicke auf das „+" in der rechten oberen Ecke und gib den Namen der nächsten Liste ein.</p>
          <p>Nun können die Aufgabenkarten erstellt werden. Dazu klickt man auf das „+" in der Liste und vergibt einen Aufgabennamen.</p>
          
          <BlogImage src={projektKanbanImage} alt="Nextcloud Deck Projekt Kanban" size="large" className="my-6" />
          
          <BlogHeading>Kartenmenü</BlogHeading>
          
          <p>Wenn du auf die drei Punkte in einer Karte klickst, öffnet sich ein kleines Menü. Hier hast du die Möglichkeit, die Kartendetails anzuzeigen, die Karte dir zuzuordnen, sie als erledigt zu markieren, die Karte zu verschieben, in einem Nextcloud Talk Chat zu posten, die Karte zu archivieren oder zu löschen.</p>
          
          <BlogImage src={cardOptionsImage} alt="Nextcloud Deck Kartenoptionen" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Kartendetails</BlogHeading>
          
          <p>In den Kartendetails kannst du z.B. den Handlungsbedarf für eine Karte mit einem Schlagwort und einem Fälligkeitsdatum festlegen. Mit einer Beschreibung kannst du weitere Informationen hinzufügen. Du kannst der Karte auch Anhänge wie Bilder oder Dokumente hinzufügen. Durch Kommentare der Bearbeiter kann der Verlauf der Aufgabe nachvollzogen werden.</p>
          
          <BlogImage src={cardDetailsImage} alt="Nextcloud Deck - Kartendetails" size="large" className="my-6" />
          
          <BlogHeading>Kanban Board teilen</BlogHeading>
          
          <p>Du kannst das Kanban Board freigeben, damit auch andere Benutzer Einträge erstellen oder bearbeiten können. Klicke dazu auf die drei Striche oben rechts und gib den gewünschten Benutzernamen oder die gewünschte Gruppe an.</p>
          
          <BlogImage src={boardShareImage} alt="Nextcloud Deck Board teilen" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Nextcloud Deck auf dem Smartphone</BlogHeading>
          
          <p>Es gibt auch eine App für Smartphones, mit der du dein Kanban Board auch unterwegs verwalten kannst. Wenn du bereits die Nextcloud App installiert hast, kannst du einfach einen bestehenden Account für die Nextcloud Deck App auswählen. In der App stehen dir die gleichen Funktionen wie im Webinterface zur Verfügung.</p>
          <p>
            <a href="https://play.google.com/store/apps/details?id=it.niedermann.nextcloud.deck.play" target="_blank" rel="noopener">PlayStore</a><br />
            <a href="https://f-droid.org/de/packages/it.niedermann.nextcloud.deck/" target="_blank" rel="noopener">F-Droid</a><br />
            <a href="https://apps.apple.com/app/nextcloud-deck/id1570892788" target="_blank" rel="noopener">AppStore</a>
          </p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            

          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Deck – Kanban Board",
      formattedDate: "23. April 2024",
      excerpt: "Nextcloud Deck ist ein Kanban Board, mit dem du sowohl geschäftliche als auch private Projekte planen und umsetzen kannst. In diesem Artikel erklären wir dir, was Kanban ist und wie du die Anwendung in Nextcloud nutzen kannst.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Deck – Kanban Board",
      formattedDate: "April 23, 2024",
      excerpt: "Nextcloud Deck is a Kanban board with which you can plan and implement both business and private projects. In this article, we explain what Kanban is and how you can use the application in Nextcloud.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Deck - Kanban Board</h1>
          
          <p>Nextcloud Deck is a Kanban board with which you can plan and implement both business and private projects. In this article, we explain what Kanban is and how you can use the application in Nextcloud.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Deck – Kanban Board",
      formattedDate: "23 April 2024",
      excerpt: "Nextcloud Deck is a Kanban board with which you can plan and implement both business and private projects. In this article, we explain what Kanban is and how you can use the application in Nextcloud.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;