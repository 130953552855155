// src/data/blogPosts/cname-record.js

// Import images
import nextcloudLogo from '../../assets/images/blog/2023/11/nextcloud-logo.png';
import thumbnailImage from '../../assets/images/blog/cname-record-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about CNAME records
 */
const post = {
  id: '20758',
  slug: 'cname-record',
  
  // Base data - same regardless of language
  date: "2024-07-26T10:05:23.000Z",
  readTime: 11,
  categories: ["Anleitungen", "Informationen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 21,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Canonical Name (CNAME) Records",
      formattedDate: "26. Juli 2024",
      excerpt: "Ein CNAME-Record ist eine effiziente Möglichkeit, Subdomains flexibel zu verwalten, ohne direkt IP-Adressen bearbeiten zu müssen. In diesem Artikel erläutern wir die relevanten Begriffe, beleuchten die Vorteile von CNAME-Records und zeigen, wie diese speziell bei PathConnect eingesetzt werden können.",
      content: () => (
        <BlogContent>
          <p>Ein CNAME-Record ist eine effiziente Möglichkeit, Subdomains flexibel zu verwalten, ohne direkt IP-Adressen bearbeiten zu müssen. In diesem Artikel erläutern wir die relevanten Begriffe, beleuchten die Vorteile von CNAME-Records und zeigen, wie diese speziell bei PathConnect eingesetzt werden können.</p>
          
          <BlogHeading>Begriffserklärungen</BlogHeading>
          
          <p>Bevor wir uns den spezifischen Vorteilen und Anwendungen von CNAME-Records zuwenden, hier einige grundlegende Begriffe.</p>
          
          <ul>
            <li>
              <p><strong>DNS (Domain Name System):</strong> Das DNS funktioniert ähnlich wie ein Telefonbuch im Internet, bei dem jeder Domainname mit einer IP-Adresse verknüpft ist. Dadurch können Benutzer einfach den Namen einer Website wie pathconnect.de eingeben, anstatt sich eine komplexe IP-Adresse merken zu müssen.</p>
            </li>
            <li>
              <p><strong>IP-Adresse:</strong> Eine IP-Adresse ist eine eindeutige Adresse, die einem Gerät im Internet zugewiesen wird, um es identifizierbar und erreichbar zu machen.</p>
            </li>
            <li>
              <p><strong>Domain und Subdomain:</strong> Eine Domain ist der Hauptname einer Website, z.B. pathconnect.de. Eine Subdomain ist eine Erweiterung der Hauptdomain, die bestimmte Inhalte oder Dienste beherbergen kann, z.B. test.pathconnect.de für die Nextcloud Testinstanz.</p>
            </li>
          </ul>
          
          <BlogHeading>CNAME (Canonical Name)</BlogHeading>
          
          <p>Ein CNAME-Record ermöglicht die Verwendung einer Subdomain als Alias für eine spezifischere Serveradresse. Dies ist besonders vorteilhaft für Unternehmen, die Cloud-Dienste wie Nextcloud nutzen und ihre eigenen Domains für den Zugriff verwenden möchten. Die Verwendung eines CNAME-Records bietet erhebliche Flexibilität und vereinfacht die Verwaltung, da Änderungen der Server-IP vom Diensteanbieter verwaltet werden können, ohne dass der Kunde seine DNS-Einstellungen anpassen muss.</p>
          
          <BlogHeading>Praktisches Beispiel für den Einsatz von CNAME-Records bei PathConnect</BlogHeading>
          
          <p>Ein Kunde mietet eine Nextcloud-Lösung von PathConnect und möchte seine eigene Domain <em>firma24.de</em> verwenden, um auf seine Cloud-Dienste zuzugreifen. Er beschließt, die Subdomain <em>cloud.firma24.de</em> einzurichten, um seinen Nutzern einen direkten Zugang zu ermöglichen.</p>
          <p>Anstatt eine IP-Adresse in einem A-Record anzugeben, erstellt der Kunde einen CNAME-Record für <em>cloud.firma24.de</em> der auf die PathConnect-spezifische Adresse nc-x.pathconnect.de verweist. Durch diese Konfiguration werden alle Anfragen an <em>cloud.firma24.de</em> transparent auf nc-x.pathconnect.de umgeleitet. Sollte sich die IP-Adresse von nc-x.pathconnect.de aus technischen Gründen ändern, muss der Kunde keine Änderungen an seiner DNS-Konfiguration vornehmen, da PathConnect diese Änderungen direkt auf seiner Seite vornimmt.</p>
          
          <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Vorteile von CNAME-Records</BlogHeading>
          
          <ul>
            <li>
              <p><u>Einfache Verwaltung</u><br />Vermeidet die Notwendigkeit, IP-Adressen für jede Subdomain direkt zu aktualisieren.</p>
            </li>
            <li>
              <p><u>Flexibilität</u><br />Ermöglicht schnelle Änderungen der Zieladresse durch einfache Aktualisierung des CNAME-Records auf Providerseite.</p>
            </li>
            <li>
              <p><u>Reduzierter Wartungsaufwand</u><br />Minimiert Fehlerquellen und den Verwaltungsaufwand für den Endnutzer.</p>
            </li>
          </ul>
          
          <BlogHeading>Einrichtung eines CNAME-Records bei deinem Domain-Provider</BlogHeading>
          
          <p>Die Einrichtung eines CNAME-Records bei einem Domain-Provider wie Strato ist einfach und kann selbst vorgenommen werden. Hier sind die Schritte, die du befolgen solltest:</p>
          
          <p><u>Domainverwaltung</u> <br />Navigiere im Dashboard zu dem Bereich, in dem du deine Domains verwalten kannst. Dieser Bereich ist oft unter „Domain & Hosting" oder einem ähnlich benannten Reiter zu finden.</p>
          
          <p><u>DNS-Einstellungen</u><br />Wähle die Subdomain aus, für die du einen CNAME-Record einrichten möchtest. Suche dann nach der Option "DNS-Einstellungen verwalten" oder "DNS-Konfiguration".</p>
          
          <p><u>Füge einen CNAME-Record hinzu</u><br />In den DNS-Einstellungen findest du in der Regel eine Option zum Hinzufügen neuer DNS-Records. Wähle hier „CNAME" als Typ des Records aus.</p>
          
          <p>Gib den CNAME-Record ein, der dir mitgeteilt wurde. Zum Beispiel nc-200.pathconnect.de.</p>
          
          <p><span style={{ textDecoration: "underline" }}>Hinweis</span><br />Beachten, dass auch der Punkt am Ende angegeben werden muss und dass keine anderen Records (wie A-Records oder TXT-Records) mit dem gleichen Namen wie der CNAME existieren, da dies zu Konflikten führen kann.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Canonical Name (CNAME) Records",
      formattedDate: "26. Juli 2024",
      excerpt: "Ein CNAME-Record ist eine effiziente Möglichkeit, Subdomains flexibel zu verwalten, ohne direkt IP-Adressen bearbeiten zu müssen. In diesem Artikel erläutern wir die relevanten Begriffe, beleuchten die Vorteile von CNAME-Records und zeigen, wie diese speziell bei PathConnect eingesetzt werden können.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Canonical Name (CNAME) Records",
      formattedDate: "July 26, 2024",
      excerpt: "A CNAME record is an efficient way to manage subdomains flexibly without having to edit IP addresses directly. In this article, we explain the relevant terms, highlight the benefits of CNAME records, and show how they can be used specifically with PathConnect.",
      content: () => (
        <BlogContent>
          <p>A CNAME record is an efficient way to manage subdomains flexibly without having to edit IP addresses directly. In this article, we explain the relevant terms, highlight the benefits of CNAME records, and show how they can be used specifically with PathConnect.</p>
          
          <BlogHeading>Term Explanations</BlogHeading>
          
          <p>Before we turn to the specific advantages and applications of CNAME records, here are some basic terms.</p>
          
          <ul>
            <li>
              <p><strong>DNS (Domain Name System):</strong> The DNS works similar to a phone book on the internet, where each domain name is linked to an IP address. This allows users to simply enter the name of a website like pathconnect.de instead of having to remember a complex IP address.</p>
            </li>
            <li>
              <p><strong>IP Address:</strong> An IP address is a unique address assigned to a device on the internet to make it identifiable and accessible.</p>
            </li>
            <li>
              <p><strong>Domain and Subdomain:</strong> A domain is the main name of a website, e.g., pathconnect.de. A subdomain is an extension of the main domain that can host specific content or services, e.g., test.pathconnect.de for the Nextcloud test instance.</p>
            </li>
          </ul>
          
          <BlogHeading>CNAME (Canonical Name)</BlogHeading>
          
          <p>A CNAME record enables the use of a subdomain as an alias for a more specific server address. This is particularly advantageous for companies that use cloud services like Nextcloud and want to use their own domains for access. Using a CNAME record provides significant flexibility and simplifies management, as changes to the server IP can be managed by the service provider without the customer having to adjust their DNS settings.</p>
          
          <BlogHeading>Practical Example for Using CNAME Records with PathConnect</BlogHeading>
          
          <p>A customer rents a Nextcloud solution from PathConnect and wants to use their own domain <em>company24.com</em> to access their cloud services. They decide to set up the subdomain <em>cloud.company24.com</em> to provide their users with direct access.</p>
          <p>Instead of specifying an IP address in an A record, the customer creates a CNAME record for <em>cloud.company24.com</em> that points to PathConnect's specific address nc-x.pathconnect.de. With this configuration, all requests to <em>cloud.company24.com</em> are transparently redirected to nc-x.pathconnect.de. If the IP address of nc-x.pathconnect.de changes for technical reasons, the customer does not need to make any changes to their DNS configuration, as PathConnect makes these changes directly on their side.</p>
          
          <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Benefits of CNAME Records</BlogHeading>
          
          <ul>
            <li>
              <p><u>Easy Management</u><br />Avoids the need to directly update IP addresses for each subdomain.</p>
            </li>
            <li>
              <p><u>Flexibility</u><br />Enables quick changes to the target address by simply updating the CNAME record on the provider side.</p>
            </li>
            <li>
              <p><u>Reduced Maintenance Effort</u><br />Minimizes sources of error and administrative effort for the end user.</p>
            </li>
          </ul>
          
          <BlogHeading>Setting Up a CNAME Record with Your Domain Provider</BlogHeading>
          
          <p>Setting up a CNAME record with a domain provider like Strato is simple and can be done yourself. Here are the steps you should follow:</p>
          
          <p><u>Domain Management</u> <br />Navigate in the dashboard to the area where you can manage your domains. This area is often found under "Domain & Hosting" or a similarly named tab.</p>
          
          <p><u>DNS Settings</u><br />Select the subdomain for which you want to set up a CNAME record. Then look for the "Manage DNS Settings" or "DNS Configuration" option.</p>
          
          <p><u>Add a CNAME Record</u><br />In the DNS settings, you usually find an option to add new DNS records. Select "CNAME" as the type of record here.</p>
          
          <p>Enter the CNAME record that was given to you. For example, nc-200.pathconnect.de.</p>
          
          <p><span style={{ textDecoration: "underline" }}>Note</span><br />Note that the period at the end must also be specified and that no other records (such as A records or TXT records) exist with the same name as the CNAME, as this can lead to conflicts.</p>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Canonical Name (CNAME) Records",
      formattedDate: "July 26, 2024",
      excerpt: "A CNAME record is an efficient way to manage subdomains flexibly without having to edit IP addresses directly. In this article, we explain the relevant terms, highlight the benefits of CNAME records, and show how they can be used specifically with PathConnect.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;