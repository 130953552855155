// src/data/blogPosts/nextcloud-office.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-office-featured.png';
import collaboraOnlineImage from '../../assets/images/blog/2022/09/collabora-online.png';
import nextcloudLogoImage from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg-1024x726.png';
import cloudIcon from '../../assets/images/blog/2023/01/iconmonstr-cloud-21-240.png';
import chatShareImage from '../../assets/images/blog/2023/01/Datei-im-Chat-teilen.png';
import newDocumentImage from '../../assets/images/blog/2023/01/Neues-Dokument-erstellen.png';
import wordDocumentImage from '../../assets/images/blog/2023/01/Word-Dokument.png';
import shareImage from '../../assets/images/blog/2023/01/Teilen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Office
 */
const post = {
  id: '10435',
  slug: 'nextcloud-office',
  
  // Base data - same regardless of language
  date: "2023-01-20T12:05:56.000Z",
  readTime: 10,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 15,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Office",
      formattedDate: "20. Januar 2023",
      excerpt: "In diesem Blogbeitrag geht es um die Office App in Nextcloud. Wir erklären, wie Collabora Online und Nextcloud zusammenhängen, wie die Office App verwendet wird und welche Vorteile sie bietet.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Office (Collaboara Online)</h1>
          
          <p>Mit Collabora Online bekommst du ein komplettes Office-Paket direkt in deine Nextcloud integriert. Egal ob Dokumente, Tabellen oder Präsentationen. Dank der gemeinsamen Bearbeitung kannst du auch bequem im Team arbeiten.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={collaboraOnlineImage} alt="Collabora Online" size="large" />
            </div>
            
          </div>
          
          <p>In diesem Blogbeitrag geht es um die Office-App in Nextcloud. Wir erklären dir, wie Collabora Online und Nextcloud zusammenhängen, wie die Office App verwendet wird und welche Vorteile du dadurch hast.</p>
          
          <BlogHeading level={3}>Collobara und Nextcloud</BlogHeading>
          
          <p>Um den Zusammenhang zu verstehen, hier eine kurze Erklärung. <br />Collabora bietet die Open Source Office-Lösung „Collabora Online" für den Einsatz in der Cloud an. <br />Die in Nextcloud integrierte Office-Anwendung nutzt diese Software. Wenn du also ein Dokument in deiner Nextcloud öffnen möchtest, wird auf einen Server zugegriffen, auf dem die Collabora Online Software läuft. <br />Bei PathConnect kannst du eine Lizenz für Collabora Online erwerben. Deine Nextcloud bekommt dann Zugriff auf den Collabora Online Server und du kannst deine Dokumente in der Cloud öffnen und bearbeiten. Für den restlichen Artikel verwenden wir den Begriff „Nextcloud Office".</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={collaboraOnlineImage} alt="Collabora Online" size="large" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={nextcloudLogoImage} alt="Nextcloud Logo" size="large" />
            </div>
          </div>
          
          <BlogHeading level={3}>Office Anwendung in der Cloud</BlogHeading>
          
          <p>Mit Nextcloud Office erhältst du eine komplette Office-Anwendung in der Cloud. Enthalten sind Dokumente, Tabellen, Präsentationen und Diagramme. Nextcloud Office kann sowohl auf dem Desktop als auch auf Smartphones und Tablets genutzt werden. Um Dokumente zu bearbeiten, müssen diese nicht heruntergeladen werden, sondern lassen sich direkt in deiner Nextcloud öffnen.<br />Für die Zusammenarbeit im Team können Dokumente einfach geteilt und gemeinsam bearbeitet werden.</p>
          
          <BlogImage src={cloudIcon} alt="Cloud Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Integration in Nextcloud Talk</BlogHeading>
          
          <p>Mit der Integration in Nextcloud Talk können Dokumente auch direkt im Chat erstellt und geteilt werden. Das ist besonders praktisch, wenn du im Chat oder Videoanruf schnell ein Dokument zur Verfügung haben möchtest, auf das alle Teilnehmer zugreifen können.</p>
          
          <BlogImage src={chatShareImage} alt="Datei im Chat teilen" size="large" className="my-6" />
          
          <BlogHeading level={3}>Verwendung von Nextcloud Office</BlogHeading>
          
          <p>Um ein neues Dokument zu erstellen, klickst du einfach auf den Button mit dem „Plus" in der Nextcloud-Weboberfläche und wählst den gewünschten Dokumententyp aus.</p>
          
          <BlogImage src={newDocumentImage} alt="Neues Dokument erstellen" size="medium" className="mx-auto my-6" />
          
          <p>Je nach Dokumententyp stehen dir unterschiedliche Bearbeitungsmöglichkeiten zur Verfügung. Jeder, der schon einmal mit Microsoft Office gearbeitet hat, wird sich hier zurechtfinden.</p>
          
          <BlogImage src={wordDocumentImage} alt="Word Dokument in Nextcloud Office" size="large" className="my-6" />
          
          <p>Um ein Dokument zu teilen, kannst du oben rechts auf das Symbol mit der Person klicken. Hier hast du dann die Möglichkeit, das Dokument direkt mit einem Nutzer deiner Nextcloud zu teilen oder einen Link zu erstellen. <br />Auch Aktivitäten und Kommentare können hier eingesehen werden. Dank der Versionsverwaltung können auch frühere Zustände des Dokuments wiederhergestellt werden.</p>
          
          <BlogImage src={shareImage} alt="Dokument teilen" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Office",
      formattedDate: "20. Januar 2023",
      excerpt: "In diesem Blogbeitrag geht es um die Office App in Nextcloud. Wir erklären, wie Collabora Online und Nextcloud zusammenhängen, wie die Office App verwendet wird und welche Vorteile sie bietet.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Office",
      formattedDate: "January 20, 2023",
      excerpt: "This blog post is about the Office app in Nextcloud. We explain how Collabora Online and Nextcloud are connected, how the Office app is used, and what benefits it offers.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Office (Collabora Online)</h1>
          
          <p>With Collabora Online, you get a complete office package directly integrated into your Nextcloud. Whether documents, spreadsheets, or presentations. Thanks to collaborative editing, you can also work comfortably as a team.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Office",
      formattedDate: "20 January 2023",
      excerpt: "This blog post is about the Office app in Nextcloud. We explain how Collabora Online and Nextcloud are connected, how the Office app is used, and what benefits it offers.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;