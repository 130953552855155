// src/data/blogPosts/nextcloud.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-featured.png';
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import flexibilityImage from '../../assets/images/blog/2024/01/opensourcegenerator-scaled.webp';
import securityIcon from '../../assets/images/blog/2023/10/iconmonstr-lock-31-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud
 */
const post = {
  id: '16454',
  slug: 'nextcloud',
  
  // Base data - same regardless of language
  date: "2024-02-12T12:49:29.000Z",
  readTime: 6,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 22,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud",
      formattedDate: "12. Februar 2024",
      excerpt: "Nextcloud ist eine vielseitige Open-Source-Plattform, die weit mehr bietet als nur Cloud-Speicher. Sie zeichnet sich durch Flexibilität und umfangreiche Erweiterungsmöglichkeiten aus und ist ideal für die individuelle Nutzung oder für Teams.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud</h1>
          
          <p>Nextcloud ist eine vielseitige Open-Source-Plattform, die weit mehr bietet als nur Cloud-Speicher. Sie zeichnet sich durch Flexibilität und umfangreiche Erweiterungsmöglichkeiten aus und ist ideal für die individuelle Nutzung oder für Teams.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Flexibilität und Anpassung</BlogHeading>
          
          <p>Die Cloud die sich deinen Bedürfnissen anpasst. Sie bietet eine breite Palette an Anwendungen und Integrationen, die weit über das einfache Speichern von Dateien hinausgehen. Du kannst deine Cloud-Umgebung ganz nach deinen Wünschen gestalten, ob für den persönlichen Gebrauch oder im Team. Von kollaborativen Tools bis hin zu spezifischen Anpassungen.</p>
          
          <BlogImage src={flexibilityImage} alt="Nextcloud Flexibilität" size="large" className="my-6" />
          
          <BlogHeading>Sicherheit & Datenschutz</BlogHeading>
          
          <p>Mit Nextcloud hast du die Gewissheit, dass deine Daten sicher und geschützt sind. Mit Funktionen wie Ende-zu-Ende-Verschlüsselung und individuell anpassbaren Zugriffsrechten setzt Nextcloud hohe Standards in Sachen Sicherheit und Datenschutz. Du behältst stets die Kontrolle über deine Informationen und kannst darauf vertrauen, dass deine Daten privat und sicher aufbewahrt werden.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={securityIcon} alt="Sicherheits-Icon" size="small" />
          </div>
          
          <BlogHeading>Funktionen in Nextcloud</BlogHeading>
          
          <ul className="list-disc pl-6 mb-6">
            <li>
              <span className="font-bold underline">Dateisynchronisation und -freigabe</span><br />
              Ermöglicht das Hochladen, Speichern, Synchronisieren und Freigeben von Dateien auf verschiedenen Geräten. Benutzer können Dateien gemeinsam nutzen und gemeinsam an ihnen arbeiten.
            </li>
            <li>
              <span className="font-bold underline">Kollaboration</span><br />
              Bietet integrierte Teamarbeitsfunktionen wie Online-Textverarbeitung, Tabellenkalkulation und Präsentationswerkzeuge, die eine gemeinsame Bearbeitung in Echtzeit ermöglichen.
            </li>
            <li>
              <span className="font-bold underline">Kalender & Kontakte</span><br />
              Verwaltung von Terminen und Kontakten direkt in der Plattform, synchronisierbar mit anderen Geräten.
            </li>
            <li>
              <span className="font-bold underline">Kommunikationstools</span><br />
              Integrierte Chat- und Videokonferenzfunktionen erleichtern die Kommunikation im Team.
            </li>
            <li>
              <span className="font-bold underline">Apps</span><br />
              Eine breite Palette von Apps ermöglicht es dem Benutzer, die Funktionalität nach seinen Bedürfnissen zu erweitern.
            </li>
            <li>
              <span className="font-bold underline">Integrationen</span><br />
              Es gibt viele Anwendungen, die integriert werden können. Mit Open Project zum Beispiel können Dateien direkt mit einem Projekt verknüpft werden.
            </li>
          </ul>
          
          <BlogHeading>Nextcloud Hosting</BlogHeading>
          
          <p>Wir kümmern uns um technische Details wie Wartung und Sicherheitsupdates, damit du dich voll und ganz auf dein Datenmanagement und die Zusammenarbeit konzentrieren kannst. Unser Hosting bietet dir eine sichere, stets aktuelle Nextcloud-Umgebung mit erstklassigem Support. Es ist ideal für Teams jeder Größe und wächst mit deinem Unternehmen mit.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud",
      formattedDate: "12. Februar 2024",
      excerpt: "Nextcloud ist eine vielseitige Open-Source-Plattform, die weit mehr bietet als nur Cloud-Speicher. Sie zeichnet sich durch Flexibilität und umfangreiche Erweiterungsmöglichkeiten aus und ist ideal für die individuelle Nutzung oder für Teams.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Nextcloud",
      formattedDate: "February 12, 2024",
      excerpt: "Nextcloud is a versatile open-source platform that offers much more than just cloud storage. It is characterized by flexibility and extensive extension options and is ideal for individual use or for teams.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud</h1>
          
          <p>Nextcloud is a versatile open-source platform that offers much more than just cloud storage. It is characterized by flexibility and extensive extension options and is ideal for individual use or for teams.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Flexibility and Customization</BlogHeading>
          
          <p>The cloud that adapts to your needs. It offers a wide range of applications and integrations that go far beyond simply storing files. You can design your cloud environment entirely according to your wishes, whether for personal use or in a team. From collaborative tools to specific customizations.</p>
          
          <BlogImage src={flexibilityImage} alt="Nextcloud Flexibility" size="large" className="my-6" />
          
          <BlogHeading>Security & Privacy</BlogHeading>
          
          <p>With Nextcloud, you can be sure that your data is secure and protected. With features such as end-to-end encryption and individually customizable access rights, Nextcloud sets high standards in terms of security and data protection. You always maintain control over your information and can trust that your data is kept private and secure.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={securityIcon} alt="Security Icon" size="small" />
          </div>
          
          <BlogHeading>Features in Nextcloud</BlogHeading>
          
          <ul className="list-disc pl-6 mb-6">
            <li>
              <span className="font-bold underline">File Synchronization and Sharing</span><br />
              Enables uploading, storing, synchronizing, and sharing files across different devices. Users can share files and collaborate on them.
            </li>
            <li>
              <span className="font-bold underline">Collaboration</span><br />
              Offers integrated teamwork features such as online word processing, spreadsheets, and presentation tools that enable real-time collaborative editing.
            </li>
            <li>
              <span className="font-bold underline">Calendar & Contacts</span><br />
              Management of appointments and contacts directly within the platform, synchronizable with other devices.
            </li>
            <li>
              <span className="font-bold underline">Communication Tools</span><br />
              Integrated chat and video conferencing features facilitate team communication.
            </li>
            <li>
              <span className="font-bold underline">Apps</span><br />
              A wide range of apps allows users to extend functionality according to their needs.
            </li>
            <li>
              <span className="font-bold underline">Integrations</span><br />
              There are many applications that can be integrated. With Open Project, for example, files can be linked directly to a project.
            </li>
          </ul>
          
          <BlogHeading>Nextcloud Hosting</BlogHeading>
          
          <p>We take care of technical details such as maintenance and security updates so that you can focus fully on your data management and collaboration. Our hosting provides you with a secure, always up-to-date Nextcloud environment with first-class support. It is ideal for teams of any size and grows with your business.</p>
          
          
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud",
      formattedDate: "12 February 2024",
      excerpt: "Nextcloud is a versatile open-source platform that offers much more than just cloud storage. It is characterized by flexibility and extensive extension options and is ideal for individual use or for teams.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;