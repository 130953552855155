// src/data/blogPosts/wordpress.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/wordpress-featured.png';
import wordpressLogo from '../../assets/images/blog/2022/01/WordPress-logotype-wordmark-white.png';
import openSourceShield from '../../assets/images/blog/2021/10/open.png';
import computerIcon from '../../assets/images/blog/2023/10/iconmonstr-computer-2-240.png';
import brickIcon from '../../assets/images/blog/2023/10/iconmonstr-brick-9-240.png';
import securityIcon from '../../assets/images/blog/2022/01/security.png';

/**
 * Blog post about WordPress
 */
const post = {
  id: '13391',
  slug: 'wordpress',
  
  // Base data - same regardless of language
  date: "2023-10-24T15:54:03.000Z",
  readTime: 9,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 21,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "WordPress",
      formattedDate: "24. Oktober 2023",
      excerpt: "Mit der Open Source Software \"WordPress\" kannst du deinen eigenen Blog, Webseite und Onlineshop erstellen. In diesem Artikel erfährst du, was WordPress genau ist und wie du es benutzen kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">WordPress</h1>
          
          <p>Mit der Open Source Software "<strong>WordPress</strong>" kannst du deinen eigenen <strong>Blog, Webseite und Onlineshop</strong> erstellen. In diesem Artikel erfährst du, was WordPress genau ist und wie du es benutzen kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={wordpressLogo} alt="WordPress Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Open Source Software</BlogHeading>
          
          <p>Wie jede Software, die wir dir vorstellen, ist auch WordPress <strong>Open Source</strong>. Das bedeutet, dass der Quellcode der Software öffentlich zugänglich ist und du sie <strong>kostenlos herunterladen und nutzen</strong> kannst. WordPress ist aber nicht nur Open Source, sondern auch <strong>weit verbreitet</strong> und bietet durch <strong>zahlreiche Erweiterungen</strong> viele Möglichkeiten.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={openSourceShield} alt="Open Source Shield" size="small" />
          </div>
          
          <BlogHeading>Möglichkeiten mit WordPress</BlogHeading>
          
          <p>Bei WordPress handelt es sich um ein <strong>Content-Management-System</strong> (CMS). Es ist also ein System zur Erstellung, Bearbeitung, Organisation und Auslieferung von digitalen Inhalten.</p>
          
          <p>Wie eingangs erwähnt hast du mit WordPress verschiedene Möglichkeiten, die wir nachfolgend kurz vorstellen.</p>
          
          <ul className="list-disc pl-6 mb-4">
            <li>
              <span className="underline"><strong>Blog</strong></span><br />
              Ein Blog zur Veröffentlichung deiner Beiträge ist mit WordPress im Handumdrehen erstellt. Dazu öffnest du in deiner WordPress Instanz den Menüpunkt "Beiträge" – "Neuen Beitrag erstellen" und los geht's. Du kannst deinen Blog sogar mit dem Fedivers verknüpfen.
            </li>
            <li>
              <span className="underline"><strong>Webseite</strong></span><br />
              Je nach Umfang ist die Erstellung deiner Webseite mit mehr Aufwand verbunden. Um eine neue Seite zu erstellen, klicke im rechten Menü auf „Seiten" – „Neue Seite erstellen". Nachdem du den Seitentitel eingegeben hast, kannst du mit dem Aufbau der Seite beginnen, indem du auf „Mit Elementor bearbeiten" klickst.
            </li>
            <li>
              <span className="underline"><strong>OnlineShop</strong></span><br />
              Mit WordPress und den entsprechenden Erweiterungen ist es auch möglich einen eigenen Onlineshop zu erstellen. Mit dem Plugin „WooCommerce" stehen dir viele Möglichkeiten offen, deinen eigenen Onlineshop zu erstellen.
            </li>
          </ul>
          
          <div className="flex justify-center my-4">
            <BlogImage src={computerIcon} alt="Computer Icon" size="small" />
          </div>
          
          <BlogHeading>Erweiterungen für WordPress</BlogHeading>
          
          <p>Man kann sich WordPress als eine Art <strong>Kern- oder Basissoftware</strong> vorstellen. Durch zusätzliche <strong>Themes und Plugins</strong> kann WordPress um weitere Funktionen erweitert und angepasst werden.</p>
          
          <ul className="list-disc pl-6 mb-4">
            <li>
              <span className="underline"><strong>Themes</strong></span><br />
              Das Theme stellt das Grunddesign dar, auf dem du dann aufbauen kannst. Für die Erstellung deiner Inhalte solltest du auf jeden Fall ein Theme aktiviert haben.<br />
              Wir empfehlen dir hier ein einfaches Theme zu wählen, mit dem du keine Einschränkungen in der Gestaltung hast:<br />
              <a href="https://de.wordpress.org/themes/hello-elementor/" target="_blank" rel="noopener">https://de.wordpress.org/themes/hello-elementor/</a>
            </li>
            <li>
              <span className="underline"><strong>Page-Builder Plugin</strong></span><br />
              Für die Erstellung der Inhalte und des Designs wird ein sogenannter „Pager Builder" benötigt. Aus langjähriger Erfahrung können wir hierfür „Elementor Pro" empfehlen.<br />
              <a href="https://elementor.com/products/page-builder-plugin/" target="_blank" rel="noopener">https://elementor.com/products/page-builder-plugin/</a>
            </li>
            <li>
              <span className="underline"><strong>Funktions Plugins</strong></span><br />
              Durch die Installation von zusätzlichen Plugins kannst du deiner WordPress Installation weitere Funktionen hinzufügen. Mit dem Plugin „WooCommerce" kannst du z.B. die Funktion eines Online-Shops hinzufügen.<br />
              <a href="https://woocommerce.com/de-de/" target="_blank" rel="noopener">https://woocommerce.com/de-de/</a>
            </li>
          </ul>
          
          <div className="flex justify-center my-4">
            <BlogImage src={brickIcon} alt="Brick Icon" size="small" />
          </div>
          
          <BlogHeading>Sicherheit</BlogHeading>
          
          <p>Vielleicht hast du schon einmal davon gehört, dass WordPress Seiten angegriffen werden. Meistens handelt es sich aber nicht um Angriffe auf die WordPress Installation selbst, sondern auf die darin installierten PlugIns. Achte also darauf, <strong>nicht zu viele Plugins</strong> zu installieren. Für einen Blog oder eine Webseite brauchst du nicht mehr als <strong>Elementor Pro.</strong></p>
          
          <p>Zusätzlich kannst du z.B. mit „Shield Security" Bot-Attacken abwehren, einen <strong>zweiten Faktor für den Login festlegen und die URL zur Login-Seite ändern.</strong><br />Außerdem spielt die Sicherheit des Servers, auf dem die WordPress-Instanz läuft, eine wichtige Rolle.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={securityIcon} alt="Security Icon" size="small" />
          </div>
          
          <BlogHeading>Hosting</BlogHeading>
          
          <p>Wir stellen dir eine <a href="https://pathconnect.de/wordpress-hosting/" target="_blank" rel="noopener"><strong>fertige WordPress Instanz</strong></a> zur Verfügung, bei der du dich nur noch anmelden musst. Die <strong>Elementor Pro Lizenz sowie eine Domain sind inklusive</strong>, wir kümmern uns um Updates, Backups und stehen dir bei Fragen rund um WordPress zur Seite.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "WordPress",
      formattedDate: "24. Oktober 2023",
      excerpt: "Mit der Open Source Software \"WordPress\" kannst du deinen eigenen Blog, Webseite und Onlineshop erstellen. In diesem Artikel erfährst du, was WordPress genau ist und wie du es benutzen kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;