// src/data/blogPosts/libreoffice.js

// Import images
import libreOfficeLogo from '../../assets/images/blog/2023/09/LibreOffice_logo.svg.png';
import centralDiagram from '../../assets/images/blog/2022/11/Zentral-Diagramm.drawio1.png';
import libreOfficeScreenshot from '../../assets/images/blog/2023/10/Bildschirmfoto-vom-2023-10-03-15-34-09.png';
import openSourceImage from '../../assets/images/blog/2021/10/open-source.png';
import donateIcon from '../../assets/images/blog/2022/12/iconmonstr-banknote-14-240.png';
import thumbnailImage from '../../assets/images/blog/libreoffice-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about LibreOffice
 */
const post = {
  id: '13075',
  slug: 'libreoffice',
  
  // Base data - same regardless of language
  date: "2023-10-03T13:32:36.000Z",
  readTime: 8,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 32,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "LibreOffice",
      formattedDate: "3. Oktober 2023",
      excerpt: "Mit LibreOffice steht dir ein komplettes und quelloffenes Office-Paket zur Verfügung. In diesem Artikel erfährst du, wer hinter LibreOffice steckt, was du damit alles machen kannst und warum wir es dir empfehlen.",
      content: () => (
        <BlogContent>
          <p>Mit LibreOffice steht dir ein komplettes und quelloffenes Office-Paket zur Verfügung. In diesem Artikel erfährst du, wer hinter LibreOffice steckt, was du damit alles machen kannst und warum wir es dir empfehlen.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={libreOfficeLogo} alt="LibreOffice Logo" size="medium" />
          </div>
          
          <BlogHeading>The Document Foundation</BlogHeading>
          
          <p>Hinter LibreOffice steht die gemeinnützige Stiftung „The Document Foundation" (TDF). Ein Großteil der Entwicklung wird von ehrenamtlichen Mitgliedern geleistet.<br />Die entwickelte Software kann von jedermann genutzt, kopiert, verbreitet, untersucht, verändert und verbessert werden. Ziel ist es, jedem den Zugang zu einem freien Office-Paket zu ermöglichen.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={centralDiagram} alt="Zentrales Diagramm" size="medium" />
          </div>
          
          <BlogHeading>Installation & Verwendung</BlogHeading>
          
          <p>LibreOffice ist für Linux, macOS und Windows erhältlich und kann direkt von der offiziellen Website heruntergeladen werden:<br />
          <a href="https://de.libreoffice.org/download/download/" target="_blank" rel="noopener">https://de.libreoffice.org/download/download/</a></p>
          
          <p>Beim Öffnen von LibreOffice hast du die Auswahl zwischen Write, Calc, Impress, Base, und Math.</p>
          
          <ul>
            <li><span style={{textDecoration: "underline"}}>Write – Textverarbeitung (Word)</span><br />
            Mit LibreOffice – Write kannst du sowohl kurze Notizen als auch umfangreiche Dokumente mit aktivierter Rechtschreibprüfung, Inhaltsverzeichnis, Diagrammen und vielem mehr erstellen. Dank zahlreicher Vorlagen lassen sich auch komplexe Dokumente schnell erstellen.</li>
            
            <li><span style={{textDecoration: "underline"}}>Calc – Tabellenkalkulation (Excel)</span><br />
            Immer wenn es darum geht, Berechnungen übersichtlich darzustellen, kommt LibreOffice – Calc zum Einsatz. Mit dem Szenario-Manager können „Was wäre wenn..." Analysen erstellt werden, der Solver löst Optimierungsprobleme und mit Pivot-Tabellen können externe Quelldaten eingebunden und berechnet werden.</li>
            
            <li><span style={{textDecoration: "underline"}}>Impress – Präsentationen (PowerPoint)</span><br />
            Für die Gestaltung deiner nächsten Präsentation kommt LibreOffice – Write zum Einsatz. Auch hier stehen zahlreiche Vorlagen zur Verfügung. Mit Animationen, Effekten, Diagrammen und Zeichnungen können Präsentationen zusätzlich gestaltet werden.</li>
            
            <li><span style={{textDecoration: "underline"}}>Draw – Zeichnungen</span><br />
            Libre Office Draw eignet sich hervorragend für z.B. Ablauf-, Organisations- und Netzwerkdiagramme.</li>
            
            <li><span style={{textDecoration: "underline"}}>Base – Datenbanken</span><br />
            LibreOffice Base bietet eine grafische Oberfläche für Datenbanken wie MySQL/MariaDB, Adabas D, MS Access und PostgreSQL.</li>
            
            <li><span style={{textDecoration: "underline"}}>Math – Formeleditor</span><br />
            Der Formeleditor kann direkt aus Write, Calc, Impress und Draw aufgerufen werden, um mathematische oder wissenschaftliche Formeln hinzuzufügen.</li>
          </ul>
          
          <div className="flex justify-center my-6">
            <BlogImage src={libreOfficeScreenshot} alt="LibreOffice Screenshot" size="medium" />
          </div>
          
          <BlogHeading>Empfehlung</BlogHeading>
          
          <p>Als Verfechter von Open Source Software können wir dir natürlich auch LibreOffice empfehlen. Du kannst es kostenlos herunterladen und benutzen. Der oben beschriebene Funktionsumfang ist sehr gut und lässt kaum Wünsche offen.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={openSourceImage} alt="Open Source" size="large" />
          </div>
          
          <BlogHeading>Spenden</BlogHeading>
          
          <p>Wie eingangs erwähnt, wird LibreOffice zum größten Teil von freiwilligen Entwicklern vorangetrieben. Durch Spenden kannst du die weltweite Community unterstützen und dazu beitragen, dass LibreOffice auch in Zukunft weiterentwickelt wird.<br />
          <a href="https://de.libreoffice.org/donate/" target="_blank" rel="noopener">https://de.libreoffice.org/donate/</a></p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={donateIcon} alt="Spenden Icon" size="small" />
          </div>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "LibreOffice",
      formattedDate: "3. Oktober 2023",
      excerpt: "Mit LibreOffice steht dir ein komplettes und quelloffenes Office-Paket zur Verfügung. In diesem Artikel erfährst du, wer hinter LibreOffice steckt, was du damit alles machen kannst und warum wir es dir empfehlen.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "LibreOffice",
      formattedDate: "October 3, 2023",
      excerpt: "LibreOffice provides you with a complete, open-source office package. In this article, you'll learn who's behind LibreOffice, what you can do with it, and why we recommend it.",
      content: () => (
        <BlogContent>
          <p>LibreOffice provides you with a complete, open-source office package. In this article, you'll learn who's behind LibreOffice, what you can do with it, and why we recommend it.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={libreOfficeLogo} alt="LibreOffice Logo" size="medium" />
          </div>
          
          <BlogHeading>The Document Foundation</BlogHeading>
          
          <p>LibreOffice is backed by the non-profit organization "The Document Foundation" (TDF). Most of the development is done by volunteer members.<br />The developed software can be used, copied, distributed, studied, modified, and improved by anyone. The goal is to provide everyone with access to a free office package.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={centralDiagram} alt="Central Diagram" size="medium" />
          </div>
          
          <BlogHeading>Installation & Usage</BlogHeading>
          
          <p>LibreOffice is available for Linux, macOS, and Windows and can be downloaded directly from the official website:<br />
          <a href="https://www.libreoffice.org/download/download/" target="_blank" rel="noopener">https://www.libreoffice.org/download/download/</a></p>
          
          <p>When opening LibreOffice, you can choose between Write, Calc, Impress, Base, and Math.</p>
          
          <ul>
            <li><span style={{textDecoration: "underline"}}>Write – Word Processing (Word)</span><br />
            With LibreOffice – Write, you can create both short notes and extensive documents with spell checking, table of contents, diagrams, and much more. Thanks to numerous templates, even complex documents can be created quickly.</li>
            
            <li><span style={{textDecoration: "underline"}}>Calc – Spreadsheets (Excel)</span><br />
            Whenever you need to present calculations clearly, LibreOffice – Calc comes into play. With the Scenario Manager, "What if..." analyses can be created, the Solver solves optimization problems, and with pivot tables, external source data can be integrated and calculated.</li>
            
            <li><span style={{textDecoration: "underline"}}>Impress – Presentations (PowerPoint)</span><br />
            For designing your next presentation, LibreOffice – Impress is used. Numerous templates are available here as well. With animations, effects, diagrams, and drawings, presentations can be further enhanced.</li>
            
            <li><span style={{textDecoration: "underline"}}>Draw – Drawings</span><br />
            LibreOffice Draw is excellent for creating flow charts, organizational charts, and network diagrams.</li>
            
            <li><span style={{textDecoration: "underline"}}>Base – Databases</span><br />
            LibreOffice Base provides a graphical interface for databases such as MySQL/MariaDB, Adabas D, MS Access, and PostgreSQL.</li>
            
            <li><span style={{textDecoration: "underline"}}>Math – Formula Editor</span><br />
            The formula editor can be called directly from Write, Calc, Impress, and Draw to add mathematical or scientific formulas.</li>
          </ul>
          
          <div className="flex justify-center my-6">
            <BlogImage src={libreOfficeScreenshot} alt="LibreOffice Screenshot" size="medium" />
          </div>
          
          <BlogHeading>Recommendation</BlogHeading>
          
          <p>As advocates of open source software, we naturally recommend LibreOffice. You can download and use it for free. The range of functions described above is very good and leaves almost nothing to be desired.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={openSourceImage} alt="Open Source" size="large" />
          </div>
          
          <BlogHeading>Donations</BlogHeading>
          
          <p>As mentioned at the beginning, LibreOffice is largely driven by volunteer developers. Through donations, you can support the worldwide community and contribute to the continued development of LibreOffice in the future.<br />
          <a href="https://www.libreoffice.org/donate/" target="_blank" rel="noopener">https://www.libreoffice.org/donate/</a></p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={donateIcon} alt="Donation Icon" size="small" />
          </div>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "LibreOffice",
      formattedDate: "3 October 2023",
      excerpt: "LibreOffice provides you with a complete, open-source office package. In this article, you'll learn who's behind LibreOffice, what you can do with it, and why we recommend it.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;