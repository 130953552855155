// src/data/blogPosts/paperless-ngx.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Paperless-ngx
 */
const post = {
  id: '20038',
  slug: 'paperless-ngx',
  
  // Base data - same regardless of language
  date: "2024-05-07T15:00:09.000Z",
  readTime: 11,
  categories: ["Anleitungen", "Empfehlungen", "Open Source Software"],
  thumbnail: "../images/blog/paperless-ngx-featured.png",
  thumbnailUrl: "../images/blog/paperless-ngx-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 0,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Paperless-ngx",
      formattedDate: "7. Mai 2024",
      excerpt: "Paperless-ngx ist ein Open Source Dokumentenmanagementsystem, das physische Dokumente direkt vom Scanner in ein durchsuchbares Online-Archiv umwandelt. In diesem Artikel werden die wichtigsten Funktionen sowie die Integration in Nextcloud vorgestellt.",
      content: () => (
        <BlogContent>
          <p>Paperless-ngx ist ein Open Source Dokumentenmanagementsystem, das physische Dokumente direkt vom Scanner in ein durchsuchbares Online-Archiv umwandelt. In diesem Artikel werden die wichtigsten Funktionen sowie die Integration in Nextcloud vorgestellt.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/logo_full_black.png" 
              alt="Paperless-ngx Logo" 
              size="large" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Funktionen</BlogHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <p>
                <strong>Hinzufügen von Dokumenten</strong><br />
                Dokumente können direkt vom Scanner, von einer E-Mail oder über das Webinterface hinzugefügt werden. Bei der Integration in Nextcloud funktioniert das Hinzufügen auch über einen Ordner, der mit deiner Nextcloud synchronisiert wird.
              </p>
              
              <p>
                <strong>OCR: Durchsuchbare Dokumente</strong><br />
                OCR steht für Optical Character Recognition und ermöglicht die Suche nach Wörtern in Dokumenten. Die OCR erfolgt automatisch beim Einfügen der Dokumente in Paperless-ngx.
              </p>
              
              <p>
                <strong>Einfügen verschiedener Formate</strong><br />
                Neben PDF-Dokumenten können auch Bilder, Textdateien und Office-Dokumente in Paperless-ngx hochgeladen werden.
              </p>
              
              <p>
                <strong>Multi User</strong><br />
                Es können mehrere Benutzer und Gruppen angelegt werden, so dass Paperless-ngx von mehreren Personen gleichzeitig genutzt werden kann.
              </p>
            </div>
            
            <div>
              <p>
                <strong>Automatisches hinzufügen von Tags, Korrespondenten und Dokumententypen</strong>
              </p>
              
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  <span className="underline">Dokumenttypen</span> sind die allgemeine Art des Dokuments, z.B. ein Brief, eine Quittung oder eine Rechnung.
                </li>
                <li>
                  <span className="underline">Korrespondenten</span> sind die Personen oder Institutionen, die mit diesem Dokument zu tun haben. Bei einer Rechnung könnte dies z.B. die entsprechende Firma sein.
                </li>
                <li>
                  <span className="underline">Tags</span> können verwendet werden, um detailliertere Informationen hinzuzufügen. Auf wen bezieht es sich? (z.B. ein bestimmtes Familienmitglied oder ein bestimmter Mitarbeiter), worauf bezieht es sich (z.B. die Finanzierung der neuen Waschmaschine oder die Autoreparatur) und für wann ist die Information relevant (z.B. Steuer für 2023).
                </li>
              </ul>
            </div>
          </div>
          
          <BlogGallery 
            images={[
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-34-00.png",
                alt: "Paperless-ngx Dashboard Ansicht"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-40.png",
                alt: "Paperless-ngx Dokumente Verwaltung"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-13.png",
                alt: "Paperless-ngx Dokumentansicht"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-05.png",
                alt: "Paperless-ngx Filteransicht"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-02.png",
                alt: "Paperless-ngx Filtereinstellungen"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-32-58.png",
                alt: "Paperless-ngx Detailansicht"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-10-22.png",
                alt: "Paperless-ngx Dokumentenvorschau"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-08-21.png",
                alt: "Paperless-ngx Einstellungen"
              }
            ]}
            columns={4}
            gap={10}
          />
          
          <BlogHeading>Integration in Nextcloud</BlogHeading>
          
          <p>Bei der Integration geht es darum, dass die Dokumente in Nextcloud und Paperless-ngx identisch sind. Es muss also möglich sein, sowohl in Paperless als auch in Nextcloud auf die gleichen Dokumente zuzugreifen.</p>
          
          <p>Wenn du eine Nextcloud bei PathConnect hast, installieren wir Paperless-ngx auf dem gleichen (virtuellen) Server, auf dem auch deine Nextcloud installiert ist.</p>
          
          <p>Dadurch können die Paperless Ordner als externer Speicher in deine Nextcloud integriert werden.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/Bildschirmfoto-vom-2024-05-07-17-18-09.png" 
              alt="Paperless-ngx als Externer Speicher in Nextcloud" 
              size="large" 
            />
          </div>
          
          <p>Zusätzlich kannst du in deiner Nextcloud die App "Paperless" installieren um auch einzelne Dateien unkompliziert direkt aus der Nextcloud in Paperless-ngx zu laden.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/Bildschirmfoto-vom-2024-05-07-17-28-19.png" 
              alt="Paperless App in Nextcloud" 
              size="large" 
            />
          </div>
          
          <p>Der Scanner sendet das Dokument in einen Ordner auf deinem Computer. Dieser Ordner wird mit deiner Nextcloud synchronisiert. Paperless greift auf diesen Ordner zu und bearbeitet das Dokument. Anschließend wird es im Medienordner abgelegt, auf den auch die Nextcloud Zugriff hat.</p>
          
          <p>Wenn du deine Nextcloud nicht bei PathConnect hast, muss anstelle des lokalen externen Speichers eine FTP-Verbindung eingerichtet werden.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/Architektur-Nextcloud-Paperless-ngx-e1715095423694.png" 
              alt="Architektur der Integration zwischen Nextcloud und Paperless-ngx" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Paperless-ngx",
      formattedDate: "7. Mai 2024",
      excerpt: "Paperless-ngx ist ein Open Source Dokumentenmanagementsystem, das physische Dokumente direkt vom Scanner in ein durchsuchbares Online-Archiv umwandelt. In diesem Artikel werden die wichtigsten Funktionen sowie die Integration in Nextcloud vorgestellt.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Paperless-ngx",
      formattedDate: "May 7, 2024",
      excerpt: "Paperless-ngx is an open source document management system that converts physical documents directly from the scanner into a searchable online archive. This article introduces the key features and the integration with Nextcloud.",
      content: () => (
        <BlogContent>
          <p>Paperless-ngx is an open source document management system that converts physical documents directly from the scanner into a searchable online archive. This article introduces the key features and the integration with Nextcloud.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/logo_full_black.png" 
              alt="Paperless-ngx Logo" 
              size="large" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Features</BlogHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <p>
                <strong>Adding Documents</strong><br />
                Documents can be added directly from the scanner, from an email, or via the web interface. When integrated with Nextcloud, documents can also be added via a folder that is synchronized with your Nextcloud.
              </p>
              
              <p>
                <strong>OCR: Searchable Documents</strong><br />
                OCR stands for Optical Character Recognition and enables the search for words in documents. OCR is performed automatically when inserting documents into Paperless-ngx.
              </p>
              
              <p>
                <strong>Insert Various Formats</strong><br />
                In addition to PDF documents, images, text files, and Office documents can also be uploaded to Paperless-ngx.
              </p>
              
              <p>
                <strong>Multi-User</strong><br />
                Multiple users and groups can be created, allowing Paperless-ngx to be used by several people simultaneously.
              </p>
            </div>
            
            <div>
              <p>
                <strong>Automatic Addition of Tags, Correspondents, and Document Types</strong>
              </p>
              
              <ul className="list-disc pl-6 space-y-2">
                <li>
                  <span className="underline">Document types</span> are the general type of the document, e.g., a letter, a receipt, or an invoice.
                </li>
                <li>
                  <span className="underline">Correspondents</span> are the people or institutions associated with this document. For an invoice, this could be the respective company.
                </li>
                <li>
                  <span className="underline">Tags</span> can be used to add more detailed information. Who does it relate to? (e.g., a specific family member or a specific employee), what does it relate to (e.g., financing the new washing machine or the car repair), and when is the information relevant (e.g., taxes for 2023).
                </li>
              </ul>
            </div>
          </div>
          
          <BlogGallery 
            images={[
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-34-00.png",
                alt: "Paperless-ngx Dashboard View"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-40.png",
                alt: "Paperless-ngx Document Management"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-13.png",
                alt: "Paperless-ngx Document View"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-05.png",
                alt: "Paperless-ngx Filter View"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-33-02.png",
                alt: "Paperless-ngx Filter Settings"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-32-58.png",
                alt: "Paperless-ngx Detail View"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-10-22.png",
                alt: "Paperless-ngx Document Preview"
              },
              {
                src: "../images/blog/2024/05/Bildschirmfoto-vom-2024-05-08-08-08-21.png",
                alt: "Paperless-ngx Settings"
              }
            ]}
            columns={4}
            gap={10}
          />
          
          <BlogHeading>Integration with Nextcloud</BlogHeading>
          
          <p>The integration is about ensuring that the documents in Nextcloud and Paperless-ngx are identical. So it must be possible to access the same documents in both Paperless and Nextcloud.</p>
          
          <p>If you have a Nextcloud with PathConnect, we install Paperless-ngx on the same (virtual) server where your Nextcloud is installed.</p>
          
          <p>This allows the Paperless folders to be integrated into your Nextcloud as external storage.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/Bildschirmfoto-vom-2024-05-07-17-18-09.png" 
              alt="Paperless-ngx as External Storage in Nextcloud" 
              size="large" 
            />
          </div>
          
          <p>Additionally, you can install the "Paperless" app in your Nextcloud to easily load individual files directly from Nextcloud into Paperless-ngx.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/Bildschirmfoto-vom-2024-05-07-17-28-19.png" 
              alt="Paperless App in Nextcloud" 
              size="large" 
            />
          </div>
          
          <p>The scanner sends the document to a folder on your computer. This folder is synchronized with your Nextcloud. Paperless accesses this folder and processes the document. It is then stored in the media folder, which Nextcloud also has access to.</p>
          
          <p>If you don't have your Nextcloud with PathConnect, an FTP connection must be set up instead of the local external storage.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/05/Architektur-Nextcloud-Paperless-ngx-e1715095423694.png" 
              alt="Architecture of the Integration between Nextcloud and Paperless-ngx" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offering includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-round service that includes installation, customization, and updates, allowing you to focus fully on your projects.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Paperless-ngx",
      formattedDate: "7 May 2024",
      excerpt: "Paperless-ngx is an open source document management system that converts physical documents directly from the scanner into a searchable online archive. This article introduces the key features and the integration with Nextcloud.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;