import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Container from '../components/container';
import Button from '../components/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faServer,
  faLock,
  faShieldAlt,
  faGlobe,
  faCloudUploadAlt,
  faBolt,
  faDatabase,
  faNetworkWired
} from '@fortawesome/free-solid-svg-icons';

/**
 * HeroSection component with interactive circular design focused on core message
 * Reduced vertical padding on mobile
 */
const HeroSection = () => {
  const { t } = useTranslation('home');
  const [activeIcon, setActiveIcon] = useState(null);

  // Icons with descriptions that reflect core values
  const hostingIcons = [
    { icon: faServer, name: t('icons.server.name'), description: t('icons.server.description') },
    { icon: faLock, name: t('icons.security.name'), description: t('icons.security.description') },
    { icon: faShieldAlt, name: t('icons.protection.name'), description: t('icons.protection.description') },
    { icon: faGlobe, name: t('icons.flexible.name'), description: t('icons.flexible.description') },
    { icon: faCloudUploadAlt, name: t('icons.cloud.name'), description: t('icons.cloud.description') },
    { icon: faBolt, name: t('icons.fast.name'), description: t('icons.fast.description') },
    { icon: faDatabase, name: t('icons.database.name'), description: t('icons.database.description') },
    { icon: faNetworkWired, name: t('icons.network.name'), description: t('icons.network.description') }
  ];

  return (
    <Container>
      {/* Minimal top padding, normal bottom padding */}
      <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-8 items-center pt-1 pb-4 sm:py-8 md:py-12 lg:py-16">
        <div className="space-y-4 md:space-y-6">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold bg-gradient-to-r from-primary to-button-dark bg-200% text-transparent bg-clip-text animate-gradient leading-tight pb-2">
            {t('c1.slogan')}
          </h1>
          <div className="text-xl md:text-2xl lg:text-3xl font-light space-y-1 md:space-y-2">
            {t('c1.claim', { returnObjects: true }).map((claim, index) => (
              <p key={index} className="flex items-center">
                {claim}
              </p>
            ))}
          </div>
          <p className="text-base md:text-lg text-gray-700 dark:text-gray-300 max-w-xl mt-2 md:mt-4">
            {t('c1.description')}
          </p>
          <div className="flex flex-col sm:flex-row gap-3 md:gap-4 pt-3 md:pt-6">
            <Button
              className="bg-gradient-to-r from-primary to-button-dark text-white text-base md:text-lg font-bold px-6 py-3 md:px-8 md:py-4 rounded-lg shadow-lg hover:shadow-primary/20 transition-all duration-300 transform hover:-translate-y-1"
              onClick={() =>
                (window.location.href =
                  'https://drive.pathconnect.de/apps/appointments/pub/eMgHf4y8FMdP5g%3D%3D/form')
              }
            >
              {t('c1.bookFreeAppointment')}
            </Button>
            <Button
              className="bg-transparent border-2 border-primary text-primary dark:text-primary text-base md:text-lg font-bold px-6 py-3 md:px-8 md:py-4 rounded-lg hover:bg-primary/10 transition-all duration-300"
              onClick={() => window.location.href = '/contact'}
            >
              {t('c1.contactButton')}
            </Button>
          </div>
        </div>
        
        {/* Circular Interactive Element - Visible on all screen sizes */}
        <div className="relative h-64 md:h-80 lg:h-96 mt-6 lg:mt-0">
          {/* Animated radial dots */}
          <div className="absolute inset-0 opacity-10" style={{ 
            backgroundImage: 'radial-gradient(rgba(29, 186, 224, 0.7) 1px, transparent 1px)', 
            backgroundSize: '20px 20px' 
          }}></div>
          
          {/* Center glow */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-10">
            <div className="w-40 h-40 rounded-full bg-primary/10 blur-xl animate-pulse-slow"></div>
          </div>
          
          {/* SVG layer for connecting lines - LOWER z-index */}
          <svg className="absolute inset-0 w-full h-full pointer-events-none z-0">
            {activeIcon !== null && (
              <line 
                x1="50%" 
                y1="50%" 
                x2={`${50 + 160 * Math.cos(activeIcon * 2 * Math.PI / hostingIcons.length) / 4}%`}
                y2={`${50 + 160 * Math.sin(activeIcon * 2 * Math.PI / hostingIcons.length) / 4}%`}
                stroke="#1DBAE0"
                strokeWidth="1"
                strokeOpacity="0.4"
                strokeDasharray="3,3"
              />
            )}
          </svg>

          {/* SVG layer for connecting lines - LOWER z-index */}
          <svg className="absolute inset-0 w-full h-full pointer-events-none z-0">
            {activeIcon !== null && (
              <line 
                x1="50%" 
                y1="50%" 
                x2={`${50 + 160 * Math.cos(activeIcon * 2 * Math.PI / hostingIcons.length) / 4}%`}
                y2={`${50 + 160 * Math.sin(activeIcon * 2 * Math.PI / hostingIcons.length) / 4}%`}
                stroke="#1DBAE0"
                strokeWidth="2"
                strokeOpacity="0.8"
                className="animate-pulse"
              />
            )}
          </svg>
          
          {/* Center circle - with PathConnect logo - HIGHER z-index */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
            <div className="relative w-32 h-32 rounded-full bg-primary/20 backdrop-blur-sm flex items-center justify-center overflow-hidden group">
              <div className="absolute inset-0 bg-gradient-to-r from-primary/30 to-button-dark/30 animate-gradient bg-200%"></div>
              {/* Logo */}
              <div className="relative z-10 flex items-center justify-center w-24 h-24">
                <img 
                  src="/assets/images/logo.png" 
                  alt="PathConnect" 
                  className="w-full h-full object-contain"
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.className = "hidden";
                    e.target.parentNode.innerHTML = '<div class="text-white font-bold">PathConnect</div>';
                  }}
                />
              </div>
            </div>
          </div>

          {/* Orbiting icons */}
          <div className="absolute inset-0">
            {hostingIcons.map((item, index) => {
              // Calculate position on the circle
              const angle = (index / hostingIcons.length) * 2 * Math.PI;
              const radius = 160; // Made larger for a better circle
              const centerX = 50; // Percent
              const centerY = 50; // Percent
              // Using the same divisor for both x and y ensures a perfect circle
              const x = centerX + radius * Math.cos(angle) / 4; 
              const y = centerY + radius * Math.sin(angle) / 4;
              
              return (
                <div 
                  key={index}
                  className={`absolute transform -translate-x-1/2 -translate-y-1/2 transition-all duration-300 ${activeIcon === index ? 'scale-110 z-30' : 'scale-100 z-20'}`}
                  style={{ left: `${x}%`, top: `${y}%` }}
                  onMouseEnter={() => setActiveIcon(index)}
                  onMouseLeave={() => setActiveIcon(null)}
                >
                  <div className="relative">
                    {/* Icon Container */}
                    <div className={`w-14 h-14 rounded-full flex items-center justify-center transition-all duration-500 cursor-pointer
                                      ${activeIcon === index 
                                        ? 'bg-primary text-white shadow-lg shadow-primary/30' 
                                        : 'bg-gray-800/80 text-primary hover:bg-gray-800 hover:text-primary/90'}`}>
                      <FontAwesomeIcon icon={item.icon} className="text-2xl" />
                    </div>
                    
                    {/* Tooltip */}
                    <div className={`absolute -bottom-20 left-1/2 transform -translate-x-1/2 w-48 bg-gray-800/90 backdrop-blur-sm text-white p-2 rounded-lg text-center text-sm
                                    transition-all duration-300 border border-gray-700 shadow-lg z-40
                                    ${activeIcon === index ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'}`}>
                      <p className="font-bold">{item.name}</p>
                      <p className="text-xs text-gray-300">{item.description}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HeroSection;