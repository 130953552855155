// src/data/blogPosts/buchempfehlung-nextcloud-schnelleinstieg.js

// Import images
import bookCover from '../../assets/images/blog/2023/05/Buchcover-Nextcloud-Schnelleinstieg.jpg';
import userIcon from '../../assets/images/blog/2023/05/iconmonstr-user-20-240.png';
import bookIcon from '../../assets/images/blog/2022/04/book.png';
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import circleIcon from '../../assets/images/blog/2023/05/iconmonstr-circle-5-240.png';
import customerIcon from '../../assets/images/blog/2022/01/Verbraucher.png';
import thumbnailImage from '../../assets/images/blog/buchempfehlung-nextcloud-schnelleinstieg-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about the Nextcloud book recommendation
 */
const post = {
  id: '11780',
  slug: 'buchempfehlung-nextcloud-schnelleinstieg',
  
  // Base data - same regardless of language
  date: "2023-05-10T08:39:12.000Z",
  readTime: 9,
  categories: ["Empfehlungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 39,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Buchempfehlung – Nextcloud Schnelleinstieg",
      formattedDate: "10. Mai 2023",
      excerpt: "In diesem Blogbeitrag möchten wir dir das Buch \"Nextcloud Schnelleinstieg\" von Herbert Hertramph vorstellen.",
      content: () => (
        <BlogContent>
          <p>In diesem Blogbeitrag möchten wir dir das Buch <strong>Nextcloud Schnelleinstieg</strong> von Herbert Hertramph vorstellen.</p>
          
          <BlogImage src={bookCover} alt="Buchcover Nextcloud Schnelleinstieg" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Über den Author</BlogHeading>
          
          <p><strong>Herbert Hertramph</strong> ist Sozialwissenschaftler an der Universität Ulm, in der er den Studiengang Mediengestütztes Lernen und Lehren aufgebaut hat. Er veröffentlicht Artikel zu den Themen Selbstorganisation und digitales Informationsmanagement, die auch auf der Website <a href="https://digital-cleaning.de" target="_blank" rel="noopener noreferrer">www.digital-cleaning.de</a> zu finden sind.</p>
          <p>Neben seinem aktuellen Buch „<a href="https://www.mitp.de/Unsere-Autoren/Herbert-Hertramph/Nextcloud-Schnelleinstieg.html?listtype=manufacturer&mnid=fc372696be54c155390d72921ce3256f&isextsearch=alist&" target="_blank" rel="noopener noreferrer">Nextcloud Schnelleinstieg</a>" hat er bereits drei weitere Bücher „<a href="https://www.mitp.de/Unsere-Autoren/Herbert-Hertramph/Digital-Cleaning.html?listtype=manufacturer&mnid=fc372696be54c155390d72921ce3256f&isextsearch=alist&" target="_blank" rel="noopener noreferrer">Digital Cleaning</a>" „<a href="https://www.mitp.de/Unsere-Autoren/Herbert-Hertramph/Raspberry-Pi-400-Schnelleinstieg.html?listtype=manufacturer&mnid=fc372696be54c155390d72921ce3256f&isextsearch=alist&" target="_blank" rel="noopener noreferrer">Raspberry Pi 400 Schnelleinstieg</a>" und „<a href="https://www.amazon.de/gp/product/3826695062/ref=dbs_a_def_rwt_bibl_vppi_i2" target="_blank" rel="noopener noreferrer">Evernote</a>" geschrieben.</p>
          
          <BlogImage src={userIcon} alt="Benutzer Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Über das Buch</BlogHeading>
          
          <p>Wer zum ersten Mal mit einer Nextcloud arbeitet, merkt schnell, dass es sich nicht um eine gewöhnliche Cloud handelt. Die Möglichkeiten sind vielfältig und können beliebig erweitert werden. Dieses Buch zeigt, welche Funktionen die Nextcloud bietet, wie sie eingerichtet und genutzt werden können. Dabei wird alles so <strong>einfach wie möglich gehalten und verständlich</strong> erklärt. Es eignet sich auch hervorragend als <strong>Nachschlagewerk</strong>, wenn du nach einer bestimmte Funktion suchst.</p>
          
          <BlogImage src={bookIcon} alt="Buch Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Grundlagen</BlogHeading>
          
          <p>Im ersten Abschnitt wird auf die allgemeine <strong>Entwicklung der Datenspeicherung</strong> und die Entstehung der Cloud eingegangen. Es werden auch die <strong>Gefahren und Risiken</strong> der großen amerikanischen Anbieter angesprochen. Du erfährst welche<strong> Vorteile eine managed Nextcloud</strong> haben kann und worauf bei der Auswahl des Anbieters zu achten ist.</p>
          
          <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Rundgang durch die Nextcloud</BlogHeading>
          
          <p>Das Buch ist in mehrere Kapitel unterteilt, in denen jeweils auf <strong>einzelne Funktionen</strong> eingegangen wird. Beginnend mit der Einführung in die Nextcloud Weboberfläche erhältst du bereits einen guten Überblick über die einzelnen Funktionen. In den weiteren Kapiteln werden alle Funktionen der Nextcloud Schritt für Schritt erklärt. <strong>Praktische Anwendungsbeispiele</strong> lassen bereits erahnen, was alles möglich ist und machen Lust aufs Ausprobieren. Du erfährst, wie sich die Nextcloud auch von unterwegs aus nutzen lässt und welche vielfältigen Möglichkeiten es zur Zusammenarbeit gibt.</p>
          <p>Auch das Thema Sicherheit kommt nicht zu kurz und du bekommst gezeigt wie du deinen Zugang absichern und Dateien verschlüsseln kannst.</p>
          
          <BlogImage src={circleIcon} alt="Kreis Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Zielgruppe</BlogHeading>
          
          <p>Das Buch richtet sich an <strong>Anwender</strong>, die mehr über die Funktionen von Nextcloud erfahren möchten. Durch die anschauliche Beschreibung mit vielen Grafiken ist dieses Buch auch für alle geeignet, die<strong> keinen technischen Hintergrund</strong> haben. Aber auch Leute, die sich schon eine Weile mit der Nextcloud beschäftigen, finden in diesem Buch tolle Tipps & Tricks, mit denen die Funktionen noch besser genutzt werden können.</p>
          
          <BlogImage src={customerIcon} alt="Verbraucher Icon" size="icon" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Buchempfehlung – Nextcloud Schnelleinstieg",
      formattedDate: "10. Mai 2023",
      excerpt: "In diesem Blogbeitrag möchten wir dir das Buch \"Nextcloud Schnelleinstieg\" von Herbert Hertramph vorstellen.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Book Recommendation - Nextcloud Quick Start",
      formattedDate: "May 10, 2023",
      excerpt: "In this blog post, we'd like to introduce you to the book \"Nextcloud Quick Start\" by Herbert Hertramph.",
      content: () => (
        <BlogContent>
          <p>In this blog post, we'd like to introduce you to the book <strong>Nextcloud Quick Start</strong> by Herbert Hertramph.</p>
          
          <BlogImage src={bookCover} alt="Book cover Nextcloud Quick Start" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>About the Author</BlogHeading>
          
          <p><strong>Herbert Hertramph</strong> is a social scientist at the University of Ulm, where he developed the Media-Supported Learning and Teaching program. He publishes articles on self-organization and digital information management, which can also be found on the website <a href="https://digital-cleaning.de" target="_blank" rel="noopener noreferrer">www.digital-cleaning.de</a>.</p>
          <p>In addition to his current book "<a href="https://www.mitp.de/Unsere-Autoren/Herbert-Hertramph/Nextcloud-Schnelleinstieg.html?listtype=manufacturer&mnid=fc372696be54c155390d72921ce3256f&isextsearch=alist&" target="_blank" rel="noopener noreferrer">Nextcloud Quick Start</a>", he has already written three other books: "<a href="https://www.mitp.de/Unsere-Autoren/Herbert-Hertramph/Digital-Cleaning.html?listtype=manufacturer&mnid=fc372696be54c155390d72921ce3256f&isextsearch=alist&" target="_blank" rel="noopener noreferrer">Digital Cleaning</a>", "<a href="https://www.mitp.de/Unsere-Autoren/Herbert-Hertramph/Raspberry-Pi-400-Schnelleinstieg.html?listtype=manufacturer&mnid=fc372696be54c155390d72921ce3256f&isextsearch=alist&" target="_blank" rel="noopener noreferrer">Raspberry Pi 400 Quick Start</a>", and "<a href="https://www.amazon.de/gp/product/3826695062/ref=dbs_a_def_rwt_bibl_vppi_i2" target="_blank" rel="noopener noreferrer">Evernote</a>".</p>
          
          <BlogImage src={userIcon} alt="User Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>About the Book</BlogHeading>
          
          <p>Those who work with Nextcloud for the first time quickly realize that it is not an ordinary cloud service. The possibilities are manifold and can be extended as needed. This book shows what features Nextcloud offers, how they can be set up and used. Everything is kept <strong>as simple as possible and explained clearly</strong>. It also serves as an excellent <strong>reference guide</strong> when you're looking for a specific function.</p>
          
          <BlogImage src={bookIcon} alt="Book Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Fundamentals</BlogHeading>
          
          <p>The first section covers the general <strong>development of data storage</strong> and the emergence of cloud computing. The <strong>dangers and risks</strong> posed by major American providers are also addressed. You'll learn about the <strong>advantages of a managed Nextcloud</strong> and what to look for when selecting a provider.</p>
          
          <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Tour Through Nextcloud</BlogHeading>
          
          <p>The book is divided into several chapters, each covering <strong>individual features</strong>. Starting with an introduction to the Nextcloud web interface, you already get a good overview of all the functions. In the subsequent chapters, all features of Nextcloud are explained step by step. <strong>Practical application examples</strong> give you an idea of what's possible and make you want to try it out yourself. You'll learn how to use Nextcloud on the go and the various collaboration options available.</p>
          <p>Security is also covered extensively, showing you how to secure your access and encrypt files.</p>
          
          <BlogImage src={circleIcon} alt="Circle Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Target Audience</BlogHeading>
          
          <p>The book is aimed at <strong>users</strong> who want to learn more about Nextcloud's features. With its illustrative descriptions and numerous graphics, this book is also suitable for those with <strong>no technical background</strong>. Even those who have been working with Nextcloud for a while will find great tips and tricks to make better use of its features.</p>
          
          <BlogImage src={customerIcon} alt="Customer Icon" size="icon" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Book Recommendation - Nextcloud Quick Start",
      formattedDate: "May 10, 2023",
      excerpt: "In this blog post, we'd like to introduce you to the book \"Nextcloud Quick Start\" by Herbert Hertramph.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;