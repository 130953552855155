// src/data/blogPosts/nextcloud-federation-share.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-federation-share-featured.png';
import shareIcon from '../../assets/images/blog/2022/12/iconmonstr-share-2-240.png';
import nextcloudHubLogo from '../../assets/images/blog/2022/01/Nextcloud-Hub-logo.png';
import ideaIcon from '../../assets/images/blog/2022/12/iconmonstr-idea-14-240.png';
import federatedCloudSharingImage from '../../assets/images/blog/2023/04/Federated-Cloud-Sharing.png';
import federatedCloudIdImage from '../../assets/images/blog/2023/04/Federated-Cloud-ID.png';
import freigabeImage from '../../assets/images/blog/2023/04/Bildschirmfoto-vom-2024-05-22-17-08-13.png';
import paperPlaneIcon from '../../assets/images/blog/2023/03/iconmonstr-paper-plane-8-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Federation Share
 */
const post = {
  id: '11714',
  slug: 'nextcloud-federation-share',
  
  // Base data - same regardless of language
  date: "2023-04-25T18:37:53.000Z",
  readTime: 9,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 11,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Federation Share",
      formattedDate: "25. April 2023",
      excerpt: "Im heutigen Blogbeitrag geht es um die Funktion Federation Share in Nextcloud. Wir erklären dir, was das genau ist und wie du es in deiner Nextcloud einrichten kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Federation Share</h1>
          
          <p>Im heutigen Blogbeitrag geht es um die Funktion <strong>Federation Share</strong> in Nextcloud. Wir erklären dir, was das genau ist und wie du es in deiner Nextcloud einrichten kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={shareIcon} alt="Share Icon" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Nextcloud</BlogHeading>
          
          <p>Mit Nextcloud bekommst du deine <strong>eigene Cloud</strong>. Diese kannst du alleine, mit deiner Familie und deinen Freunden oder auch geschäftlich mit deinen Kollegen nutzen. Um Dinge innerhalb einer Nextcloud zu teilen, trägst du einfach den Namen der Person ein, wählst die Berechtigungen für den Ordner aus und fertig.</p>
          <p>Aber wie funktioniert das, wenn die Person ihre eigene Nextcloud hat?</p>
          
          <BlogImage src={nextcloudHubLogo} alt="Nextcloud Hub Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Teilen kennt keine Grenzen</BlogHeading>
          
          <p>Für das Teilen mit Personen auf anderen Nextcloud Instanzen gibt es den „Nextcloud Federation Share". Mit dieser Funktion kannst du <strong>mehrere Nextcloud Instanzen miteinander verbinden</strong> und dann wie gewohnt einfach den Namen der Person eingeben, mit der du einen Ordner teilen möchtest.<br />Sind die Nextcloud Instanzen nicht miteinander verbunden, reicht auch deine <strong>Federated-Cloud-ID</strong> aus und schon kannst du zwischen verschiedenen Nextcloud Instanzen freigeben.</p>
          
          <BlogImage src={ideaIcon} alt="Idea Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <p>Um eine Verbindung zwischen zwei oder mehreren Nextcloud Instanzen einzurichten, öffne die „Administrationseinstellungen" und wähle unter „Verwaltung" den Menüpunkt „Teilen". Hier kannst du unter „Federated-Cloud-Sharing" verschiedene Einstellungen vornehmen. Die Standardeinstellungen erlauben das Empfangen von Dateien von anderen Nextcloud Servern und das gegenseitige Finden von Kontakten.<br />Darunter siehst du die Überschrift <strong>„Vertrauenswürdige" Server</strong>. Hier kannst du die Serveradresse der Nextcloud eintragen, mit der du deine eigene Nextcloud verbinden möchtest. Auf der Gegenseite muss das Gleiche eingestellt werden. Nach einer Weile wechselt die Farbe neben der Adresse von orange auf grün. Erst dann funktioniert die <strong>gegenseitige Benutzersuche</strong> und damit das <strong>direkte Teilen zwischen den Nextcloud Instanzen</strong>.</p>
          
          <BlogImage src={federatedCloudSharingImage} alt="Federated Cloud Sharing" size="large" className="my-6" />
          
          <p>Ohne diese Verbindung wird die <strong>Federated-Cloud-ID</strong> benötigt. Diese findest du in den persönlichen Einstellungen unter „Teilen". Dort kannst du die ID direkt kopieren und weitergeben.</p>
          
          <BlogImage src={federatedCloudIdImage} alt="Federated Cloud ID" size="large" className="my-6" />
          
          <BlogHeading>Ordner freigeben</BlogHeading>
          
          <p>Um einen Ordner für eine Person auf einer anderen Nextcloud freizugeben, gibt man einfach auf der rechten Menüseite unter &#8222;Teilen" den Benutzernamen@andereNextcloud ein.</p>
          <p>Der Empfänger muss die Freigabe dann in seiner Nextcloud bestätigen.</p>
          
          <BlogImage src={freigabeImage} alt="Federated Share Freigabe" size="large" className="my-6" />
          
          <BlogHeading>Vorteile und Nutzen</BlogHeading>
          
          <p>Für das Teilen mit Personen auf anderen Nextcloud Instanzen gibt es den „Nextcloud Federation Share". Mit dieser Funktion kannst du <strong>mehrere Nextcloud Instanzen miteinander verbinden</strong> und dann wie gewohnt einfach den Namen der Person eingeben, mit der du einen Ordner teilen möchtest.<br />Sind die Nextcloud Instanzen nicht miteinander verbunden, reicht auch deine <strong>Federated-Cloud-ID</strong> aus und schon kannst du zwischen verschiedenen Nextcloud Instanzen freigeben.</p>
          
          <BlogImage src={paperPlaneIcon} alt="Paper Plane Icon" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Federation Share",
      formattedDate: "25. April 2023",
      excerpt: "Im heutigen Blogbeitrag geht es um die Funktion Federation Share in Nextcloud. Wir erklären dir, was das genau ist und wie du es in deiner Nextcloud einrichten kannst.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Federation Share",
      formattedDate: "April 25, 2023",
      excerpt: "In today's blog post, we'll discuss the Federation Share feature in Nextcloud. We'll explain what it is exactly and how you can set it up in your Nextcloud.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Federation Share</h1>
          
          <p>In today's blog post, we'll discuss the <strong>Federation Share</strong> feature in Nextcloud. We'll explain what it is exactly and how you can set it up in your Nextcloud.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Federation Share",
      formattedDate: "25 April 2023",
      excerpt: "In today's blog post, we'll discuss the Federation Share feature in Nextcloud. We'll explain what it is exactly and how you can set it up in your Nextcloud.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;