// src/data/blogPosts/mailcow-e-mail-server.js

// Import all external modules first
import thunderbirdLogo from '../../assets/images/blog/2023/01/480px-Mozilla_Thunderbird_Logo.svg.png';
import emailSync from '../../assets/images/blog/2024/02/E-Mail-sync.png';
import domainAdd from '../../assets/images/blog/2024/02/Domain-hinzufuegen.png';
import mailboxAdd from '../../assets/images/blog/2024/02/Mailbox-hinzufuege.png';
import thunderbirdAccount from '../../assets/images/blog/2024/02/Thunderbird-E-Mail-Konto-hizufuegen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection 
} from '../../components/blog';

// Using static placeholders to resolve SVG namespace issues (after imports)
const thumbnailImage = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjcxIiBoZWlnaHQ9IjI5OSIgdmlld0JveD0iMCAwIDI3MSAyOTkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3Qgd2lkdGg9IjI3MSIgaGVpZ2h0PSIyOTkiIGZpbGw9IiM1YTM2MjAiLz48cGF0aCBkPSJNMTM1LjUgNzVMMjAzLjI3OSAxOTAuNUg2Ny43MjA1TDEzNS41IDc1WiIgZmlsbD0iI2ZlZTcwZiIvPjwvc3ZnPg==';
const mailcowLogo = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwIiBoZWlnaHQ9IjEwMCIgdmlld0JveD0iMCAwIDEwMCAxMDAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iNTAiIGN5PSI1MCIgcj0iNDAiIGZpbGw9IiM1YTM2MjAiLz48cGF0aCBkPSJNMzUgNTBINjVNNTAgMzVWNjUiIHN0cm9rZT0iI2ZlZTcwZiIgc3Ryb2tlLXdpZHRoPSI0Ii8+PC9zdmc+';

/**
 * Multilingual blog post about Mailcow Email Server
 */
const post = {
  id: '16382',
  slug: 'mailcow-e-mail-server',
  
  // Base data - same regardless of language
  date: "2024-02-12T10:19:58.000Z",
  readTime: 9,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 8,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Mailcow E-Mail Server",
      formattedDate: "12. Februar 2024",
      excerpt: "Mit einem Mailcow E-Mail Server kannst du verschiedene Postfächer für deine eigene Domain anlegen. In diesem Artikel erklären wir dir was überhaupt ein E-Mail Server ist und zeigen dir die ersten Schritte mit Mailcow.",
      content: () => (
        <BlogContent>
          <p>Mit einem Mailcow E-Mail Server kannst du verschiedene Postfächer für deine eigene Domain anlegen. In diesem Artikel erklären wir dir was überhaupt ein E-Mail Server ist und zeigen dir die ersten Schritte mit Mailcow.</p>
          
          <BlogImage src={mailcowLogo} alt="Mailcow Logo" size="medium" className="my-6" />
          
          <BlogHeading>E-Mail Server & Client</BlogHeading>
          
          <p>Vereinfacht gesagt, sendet und empfängt ein E-Mail-Server Nachrichten. Wenn du eine E-Mail an eine andere Person sendest, sendet dein E-Mail-Server die Nachricht an den E-Mail-Server der anderen Person.</p>
          
          <p>Mit einem E-Mail-Client wie Thunderbird kannst du dich mit den Postfächern auf deinem E-Mail-Server verbinden. So kannst du Nachrichten vom Server empfangen und versenden.</p>
          
          <BlogImage src={thunderbirdLogo} alt="Thunderbird Icon" size="medium" className="my-6" />
          
          <BlogHeading>Mailcow E-Mail Server</BlogHeading>
          
          <p>Mailcow basiert auf mehreren Open Source Tools, die zusammen einen kompletten Mailserver inklusive Webmail und Spamschutz ergeben. Über das Webinterface können verschiedene Einstellungen vorgenommen, Filterregeln erstellt und natürlich neue Mailboxen und Domains hinzugefügt werden.</p>
          
          <BlogImage src={emailSync} alt="Mailcow E-Mail Server E-Mail sync" size="large" className="my-6" />
          
          <BlogHeading>Domain anlegen</BlogHeading>
          
          <p>Zuerst muss eine Domäne hinzugefügt werden. Klicke dazu in der oberen Menüleiste auf E-Mail – Einstellungen. Um eine neue Domain hinzuzufügen, klicke auf „+ Domain hinzufügen" und trage deine Domain ein. Danach musst du noch einige DNS-Einstellungen für deine Domain vornehmen. Welche das sind, siehst du, wenn du rechts neben der Domain auf den Button „DNS" klickst. Sobald alle Einträge hier einen grünen Haken haben, kannst du mit den Postfächern weitermachen.</p>
          
          <BlogImage src={domainAdd} alt="Mailcow E-Mail Server Domain-hinzufügen" size="large" className="my-6" />
          
          <BlogHeading>Postfach anlegen</BlogHeading>
          
          <p>Im zweiten Schritt werden die Mailboxen angelegt. Klicke dazu auf „Mailboxen" und dann auf „+ Mailbox hinzufügen". In der oberen Textzeile wird der erste Teil der E-Mail-Adresse eingetragen, z.B. m.mustermann. Unter „Domain" wird die soeben angelegte Domain ausgewählt. Anschließend werden noch Vor- und Nachname sowie ein Passwort benötigt.</p>
          
          <BlogImage src={mailboxAdd} alt="Mailcow E-Mail Server Mailbox hinzufügen" size="large" className="my-6" />
          
          <BlogHeading>Postfächer importieren</BlogHeading>
          
          <p>Um die E-Mails aus den bisherigen Postfächern zu importieren, klicke auf „Synchronisation" und erstelle mit „+ Neuen Sync-Job erstellen" eine neue Synchronisation. Hier werden die Daten des bisherigen E-Mail-Providers eingetragen und anschließend die Synchronisation gestartet. Nach wenigen Minuten sind alle E-Mails im neuen Postfach.</p>
          
          <BlogImage src={emailSync} alt="Mailcow E-Mail Server E-Mail sync" size="large" className="my-6" />
          
          <BlogHeading>In Thunderbird einbinden</BlogHeading>
          
          <p>Um E-Mails direkt auf deinem Computer senden und empfangen zu können, kannst du dein soeben erstelltes Postfach in Thunderbird integrieren. Klicke dazu in Thunderbird auf das Zahnrad in der linken unteren Ecke – Kontoeinstellungen – Kontoaktionen – E-Mail-Konto hinzufügen. Hier musst du nichts weiter tun, als deinen Namen, deine E-Mail-Adresse und dein Passwort einzugeben. Alle anderen Einstellungen erkennt Thunderbird automatisch.</p>
          
          <BlogImage src={thunderbirdAccount} alt="Thunderbird E-Mail Konto hinzufügen" size="large" className="my-6" />
          
          <BlogHeading>Mailcow E-Mail Server Hosting</BlogHeading>
          
          <p>Wir stellen dir deine eigene Mailcow-Installation inklusive Wartung & Support auf einem virtuellen Server zur Verfügung. Falls du Hilfe bei der Einrichtung benötigst oder sonstige Fragen hast, schreibe uns einfach. Wir helfen dir gerne weiter.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Mailcow E-Mail Server",
      formattedDate: "12. Februar 2024",
      excerpt: "Mit einem Mailcow E-Mail Server kannst du verschiedene Postfächer für deine eigene Domain anlegen. In diesem Artikel erklären wir dir was überhaupt ein E-Mail Server ist und zeigen dir die ersten Schritte mit Mailcow.",
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Mailcow Email Server",
      formattedDate: "February 12, 2024",
      excerpt: "With a Mailcow email server, you can create various mailboxes for your own domain. In this article, we explain what an email server is and show you the first steps with Mailcow.",
      content: () => (
        <BlogContent>
          <p>With a Mailcow email server, you can create various mailboxes for your own domain. In this article, we explain what an email server is and show you the first steps with Mailcow.</p>
          
          <BlogImage src={mailcowLogo} alt="Mailcow Logo" size="medium" className="my-6" />
          
          <BlogHeading>Email Server & Client</BlogHeading>
          
          <p>Simply put, an email server sends and receives messages. When you send an email to another person, your email server sends the message to the recipient's email server.</p>
          
          <p>With an email client like Thunderbird, you can connect to the mailboxes on your email server. This way, you can receive and send messages from the server.</p>
          
          <BlogImage src={thunderbirdLogo} alt="Thunderbird Icon" size="medium" className="my-6" />
          
          <BlogHeading>Mailcow Email Server</BlogHeading>
          
          <p>Mailcow is based on several open source tools that together form a complete mail server including webmail and spam protection. Various settings can be made via the web interface, filter rules can be created, and of course, new mailboxes and domains can be added.</p>
          
          <BlogImage src={emailSync} alt="Mailcow Email Server Email sync" size="large" className="my-6" />
          
          <BlogHeading>Adding a Domain</BlogHeading>
          
          <p>First, a domain must be added. To do this, click on Email – Settings in the top menu bar. To add a new domain, click on "+ Add Domain" and enter your domain. After that, you'll need to make some DNS settings for your domain. You can see which ones these are by clicking the "DNS" button next to the domain. Once all entries have a green checkmark here, you can proceed with the mailboxes.</p>
          
          <BlogImage src={domainAdd} alt="Mailcow Email Server Domain add" size="large" className="my-6" />
          
          <BlogHeading>Creating a Mailbox</BlogHeading>
          
          <p>In the second step, the mailboxes are created. Click on "Mailboxes" and then on "+ Add Mailbox". In the upper text line, enter the first part of the email address, e.g., m.mustermann. Under "Domain", select the domain you just added. Then you will need to enter a first name, last name, and password.</p>
          
          <BlogImage src={mailboxAdd} alt="Mailcow Email Server Mailbox add" size="large" className="my-6" />
          
          <BlogHeading>Importing Mailboxes</BlogHeading>
          
          <p>To import emails from existing mailboxes, click on "Synchronization" and create a new synchronization with "+ Create New Sync Job". Here, enter the data of the previous email provider and then start the synchronization. After a few minutes, all emails will be in the new mailbox.</p>
          
          <BlogImage src={emailSync} alt="Mailcow Email Server Email sync" size="large" className="my-6" />
          
          <BlogHeading>Integration with Thunderbird</BlogHeading>
          
          <p>To be able to send and receive emails directly on your computer, you can integrate your newly created mailbox into Thunderbird. In Thunderbird, click on the gear icon in the lower left corner – Account Settings – Account Actions – Add Email Account. Here you just need to enter your name, email address, and password. Thunderbird automatically recognizes all other settings.</p>
          
          <BlogImage src={thunderbirdAccount} alt="Thunderbird Email Account add" size="large" className="my-6" />
          
          <BlogHeading>Mailcow Email Server Hosting</BlogHeading>
          
          <p>We provide you with your own Mailcow installation including maintenance & support on a virtual server. If you need help with the setup or have any other questions, just write to us. We'll be happy to help.</p>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Mailcow Email Server",
      formattedDate: "12 February 2024",
      excerpt: "With a Mailcow email server, you can create various mailboxes for your own domain. In this article, we explain what an email server is and show you the first steps with Mailcow.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;