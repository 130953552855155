// src/data/blogPosts/2-faktor-authentifizierung.js

// Import blog components
import { 
	BlogContent, 
	BlogHeading,
	BlogImage 
  } from '../../components/blog';
  
  // Import images
  import thumbnailImage from '../../assets/images/blog/2-faktor-authentifizierung-featured.jpg';
  import lockIcon from '../../assets/images/blog/2022/01/lock.png';
  import keyIcon from '../../assets/images/blog/2022/01/key.png';
  import questionIcon from '../../assets/images/blog/2022/01/question.png';
  import smartphoneIcon from '../../assets/images/blog/2022/01/smartphone.png';
  import settingsImage from '../../assets/images/blog/2022/01/Einstellungen-nextcloud.png';
  import securitySettingsImage from '../../assets/images/blog/2022/01/Bildschirmfoto-vom-2023-03-02-14-37-38.png';
  import qrCodeImage from '../../assets/images/blog/2022/01/Bildschirmfoto-vom-2023-03-02-14-38-35.png';
  import backupCodesImage from '../../assets/images/blog/2022/01/Bildschirmfoto-vom-2023-03-02-14-39-39.png';
  import loginImage from '../../assets/images/blog/2022/01/Bildschirmfoto-vom-2023-03-02-14-41-43.png';
  
  /**
   * Blog post about Two-Factor Authentication
   */
  const post = {
	id: '6266',
	slug: '2-faktor-authentifizierung',
	
	// Base data - same regardless of language
	date: "2023-03-02T13:47:46.000Z",
	readTime: 11,
	categories: ["Anleitungen"],
	thumbnail: thumbnailImage,
	thumbnailUrl: thumbnailImage,
	thumbnailSize: 'medium',
	showInPostHeader: true,
	likes: 8,
	comments: [],
	
	// Language-specific data
	translations: {
	  // German content
	  de: {
		title: "2-Faktor Authentifizierung",
		formattedDate: "2. März 2023",
		excerpt: "In diesem Artikel geht es um die 2-Faktor Authentifizierung. Hier erfährst du was das überhaupt ist, welche Vorteile du davon hast und wie du sie in deiner Nextcloud einrichten kannst.",
		content: () => (
		  <BlogContent>
			<h1 className="text-3xl md:text-4xl font-bold mb-4">Zwei-Faktor-Authentifizierung</h1>
			
			<p>In diesem Artikel geht es um die 2-Faktor-Authentifizierung. Hier erfährst du, was es damit auf sich hat, welche Vorteile es bringt und wie du es in deiner Nextcloud einrichten kannst.</p>
			
			<div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
			  <div className="w-full md:w-1/2 text-center">
				<BlogImage src={lockIcon} alt="Schloss Icon" size="medium" />
			  </div>
			  
			</div>
			
			<BlogHeading>Was ist überhaupt ein zweiter Faktor?</BlogHeading>
			
			<p>Ein zweiter Faktor bedeutet, dass zusätzlich zum Passwort eine zweite Verifizierung stattfindet.<br />Dies kann auf verschiedene Arten geschehen, z.B. über einen <strong>6-stelligen Code</strong>, der auf dem Smartphone generiert wird, über eine <strong>Bestätigungs-E-Mail,</strong> über <strong>SMS</strong>, über ein <strong>U2F-Gerät</strong> oder man wird auf dem Smartphone aufgefordert, die Anmeldung zu bestätigen.<br />Der zweite Faktor ändert sich im Gegensatz zu deinem Passwort bei jeder Anmeldung. Es wird also jedes Mal ein <strong>neuer Code generiert</strong> oder eine neue Bestätigungsanfrage gesendet.</p>
			
			<div className="flex justify-center my-4">
			  <BlogImage src={keyIcon} alt="Schlüssel Icon" size="small" />
			</div>
			
			<BlogHeading>Aber was soll das bringen?</BlogHeading>
			
			<p>Der zweite Faktor dient als zusätzliche <strong>Sicherheitsmaßnahme</strong> für deinen Account. Sollte ein Angreifer dein Passwort herausfinden, hat er trotzdem keinen Zugriff, da nach der Passworteingabe zusätzlich der zweite Faktor abgefragt wird. So kannst du mit wenig Aufwand die Sicherheit deines Accounts erhöhen. Bei PathConnect wirst du zusätzlich benachrichtigt, wenn ein ungewöhnlicher Login erkannt wird.</p>
			
			<div className="flex justify-center my-4">
			  <BlogImage src={questionIcon} alt="Fragezeichen Icon" size="small" />
			</div>
			
			<BlogHeading>Welche Art von 2-Faktor Authentifizierung soll ich wählen?</BlogHeading>
			
			<p>Wir empfehlen <strong>TOTP</strong> (Time-based One-time Password), da bei diesem Verfahren ca. alle 60 Sekunden ein neuer 6-stelliger Code auf dem Smartphone generiert wird. Eine SMS ist unverschlüsselt und könnte theoretisch abgefangen werden. Eine E-Mail ist standardmäßig ebenfalls unverschlüsselt, außerdem besteht hier die Gefahr, dass viele das gleiche Passwort auch für ihr E-Mail-Konto verwenden. Die Bestätigung auf dem Smartphone hat bei unseren Versuchen leider etwas lange gedauert.<br />Wer ein paar Euro mehr ausgeben möchte, dem sei ein U2F-Gerät empfohlen.</p>
			
			<div className="flex justify-center my-4">
			  <BlogImage src={smartphoneIcon} alt="Smartphone Icon" size="small" />
			</div>
			
			<BlogHeading>Und wie aktiviere ich das bei Nextcloud?</BlogHeading>
			
			<p>Wir zeigen die 2-Faktor Aktivierung für die Variante mit einer TOTP.</p>
			
			<p>Dafür benötigt ihr eine TOTP-App auf eurem Smartphone</p>
			
			<p><strong>Android:</strong><br />
			<a href="https://play.google.com/store/apps/details?id=me.jmh.authenticatorpro&hl=de_AT&gl=US&pli=1" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=me.jmh.authenticatorpro&hl=de_AT&gl=US&pli=1</a></p>
			
			<p><strong>iOS</strong><br />
			<a href="https://apps.apple.com/de/app/freeotp-authenticator/id872559395" target="_blank" rel="noopener">https://apps.apple.com/de/app/freeotp-authenticator/id872559395</a></p>
			
			<p><strong>Danach geht es in der Nextcloud Weboberfläche weiter:</strong></p>
			
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
			  <div>
				<h3 className="font-bold text-xl mb-3">1. Rechts oben auf euer Kürzel klicken - Einstellungen</h3>
				<BlogImage src={settingsImage} alt="Nextcloud Einstellungen" size="medium" />
			  </div>
			  <div>
				<h3 className="font-bold text-xl mb-3">2. Auf Sicherheit klicken - TOTP aktivieren</h3>
				<BlogImage src={securitySettingsImage} alt="Sicherheitseinstellungen" size="medium" />
			  </div>
			  <div>
				<h3 className="font-bold text-xl mb-3">3. QR-Code mit der TOTP-App abscannen und den angezeigten Code eingeben</h3>
				<BlogImage src={qrCodeImage} alt="QR-Code scannen" size="medium" />
			  </div>
			  <div>
				<h3 className="font-bold text-xl mb-3">4. Backup-Codes erzeugen und abspeichern</h3>
				<BlogImage src={backupCodesImage} alt="Backup-Codes" size="medium" />
			  </div>
			</div>
			
			<div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
			  <div>
				<BlogHeading>Verwendung</BlogHeading>
				<p>Beim nächsten Login wird nach der Passworteingabe der 6-stellige Code aus der App abgefragt.</p>
				<p>Danach klickst du auf "Übermitteln" und schon bist du eingeloggt.</p>
			  </div>
			  <div className="flex justify-center items-center">
				<BlogImage src={loginImage} alt="2FA Login" size="medium" />
			  </div>
			</div>
			
			<BlogHeading>Open Source Software bei PathConnect</BlogHeading>
			
			<p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
			
			
		  </BlogContent>
		)
	  },
	  
	  // Same content for full locale format
	  de_DE: {
		title: "2-Faktor Authentifizierung",
		formattedDate: "2. März 2023",
		excerpt: "In diesem Artikel geht es um die 2-Faktor Authentifizierung. Hier erfährst du was das überhaupt ist, welche Vorteile du davon hast und wie du sie in deiner Nextcloud einrichten kannst.",
		get content() { return post.translations.de.content; }
	  }
	},
	
	// Default getters for convenience
	get title() {
	  return this.translations.de.title;
	},
	
	get formattedDate() {
	  return this.translations.de.formattedDate;
	},
	
	get excerpt() {
	  return this.translations.de.excerpt;
	},
	
	get content() {
	  return this.translations.de.content;
	}
  };
  
  export default post;