// Layout.js
import React from 'react'
import PropTypes from 'prop-types'
import Header from './layout/header'
import Footer from './layout/footer'

const Layout = ({ children }) => {
  return (
    <div className="flex flex-col min-h-screen w-screen">
      <Header />
      <main className="flex-grow pt-24 pb-24">{children}</main>
      <Footer />
    </div>
  )
}
Layout.propTypes = {
  children: PropTypes.node.isRequired
}

export default Layout
