// src/data/blogPosts/nextcloud-android.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading 
} from '../../components/blog';

/**
 * Blog post about Nextcloud Android automatic image upload
 */
const post = {
  id: '9649',
  slug: 'nextcloud-android',
  
  // Base data - same regardless of language
  date: "2022-11-23T11:38:24.000Z",
  readTime: 10,
  categories: ["Anleitungen"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 26,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Android – Automatischer Bilder Upload",
      formattedDate: "23. November 2022",
      excerpt: "In diesem Blogartikel geht es darum wie du deine Bilder von deinem Android Smartphone automatisch in die Nextcloud laden kannst. Wir zeigen dir wie du das in nur wenigen Schritten erledigt und welche Vorteile dir der automatische Upload gibt.",
      content: () => (
        <BlogContent>
          <p>In diesem Blogartikel geht es darum wie du deine Bilder von einem Android Smartphone automatisch in die Nextcloud laden kannst. Wir zeigen dir, wie du das in nur wenigen Schritten erledigen kannst und welche Vorteile der automatische Upload hat.</p>
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <ol>
            <li>
              <p>Öffne die Nextcloud App auf deinem Smartphone</p>
            </li>
            <li>
              <p>Links oben findest du <b>drei horizontal Striche</b> die untereinander angeordnet sind – hier bitte einmal darauf tippen</p>
            </li>
            <li>
              <p>Es öffnet sich eine Seitenleiste, hier auf den Menüpunkt <b>„Einstellungen"</b> tippen</p>
            </li>
            <li>
              <p>Jetzt befindest du dich in den Nextcloud – Einstellungen und kannst den Punkt <b>„Automatisches hochladen"</b> auswählen</p>
            </li>
            <li>
              <p>Im nächsten Fenster befinden sich alle Ordner von deinem Smartphone die Bilder oder Videos enthalten.
              Android erstellt manchmal mehrere Ordner mit Handybildern, achte also darauf, dass du den <b>Ordner auswählt in dem die aktuellen Aufnahmen</b> zu sehen sind.</p>
            </li>
            <li>
              <p>Neben dem Ordnernamen, ganz rechts sind <b>drei Punkte untereinander angeordnet</b>, hier einmal darauf tippen und „Einrichten" auswählen</p>
            </li>
            <li>
              <p>Hier kannst du das <b>automatische hochladen</b> durch tippen auf den blauen Punkt aktiviere
              – Bei <b>„Remote Ordner"</b> wählst du den Ordner auf der Nextcloud aus, in den deine Bilder hochgeladen werden sollen
              – Du kannst außerdem festlegen, dass die Bilder nur über <b>WLAN</b> oder nur während des Ladens des Akkus hochgeladen werden sollen
              – Möchtest du <b>bereits vorhanden Bilder</b> hochladen kannst du auch diese Option auswählen</p>
            </li>
            <li>
              <p>Hast du deine gewünschten Einstellungen festgelegt kannst du auf <b>Speichern</b> tippen</p>
            </li>
            <li>
              <p>Es erscheint eine Meldung die dich auffordert die <b>Batterie-Optimierung</b> zu deaktivieren damit die Bilder im Hintergrund hochgeladen werden können – Tippe hier bitte auf "Deaktivieren"</p>
            </li>
          </ol>
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p>Nach der Einrichtung laden deine Bilder ganz automatisch in deine Nextcloud, du musst dir keine Gedanken mehr darüber machen ob deine Bilder gesichert sind oder nicht. Du kannst außerdem ebenfalls deinen Bilderordner mit anderen Geräten wie z.B. deinem Computer synchronisieren damit du deine Bilder auch gleich dort parat hast.</p>
          
          <BlogHeading>Nextcloud Android App</BlogHeading>
          
          <p>Playstore<br />
          <a href="https://play.google.com/store/apps/details?id=com.nextcloud.client" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=com.nextcloud.client</a></p>
          
          <p>F-Droid<br />
          <a href="https://f-droid.org/de/packages/com.nextcloud.client/" target="_blank" rel="noopener">https://f-droid.org/de/packages/com.nextcloud.client/</a></p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Android – Automatischer Bilder Upload",
      formattedDate: "23. November 2022",
      excerpt: "In diesem Blogartikel geht es darum wie du deine Bilder von deinem Android Smartphone automatisch in die Nextcloud laden kannst. Wir zeigen dir wie du das in nur wenigen Schritten erledigt und welche Vorteile dir der automatische Upload gibt.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;