// src/data/blogPosts/kalender-und-kontakt-synchronisieren.js

// Import images
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import calendarIcon from '../../assets/images/blog/2022/03/calendar.png';
import customerIcon from '../../assets/images/blog/2022/01/Verbraucher.png';
import thumbnailImage from '../../assets/images/blog/kalender-und-kontakt-synchronisieren-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about syncing calendar and contacts with iPhone
 */
const post = {
  id: '7087',
  slug: 'kalender-und-kontakt-synchronisieren',
  
  // Base data - same regardless of language
  date: "2022-03-13T09:22:44.000Z",
  readTime: 6,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 19,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Kalender und Kontakt auf das iPhone synchronisieren",
      formattedDate: "13. März 2022",
      excerpt: "Nextcloud ist eine umfangreiche Cloud-Lösung, die mehr bietet als Dateien in die Cloud zu laden. Eine weitere Stärke ist die Kalender- und Kontaktverwaltung, die mittels CalDAV/CardDAV synchronisiert werden können. In diesem Blogpost erklären wir euch, wie ihr eure Kalender und Kontakte mir eurem iPhone synchronisiert.",
      content: () => (
        <BlogContent>
          <p>Nextcloud ist eine umfangreiche Cloud-Lösung, die mehr bietet als Dateien in die Cloud zu laden.<br />Eine weitere Stärke ist die <strong>Kalender- und Kontaktverwaltung</strong>, die mittels CalDAV/CardDAV synchronisiert werden können. In diesem Blogpost erklären wir euch, wie ihr eure Kalender und Kontakte mir eurem <strong>iPhone synchronisiert.</strong></p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Kalender auf das iPhone synchronisieren</BlogHeading>
          
          <ol>
            <li>Öffne die &#8222;Einstellungen&#8220; -App </li>
            <li>Wähle &#8222;Kalender&#8220;</li>
            <li>Tippe auf &#8222;Account hinzufügen&#8220;</li>
            <li>Auf &#8222;Anderer&#8220; als Account-Typ</li>
            <li>Tippe auf &#8222;CalDAV-Account hinzufügen&#8220;</li>
            <li>Gebe deine Serveradresse ein.</li>
            <li>Bei Benutzername trägst du dein Benutzername ein, den du auch im Browser verwendest</li>
            <li>Für das Passwort generierst du dir ein sogenannte &#8222;App-Passwort&#8220; in den Einstellungen in der Nextcloud Weboberfläche:<br /><em>Einstellungen – Sicherheit – Neues App-Passwort erstellen</em><br /><strong>Tipp:</strong> Kopiere dir das Passwort in eine Textdatei und öffene diese dann in der Nextcloud App auf deinem Smartphone<br />damit du es nicht abtippen musst</li>
            <li>Auf &#8222;Weiter&#8220; tippen und speichern</li>
            <li>Geschafft! Der Kalender wird nun mit und über Nextcloud synchronisiert</li>
          </ol>
          
          <BlogImage src={calendarIcon} alt="Kalender Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Kontakte auf das iPhone synchronisieren</BlogHeading>
          
          <p>Natürlich könnt ihr auch eure <strong>Kontakte</strong> über Nextcloud auf das iPhone <strong>synchronisieren</strong> und sichern. So schützt ihr nicht nur eure <strong>Privatsphäre</strong> sondern auch die von euren Freunden, Verwandten und Geschäftskollegen. Auch wenn das iPhone kaputt geht habt ihr immer alle Kontakte auf eurer Nextcloud gespeichert.</p>
          
          <ol>
            <li>Öffne die &#8222;Einstellungen&#8220; -App </li>
            <li>Wähle &#8222;Mail, Kontakte, Kalender&#8220;</li>
            <li>Tippe auf &#8222;Account hinzufügen&#8220;</li>
            <li>Auf &#8222;Anderer&#8220; als Account-Typ</li>
            <li>Tippe auf &#8222;CardDAV-Account hinzufügen&#8220;</li>
            <li>Gebe deine Serveradresse ein. </li>
            <li>Bei Benutzername trägst du dein Benutzername ein den du auch im Browser verwendest</li>
            <li>Für das Passwort Feld generierst du dir ein sogenannte App-Passwort in den Einstellungen in der Nextcloud Weboberfläche:<br /><em>Einstellungen – Sicherheit – Neues App-Passwort erstellen</em><br /><strong>Tipp:</strong> Kopiere dir das Passwort in eine Textdatei und öffene diese dann in der Nextcloud App auf deinem Smartphone<br />damit du es nicht abtippen musst</li>
            <li>Auf &#8222;Weiter&#8220; tippen und speichern</li>
            <li>Geschafft! Deine Kontakte werden nun mit und über Nextcloud synchronisiert.</li>
          </ol>
          
          <BlogImage src={customerIcon} alt="Verbraucher Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Zusammenfassung</BlogHeading>
          
          <p>Mit Nextcloud könnte ihr problemlos eure Kontakte und Kalender auf das iPhone synchronisieren.</p>
          
          <p><strong><u>Vorteile:</u></strong></p>
          
          <ul>
            <li>Schützt eure Privatsphäre</li>
            <li>Eure Kontakte und Kalendereinträge bleiben unter eurer Kontrolle und landen nicht bei Apple</li>
            <li>Kalender -und Kontakteinträge von der Nextcloud Weboberfläche werden automatisch auf das iPhone synchronisiert</li>
            <li>Kein Verlust von Kalendereinträgen und Kontakten</li>
          </ul>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Kalender und Kontakt auf das iPhone synchronisieren",
      formattedDate: "13. März 2022",
      excerpt: "Nextcloud ist eine umfangreiche Cloud-Lösung, die mehr bietet als Dateien in die Cloud zu laden. Eine weitere Stärke ist die Kalender- und Kontaktverwaltung, die mittels CalDAV/CardDAV synchronisiert werden können. In diesem Blogpost erklären wir euch, wie ihr eure Kalender und Kontakte mir eurem iPhone synchronisiert.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Synchronize Calendar and Contacts with iPhone",
      formattedDate: "March 13, 2022",
      excerpt: "Nextcloud is a comprehensive cloud solution that offers more than just uploading files to the cloud. Another strength is the calendar and contact management, which can be synchronized using CalDAV/CardDAV. In this blog post, we'll explain how to synchronize your calendars and contacts with your iPhone.",
      content: () => (
        <BlogContent>
          <p>Nextcloud is a comprehensive cloud solution that offers more than just uploading files to the cloud.<br />Another strength is the <strong>calendar and contact management</strong>, which can be synchronized using CalDAV/CardDAV. In this blog post, we'll explain how to synchronize your calendars and contacts with your <strong>iPhone</strong>.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Synchronize Calendar to iPhone</BlogHeading>
          
          <ol>
            <li>Open the "Settings" app</li>
            <li>Select "Calendar"</li>
            <li>Tap on "Add Account"</li>
            <li>Select "Other" as account type</li>
            <li>Tap on "Add CalDAV Account"</li>
            <li>Enter your server address</li>
            <li>For username, enter the same username you use in the browser</li>
            <li>For the password, generate an "App Password" in the settings of the Nextcloud web interface:<br /><em>Settings – Security – Create new app password</em><br /><strong>Tip:</strong> Copy the password to a text file and open it in the Nextcloud app on your smartphone so you don't have to type it manually</li>
            <li>Tap on "Next" and save</li>
            <li>Done! The calendar will now be synchronized with and through Nextcloud</li>
          </ol>
          
          <BlogImage src={calendarIcon} alt="Calendar Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Synchronize Contacts to iPhone</BlogHeading>
          
          <p>Of course, you can also <strong>synchronize</strong> and back up your <strong>contacts</strong> via Nextcloud to your iPhone. This way, you protect not only your own <strong>privacy</strong> but also that of your friends, relatives, and business associates. Even if your iPhone breaks, you'll always have all your contacts stored on your Nextcloud.</p>
          
          <ol>
            <li>Open the "Settings" app</li>
            <li>Select "Mail, Contacts, Calendars"</li>
            <li>Tap on "Add Account"</li>
            <li>Select "Other" as account type</li>
            <li>Tap on "Add CardDAV Account"</li>
            <li>Enter your server address</li>
            <li>For username, enter the same username you use in the browser</li>
            <li>For the password field, generate an App Password in the settings of the Nextcloud web interface:<br /><em>Settings – Security – Create new app password</em><br /><strong>Tip:</strong> Copy the password to a text file and open it in the Nextcloud app on your smartphone so you don't have to type it manually</li>
            <li>Tap on "Next" and save</li>
            <li>Done! Your contacts will now be synchronized with and through Nextcloud</li>
          </ol>
          
          <BlogImage src={customerIcon} alt="User Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Summary</BlogHeading>
          
          <p>With Nextcloud, you can easily synchronize your contacts and calendars to your iPhone.</p>
          
          <p><strong><u>Benefits:</u></strong></p>
          
          <ul>
            <li>Protects your privacy</li>
            <li>Your contacts and calendar entries remain under your control and don't end up with Apple</li>
            <li>Calendar and contact entries from the Nextcloud web interface are automatically synchronized to your iPhone</li>
            <li>No loss of calendar entries and contacts</li>
          </ul>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customization, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Synchronise Calendar and Contacts with iPhone",
      formattedDate: "13 March 2022",
      excerpt: "Nextcloud is a comprehensive cloud solution that offers more than just uploading files to the cloud. Another strength is the calendar and contact management, which can be synchronised using CalDAV/CardDAV. In this blog post, we'll explain how to synchronise your calendars and contacts with your iPhone.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;