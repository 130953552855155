// src/data/blogPosts/nextcloud-kalender.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-kalender-featured.png';
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import syncIcon from '../../assets/images/blog/2022/03/sync.png';
import kalenderIconMenuleiste from '../../assets/images/blog/2022/12/Nextcloud-Kalender-icon-Menueleiste.png';
import neuerKalenderHinzufuegen from '../../assets/images/blog/2022/12/Neuer-Kalender-hinzufuegen.png';
import kalenderTeilen from '../../assets/images/blog/2022/12/Kalender-teilen.png';
import kalenderImportieren from '../../assets/images/blog/2022/12/Kalender-importieren.png';
import securityIcon from '../../assets/images/blog/2022/01/security.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Calendar
 */
const post = {
  id: '9974',
  slug: 'nextcloud-kalender',
  
  // Base data - same regardless of language
  date: "2022-12-02T10:27:36.000Z",
  readTime: 12,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 13,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Kalender",
      formattedDate: "2. Dezember 2022",
      excerpt: "In diesem Blogartikel widmen wir uns der Nextcloud Kalender App. Du erfährst was genau dahinter steckt, wie du den Kalender verwendest und welche Vorteile dir das bringt.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Kalender</h1>
          
          <p className="western" align="left">In diesem Blogartikel widmen wir uns der <b>Nextcloud Kalender</b> App. Du erfährst was genau dahinter steckt, wie du den Kalender verwendest und welche Vorteile dir das bringt.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Noch ein Kalender?</BlogHeading>
          
          <p className="western" align="left">Jedes Smartphone, egal ob iOS oder Android, hat bereits eine vorinstallierte Kalender App. Und auch bei Linux, Windows und MacOS gibt es schon integrierte Kalender. Wieso also noch einen in Nextcloud?</p>
          <p className="western" align="left">Die Frage ist allerdings nicht, wieso noch einen Kalender, sondern <b>wie bekomme ich meine vielen Kalender zusammen</b>, so dass ich auf allen Geräten die gleichen Einträge habe? Und hier kommt die Lösung von Nextcloud ins Spiel.</p>
          
          <BlogHeading>Einer für alle</BlogHeading>
          
          <p className="western" align="left">Erstelle einfach einen Kalender in der Nextcloud Weboberfläche, diesen kannst du dann auf deine Geräte <b>synchronisieren</b>. Dazu brauchst du nicht noch eine zusätzliche Kalender App sondern kannst schon deine bestehende verwenden.</p>
          
          <BlogImage src={syncIcon} alt="Sync Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Kalender in der Nextcloud Weboberfläche erstellen</BlogHeading>
          
          <p className="western" align="left">Klicke dazu in der Nextcloud Weboberfläche auf das „<b>Kalender Icon</b>" in der oberen Menüleiste und dann auf „<b>Neuen Kalender</b>". Hier kannst du dann einen passenden Namen eingeben.</p>
          <p>Durch Bestätigung der Pfeiltaste wird der Kalender erstellt. Klicke auf die drei Punkte und du hast die Möglichkeit den Namen wieder zu ändern, die Farbe anzupassen, einen privaten Link zu kopieren, den Kalender zu exportieren oder auch wieder zu löschen.</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={kalenderIconMenuleiste} alt="Nextcloud Kalender Icon in Menüleiste" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={neuerKalenderHinzufuegen} alt="Neuer Kalender hinzufügen" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <BlogHeading>Kalender teilen</BlogHeading>
          
          <p className="western" align="left">Eine weitere sehr nützliche Option ist das <b>Teilen</b> mit anderen. Dazu klickst du einfach auf das „Teilen Symbol". Hier kannst du einen Benutzernamen eingeben und auch festlegen ob derjenige ebenfalls Einträge erstellen darf und nur Leseberechtigungen bekommt.</p>
          
          <BlogImage src={kalenderTeilen} alt="Kalender teilen" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Andere Kalender importieren</BlogHeading>
          
          <p className="western" align="left">Damit du nicht deine Einträge aus einem anderen Kalender verlierst kannst du diese einfach einmalig <b>importieren</b>. Klicke dazu auf auf „Kalender Einstellungen" und dann auf „Kalender importieren".</p>
          
          <BlogImage src={kalenderImportieren} alt="Kalender importieren" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Kalender synchronisieren</BlogHeading>
          
          <p className="western" align="left">Damit du auch wirklich auf allen Geräten den gleichen Kalendereinträge hast, muss dieser synchronisiert werden. Da das auf jedem Betriebssystem etwas anders ist, suche nach Anleitungen für dein spezifisches Betriebssystem (Android, iOS, Linux oder Windows).</p>
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p className="western" align="left">Wie bereits schon erwähnt hast du dadurch auf allen Geräten die gleichen Kalendereinträge und bist somit überall auf dem <b>gleichen Stand</b>.</p>
          <p className="western" align="left">Deine Kalender werden nicht über die Dienste von Google, Microsoft oder Apple synchronisiert sondern <b>über deine eigene Nextcloud</b>. Dadurch werden keinerlei Daten von dir ausgewertet und deine digitale <b>Privatsphäre</b> bleibt bewahrt.</p>
          
          <BlogImage src={securityIcon} alt="Security Icon" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Kalender",
      formattedDate: "2. Dezember 2022",
      excerpt: "In diesem Blogartikel widmen wir uns der Nextcloud Kalender App. Du erfährst was genau dahinter steckt, wie du den Kalender verwendest und welche Vorteile dir das bringt.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Calendar",
      formattedDate: "December 2, 2022",
      excerpt: "In this blog post, we focus on the Nextcloud Calendar app. You'll learn what's behind it, how to use the calendar, and what benefits it offers you.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Calendar</h1>
          
          <p>In this blog post, we focus on the <b>Nextcloud Calendar</b> app. You'll learn what's behind it, how to use the calendar, and what benefits it offers you.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Calendar",
      formattedDate: "2 December 2022",
      excerpt: "In this blog post, we focus on the Nextcloud Calendar app. You'll learn what's behind it, how to use the calendar, and what benefits it offers you.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;