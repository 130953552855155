import React from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/container'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelopeOpen,
  faExternalLink
} from '@fortawesome/free-solid-svg-icons'

/**
 * AGB component renders the terms and conditions page.
 * It uses the `useTranslation` hook to fetch translations for the content.
 * The component maintains counters for list items to dynamically generate numbered headings and sub-items.
 *
 * @component
 * @example
 * return (
 *   <AGB />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 */
const AGB = () => {
  /**
   * A counter variable used for tracking the level 1 headings.
   * @type {number}
   */
  var l1Counter = 1

  /**
   * Counter for level 2 headings.
   * @type {number}
   */
  var l2Counter = 1

  /**
   * Increments the l1Counter by 1 and returns the previous value.
   *
   * @returns {number} The previous value of l1Counter before incrementing.
   */
  const getNextL1Counter = () => {
    const tmp = l1Counter
    l1Counter += 1
    return tmp
  }

  /**
   * Increments the l2Counter by 1 and returns the previous value.
   *
   * @returns {number} The previous value of l2Counter before incrementing.
   */
  const getNextL2Counter = () => {
    const tmp = l2Counter
    l2Counter += 1
    return tmp
  }

  const { t } = useTranslation('agb')
  return (
    <>
      {/* The first container can be plainly redered, no things that require extra care. c1 section in the translation file. */}
      <Container className="break-words">
        <h1 className="text-center">{t('title')}</h1>
        <ul>
          <li>
            <h2 className="mt-10">
              {getNextL1Counter() + '. ' + t('c1.areaOfValidity')}
            </h2>
            <ul className="mt-5 space-y-2">
              {t('c1.list', { returnObjects: true }).map((item, index) => (
                <li key={index}>{`(${index + 1}) ${item}`}</li>
              ))}
            </ul>
          </li>
        </ul>
      </Container>
      {/* This container does have custom logic for the indices 2, 3 and 7. Please be careful editing the c2 section in the localization file. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c2.contract')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c2.li', { returnObjects: true }).map((item, index) => {
            if (index === 2) {
              return (
                <div className="pl-5" key={index}>
                  {item.map((subItem, subIndex) => {
                    return <p key={subIndex}>{subItem}</p>
                  })}
                </div>
              )
            }
            if (index === 3) {
              return <p key={index}>{item}</p>
            }
            if (index === 7) {
              return (
                <div key={index} className="pl-5">
                  <ul>
                    {item.map((subItem, subIndex) => {
                      return (
                        <li key={subIndex}>{`${subIndex + 1}) ${subItem}`}</li>
                      )
                    })}
                  </ul>
                </div>
              )
            }
            return <li key={index}>{`(${getNextL2Counter()}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* In this Container there is only one paragraph that needs special attention, I realized it using an array inside the array.
          Corresponds to c3 section. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c3.servicesAndLimits')}</h2>
        <ul className="mt-5 space-y-2">
          <div className="hidden">{(l2Counter = 1)}</div>
          {t('c3.li', { returnObjects: true }).map((item, index) => {
            if (Array.isArray(item)) {
              return (
                <div className="pl-10" key={index}>
                  <ul>
                    {item.map((subItem, subIndex) => (
                      <li key={subIndex} className="list-disc">
                        {subItem}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            } else {
              return <li key={index}>{`(${getNextL2Counter()}) ${item}`}</li>
            }
          })}
        </ul>
      </Container>
      {/* This container is on the easy side. So no custom logic targeting the c4 section :D */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c4.payment')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c4.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* Again no custom logic needed. Corresponds to the c5 section. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c5.delivery')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c5.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      <Container className="break-words">
        <p>**************************************************************</p>
      </Container>
      {/* Here a lot of custom logic is used. Please be carefull changeing the structure of the json file.
          Especially the namings of the keys. Corresponds to the c6 section */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c6.withdrawalCustomer')}</h2>
        <h2>{t('c6.withdrawal')}</h2>
        <div className="mt-5 space-y-2">
          <p>{t('c6.p_1')}</p>
          <h3>{t('c6.h3_1')}</h3>
          <h3>{t('c6.h3_2')}</h3>
          {t('c6.p_2', { returnObjects: true }).map((item, index) => {
            return <p key={index}>{item}</p>
          })}
          <div className="pl-5">
            {t('c2.li', { returnObjects: true })[2].map((item, index) => {
              return <p key={index}>{item}</p>
            })}
            <a href={`mailto:${t('c6.mail')}`}>
              <FontAwesomeIcon icon={faEnvelopeOpen} className="mx-1" />
              {t('c6.mail')}
            </a>
          </div>
          {t('c6.p_3', { returnObjects: true }).map((item, index) => {
            return <p key={index}>{item}</p>
          })}
          <h3>{t('c6.consequences')}</h3>
          {t('c6.p_4', { returnObjects: true }).map((item, index) => {
            return <p key={index}>{item}</p>
          })}
          <h3>{t('c6.end')}</h3>
        </div>
      </Container>
      <Container className="break-words">
        <p>**************************************************************</p>
      </Container>
      {/* Easy container, no custom logic. Corresponds to the c7 section in the json. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c7.warranty')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c7.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* Here is a lot of custom logic. In the json this is section c8. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c8.privacy')}</h2>
        <ul className="mt-5 space-y-2">
          {/* Iterating over the elements of an array. */}
          {t('c8.li', { returnObjects: true }).map((item, index) => {
            if (typeof item === 'object') {
              return (
                <li key={index}>
                  <p>
                    {'(' + (index + 1) + ') '}
                    {/* Now this is the complex part. I fetch key and value from the json object. 
                        The key is split at "_". The first part determines the type of tag it is rendered as.
                        The second part is only for unique identification. Unknown tags (currently evrything other than p and link)
                        are completly ignored (return null in default).*/}
                    {Object.keys(item).map((key, subIndex) => {
                      let k = key.split('_')[0]
                      switch (k) {
                        case 'p':
                          return item[key]
                        case 'link':
                          return (
                            <Link key={subIndex} to={item[key].to}>
                              <FontAwesomeIcon
                                icon={faExternalLink}
                                className="mx-1"
                              />
                              {item[key].text}
                            </Link>
                          )
                        default:
                          return null
                      }
                    })}
                  </p>
                </li>
              )
            } else {
              return <li key={index}>{`(${index + 1}) ${item}`}</li>
            }
          })}
        </ul>
      </Container>
      {/* Easy container coresponding to c9 */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c9.licence')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c9.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* container coresponding to c10, using custom logic like in c8. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c10.duration')}</h2>
        <ul className="mt-5 space-y-2">
          {/* Iterate over array */}
          {t('c10.li', { returnObjects: true }).map((item, index) => {
            if (typeof item === 'object') {
              return (
                <li key={index}>
                  <p>
                    {'(' + (index + 1) + ') '}
                    {/* Key determines the tag rendered. See c8 for details. */}
                    {Object.keys(item).map((key, subIndex) => {
                      let k = key.split('_')[0]
                      switch (k) {
                        case 'p':
                          return item[key]
                        case 'mail':
                          return (
                            <a key={subIndex} href={`mailto:${item[key]}`}>
                              <FontAwesomeIcon
                                icon={faEnvelopeOpen}
                                className="mx-1"
                              />
                              {item[key]}
                            </a>
                          )
                        default:
                          return null
                      }
                    })}
                  </p>
                </li>
              )
            } else {
              return <li key={index}>{`(${index + 1}) ${item}`}</li>
            }
          })}
        </ul>
      </Container>
      {/* Easy container coresponding to c11 */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c11.thirdParty')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c11.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* Container coresponding to c12. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c12.law')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c12.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* Container coresponding to c13. */}
      <Container className="break-words">
        <h2>{getNextL1Counter() + '. ' + t('c13.final')}</h2>
        <ul className="mt-5 space-y-2">
          {t('c13.li', { returnObjects: true }).map((item, index) => {
            return <li key={index}>{`(${index + 1}) ${item}`}</li>
          })}
        </ul>
      </Container>
      {/* Container coresponding to c14. */}
      <Container className="break-words">
        <p>{t('c14.last-update')}</p>
      </Container>
      {/* Container coresponding to c15. Empty strings are rendered as line breaks. The others as normal <p>*/}
      <Container className="break-words">
        <h2>{t('c15.attatchment')}</h2>
        <div className="space-y-2">
          {t('c15.p', { returnObjects: true }).map((item, index) => {
            return item === '' ? <br /> : <p key={index}>{item}</p>
          })}
        </div>
      </Container>
    </>
  )
}

export default AGB
