// src/data/blogPosts/gitlab-all-in-one-devops-plattform.js

// Import images - only the main logos that are likely to exist
import thumbnailImage from '../../assets/images/blog/gitlab-all-in-one-devops-plattform-featured.png';
import gitlabLogo from '../../assets/images/blog/2024/08/gitlab-logo-100.png';
import gitlabLogoSquare from '../../assets/images/blog/2024/08/gitlab-logo-500.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about GitLab DevOps platform
 */
const post = {
  id: '21433',
  slug: 'gitlab-all-in-one-devops-plattform',
  
  // Base data - same regardless of language
  date: "2024-08-13T04:26:41.000Z",
  readTime: 16,
  categories: ["Empfehlungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 13,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Gitlab – All-in-One DevOps-Plattform",
      formattedDate: "13. August 2024",
      excerpt: "Gitlab – All-in-One DevOps-Plattform Effizienz und nahtlose Zusammenarbeit sind Schlüsselfaktoren für moderne Softwareprojekte. GitLab ist eine leistungsstarke Plattform, die als Komplettlösung für den gesamten DevOps-Lebenszyklus dient.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Gitlab - All-in-One DevOps-Plattform</h1>
          
          <p>Effizienz und nahtlose Zusammenarbeit sind Schlüsselfaktoren für moderne Softwareprojekte. GitLab ist eine leistungsstarke Plattform, die als Komplettlösung für den gesamten DevOps-Lebenszyklus dient. In diesem Artikel stellen wir die Software und ihre wichtigsten Funktionen vor.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={gitlabLogo} alt="GitLab Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>DevOps</BlogHeading>
          
          <p>Unter DevOps versteht man in der Softwareentwicklung die Zusammenarbeit von Entwicklungsteams (Dev) und Betriebsteams (Ops). Ziel ist die Optimierung des gesamten Software-Lebenszyklus. Durch die Integration von Prozessen, Werkzeugen und Richtlinien aus beiden Bereichen wird eine schnelle, effiziente und qualitativ hochwertige Entwicklung, Bereitstellung und Wartung von Software erreicht. Durch Automatisierung, Continuous Integration und Deployment kann die Zeit von der Codegenerierung bis zur Auslieferung an den Kunden verkürzt und gleichzeitig die Zuverlässigkeit und Sicherheit der Software erhöht werden.</p>
          
          <div className="mt-8 mb-4 flex justify-center">
            <div className="w-12 h-12 text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12 5c3.453 0 5.891 2.797 5.567 6.78 1.745-.046 4.433.751 4.433 3.72 0 1.93-1.57 3.5-3.5 3.5h-13c-1.93 0-3.5-1.57-3.5-3.5 0-2.797 2.479-3.833 4.433-3.72-.167-4.218 2.208-6.78 5.567-6.78zm0-2c-4.006 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408-.212-3.951-3.473-7.092-7.479-7.092zm-1 14l-4-1.701v-1.599l4-1.7v1.567l-2.229.933 2.229.93v1.57zm2-5v1.567l2.229.933-2.229.93v1.57l4-1.701v-1.599l-4-1.7z"></path>
              </svg>
            </div>
          </div>
          
          <BlogHeading>GitLab</BlogHeading>
          
          <p>Open Source Software bietet eine Vielzahl von Werkzeugen und Funktionen, die den gesamten Softwareentwicklungsprozess abdecken. Von der Verwaltung des Quellcodes über Continuous Integration (CI) und Continuous Deployment (CD) bis hin zu Monitoring und Sicherheit. GitLab eignet sich für alle Teams, die eine effiziente, kollaborative und skalierbare Entwicklungsumgebung benötigen.</p>
          
          <BlogImage src={gitlabLogoSquare} alt="GitLab Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>GitLab Funktionen</BlogHeading>
          
          <p>Neben vielen anderen Funktionen sind diese besonders hervorzuheben.</p>
          
          <p><strong>Versionskontrolle</strong><br />GitLab bietet ein komplettes Git Repository Management, das es deinem Team ermöglicht, Codeversionen zu verfolgen, Änderungen effizient zu verwalten und in verschiedenen Zweigen zu arbeiten.</p>
          
          <p><strong>CI/CD Pipelines</strong><br />GitLab bietet Continuous Integration (CI) und Continuous Deployment (CD) Pipelines, die es ermöglichen den gesamten Build, Test und Deployment Prozess direkt in der Plattform zu automatisieren.</p>
          
          <p><strong>Code-Reviews und Merge-Request</strong><br />Für die kollaborative Entwicklung können Code Reviews und Merge Requests erstellt werden. Dies ermöglicht es Entwicklern, ihren Code zur Überprüfung einzureichen, Feedback zu erhalten und Änderungen gemeinsam zu bearbeiten, bevor der Code in den Hauptstrang aufgenommen wird.</p>
          
          <p><strong>Projektmanagement</strong><br />Die integrierten Projektmanagement-Funktionen für Issues, Boards und Milestones helfen dir und deinem Team, die Arbeit zu organisieren, den Fortschritt zu verfolgen und Projekte termingerecht abzuschließen.</p>
          
          <p><strong>Monitoring & Leistungsüberwachung</strong><br />Mit dem integrierten Monitoring und Performance-Tracking kann das Team die Leistung der Software in Echtzeit überwachen und analysieren. Ein Dashboard bietet wertvolle Einblicke in den Zustand des Systems und hilft den Teams, potenzielle Probleme schnell zu erkennen und zu beheben.</p>
          
          <p><strong>Werkzeuge für Sicherheit und Compliance</strong><br />Code-Sicherheit ist heute wichtiger denn je. Mit integrierten Tools wie Static Application Security Testing (SAST), Dynamic Application Security Testing (DAST) und Dependency Scanning können Sicherheitslücken frühzeitig erkannt und behoben werden.</p>
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p><strong>All-in-One Lösung</strong><br />GitLab ist mehr als nur ein Git Repository Manager. Die Software deckt alle Aspekte des DevOps-Lebenszyklus ab und vereinfacht so die Verwaltung und Integration.</p>
          
          <p><strong>Zusammenarbeit</strong><br />Tools für Code Review, Merge Request und Projektmanagement ermöglichen eine effiziente und sichere Zusammenarbeit.</p>
          
          <p><strong>Automatisierung</strong><br />Durch die Automatisierung von Tests und Deployments mit CI/CD-Pipelines sparen Teams Zeit und reduzieren das Fehlerrisiko.</p>
          
          <p><strong>Skalierbarkeit</strong><br />Ob kleines Startup oder großes Unternehmen, GitLab kann an die Bedürfnisse jeder Organisation angepasst werden.</p>
          
          <p><strong>Sicherheit und Compliance</strong><br />GitLab bietet fortschrittliche Sicherheitsfunktionen, die sicherstellen, dass der Code sicher bleibt und den Compliance-Anforderungen entspricht.</p>
          
          <div className="mt-8 mb-4 flex justify-center">
            <div className="w-12 h-12 text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" clipRule="evenodd" fillRule="evenodd" strokeLinejoin="round" strokeMiterlimit="2" viewBox="0 0 24 24" fill="currentColor">
                <path d="m12.002 2c5.518 0 9.998 4.48 9.998 9.998 0 5.517-4.48 9.997-9.998 9.997-5.517 0-9.997-4.48-9.997-9.997 0-5.518 4.48-9.998 9.997-9.998zm0 1.5c-4.69 0-8.497 3.808-8.497 8.498s3.807 8.497 8.497 8.497 8.498-3.807 8.498-8.497-3.808-8.498-8.498-8.498zm-.747 7.75h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fillRule="nonzero"></path>
              </svg>
            </div>
          </div>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Gitlab – All-in-One DevOps-Plattform",
      formattedDate: "13. August 2024",
      excerpt: "Gitlab – All-in-One DevOps-Plattform Effizienz und nahtlose Zusammenarbeit sind Schlüsselfaktoren für moderne Softwareprojekte. GitLab ist eine leistungsstarke Plattform, die als Komplettlösung für den gesamten DevOps-Lebenszyklus dient.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Gitlab – All-in-One DevOps Platform",
      formattedDate: "August 13, 2024",
      excerpt: "Gitlab – All-in-One DevOps Platform Efficiency and seamless collaboration are key factors for modern software projects. GitLab is a powerful platform that serves as a complete solution for the entire DevOps lifecycle.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Gitlab - All-in-One DevOps Platform</h1>
          
          <p>Efficiency and seamless collaboration are key factors for modern software projects. GitLab is a powerful platform that serves as a complete solution for the entire DevOps lifecycle. In this article, we introduce the software and its most important functions.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Gitlab – All-in-One DevOps Platform",
      formattedDate: "13 August 2024",
      excerpt: "Gitlab – All-in-One DevOps Platform Efficiency and seamless collaboration are key factors for modern software projects. GitLab is a powerful platform that serves as a complete solution for the entire DevOps lifecycle.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;