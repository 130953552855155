import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Container from '../components/container'
import CustomModal from '../components/modal'
import ContactForm from './contact'
import HeroSection from '../components/HeroSection'
import ServicesSection from '../components/ServicesSection'
import SectorSection from '../components/SectorSection'
import SoftwareSection from '../components/SoftwareSection'
import PartnersSection from '../components/PartnersSection'
import OpenSourceSection from '../components/OpenSourceSection'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faServer,
  faLock,
  faShieldAlt,
  faGlobe,
  faCloudUploadAlt,
  faBolt,
  faDatabase,
  faNetworkWired
} from '@fortawesome/free-solid-svg-icons'

/**
 * Home component renders the main page of the application.
 * It includes various sections such as HeroSection, ServicesSection, hosting container,
 * business/private/public container, and more.
 *
 * @component
 * @example
 * return (
 *   <Home />
 * )
 *
 * @returns {JSX.Element} The rendered component.
 */
const Home = () => {
  // State management
  const [isModalOpen, setIsModalOpen] = useState(false)

  // Add effect to prevent horizontal scrolling on mobile
  useEffect(() => {
    // Add overflow-x: hidden to both html and body elements
    document.documentElement.style.overflowX = 'hidden';
    document.body.style.overflowX = 'hidden';
    
    // Cleanup function to remove the style when component unmounts
    return () => {
      document.documentElement.style.overflowX = '';
      document.body.style.overflowX = '';
    };
  }, []);

  const { t } = useTranslation('home')
  
  // Predefined array of icons to ensure each is used only once
  const hostingIcons = [
    faServer,
    faLock,
    faShieldAlt,
    faGlobe,
    faCloudUploadAlt,
    faBolt,
    faDatabase,
    faNetworkWired
  ];
  
  // Get unique icon for each bullet point
  const getIconForBullet = (index) => {
    return hostingIcons[index % hostingIcons.length];
  };
  
  return (
    <div className="overflow-x-hidden w-full">
      <CustomModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        <ContactForm />
      </CustomModal>
      
      {/* Hero section component - no extra wrapper to reduce spacing */}
      <HeroSection />
      
      {/* Services section component */}
      <ServicesSection openContactModal={() => setIsModalOpen(true)} />
      
      {/* Partners Section - moved here to appear after Services */}
      <div className="mt-8 md:mt-16 lg:mt-24">
        <PartnersSection />
      </div>
      
      {/* Hosting container */}
      <div className="mt-12 md:mt-16 lg:mt-28">
        <Container>
          <div className="w-full">
            <div className="text-center mb-6 md:mb-10">
              <h2 className="text-2xl md:text-3xl font-bold">{t('c3.hosting')}</h2>
              <p className="text-lg md:text-xl max-w-3xl mx-auto mt-2 md:mt-3">
                Unser Rechenzentrum in Frankfurt bietet höchste Standards für Ihre IT-Infrastruktur.
              </p>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 md:gap-6">
              {t('c3.hosting_bulletpoints', { returnObjects: true }).map((bullet, index) => (
                <div 
                  key={index} 
                  className="bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900 
                          p-3 md:p-6 rounded-lg shadow-md hover:shadow-lg transition-all duration-300 
                          hover:scale-105 hover:bg-gradient-to-br hover:from-gray-50 hover:to-gray-100
                          dark:hover:from-gray-700 dark:hover:to-gray-800 border-2 border-transparent
                          hover:border-primary flex items-start group"
                >
                  <div className="flex-shrink-0 mr-3 md:mr-4">
                    <div className="w-8 h-8 md:w-12 md:h-12 rounded-full bg-primary bg-opacity-20 flex items-center justify-center
                                transition-all duration-300 group-hover:bg-opacity-50">
                      <FontAwesomeIcon 
                        icon={getIconForBullet(index)} 
                        className="text-primary text-base md:text-2xl transition-all duration-300 group-hover:scale-125" 
                      />
                    </div>
                  </div>
                  <div>
                    <p className="text-sm md:text-lg">{bullet}</p>
                  </div>
                </div>
              ))}
            </div>
            
            <div className="mt-6 md:mt-12 text-center">
              <div className="inline-flex items-center justify-center p-2 md:p-4 border-2 border-primary rounded-full">
                <FontAwesomeIcon icon={faServer} className="text-primary text-2xl md:text-4xl" />
              </div>
              <p className="mt-2 md:mt-4 text-sm md:text-lg max-w-xl mx-auto">
                Unser Hostingstandort in Frankfurt garantiert optimale Verfügbarkeit und schnelle Zugriffszeiten innerhalb der EU.
              </p>
            </div>
          </div>
        </Container>
      </div>
      
      {/* Enhanced Sector Section */}
      <div className="mt-12 md:mt-16 lg:mt-28">
        <SectorSection />
      </div>
      
      {/* Software Section */}
      <div className="mt-12 md:mt-16 lg:mt-28">
        <SoftwareSection />
      </div>
      
      {/* Open Source section */}
      <div className="mt-12 md:mt-16 lg:mt-28">
        <OpenSourceSection />
      </div>
    </div>
  )
}

export default Home