// src/data/blogPosts/nextcloud-memories.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-memories-featured.png';
import memoriesIcon from '../../assets/images/blog/2024/03/memories.png';
import installationImage from '../../assets/images/blog/2024/03/Bildschirmfoto-vom-2024-03-26-07-55-48.png';
import timelineImage from '../../assets/images/blog/2024/03/Timeline.png';
import recognitionImage from '../../assets/images/blog/2024/03/Objekt-und-Gesichterkennung.png';
import photoMapImage from '../../assets/images/blog/2024/03/Bildschirmfoto-vom-2024-03-26-08-05-01.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Memories
 */
const post = {
  id: '19223',
  slug: 'nextcloud-memories',
  
  // Base data - same regardless of language
  date: "2024-03-26T07:10:53.000Z",
  readTime: 7,
  categories: ["Anleitungen", "Empfehlungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 25,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Memories",
      formattedDate: "26. März 2024",
      excerpt: "Nextcloud Memories. Mit Nextcloud Memories werden deine Fotos in Nextcloud übersichtlich dargestellt. Es gibt viele tolle Funktionen wie z.B. eine Foto-Zeitleiste und eine Fotokarte. In diesem Artikel erklären wir dir die Einrichtung und stellen dir die besten Funktionen vor.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Memories</h1>
          
          <p>Mit Nextcloud Memories werden deine Fotos in Nextcloud übersichtlich dargestellt. Es gibt viele tolle Funktionen wie z.B. eine Foto-Zeitleiste und eine Fotokarte. In diesem Artikel erklären wir dir die Einrichtung und stellen dir die besten Funktionen vor.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={memoriesIcon} alt="Nextcloud Memories Icon" size="small" />
            </div>
            
          </div>
          
          <BlogHeading>Einrichtung in Nextcloud</BlogHeading>
          
          <p>Um die App in deiner Nextcloud zu installieren, melde dich als Administrator an, klicke auf das Icon in der rechten oberen Ecke und wähle "Apps".<br />In der Suche kannst du nun "Memories" eingeben und die App wird dir direkt angezeigt. Klicke dann auf "Herunterladen & aktivieren".</p>
          
          <BlogImage src={installationImage} alt="Installation von Memories in Nextcloud" size="large" className="my-6" />
          
          <p>In den Verwaltungseinstellungen erscheint ein neuer Menüpunkt "Erinnerungen". Überprüfe ob hier alles einen grünen Haken hat. Für die Vorschaubilder und die Karte müssen noch Einstellungen getätigt werden. Die Einrichtung übernehmen wir kostenlos für dich.</p>
          
          <BlogHeading>Top Features in Nextcloud Memories</BlogHeading>
          
          <BlogHeading level={3}>Zeitstrahl</BlogHeading>
          
          <p>Nachdem du die Memories App in Nextcloud geöffnet hast, kannst du dich durch die Zeitleiste der Bilder bewegen, indem du nach unten scrollst.<br />Das Besondere daran ist, dass du auf der rechten Seite direkt zu einem bestimmten Jahr und Monat springen kannst.</p>
          
          <BlogImage src={timelineImage} alt="Zeitleiste in Nextcloud Memories" size="large" className="my-6" />
          
          <BlogHeading level={3}>Objekt- und Gesichtserkennung</BlogHeading>
          
          <p>Durch die zusätzliche Installation der App "Recognize" werden Objekte und Bilder automatisch erkannt und können direkt über die Sidebar abgerufen werden.<br />Die Objekt- und Gesichtserkennung läuft direkt auf deinem Nextcloud-Server, wodurch deine Privatsphäre geschützt wird.</p>
          
          <BlogImage src={recognitionImage} alt="Objekt- und Gesichtserkennung in Nextcloud Memories" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Fotokarte</BlogHeading>
          
          <p>Die Fotokarte zeigt dir, wo du schon Fotos gemacht hast. Wenn du hineinzoomst, kannst du genau sehen, wo das Bild aufgenommen wurde. Auf der rechten Seite kannst du die Bilder direkt anschauen.</p>
          
          <BlogImage src={photoMapImage} alt="Nextcloud Memories Fotokarte" size="large" className="my-6" />
          
          <BlogHeading level={3}>App für das Smartphone</BlogHeading>
          
          <p>Es gibt auch eine App für Smartphones (Android). Diese ist noch in der Testphase, funktioniert aber schon sehr gut. Damit stehen alle Funktionen wie Zeitleiste, Objekt- und Gesichtserkennung und die Fotokarte auch auf dem Smartphone zur Verfügung.</p>
          
          <p>
            <a href="https://play.google.com/store/apps/details?id=gallery.memories&hl=en_US" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://play.google.com/store/apps/details?id=gallery.memories&hl=en_US
            </a>
            <br /><br />
            <a href="https://f-droid.org/packages/gallery.memories/" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://f-droid.org/packages/gallery.memories/
            </a>
          </p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Memories",
      formattedDate: "26. März 2024",
      excerpt: "Nextcloud Memories. Mit Nextcloud Memories werden deine Fotos in Nextcloud übersichtlich dargestellt. Es gibt viele tolle Funktionen wie z.B. eine Foto-Zeitleiste und eine Fotokarte. In diesem Artikel erklären wir dir die Einrichtung und stellen dir die besten Funktionen vor.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Memories",
      formattedDate: "March 26, 2024",
      excerpt: "Nextcloud Memories. With Nextcloud Memories, your photos are displayed clearly in Nextcloud. There are many great features such as a photo timeline and a photo map. In this article, we explain how to set it up and introduce you to the best features.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Memories</h1>
          
          <p>With Nextcloud Memories, your photos are displayed clearly in Nextcloud. There are many great features such as a photo timeline and a photo map. In this article, we explain how to set it up and introduce you to the best features.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Memories",
      formattedDate: "26 March 2024",
      excerpt: "Nextcloud Memories. With Nextcloud Memories, your photos are displayed clearly in Nextcloud. There are many great features such as a photo timeline and a photo map. In this article, we explain how to set it up and introduce you to the best features.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;