// src/data/blogPosts/dsgvo.js

// Import images
import thumbnailImage from '../../assets/images/blog/dsgvo-featured.jpg';
import dsgvoMainImage from '../../assets/images/blog/2022/01/dsgvo-3583628_1920.jpg';
import letterIcon from '../../assets/images/blog/2022/01/letter.png';
import questionIcon from '../../assets/images/blog/2022/01/question.png';
import consumerIcon from '../../assets/images/blog/2022/01/Verbraucher.png';
import smileyIcon from '../../assets/images/blog/2022/01/iconmonstr-smiley-13-240.png';
import databaseIcon from '../../assets/images/blog/2022/01/database.png';
import securityIcon from '../../assets/images/blog/2022/01/security.png';
import copIcon from '../../assets/images/blog/2022/01/cop.png';
import connectIcon from '../../assets/images/blog/2021/11/connect.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about DSGVO (GDPR)
 */
const post = {
  id: '5900',
  slug: 'dsgvo',
  
  // Base data - same regardless of language
  date: "2022-01-04T10:44:21.000Z",
  readTime: 12,
  categories: ["Informationen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 21,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "DSGVO",
      formattedDate: "4. Januar 2022",
      excerpt: "Die DSGVO – Was sich dahinter verbirgt. In diesem Blogartikel soll es Rund um die DSGVO gehen, Fragen beantwortet und Unklarheiten beseitigt werden.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Die DSGVO - Was sich dahinter verbirgt</h1>
          
          <p>In diesem Blogartikel soll es Rund um die DSGVO gehen, Fragen beantwortet und Unklarheiten beseitigt werden.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={dsgvoMainImage} alt="DSGVO" size="large" />
            </div>
            
          </div>
          
          <BlogHeading>Welche Datenschutzmaßnahmen gab es schon vor der DSGVO?</BlogHeading>
          
          <p>Seit dem 25. Mai 2018 gilt die DSGVO (Datenschutzgrundverordnung) für die gesamte EU. Davor gab es in Europa lediglich <strong>Richtlinien</strong> für die Verarbeitung personenbezogener Daten.<br />Speziell in Deutschland gab es davor schon das <strong>Bundesdatenschutzgesetz</strong> (BDSG).</p>
          
          <BlogImage src={letterIcon} alt="Brief Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Was genau ist die DSGVO?</BlogHeading>
          
          <p>Die DSGVO ist eine Verordnung der europäischen Union. Sie hat das Ziel den <strong>Datenschutz in Europa zu vereinheitlichen</strong> und somit einen Datenschutzstandard für alle Mitgliedsstaaten zu schaffen. Es gibt allerdings einige offene Regelungen, die die einzelnen Ländern selber ergänzen oder ausfüllen dürfen. In Deutschland gilt zusätzlich das BDSG (Bundesdatenschutzgesetz) das die Regeln der DSGVO nochmal verschärft.</p>
          
          <BlogImage src={questionIcon} alt="Fragezeichen Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Wie kann ich als Verbraucher davon profitieren?</BlogHeading>
          
          <p>Vor allem Verbraucher profitieren von der DSGVO, denn Unternehmen müssen personenbezogene Daten nach dem neusten Stand der Technik schützen und unterliegen damit einigen Auflagen.<br />Unternehmen sind dazu verpflichtet die <strong>Einwilligung der Verbraucher</strong> zu bekommen wenn personenbezogene Daten verarbeitet werden. Kinder und Jugendliche unter 16 Jahren benötigen die Einwilligung der Eltern. Diese <strong>Einwilligung kann außerdem jederzeit widerrufen</strong> werden. Dann dürfen keine weiteren Daten mehr verarbeitet werden.</p>
          <p>Außerdem haben Verbraucher das<strong> Recht auf Löschung</strong> der Daten. Wenn du also die Einwilligung widerrufst oder Zweck für die Datenverarbeitung wegfällt müssen deine personenbezogene Daten gelöscht werden.</p>
          <p>Als Verbraucher hast du außerdem ein <strong>Auskunftsrecht</strong>. So kannst du dich z.B. über den Zweck der Datenverarbeitung informieren.</p>
          
          <BlogImage src={consumerIcon} alt="Verbraucher Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Schränkt die DSGVO mich nicht in meinem Handeln ein?</BlogHeading>
          
          <p>Auch wenn es manchmal etwas lästig erscheint die Datenschutzbestimmungen durchzulesen oder zusätzliche Formulare auszufüllen um seine Einwilligung abzugeben <strong>schützt die DSGVO dich bzw. deine personenbezogenen Daten</strong>.</p>
          
          <BlogImage src={smileyIcon} alt="Smiley Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Was sind personenbezogene Daten nach DSGVO?</BlogHeading>
          
          <p>Personenbezogene Daten sind Daten die sich auf eine natürliche Person beziehen. Eine Person kann z.B. durch Zuordnung oder Kombination einer Kennung wie <strong>Kundennummer, Personalnummer oder Onlinekennung</strong> (Username) erkannt werden. <br />Dazu zählen auch <strong>Name, Telefonnummer, E-Mail Adresse, IP-Adresse und Postanschrift</strong>.</p>
          
          <BlogImage src={databaseIcon} alt="Datenbank Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Wie erlange ich mehr Datensicherheit im Netz?</BlogHeading>
          
          <p>Achte darauf dass du <strong>Open Source Software</strong> verwendest und Anbietern vertraust die nach der <strong>DSGVO</strong> arbeiten, ihren Firmensitz und Serverstandort in Deutschland bzw. Europa haben. Gehe mit <a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="noopener">Firefox</a> oder <a href="https://vivaldi.com/de/" target="_blank" rel="noopener">Vivaldi</a> ins Internet und surfe mit <a href="https://www.startpage.com/" target="_blank" rel="noopener">Startpage</a>.</p>
          <p>Nimm dir ein paar Minuten Zeit und lese dir Datenschutzbestimmungen durch, vor allem den Teil bei dem es um die Datenverarbeitung geht! Du wirst erstaunt sein was da in der einen oder anderen steht.</p>
          
          <BlogImage src={securityIcon} alt="Sicherheit Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Gab es schon Verstöße gegen die DSGVO?</BlogHeading>
          
          <p>Es gab schon zahlreiche Verstöße gegen die DSGVO. Bei jedem Verstoß muss ein Bußgeld bezahlt werden, wie hoch das ausfällt wird im Bußgeldkatalog geregelt. <strong>Google</strong> z.B musste schon mehrere Strafen zahlen darunter auch ein Bußgeld in Höhe von <strong>60.000.000 €</strong> da sie Cookies für Werbewecken ohne Einwilligung der Betroffenen eingesetzt haben. Aber auch <strong>WhatsApp bzw. Facebook</strong> mussten schon Bußgelder in Höhe von<strong> 25.000.000</strong> <strong>€</strong> zahlen da sie unrechtmäßig Daten an andere Facebook Unternehmen weitergeben haben. Und auch <strong>Amazon</strong> musste schon eine Strafe in Höhe von <strong>746.000.000</strong> <strong>€ </strong>wegen unerlaubter Anzeige von Werbung und Datenweitergabe an Dritte zahlen.<br />Eine vollständige Liste findet ihr hier:<br /><a href="https://www.dsgvo-portal.de/dsgvo-bussgeld-datenbank.php" target="_blank" rel="noopener">https://www.dsgvo-portal.de/dsgvo-bussgeld-datenbank.php</a></p>
          
          <BlogImage src={copIcon} alt="Polizist Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Vergleich EU und USA</BlogHeading>
          
          <p>In den USA gibt es generell kein allgemeines und umfassendes Datenschutzgesetz sonder jeder einzelne Bereich hat seine eigene Gesetze. Grundsätzlich gilt das Prinzip dass <strong>Unternehmen ihr eigenes Datenschuttniveau festlegen</strong>. Der Schutz von personenbezogenen Daten wird in Europa als Grundrecht angesehen, in den USA hingegen als Teil des Verbraucherschutzrechts, also als Element des Wirtschaftslebens. Jede Firma mit Sitz in den USA ist durch den <a href="https://pathconnect.de/blog/cloud-act/" target="_blank" rel="noopener"><strong>CLOUD-Act</strong></a> dazu verpflichtet sämtlicher Daten den US-Behörden zur Verfügung zu stellen auch wenn die Server außerhalb der USA stehen. Die Betroffenen müssen dabei nicht informiert werden.</p>
          
          <BlogImage src={connectIcon} alt="Verbindung Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Quellen</BlogHeading>
          
          <p><a href="https://www.datenschutz.org/usa/" target="_blank" rel="noopener">https://www.datenschutz.org/usa/</a></p>
          <p><a href="https://dsgvo-gesetz.de/" target="_blank" rel="noopener">https://dsgvo-gesetz.de/</a></p>
          <p><a href="https://www.datenschutzexperte.de/eu-dsgvo/" target="_blank" rel="noopener">https://www.datenschutzexperte.de/eu-dsgvo/</a></p>
          <p><a href="https://www.e-recht24.de/datenschutzgrundverordnung.html" target="_blank" rel="noopener">https://www.e-recht24.de/datenschutzgrundverordnung.html</a></p>
          <p><a href="https://www.intersoft-consulting.de/infos/datenschutz-grundverordnung-dsgvo/" target="_blank" rel="noopener">https://www.intersoft-consulting.de/infos/datenschutz-grundverordnung-dsgvo/</a></p>
          <p><a href="https://www.datenschutzzentrum.de/dsgvo/" target="_blank" rel="noopener">https://www.datenschutzzentrum.de/dsgvo/</a></p>
          <p><a href="https://www.cloudcomputing-insider.de/der-lange-arm-der-usa--neues-cloud-gesetz-in-kraft-a-704793/" target="_blank" rel="noopener">https://www.cloudcomputing-insider.de/der-lange-arm-der-usa--neues-cloud-gesetz-in-kraft-a-704793/</a></p>
          <p><a href="https://www.datenschutzexperte.de/personenbezogene-daten/" target="_blank" rel="noopener">https://www.datenschutzexperte.de/personenbezogene-daten/</a></p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "DSGVO",
      formattedDate: "4. Januar 2022",
      excerpt: "Die DSGVO – Was sich dahinter verbirgt. In diesem Blogartikel soll es Rund um die DSGVO gehen, Fragen beantwortet und Unklarheiten beseitigt werden.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "GDPR",
      formattedDate: "January 4, 2022",
      excerpt: "The GDPR – What's Behind It. In this blog post, we will discuss all about the GDPR, answer questions, and clear up any confusion.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">The GDPR - What's Behind It</h1>
          
          <p>In this blog post, we will discuss all about the GDPR, answer questions, and clear up any confusion.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "GDPR",
      formattedDate: "4 January 2022",
      excerpt: "The GDPR – What's Behind It. In this blog post, we will discuss all about the GDPR, answer questions, and clear up any confusion.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;