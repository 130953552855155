import React from 'react'
import PropTypes from 'prop-types'

/**
 * Button component that renders a styled button element.
 *
 * @param {Function} onClick - The function to call when the button is clicked.
 * @param {React.ReactNode} children - The content to display inside the button.
 * @param {string} className - Additional CSS classes to apply to the button.
 * @returns {JSX.Element} The rendered button component.
 */
const Button = ({ onClick, children, className = '', noDefault }) => {
  return (
    <>
      <button
        onClick={onClick}
        className={`${!noDefault ? 'p-5 rounded-3xl transition-all duration-300 hover:text-hover' : ''} ${className}`}
      >
        {children}
      </button>
    </>
  )
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
  noDefault: PropTypes.bool
}

export default Button
