// src/data/blogPosts/nextcloud-user-migration-app.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-user-migration-app-featured.png';
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import userMigrationAppScreenshot from '../../assets/images/blog/2023/09/User_migration_app.png';
import dataMigrationScreenshot from '../../assets/images/blog/2023/09/Datenmigration.png';
import importScreenshot from '../../assets/images/blog/2023/09/Import.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about the Nextcloud User Migration app
 */
const post = {
  id: '12410',
  slug: 'nextcloud-user-migration-app',
  
  // Base data - same regardless of language
  date: "2023-09-21T18:27:38.000Z",
  readTime: 7,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 16,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud User Migration App",
      formattedDate: "21. September 2023",
      excerpt: "Eine große Besonderheit von Nextcloud ist, dass es keine Anbieterbindung gibt. Du hast also die Möglichkeit, deine Nextcloud zu einem anderen Anbieter zu migrieren. Mit der App 'User Migration' können alle Dateien, Profileinstellungen, Kalender und Kontakte exportiert und wieder in die neue Nextcloud importiert werden.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud User Migration</h1>
          
          <p>Eine große Besonderheit von Nextcloud ist, dass es <strong>keine Anbieterbindung</strong> gibt. Du hast also die Möglichkeit, deine Nextcloud zu einem anderen Anbieter zu migrieren. Mit der App '<strong>User Migration</strong>' können alle Dateien, Profileinstellungen, Kalender und Kontakte exportiert und wieder in die neue Nextcloud importiert werden. Wie das funktioniert, was dabei zu beachten ist erfährst du in diesem Artikel.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Für die Installation loggst du dich mit einem <strong>Administrator-Account</strong> in deine Nextcloud ein, klickst auf das runde Icon oben rechts und dann auf '+Apps'.<br />In der Suche kannst du einfach "User Migration" eingeben und dann auf "Herunterladen und aktivieren" klicken.<br />In den Einstellungen erscheint nun ein neuer Menüpunkt "User Migration".</p>
          
          <BlogImage src={userMigrationAppScreenshot} alt="User Migration App in den Einstellungen" size="large" className="my-6" />
          
          <BlogHeading level={3}>Export</BlogHeading>
          
          <p>Um deine Daten zu exportieren, klickst du in den Einstellungen auf '<strong>Datenmigration</strong>'. Dort kannst du auswählen, was genau exportiert werden soll. Durch Klicken auf '<strong>Exportieren</strong>' werden die ausgewählten Informationen/Daten in eine Datei exportiert.</p>
          
          <p>Wenn du viele Daten hast, empfehlen wir dir nur die Profilinformationen, Kontakte und Kalender zu exportieren. Für die Datenübertragung kannst du die External Storage App nutzen. Wie das funktioniert erklären wir dir in diesem Artikel: <a href="https://pathconnect.de/?p=21602" target="_blank" rel="noopener">Von einer Nextcloud-Instanz zu einer anderen wechseln</a></p>
          
          <BlogImage src={dataMigrationScreenshot} alt="Datenmigration Einstellungen" size="large" className="my-6" />
          
          <BlogHeading level={3}>Import</BlogHeading>
          
          <p>Wenn du deine Informationen/Daten in die neue Nextcloud importieren möchtest, kannst du erneut die '<strong>User Migration</strong>' App installieren. Klicke diesmal auf '<strong>Import</strong>' und wähle die Exportdatei deiner alten Nextcloud aus. Für diesen Schritt muss die <strong>Exportdatei deiner alten Nextcloud in die neue Nextcloud übertragen</strong> werden. Hierbei ist zu beachten, dass du kurzfristig <strong>doppelten Speicher</strong> benötigst, da du einmal die Export Datei und zusätzlich die entpackten Daten hast.</p>
          
          <BlogImage src={importScreenshot} alt="Import Funktion" size="large" className="my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud User Migration App",
      formattedDate: "21. September 2023",
      excerpt: "Eine große Besonderheit von Nextcloud ist, dass es keine Anbieterbindung gibt. Du hast also die Möglichkeit, deine Nextcloud zu einem anderen Anbieter zu migrieren. Mit der App 'User Migration' können alle Dateien, Profileinstellungen, Kalender und Kontakte exportiert und wieder in die neue Nextcloud importiert werden.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Nextcloud User Migration App",
      formattedDate: "September 21, 2023",
      excerpt: "A notable feature of Nextcloud is that there is no vendor lock-in. This means you have the option to migrate your Nextcloud to another provider. With the 'User Migration' app, you can export all files, profile settings, calendars, and contacts and import them into a new Nextcloud instance.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud User Migration</h1>
          
          <p>A notable feature of Nextcloud is that there is <strong>no vendor lock-in</strong>. This means you have the option to migrate your Nextcloud to another provider. With the '<strong>User Migration</strong>' app, you can export all files, profile settings, calendars, and contacts and import them into a new Nextcloud instance. In this article, you'll learn how this works and what to keep in mind during the process.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>To install, log in to your Nextcloud with an <strong>administrator account</strong>, click on the round icon in the top right, and then on "+Apps".<br />In the search, you can simply enter "User Migration" and then click on "Download and enable".<br />A new menu item "User Migration" now appears in the settings.</p>
          
          <BlogImage src={userMigrationAppScreenshot} alt="User Migration App in settings" size="large" className="my-6" />
          
          <BlogHeading level={3}>Export</BlogHeading>
          
          <p>To export your data, click on '<strong>Data Migration</strong>' in the settings. There you can select exactly what should be exported. By clicking on '<strong>Export</strong>', the selected information/data will be exported to a file.</p>
          
          <p>If you have a lot of data, we recommend only exporting profile information, contacts, and calendars. For data transfer, you can use the External Storage App. We explain how this works in this article: <a href="https://pathconnect.de/?p=21602" target="_blank" rel="noopener">Switching from one Nextcloud instance to another</a></p>
          
          <BlogImage src={dataMigrationScreenshot} alt="Data migration settings" size="large" className="my-6" />
          
          <BlogHeading level={3}>Import</BlogHeading>
          
          <p>If you want to import your information/data into the new Nextcloud, you can install the '<strong>User Migration</strong>' app again. This time, click on '<strong>Import</strong>' and select the export file from your old Nextcloud. For this step, the <strong>export file from your old Nextcloud must be transferred to the new Nextcloud</strong>. Please note that you will temporarily need <strong>double the storage space</strong>, as you will have both the export file and the unpacked data.</p>
          
          <BlogImage src={importScreenshot} alt="Import function" size="large" className="my-6" />
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud User Migration App",
      formattedDate: "21 September 2023",
      excerpt: "A notable feature of Nextcloud is that there is no vendor lock-in. This means you have the option to migrate your Nextcloud to another provider. With the 'User Migration' app, you can export all files, profile settings, calendars, and contacts and import them into a new Nextcloud instance.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;