import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faContactCard,
  faDungeon,
  faInfoCircle,
  faBook
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as PathConnectLogo } from '../../../assets/images/pathconnect_logo.svg'
import LanguageSwitcher from '../../languageSwitcher'
import ThemeToggleButton from '../../themeToggleButton'
import Button from '../../button'

const NavigationLG = () => {
  const location = useLocation()
  const { t } = useTranslation('navigation')
  
  // Button css base classes
  const buttonClasses =
    'hover:underline px-3 py-1 rounded-md transition-all duration-300 hover:text-hover lg:text-xl'
    
  return (
    <nav className="flex items-center h-full">
      {/* Left side logo - reduced width */}
      <div className="flex items-center w-1/5">
        <Button
          className={
            buttonClasses +
            `${location.pathname === '/' ? ' text-primary' : ''}`
          }
        >
          <Link to="/">
            <PathConnectLogo className="w-4/5" />
          </Link>
        </Button>
      </div>
      
      {/* Middle part of the navigation - pushed more to the right */}
      <div className="flex items-center justify-end space-x-6 ml-auto w-3/5 pr-6">
        {/* Portal */}
        <Button
          className={
            buttonClasses +
            `${location.pathname === '/portal' ? ' text-primary' : ''}`
          }
        >
          <Link to="/portal">
            <FontAwesomeIcon icon={faDungeon} />
            <br />
            <span className="ml-1 lg:ml-2">{t('portal')}</span>
          </Link>
        </Button>
        
        {/* Blog */}
        <Button
          className={
            buttonClasses +
            `${location.pathname === '/blog' ? ' text-primary' : ''}`
          }
        >
          <Link to="/blog">
            <FontAwesomeIcon icon={faBook} />
            <br />
            <span className="ml-1 lg:ml-2">{t('blog')}</span>
          </Link>
        </Button>
        
        {/* About Us */}
        <Button
          className={
            buttonClasses +
            `${location.pathname === '/about' ? ' text-primary' : ''}`
          }
        >
          <Link to="/about">
            <FontAwesomeIcon icon={faInfoCircle} />
            <br />
            <span className="ml-1 lg:ml-2">{t('about')}</span>
          </Link>
        </Button>
        
        {/* Contact */}
        <Button
          className={
            buttonClasses +
            `${location.pathname === '/contact' ? ' text-primary' : ''}`
          }
        >
          <Link to="/contact">
            <FontAwesomeIcon icon={faContactCard} />
            <br />
            <span className="ml-1 lg:ml-2">{t('contact')}</span>
          </Link>
        </Button>
      </div>
      
      {/* Right part of the navigation */}
      <div className="flex items-center w-1/5 justify-end">
        <LanguageSwitcher className={buttonClasses} />
        <ThemeToggleButton className={buttonClasses} />
      </div>
    </nav>
  )
}

export default NavigationLG