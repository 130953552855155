import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { ThemeContext } from "../themeProvider";

const Background = ({ children }) => {
  const { darkMode } = useContext(ThemeContext);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  
  // Optional: Subtle parallax effect on mouse movement
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: e.clientX / window.innerWidth,
        y: e.clientY / window.innerHeight
      });
    };
    
    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className="relative min-h-screen">
      {/* Background Elements */}
      <div className="fixed inset-0 -z-10 overflow-hidden">
        {/* Base Background */}
        <div className={`absolute inset-0 ${
          darkMode ? "bg-gray-950" : "bg-gray-50"
        }`}></div>
        
        {/* Subtle Grid Pattern - Only in Dark Mode */}
        {darkMode && (
          <div className="absolute inset-0 opacity-5"
            style={{
              backgroundImage: 'linear-gradient(#333 1px, transparent 1px), linear-gradient(to right, #333 1px, transparent 1px)',
              backgroundSize: '40px 40px'
            }}>
          </div>
        )}
        
        {/* Enhanced Gradient Overlay */}
        <div className={`absolute inset-0 transition-opacity duration-1000 ${
          darkMode
            ? "bg-gradient-to-br from-cyan-900/5 via-blue-900/3 to-indigo-900/5"
            : "bg-gradient-to-br from-cyan-500/3 via-blue-500/2 to-indigo-500/3"
        }`}></div>
        
        {/* Soft Vignette Effect */}
        <div className="absolute inset-0 bg-radial-gradient pointer-events-none opacity-40"
          style={{
            background: darkMode 
              ? 'radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.3) 100%)' 
              : 'radial-gradient(circle at center, transparent 0%, rgba(0,0,0,0.1) 100%)'
          }}>
        </div>
        
        {/* Animated Accent Elements */}
        <div 
          className={`absolute top-0 left-0 w-72 h-72 rounded-full blur-3xl -translate-x-1/2 -translate-y-1/2 transition-all duration-700 animate-pulse-slow ${
            darkMode ? "bg-cyan-500/5" : "bg-cyan-500/5"
          }`}
          style={{
            transform: `translate(calc(-50% + ${mousePosition.x * 20}px), calc(-50% + ${mousePosition.y * 20}px))`
          }}
        ></div>
        
        <div 
          className={`absolute bottom-0 right-0 w-72 h-72 rounded-full blur-3xl translate-x-1/2 translate-y-1/2 transition-all duration-700 animate-pulse-slow-offset ${
            darkMode ? "bg-indigo-500/5" : "bg-indigo-500/5"
          }`}
          style={{
            transform: `translate(calc(50% - ${mousePosition.x * 20}px), calc(50% - ${mousePosition.y * 20}px))`
          }}
        ></div>
        
        {/* Subtle Noise Texture - Apply to both modes */}
        <div 
          className="absolute inset-0 opacity-5 pointer-events-none" 
          style={{
            backgroundImage: 'url("data:image/svg+xml,%3Csvg viewBox=\'0 0 200 200\' xmlns=\'http://www.w3.org/2000/svg\'%3E%3Cfilter id=\'noiseFilter\'%3E%3CfeTurbulence type=\'fractalNoise\' baseFrequency=\'0.65\' numOctaves=\'3\' stitchTiles=\'stitch\'/%3E%3C/filter%3E%3Crect width=\'100%25\' height=\'100%25\' filter=\'url(%23noiseFilter)\'/%3E%3C/svg%3E")',
            backgroundSize: '200px 200px'
          }}>
        </div>
      </div>
      
      {/* Content */}
      <div className="relative z-0 min-h-screen flex items-center justify-center">
        {children}
      </div>
    </div>
  );
};

Background.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Background;