import React, { createRef, useRef } from 'react'
import Container from '../components/container'
import Button from '../components/button'
import { useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons'

/**
 * Privacy component
 *
 * This component renders the Privacy page.
 *
 * @component
 * @example
 * return (
 *   <Privacy />
 * )
 */
const Privacy = () => {
  const { t } = useTranslation('privacy')

  const arrLength = 14
  const containerRefs = useRef([])

  if (containerRefs.current.length !== arrLength) {
    // add or remove refs
    containerRefs.current = Array(arrLength)
      .fill()
      .map((_, i) => containerRefs.current[i] || createRef())
  }

  const scrollToContainer = index => {
    containerRefs.current[index]?.current?.scrollIntoView({
      behavior: 'smooth'
    })
  }

  const content = t('content', { returnObjects: true })

  const renderContent = (key, value, index) => {
    const k = key.split('_')[0]
    switch (k) {
      case 'title':
        return (
          <h2 key={index} className="mt-20">
            {value}
          </h2>
        )

      case 'h3':
        return (
          <h3 key={index} className="mt-10">
            {value}
          </h3>
        )

      case 'p':
        if (typeof value === 'string') {
          return (
            <>
              <p key={index}>{value}</p>
              <br />
            </>
          )
        } else if (Array.isArray(value)) {
          return value.map((item, subIndex) => {
            if (typeof item === 'string') {
              return <p key={`${index}-${subIndex}`}>{item}</p>
            } else if (typeof item === 'object') {
              return Object.keys(item).map((subKey, subSubIndex) =>
                renderContent(
                  subKey,
                  item[subKey],
                  `${index}-${subIndex}-${subSubIndex}`
                )
              )
            }
            return null
          })
        } else if (typeof value === 'object') {
          return Object.keys(value).map((subKey, subIndex) =>
            renderContent(subKey, value[subKey], subIndex)
          )
        }
        break

      case 'bold':
        return <b key={index}>{value}</b>

      case 'ul':
        return (
          <ul key={index} className="list-disc ml-5">
            {value.map((item, subIndex) => {
              if (typeof item === 'string') {
                return <li key={`${index}-${subIndex}`}>{item}</li>
              } else if (Array.isArray(item)) {
                return (
                  <li key={`${index}-${subIndex}`}>
                    <ul className="list-disc ml-5">
                      {item.map((subItem, subSubIndex) => (
                        <li key={`${index}-${subIndex}-${subSubIndex}`}>
                          {typeof subItem === 'string'
                            ? subItem
                            : renderContent(
                                Object.keys(subItem),
                                Object.values(subItem),
                                `${index}-${subIndex}-${subSubIndex}`
                              )}
                        </li>
                      ))}
                    </ul>
                  </li>
                )
              } else if (typeof item === 'object') {
                return (
                  <li key={`${index}-${subIndex}`}>
                    {Object.keys(item).map((subKey, subSubIndex) =>
                      renderContent(
                        subKey,
                        item[subKey],
                        `${index}-${subIndex}-${subSubIndex}`
                      )
                    )}
                  </li>
                )
              }
              return null
            })}
          </ul>
        )

      case 'mail':
        return (
          <a key={index} href={`mailto:${value.link}`}>
            <FontAwesomeIcon icon={faEnvelope} className="mr-1" /> {value.text}
          </a>
        )

      case 'link':
        return (
          <a key={index} href={value.link}>
            <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1" />
            {value.text}
          </a>
        )

      default:
        console.log('Unknown key:', key + ' <-> ' + value)
        return value
    }
  }

  return (
    <>
      <Container className="block">
        <h1 className="text-center">{t('title')}</h1>
        <nav>
          <h2 className="mt-20">{t('toc')}</h2>
          <ul className="space-y-1 list-decimal">
            {Object.keys(content).map((key, index) => (
              <li key={index} className="ml-5">
                <Button
                  onClick={() => scrollToContainer(index)}
                  className="p-0 m-0"
                  noDefault
                >
                  {content[key].title}
                </Button>
              </li>
            ))}
          </ul>
        </nav>
      </Container>
      <div className="mt-20"></div>
      {Object.keys(content).map((key, index) => (
        <Container
          ref={containerRefs.current[index]}
          key={index}
          className="block"
        >
          {Object.keys(content[key]).map((subKey, subIndex) =>
            renderContent(subKey, content[key][subKey], subIndex)
          )}
        </Container>
      ))}
      <div className="mt-20"></div>
      <Container className="block">
        <p className="text-center">
          <a
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            href="https://datenschutz-generator.de/"
            target="_blank"
            rel="noopener noreferrer nofollow"
            className="inline-block"
          >
            <img
              decoding="async"
              src="https://datenschutz-generator.de/wp-content/plugins/ts-dsg/images/dsg-seal/dsg-seal-pp-de.png"
              alt="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
              width="250"
              height="250"
            />
          </a>
        </p>
      </Container>
    </>
  )
}

export default Privacy
