// src/data/blogPosts/nextcloud-e-mail-signatur.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-e-mail-signatur-featured.png';
import noteIcon from '../../assets/images/blog/2023/03/iconmonstr-note-23-240.png';
import taskListIcon from '../../assets/images/blog/2023/04/iconmonstr-task-list-lined-240.png';
import toolsIcon from '../../assets/images/blog/2023/03/iconmonstr-tools-14-240.png';
import kontoEinstellungenImage from '../../assets/images/blog/2023/11/Konto-Einstellungen.png';
import signaturEinfuegenImage from '../../assets/images/blog/2023/11/Signatur-einfuegen.png';
import schreibmodusImage from '../../assets/images/blog/2023/11/Schreibmodus.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud E-Mail Signature
 */
const post = {
  id: '13480',
  slug: 'nextcloud-e-mail-signatur',
  
  // Base data - same regardless of language
  date: "2023-11-08T15:10:39.000Z",
  readTime: 7,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 7,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud E-Mail Signatur",
      formattedDate: "8. November 2023",
      excerpt: "Eine E-Mail Signatur ist ein wichtiger Bestandteil jeder geschäftlichen E-Mail. Aber auch im privaten Bereich kann eine Signatur sinnvoll sein. In diesem Artikel erfährst du, wie du deine E-Mail Signatur in Nextcloud E-Mail einrichtest.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud E-Mail Signatur</h1>
          
          <p>Eine E-Mail Signatur ist ein wichtiger Bestandteil jeder geschäftlichen E-Mail. Aber auch im privaten Bereich kann eine Signatur sinnvoll sein. In diesem Artikel erfährst du, wie du deine E-Mail Signatur in Nextcloud E-Mail einrichtest.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={noteIcon} alt="Notiz Icon" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>E-Mail Signatur</BlogHeading>
          
          <p>In einer E-Mail-Signatur sollten alle wichtigen Kontaktinformationen zu finden sein. Da es sich bei einer geschäftlichen E-Mail um einen Geschäftsbrief handelt, müssen alle wichtigen Informationen wie Name und Adresse des Unternehmens enthalten sein. Weitere Kontaktinformationen wie eine Telefonnummer oder ein Link zur Website sind ebenfalls hilfreich. Bei einer privaten E-Mail-Signatur kann es z.B. sinnvoll sein, einen Abschiedsgruß zu hinterlegen.</p>
          
          <BlogImage src={taskListIcon} alt="Task Liste Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Nextcloud E-Mail</BlogHeading>
          
          <p>Mit Nextcloud kannst du deine E-Mails direkt in der Weboberfläche abrufen. Wie du die E-Mail-Anwendung in Nextcloud einrichten und nutzen kannst, erfährst du in unserem Artikel über Nextcloud E-Mail.</p>
          
          <BlogImage src={toolsIcon} alt="Tools Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Signatur einrichten</BlogHeading>
          
          <p>Nachdem du die E-Mail-Anwendung mit einer E-Mail-Adresse eingerichtet hast, klicke auf die drei Punkte neben deiner E-Mail-Adresse und dann auf „Kontoeinstellungen".</p>
          
          <BlogImage src={kontoEinstellungenImage} alt="Kontoeinstellungen" size="medium" className="mx-auto my-6" />
          
          <p>Es öffnet sich ein Popup-Fenster, in dem du verschiedene Einstellungen für dein E-Mail-Konto vornehmen kannst. Auf der linken Seite findest du den Menüpunkt „Signatur". Klicke auf diesen Menüpunkt um zu den Signatur-Einstellungen zu gelangen. Wenn du möchtest, dass deine Signatur über dem Text steht, wenn du auf eine E-Mail antwortest, setze ein Häkchen bei „Signatur über dem zitierten Text". Im Textfeld darunter kannst du dann deine Signatur eingeben.</p>
          
          <p>Durch Markieren des Textes kannst du z.B. die Schriftfarbe und -größe anpassen. Klicke dann auf „Signatur speichern".</p>
          
          <BlogImage src={signaturEinfuegenImage} alt="Signatur einfügen" size="large" className="my-6" />
          
          <p>Im letzten Schritt muss unter „Schreibmodus" die Option „Rich Text" ausgewählt werden. Ansonsten werden Änderungen an Farbe oder Schriftgröße ignoriert.</p>
          
          <BlogImage src={schreibmodusImage} alt="Schreibmodus" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud E-Mail Signatur",
      formattedDate: "8. November 2023",
      excerpt: "Eine E-Mail Signatur ist ein wichtiger Bestandteil jeder geschäftlichen E-Mail. Aber auch im privaten Bereich kann eine Signatur sinnvoll sein. In diesem Artikel erfährst du, wie du deine E-Mail Signatur in Nextcloud E-Mail einrichtest.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Email Signature",
      formattedDate: "November 8, 2023",
      excerpt: "An email signature is an important component of every business email. But a signature can also be useful in private communication. In this article, you will learn how to set up your email signature in Nextcloud Mail.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Email Signature</h1>
          
          <p>An email signature is an important component of every business email. But a signature can also be useful in private communication. In this article, you will learn how to set up your email signature in Nextcloud Mail.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Email Signature",
      formattedDate: "8 November 2023",
      excerpt: "An email signature is an important component of every business email. But a signature can also be useful in private communication. In this article, you will learn how to set up your email signature in Nextcloud Mail.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;