// src/data/blogPosts/die-ersten-schritte-mit-nextcloud.js

// Import images
import thumbnailImage from '../../assets/images/blog/die-ersten-schritte-mit-nextcloud-featured.png';
import nextcloudLogo from '../../assets/images/blog/2023/11/nextcloud-logo.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about first steps with Nextcloud
 */
const post = {
  id: '10751',
  slug: 'die-ersten-schritte-mit-nextcloud',
  
  // Base data - same regardless of language
  date: "2023-02-06T12:57:26.000Z",
  readTime: 12,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 11,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Die ersten Schritte mit Nextcloud",
      formattedDate: "6. Februar 2023",
      excerpt: "In diesem Blogbeitrag zeigen wir dir die ersten Schritte mit Nextcloud, nachdem du eine Bestellung bei PathConnect aufgegeben hast.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Die ersten Schritte mit Nextcloud</h1>
          
          <p>In diesem Blogbeitrag zeigen wir dir die ersten Schritte mit Nextcloud. Solltest du an einer Stelle nicht weiterkommen oder Fragen haben, schreibe uns einfach eine Nachricht an <a href="mailto:info@pathconnect.de" target="_blank" rel="noopener">info@pathconnect.de</a></p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Erster Login</BlogHeading>
          
          <p>Sobald die Nextcloud online ist, kannst du einen Account erstellen, indem du auf den Button „Registrieren" klickst. Wir verschieben dich dann in die Gruppe der Administratoren. Ab jetzt hast du die volle Kontrolle. Du kannst also selbst weitere Benutzer anlegen, Apps installieren, das Logo ändern etc.</p>
          <p>Um die Sicherheit deines Accounts zu erhöhen, empfehlen wir dir dringend, die 2-Faktor-Authentifizierung zu aktivieren.</p>
          <p><a href="https://pathconnect.de/blog/2-faktor-authentifizierung/" target="_blank" rel="noopener">https://pathconnect.de/blog/2-faktor-authentifizierung/</a></p>
          
          <BlogHeading level={3}>Benutzer anlegen</BlogHeading>
          
          <p>Um weitere Benutzer anzulegen, klicke einfach auf das Icon in der rechten oberen Ecke und dann auf „Benutzer".</p>
          <p>Hier hast du die Möglichkeit, weitere Benutzer anzulegen, indem du auf „Neuer Benutzer" klickst.</p>
          
          <BlogHeading level={3}>Apps installieren</BlogHeading>
          
          <p>Nextcloud bietet eine Vielzahl von Apps, um die Funktionalität deiner Nextcloud zu erweitern. Klicke dazu auf das Icon in der rechten oberen Ecke und dann auf „+ Apps".</p>
          <p>Wenn du eine App hinzufügen möchtest, klicke auf „Herunterladen und aktivieren".</p>
          
          <BlogHeading level={3}>Synchronisation</BlogHeading>
          
          <p>Eine der wichtigsten Funktionen ist die Synchronisation mit deinen Geräten. Wir empfehlen dir daher, den Nextcloud Client für deinen PC/Laptop und die entsprechende App für dein Smartphone herunterzuladen, die du hier findest:<br /><a href="https://nextcloud.com/de/install/" target="_blank" rel="noopener">https://nextcloud.com/de/install/</a></p>
          
          <BlogHeading level={3}>Kalender und Kontakte</BlogHeading>
          
          <p>Eine weitere nützliche Funktion sind die Nextcloud Apps für Kalender und Kontakte. Bestehende Kontakte und Kalendereinträge können einfach importiert und mit dem Smartphone synchronisiert werden.</p>
          <p>Anleitung für Android:<br /><a href="https://pathconnect.de/blog/kalender-und-kontakt-mit-android-synchronisieren/" target="_blank" rel="noopener">https://pathconnect.de/blog/kalender-und-kontakt-mit-android-synchronisieren/</a></p>
          <p>Anleitung für iOS:<br /><a href="https://pathconnect.de/blog/kalender-und-kontakt-synchronisieren/" target="_blank" rel="noopener">https://pathconnect.de/blog/kalender-und-kontakt-synchronisieren/</a></p>
          
          <BlogHeading level={3}>Nextcloud Office</BlogHeading>
          
          <p>Für alle, die viel mit Dokumenten zu tun haben, ist die Nextcloud Office App genau das Richtige. Neben dem Öffnen und Bearbeiten von Dokumenten kannst du diese auch mit anderen teilen, um gemeinsam daran zu arbeiten.</p>
          <p>Genauere Informationen findest du hier:<br /><a href="https://pathconnect.de/blog/nextcloud-office/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-office/</a></p>
          
          <BlogHeading level={3}>Notizen</BlogHeading>
          
          <p>Sehr praktisch sind auch die Nextcloud Notizen. Du kannst sie entweder direkt in der Nextcloud-Weboberfläche oder wieder auf deinem Smartphone öffnen.</p>
          <p>Anleitung:<br /><a href="https://pathconnect.de/blog/nextcloud-notes/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-notes</a></p>
          
          <BlogHeading level={3}>E-Mail</BlogHeading>
          
          <p>Mit der integrierten E-Mail App kannst du deine E-Mail Konten direkt in Nextcloud integrieren, wie das funktioniert erfährst du hier:</p>
          <p>Anleitung:<br /><a href="https://pathconnect.de/blog/nextcloud-e-mail/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-e-mail/</a></p>
          
          <BlogHeading level={3}>Nextcloud Talk</BlogHeading>
          
          <p>Für Chats und Videokonferenzen gibt es Nextcloud Talk. Damit kannst du sowohl mit Nutzern in deiner Nextcloud als auch mit externen Personen kommunizieren, indem du ihnen einen Link schickst. Weitere Informationen findest du hier:</p>
          <p><a href="https://pathconnect.de/blog/nextcloud-talk/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-talk/</a></p>
          
          <BlogHeading level={3}>Nextcloud News</BlogHeading>
          
          <p>Du kannst sogar Nachrichten direkt in deiner Nextcloud lesen. Auch dafür gibt es Apps für Smartphones.</p>
          <p>Anleitung:<br /><a href="https://pathconnect.de/blog/nextcloud-news/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-news/</a></p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Die ersten Schritte mit Nextcloud",
      formattedDate: "6. Februar 2023",
      excerpt: "In diesem Blogbeitrag zeigen wir dir die ersten Schritte mit Nextcloud, nachdem du eine Bestellung bei PathConnect aufgegeben hast.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "First Steps with Nextcloud",
      formattedDate: "February 6, 2023",
      excerpt: "In this blog post, we show you the first steps with Nextcloud after you have placed an order with PathConnect.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">First Steps with Nextcloud</h1>
          
          <p>In this blog post, we show you the first steps with Nextcloud. If you get stuck or have questions, simply write us a message at <a href="mailto:info@pathconnect.de" target="_blank" rel="noopener">info@pathconnect.de</a></p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "First Steps with Nextcloud",
      formattedDate: "6 February 2023",
      excerpt: "In this blog post, we show you the first steps with Nextcloud after you have placed an order with PathConnect.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;