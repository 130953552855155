import React, { createContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/**
 * Context to provide theme-related data and functions.
 * @type {React.Context}
 */
export const ThemeContext = createContext()

/**
 * ThemeProvider component that manages and provides the theme context to its children.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {React.ReactNode} props.children - The child components that will receive the theme context.
 *
 * @returns {JSX.Element} The ThemeProvider component.
 *
 * @example
 * <ThemeProvider>
 *   <App />
 * </ThemeProvider>
 *
 * @description
 * This component uses the `useState` and `useEffect` hooks to manage the theme state (dark mode or light mode).
 * It loads the user's theme preference from localStorage on initial load and applies the corresponding theme.
 * The `toggleTheme` function allows toggling between dark and light modes and saves the preference to localStorage.
 *
 * The theme context value includes:
 * - `darkMode` (boolean): Indicates whether dark mode is enabled.
 * - `toggleTheme` (function): Function to toggle the theme.
 */
export const ThemeProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useState(false)

  // Load the user's theme preference from localStorage on initial load
  useEffect(() => {
    const savedTheme = localStorage.getItem('theme') || 'dark'

    // Set initial mode based on saved preference or default to light mode
    const isDarkMode = savedTheme === 'dark'
    setDarkMode(isDarkMode)
    document.documentElement.classList.toggle('dark', isDarkMode)
  }, [])

  // Toggle theme and save the preference
  const toggleTheme = () => {
    const newTheme = !darkMode ? 'dark' : 'light'
    setDarkMode(!darkMode)

    // Toggle the 'dark' class on the root element
    document.documentElement.classList.toggle('dark', newTheme === 'dark')

    // Save the preference to localStorage
    localStorage.setItem('theme', newTheme)
  }

  return (
    <ThemeContext.Provider value={{ darkMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
}
