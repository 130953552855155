// src/data/blogPosts/mailcow-dns-einstellungen.js

// Import images
import thumbnailImage from '../../assets/images/blog/mailcow-dns-einstellungen-featured.png';
import mailcowDns from '../../assets/images/blog/2024/10/Mailcow-DNS.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection 
} from '../../components/blog';

/**
 * Multilingual blog post about Mailcow DNS settings
 */
const post = {
  id: '22030',
  slug: 'mailcow-dns-einstellungen',
  
  // Base data - same regardless of language
  date: "2024-10-27T11:01:58.000Z",
  readTime: 9,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 19,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Mailcow DNS-Einstellungen",
      formattedDate: "27. Oktober 2024",
      excerpt: "Die richtigen DNS-Einstellungen sind ein entscheidender Faktor, damit dein Open Source Mailserver Mailcow einwandfrei funktioniert. In diesem Artikel erfährst du, welche Einträge du vornehmen musst und was sie bewirken.",
      content: () => (
        <BlogContent>
          <p>Die richtigen DNS-Einstellungen sind ein entscheidender Faktor, damit dein Open Source Mailserver Mailcow einwandfrei funktioniert. In diesem Artikel erfährst du, welche Einträge du vornehmen musst und was sie bewirken.</p>
          
          <BlogImage src={mailcowDns} alt="Mailcow DNS" size="large" className="my-6" />
          
          <BlogHeading>DNS</BlogHeading>
          
          <p>Die Abkürzung „DNS" steht für „Domain Name System". Ein DNS Server ist z.B. dazu da, ähnlich wie ein Telefonbuch, die Zuordnung zwischen einem Domainnamen und der IP Adresse herzustellen. Ohne DNS-Server müsste man die IP-Adresse jeder Webseite kennen, um sie zu öffnen. Dank DNS musst du nur den Namen in die Suchleiste eingeben, die Auflösung der IP Adresse übernimmt der DNS Server.<br />Neben dieser Namensauflösung dient der DNS Server noch vielen anderen Dingen, wie zum Beispiel der Validierung von E-Mails.</p>
          
          <BlogHeading>Grundlegende Mailcow DNS-Einstellungen</BlogHeading>
          
          <p>Damit deine Mailcow überhaupt online erreichbar ist und E-Mails versendet werden können, sind einige grundlegende DNS-Einträge notwendig.</p>
          
          <ul>
            <li><strong>A-Record:</strong><br />Der A-Record (Address Record) ordnet einer Domain eine IPv4-Adresse zu. Für Mailcow bedeutet dies, dass der A-Record den Hostnamen des Mailservers (z.B. mail.deinedomain.de) auf die IPv4 Adresse des Servers zeigt. Dieser Eintrag stellt sicher, dass der Mailserver über den Hostnamen im Internet erreichbar ist.<br /><span style={{textDecoration: "underline"}}>Beispiel:</span><br />◦ Hostname: mail.deinedomain.de<br />◦ IPv4-Adresse: 123.123.123.123</li>
            <li><strong>AAAA-Record:</strong><br />Der AAAA-Record ist das IPv6-Äquivalent zum A-Record. Er ordnet einem Hostnamen eine IPv6-Adresse zu.<br />Wenn dein Server eine IPv6-Adresse hat und du möchtest, dass er auch über IPv6 erreichbar ist, brauchst du diesen Eintrag.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Hostname: mail.deinedomain.de<br />◦ IPv6-Adresse: 2001:0db8:85a3:0000:0000:8a2e:0370:7334</li>
            <li><strong>PTR-Eintrag:</strong><br />Der PTR-Record (Pointer Record) dient der sogenannten &#8222;Reverse DNS\"-Auflösung, bei der die IP-Adresse des Mailservers auf einen Hostnamen zeigt.<br />Dies ist besonders wichtig, damit andere Mailserver erkennen können, dass es sich um einen legitimen Mailserver und nicht um einen Spam-Absender handelt.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Hostname: mail.deinedomain.de<br />◦ IP-Adresse(rückwarts): 123.123.123.123.in-addr.arpa</li>
            <li><strong>MX-Eintrag:</strong><br />Der MX-Record (Mail Exchange Record) gibt an, welcher Server für den Empfang von E-Mails einer Domain zuständig ist.<br />Er zeigt auf den Hostnamen des Mailservers (z.B. mail.deine_domain.de), der über den A-Record erreichbar sein muss.<br />Es können mehrere MX-Records mit unterschiedlichen Prioritäten angegeben werden. Der Server mit der niedrigsten Priorität wird zuerst angesprochen.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Hostname: deinedomain.de<br />◦ Mailserver: mail.deinedomain.de<br />◦ Priorität: 10</li>
          </ul>
          
          <BlogHeading>Sicherheits-DNS-Einträge</BlogHeading>
          
          <p>Damit deine E-Mails auch wirklich ankommen, unterwegs nicht verändert und vom Empfänger angenommen werden, sind noch einige weitere Eingaben notwendig.</p>
          
          <ul>
            <li><strong>SPF-Record (Sender Policy Framework):</strong><br />Der SPF-Record ist ein TXT-Record, der festlegt, welche IP-Adressen oder Hostnamen E-Mails im Namen der Domain versenden dürfen.<br />Er hilft E-Mail-Spoofing zu verhindern und stellt sicher, dass eingehende E-Mails von vertrauenswürdigen Servern stammen.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Typ: TXT<br />◦ Hostname: deinedomain.de<br />◦ Wert: v=spf1 mx a ip4:123.123.123.123 -all<br />Dieser Eintrag erlaubt dem Mailserver mit der IP-Adresse 123.123.123.123 E-Mails zu versenden und blockiert alle anderen.</li>
            <li><strong>DKIM-Record (DomainKeys Identified Mail):</strong><br />Der DKIM-Record ist ebenfalls ein TXT-Record, der zum Signieren von E-Mails verwendet wird. Er ermöglicht es dem empfangenden Mailserver zu überprüfen, ob eine E-Mail tatsächlich vom angegebenen Absender stammt und auf dem Weg dorthin nicht verändert wurde.<br />Mailcow generiert automatisch den passenden DKIM-Schlüssel, den man dann als TXT-Record bei seinem DNS-Provider eintragen muss.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Typ: TXT<br />◦ Hostname: default._domainkey.deinedomain.de<br />◦ Wert: v=DKIM1; k=rsa; p=MIIBIjANBgkqh&#8230;</li>
            <li><strong>DMARC-Record (Domain-based Message Authentication, Reporting, and Conformance):</strong><br />Der DMARC-Record ist ein TXT-Record, der SPF und DKIM ergänzt und angibt, wie E-Mail-Server mit Nachrichten umgehen sollen, die die SPF- und DKIM-Prüfungen nicht bestehen.<br />DMARC ermöglicht es, Richtlinien für den Umgang mit nicht authentifizierten E-Mails zu definieren (z.B. in Quarantäne verschieben oder ablehnen) und Berichte über verdächtige Aktivitäten zu erhalten.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Typ: TXT<br />◦ Hostname: _dmarc.deinedomain.de<br />◦ Wert: v=DMARC1; p=quarantine; rua=mailto:dmarc-reports@deinedomain.de;</li>
          </ul>
          
          <BlogHeading>Automatisierung und Zusatz DNS-Einträge</BlogHeading>
          
          <p>Zuletzt gibt es noch einige optimale DNS-Einträge, die du in Mailcow setzen kannst. Sie sind nicht zwingend notwendig, erleichtern aber die Arbeit bei der Konfiguration auf dem Client.</p>
          
          <ul>
            <li><strong>CNAME-Record für Autodiscover und Autoconfig:</strong><br />Diese CNAME-Einträge sind nützlich, wenn E-Mail-Clients wie Outlook oder Thunderbird automatisch die richtigen Servereinstellungen finden sollen.<br />Sie werden oft auf den Hauptserver oder eine entsprechende Subdomain geleitet.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Typ: CNAME<br />◦ Hostname: autodiscover.deine_domain.de<br />◦ Wert: mail.deinedomain.de<br />◦ Hostname: autoconfig.deinedomain.de<br />◦ Wert: mail.deinedomain.de</li>
            <li><strong>SRV-Records (für XMPP und andere Dienste):</strong><br />Wenn man in Mailcow zusätzliche Dienste wie XMPP (Chat-Dienste) nutzen möchte, können SRV-Records relevant sein. Für den normalen E-Mail-Betrieb sind sie nicht zwingend erforderlich.<br /><span style={{textDecoration: "underline"}}>Beispiel</span><br />◦ Typ: SRV<br />◦ Hostname: _xmpp-client._tcp.deinedomain.de<br />◦ Wert: 10 0 5222 mail.deinedomain.de</li>
          </ul>
          
          <BlogHeading>Einrichtung der Mailcow DNS-Einstellungen</BlogHeading>
          
          <p>Alle zu setzenden DNS Einträge findest du im Mailcow Webinterface unter „Konfiguration – E-Mail". Klicke auf „DNS" rechts neben der Domain um die Einträge anzuzeigen.<br />Auf der linken Seite siehst du den Wert, der gesetzt werden muss und auf der rechten Seite den Wert, der aktuell auf dem DNS Server gespeichert ist.</p>
          
          <p>Mit einer kostenlosen Mailcow Testinstanz kannst du einmal alles ausprobieren.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Mailcow DNS-Einstellungen",
      formattedDate: "27. Oktober 2024",
      excerpt: "Die richtigen DNS-Einstellungen sind ein entscheidender Faktor, damit dein Open Source Mailserver Mailcow einwandfrei funktioniert. In diesem Artikel erfährst du, welche Einträge du vornehmen musst und was sie bewirken.",
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Mailcow DNS Settings",
      formattedDate: "October 27, 2024",
      excerpt: "The correct DNS settings are a crucial factor for your open source Mailcow mail server to function properly. In this article, you'll learn which entries you need to make and what they do.",
      content: () => (
        <BlogContent>
          <p>The correct DNS settings are a crucial factor for your open source Mailcow mail server to function properly. In this article, you'll learn which entries you need to make and what they do.</p>
          
          <BlogImage src={mailcowDns} alt="Mailcow DNS" size="large" className="my-6" />
          
          <BlogHeading>DNS</BlogHeading>
          
          <p>The abbreviation "DNS" stands for "Domain Name System". A DNS server serves, for example, like a phone book, to establish the mapping between a domain name and the IP address. Without DNS servers, you would have to know the IP address of every website to open it. Thanks to DNS, you just need to enter the name in the search bar, and the DNS server takes care of resolving the IP address.<br />In addition to this name resolution, the DNS server serves many other purposes, such as email validation.</p>
          
          <BlogHeading>Basic Mailcow DNS Settings</BlogHeading>
          
          <p>For your Mailcow to be reachable online and able to send emails, some basic DNS entries are necessary.</p>
          
          <ul>
            <li><strong>A Record:</strong><br />The A Record (Address Record) assigns an IPv4 address to a domain. For Mailcow, this means that the A Record points the hostname of the mail server (e.g., mail.yourdomain.com) to the IPv4 address of the server. This entry ensures that the mail server is accessible via the hostname on the internet.<br /><span style={{textDecoration: "underline"}}>Example:</span><br />◦ Hostname: mail.yourdomain.com<br />◦ IPv4 address: 123.123.123.123</li>
            <li><strong>AAAA Record:</strong><br />The AAAA Record is the IPv6 equivalent to the A Record. It assigns an IPv6 address to a hostname.<br />If your server has an IPv6 address and you want it to be accessible via IPv6, you need this entry.<br /><span style={{textDecoration: "underline"}}>Example</span><br />◦ Hostname: mail.yourdomain.com<br />◦ IPv6 address: 2001:0db8:85a3:0000:0000:8a2e:0370:7334</li>
            <li><strong>PTR Entry:</strong><br />The PTR Record (Pointer Record) is used for "Reverse DNS" resolution, in which the IP address of the mail server points to a hostname.<br />This is particularly important so that other mail servers can recognize that it is a legitimate mail server and not a spam sender.<br /><span style={{textDecoration: "underline"}}>Example</span><br />◦ Hostname: mail.yourdomain.com<br />◦ IP address (reversed): 123.123.123.123.in-addr.arpa</li>
            <li><strong>MX Entry:</strong><br />The MX Record (Mail Exchange Record) indicates which server is responsible for receiving emails for a domain.<br />It points to the hostname of the mail server (e.g., mail.yourdomain.com), which must be accessible via the A Record.<br />Multiple MX Records with different priorities can be specified. The server with the lowest priority is contacted first.<br /><span style={{textDecoration: "underline"}}>Example</span><br />◦ Hostname: yourdomain.com<br />◦ Mail server: mail.yourdomain.com<br />◦ Priority: 10</li>
          </ul>
          
          {/* The rest of the English translation would go here */}
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Mailcow DNS Settings",
      formattedDate: "27 October 2024",
      excerpt: "The correct DNS settings are a crucial factor for your open source Mailcow mail server to function properly. In this article, you'll learn which entries you need to make and what they do.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;