// src/data/blogPosts/heliboard.js

// Import images
import thumbnailImage from '../../assets/images/blog/heliboard-featured.png';
import heliboardLogo from '../../assets/images/blog/2024/06/icon_-nkf9NO4Zp7Y0YX1AOcQdZMf32R_bPt8AZC2Ycn2v1I.png';
import fDroidHeliboardInstall from '../../assets/images/blog/2024/06/F-Droid-Heliboard-installieren.jpg';
import heliboardAssistant1 from '../../assets/images/blog/2024/06/Heliboard-Assistant-1.jpg';
import heliboardAssistant2 from '../../assets/images/blog/2024/06/Heliboard-Assistant-2.jpg';
import heliboardAssistant3 from '../../assets/images/blog/2024/06/Heliboard-Assistant-3.jpg';
import heliboardAssistant4 from '../../assets/images/blog/2024/06/Heliboard-Assistant-4.jpg';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Heliboard keyboard app
 */
const post = {
  id: '20563',
  slug: 'heliboard',
  
  // Base data - same regardless of language
  date: "2024-06-25T12:25:48.000Z",
  readTime: 13,
  categories: ["Empfehlungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 36,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Heliboard",
      formattedDate: "25. Juni 2024",
      excerpt: "Heliboard Die Open Source Tastatur für Android ist anpassbar, datenschutzfreundlich und bietet viele tolle Funktionen. In diesem Artikel stellen wir die App vor und zeigen, wie sie grundlegend konfiguriert werden kann.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Heliboard</h1>
          
          <p>Die Open Source Tastatur für Android ist anpassbar, datenschutzfreundlich und bietet viele tolle Funktionen. In diesem Artikel stellen wir die App vor und zeigen, wie sie grundlegend konfiguriert werden kann.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={heliboardLogo} alt="Heliboard Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Wieso eine Tastatur App?</BlogHeading>
          
          <p>Alle Smartphones, die mit einem herstellerspezifischen Android ausgeliefert werden, haben eine voreingestellte Tastatur. Diese sind meist mit dem Internet verbunden und haben unnötig viele Berechtigungen. Gerade bei der Integration von KI-Funktionen in die Tastatur werden die Eingaben an den jeweiligen Dienstanbieter zur Verarbeitung gesendet.<br />Bei normalen Nachrichten mag das für viele noch kein Problem sein, da es ja nichts zu verbergen gibt 😉 Aber wie sieht es mit der Eingabe beim Online-Banking oder der Passwortabfrage aus?<br />Natürlich hat jeder etwas zu verbergen. Eine Tastatur-App wie „Heliboard" eignet sich also sowohl für Menschen, die Wert auf ihre digitale Privatsphäre legen, als auch für alle anderen.</p>
          
          <div className="mt-8 mb-4 flex justify-center">
            <div className="w-12 h-12 text-primary">
              <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor">
                <path d="M12.729 15.415c.508 0 1.303.372 1.695.639l-2.131-5.413c-.464-1.177 1.301-1.828 1.75-.688l1.443 3.661c.167.424.808.173.641-.252l-.355-.903c.315-.001.751-.055 1.055.021.197.049.354.151.434.358l.292.74c.166.422.805.171.641-.252l-.337-.855c.426-.017.73-.03 1.01.04.199.05.36.153.441.361l.248.628c.162.425.805.174.641-.251l-.279-.705c.236.016.442.036.627.082.523.131.884.468 1.28 1.477.788 2.001.208 2.896 1.001 4.91l-5.201 1.987c-2.064-2.522-4.494-3.269-5.144-3.682-1.283-.82-.765-1.903.248-1.903zm-1.291-5.26c-.055-.709.369-1.338 1.039-1.602.659-.262 1.403-.098 1.851.464-.05-1.118-1.185-1.865-2.24-1.453-1.051.415-1.377 1.737-.65 2.591zm-.084 3.514l-.263-.669h-9.091v-8h20v6.05c.902.564 1.371 1.477 1.685 2.271.135.341.234.658.315.958v-11.279h-24v12h10.008c.284-.583.753-1.05 1.346-1.331zm-4.354-4.669c0 .552.447 1 1 1s1-.448 1-1-.447-1-1-1-1 .448-1 1zm-3 0c0 .552.447 1 1 1s1-.448 1-1-.447-1-1-1-1 .448-1 1z"></path>
              </svg>
            </div>
          </div>
          
          <BlogHeading>Installation</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 my-6">
            <div className="w-full md:w-1/2">
              <p>Die Heliboard App ist derzeit im F-Droid Store erhältlich:<br /><a href="https://f-droid.org/packages/helium314.keyboard/" target="_blank" rel="noopener">https://f-droid.org/packages/helium314.keyboard/</a></p>
              <p>F-Droid ist eine Alternative zum herkömmlichen PlayStore, in dem ausschließlich datenschutzfreundliche Apps zu finden sind.<br />Falls du dir F-Droid noch nicht installiert hast, klicke einfach unter <a href="https://f-droid.org/de/">https://f-droid.org/de/</a> auf „F-Droid herunterladen"</p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={fDroidHeliboardInstall} alt="F-Droid Heliboard Installation" size="medium" />
            </div>
          </div>
          
          <BlogHeading>Erster Start von Heliboard</BlogHeading>
          
          <p>Wenn du die Heliboard App zum ersten Mal öffnest, wirst du durch einen kurzen Einrichtungsassistenten geführt. Danach ist die Tastatur aktiviert und einsatzbereit. Zum Testen kannst du z.B. einen Messenger öffnen und eine Testnachricht an einen Kontakt schreiben.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 my-6">
            <BlogImage src={heliboardAssistant3} alt="Heliboard Assistant (3)" size="small" />
            <BlogImage src={heliboardAssistant4} alt="Heliboard Assistant (4)" size="small" />
            <BlogImage src={heliboardAssistant1} alt="Heliboard Assistant (1)" size="small" />
            <BlogImage src={heliboardAssistant2} alt="Heliboard Assistant (2)" size="small" />
          </div>
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <p>Ab sofort gelangst du beim Öffnen der App zu den Heliboard Einstellungen. Hier kannst du die App nach deinen Wünschen konfigurieren.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-6">
            <div>
              <h3 className="text-xl font-semibold mb-2">Sprache & Layouts</h3>
              <p>Hier kannst du neben deiner Systemsprache weitere Sprachen hinzufügen. Wenn du keine weiteren Einstellungen vornehmen möchtest, setze einfach ein Häkchen bei „Systemsprache verwenden".</p>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2">Optionen</h3>
              <p>Hier kann z.B. das Vibrieren bei Tastendruck eingestellt, die obere Zahlenreihe und die Zwischenablage aktiviert werden.</p>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2">Aussehen</h3>
              <p>Farben ändern, Tastenränder hinzufügen, Dark Mode aktivieren. Zusätzlich kann eine geteilte Tastatur aktiviert und die Tastaturgröße eingestellt werden.</p>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2">Erweitert</h3>
              <p>Neben einigen anderen Einstellungen kannst du die Dauer für einen langen Tastendruck festlegen, Löschoptionen einstellen und ein Hintergrundbild festlegen.</p>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2">Textkorrektur</h3>
              <p>Mit deinem persönlichen Wörterbuch kannst du dem Standard-Wörterbuch weitere Wörter hinzufügen. Das hat den Vorteil, dass diese von der Autokorrektur erkannt werden. Außerdem kannst du einstellen, wie zuverlässig die Autokorrektur sein soll, ob es eine automatische Groß-/Kleinschreibung geben soll und ob Änderungsvorschläge angezeigt werden sollen.</p>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold mb-2">Über</h3>
              <p>Dieser Menüpunkt enthält Informationen über die Version und die Open-Source-Lizenz der App.</p>
            </div>
          </div>
          
          <BlogHeading>Zusammenfassung</BlogHeading>
          
          <p>Mit der Heliboard App erhältst du eine anpassbare und datenschutzfreundliche Tastatur für dein Android Smartphone. Es wird keine Internetverbindung benötigt, die App ist Open Source und bringt alle benötigten Funktionen mit.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Heliboard",
      formattedDate: "25. Juni 2024",
      excerpt: "Heliboard Die Open Source Tastatur für Android ist anpassbar, datenschutzfreundlich und bietet viele tolle Funktionen. In diesem Artikel stellen wir die App vor und zeigen, wie sie grundlegend konfiguriert werden kann.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Heliboard",
      formattedDate: "June 25, 2024",
      excerpt: "Heliboard The open source keyboard for Android is customizable, privacy-friendly, and offers many great features. In this article we introduce the app and show how it can be configured.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Heliboard</h1>
          
          <p>The open source keyboard for Android is customizable, privacy-friendly, and offers many great features. In this article we introduce the app and show how it can be configured.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Heliboard",
      formattedDate: "25 June 2024",
      excerpt: "Heliboard The open source keyboard for Android is customizable, privacy-friendly, and offers many great features. In this article we introduce the app and show how it can be configured.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;