// src/data/blogPosts/android-app-newpipe.js
// Example of a blog post using the new component system

// Import images
import appIcon from '../../assets/images/blog/2023/02/NewPipe_app_icon_400x400.png';
import downloadIcon from '../../assets/images/blog/2022/12/iconmonstr-download-20-240.png';
import channelImportScreenshot from '../../assets/images/blog/2023/02/Screenshot_20230216-134300.jpg';
import downloadDialog from '../../assets/images/blog/2023/02/NewPipe_screenshot_download_dialog.png';
import downloadPage from '../../assets/images/blog/2023/02/NewPipe_screenshot_download_page.png';
import smartphoneIcon from '../../assets/images/blog/2022/03/smartphone.png';
import youtubeIcon from '../../assets/images/blog/2023/02/iconmonstr-youtube-8-240.png';
import donateIcon from '../../assets/images/blog/2022/12/iconmonstr-banknote-14-240.png';
import thumbnailImage from '../../assets/images/blog/android-app-newpipe-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery 
} from '../../components/blog';

/**
 * Multilingual blog post about the NewPipe Android app
 */
const post = {
  id: '11122',
  slug: 'android-app-newpipe',
  
  // Base data - same regardless of language
  date: "2023-02-16T12:48:53.000Z",
  readTime: 11,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage, // Thumbnail for card view
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'small', // Controls size in the post view
  showInPostHeader: false, // This will hide the thumbnail in the full post view
  likes: 32,
  comments: [],
  
  // Language-specific data - using both formats for maximum compatibility
  translations: {
    // German content
    de: {
      title: "Android-App NewPipe",
      formattedDate: "16. Februar 2023",
      excerpt: 'In diesem Blogbeitrag geht es um die Android-App „NewPipe". Was du damit machen kannst und warum es so praktisch ist, erfährst du hier.',
      content: () => (
        <BlogContent>
          <p>In diesem Blogbeitrag geht es um die Android-App <strong>NewPipe</strong>. Was du damit machen kannst und warum es so praktisch ist, erfährst du hier.</p>
          
          <BlogImage src={thumbnailImage} alt="NewPipe App" size="small" className="rounded-lg shadow-md my-6" />
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Die App ist <strong>Open Source</strong> und nur über den F-Droid Store oder direkt über die Webseite erhältlich.</p>
          <p><a href="https://f-droid.org/en/packages/org.schabi.newpipe/" target="_blank" rel="noopener">https://f-droid.org/en/packages/org.schabi.newpipe/</a></p>
          <p>
            Um mit deinem Smartphone direkt auf den F-Droid Store zugreifen zu können, musst du die App von der offiziellen Seite herunterladen und das APK installieren.
            <br />
            <a href="https://f-droid.org/en/" target="_blank" rel="noopener">https://f-droid.org/en/</a>
          </p>
          
          <BlogImage src={downloadIcon} alt="Download Icon" size="icon" />
          
          <BlogHeading>Kanäle importieren</BlogHeading>
          
          <p>Wenn du bereits Kanäle auf YouTube abonniert hast, kannst du diese einfach in NewPipe importieren.</p>
          <p>Klicke dazu in der Ansicht "Abonnements" auf die drei Punkte oben rechts und wähle hier <strong>Importieren von – YouTube</strong> aus.</p>
          
          <BlogImage src={channelImportScreenshot} alt="Screenshot der Kanäle-Import-Funktion in NewPipe" size="medium" />
          
          <BlogHeading>Downloads</BlogHeading>
          
          <p>Du hast die Möglichkeit, die Videos sowohl als Audio als auch als <strong>Video herunterzuladen</strong>. Klicke dazu auf ein Video und dann auf den Button „Download". Hier kannst du dann das gewünschte Format auswählen und auch den Namen anpassen. Die heruntergeladenen Videos findest du dann unter Downloads in der Seitenleiste.</p>
          
          <BlogGallery 
            images={[
              { src: downloadDialog, alt: "Screenshot des Download-Dialogs in NewPipe" },
              { src: downloadPage, alt: "Screenshot der Download-Seite in NewPipe" }
            ]}
          />
          
          <BlogHeading>Gestensteuerung</BlogHeading>
          
          <p>Um die Gestensteuerung zu verwenden, drehe dein Smartphone so, dass das Video im Vollbildmodus abgespielt wird. Jetzt kannst du durch Antippen der rechten Bildschirmhälfte die <strong>Helligkeit</strong> ändern und durch Antippen der linken Bildschirmhälfte die <strong>Lautstärke</strong> einstellen.</p>
          
          <BlogImage src={smartphoneIcon} alt="Smartphone Icon" size="icon" />
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p>Mit NewPipe kannst du alle Videos ohne Werbung anschauen und sogar den Bildschirm deines Smartphones ausschalten, ohne dass das Video stoppt. Die App ist schnell, akkuschonend und verbraucht sehr wenig Datenvolumen. Alle Informationen werden auf deinem Smartphone gespeichert und es findet keine Auswertung deines Verhaltens statt.</p>
          
          <BlogImage src={youtubeIcon} alt="YouTube Icon" size="icon" />
          
          <BlogHeading>Spenden</BlogHeading>
          
          <p>
            Wenn dir die App also gefällt, kannst du den Entwicklern gerne eine kleine Spende zukommen lassen:
            <br />
            <a href="https://newpipe.net/donate/" target="_blank" rel="noopener">https://newpipe.net/donate/</a>
          </p>
          <p>Da du mit NewPipe Videos ohne Werbung anschauen kannst, verdienen deine Lieblings-Youtuber auch weniger an ihren Videos. Du kannst also auch hier nach dem „<strong>Value for Value</strong>"-Prinzip für ihre Inhalte spenden.</p>
          
          <BlogImage src={donateIcon} alt="Geld-Spenden Icon" size="icon" />
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Android-App NewPipe",
      formattedDate: "16. Februar 2023",
      excerpt: 'In diesem Blogbeitrag geht es um die Android-App „NewPipe". Was du damit machen kannst und warum es so praktisch ist, erfährst du hier.',
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Android App NewPipe",
      formattedDate: "February 16, 2023",
      excerpt: 'This blog post is about the Android app "NewPipe". Find out what you can do with it and why it\'s so practical.',
      content: () => (
        <BlogContent>
          <p>This blog post is about the Android app <strong>NewPipe</strong>. Find out what you can do with it and why it's so practical.</p>
          
          <BlogImage src={thumbnailImage} alt="NewPipe App" size="small" className="rounded-lg shadow-md my-6" />
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>The app is <strong>Open Source</strong> and only available through the F-Droid Store or directly from the website.</p>
          <p><a href="https://f-droid.org/en/packages/org.schabi.newpipe/" target="_blank" rel="noopener">https://f-droid.org/en/packages/org.schabi.newpipe/</a></p>
          <p>
            To access the F-Droid Store directly from your smartphone, you need to download the app from the official website and install the APK.
            <br />
            <a href="https://f-droid.org/en/" target="_blank" rel="noopener">https://f-droid.org/en/</a>
          </p>
          
          <BlogImage src={downloadIcon} alt="Download Icon" size="icon" />
          
          <BlogHeading>Importing Channels</BlogHeading>
          
          <p>If you already subscribe to channels on YouTube, you can easily import them into NewPipe.</p>
          <p>To do this, in the "Subscriptions" view, click on the three dots in the top right and select <strong>Import from - YouTube</strong>.</p>
          
          <BlogImage src={channelImportScreenshot} alt="Screenshot of the channel import function in NewPipe" size="medium" />
          
          <BlogHeading>Downloads</BlogHeading>
          
          <p>You have the option to download videos as both audio and <strong>video</strong>. To do this, click on a video and then on the "Download" button. Here you can select the desired format and also customize the name. You'll find your downloaded videos under Downloads in the sidebar.</p>
          
          <BlogGallery 
            images={[
              { src: downloadDialog, alt: "Screenshot of the download dialog in NewPipe" },
              { src: downloadPage, alt: "Screenshot of the download page in NewPipe" }
            ]}
          />
          
          <BlogHeading>Gesture Control</BlogHeading>
          
          <p>To use gesture control, rotate your smartphone so that the video plays in full-screen mode. Now you can change the <strong>brightness</strong> by tapping the right half of the screen and adjust the <strong>volume</strong> by tapping the left half of the screen.</p>
          
          <BlogImage src={smartphoneIcon} alt="Smartphone Icon" size="icon" />
          
          <BlogHeading>Benefits</BlogHeading>
          
          <p>With NewPipe, you can watch all videos without ads and even turn off your smartphone screen without stopping the video. The app is fast, battery-friendly, and uses very little data. All information is stored on your smartphone, and your behavior is not analyzed.</p>
          
          <BlogImage src={youtubeIcon} alt="YouTube Icon" size="icon" />
          
          <BlogHeading>Donations</BlogHeading>
          
          <p>
            If you like the app, you can make a small donation to the developers:
            <br />
            <a href="https://newpipe.net/donate/" target="_blank" rel="noopener">https://newpipe.net/donate/</a>
          </p>
          <p>Since you can watch videos without ads with NewPipe, your favorite YouTubers earn less from their videos. So you can also donate for their content according to the <strong>"Value for Value"</strong> principle.</p>
          
          <BlogImage src={donateIcon} alt="Donation Icon" size="icon" />
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Android App NewPipe",
      formattedDate: "February 16, 2023",
      excerpt: 'This blog post is about the Android app "NewPipe". Find out what you can do with it and why it\'s so practical.',
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    },
    
    // French content (same pattern - simple and full locale)
    fr: {
      title: "Application Android NewPipe",
      formattedDate: "16 février 2023",
      excerpt: 'Ce billet de blog traite de l\'application Android "NewPipe". Découvrez ce que vous pouvez faire avec et pourquoi elle est si pratique.',
      content: () => (
        <BlogContent>
          <p>Ce billet de blog traite de l'application Android <strong>NewPipe</strong>. Découvrez ce que vous pouvez faire avec et pourquoi elle est si pratique.</p>
          
          {/* Rest of French content would go here */}
          <p>Contenu en français à compléter...</p>
        </BlogContent>
      )
    },
    
    fr_FR: {
      title: "Application Android NewPipe",
      formattedDate: "16 février 2023",
      excerpt: 'Ce billet de blog traite de l\'application Android "NewPipe". Découvrez ce que vous pouvez faire avec et pourquoi elle est si pratique.',
      get content() { return post.translations.fr.content; }
    },
    
    // Dutch content (same pattern - simple and full locale)
    nl: {
      title: "Android-app NewPipe",
      formattedDate: "16 februari 2023",
      excerpt: 'Deze blogpost gaat over de Android-app "NewPipe". Ontdek wat je ermee kunt doen en waarom het zo praktisch is.',
      content: () => (
        <BlogContent>
          <p>Deze blogpost gaat over de Android-app <strong>NewPipe</strong>. Ontdek wat je ermee kunt doen en waarom het zo praktisch is.</p>
          
          {/* Rest of Dutch content would go here */}
          <p>Nederlandse inhoud nog aan te vullen...</p>
        </BlogContent>
      )
    },
    
    nl_NL: {
      title: "Android-app NewPipe",
      formattedDate: "16 februari 2023",
      excerpt: 'Deze blogpost gaat over de Android-app "NewPipe". Ontdek wat je ermee kunt doen en waarom het zo praktisch is.',
      get content() { return post.translations.nl.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;