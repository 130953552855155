import React from "react";
import NavigationMD from "./md";
import NavigationLG from "./lg";
import NavigationXL from "./xl";
import NavigationSM from "./sm";

/**
 * Navbar renders the navigation bar at the top of the page depending on the device size.
 * Please see the corresponding .js files for the mobile and desktop versions.
 */
const Navbar = () => {
  return (
    <>
      <div className="sm:block md:hidden h-full">
        <NavigationSM />
      </div>
      <div className="hidden md:block lg:hidden h-full">
        <NavigationMD />
      </div>
      <div className="hidden lg:block xl:hidden h-full">
        <NavigationLG />
      </div>
      <div className="hidden xl:block h-full">
        <NavigationXL />
      </div>
    </>
  );
};
export default Navbar;
