// src/data/blogPosts/nextcloud-whiteboard-kreatives-arbeiten-in-der-cloud.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-whiteboard-kreatives-arbeiten-in-der-cloud-featured.png';
import whiteboardHeroImage from '../../assets/images/blog/2024/11/Nextcloud-Whiteboard-1920-x-1080-px.png';
import excalidrawLogo from '../../assets/images/blog/2024/11/68747470733a2f2f657863616c69647261772e6e7963332e63646e2e6469676974616c6f6365616e7370616365732e636f6d2f6769746875622f657863616c69647261775f6769746875625f636f7665725f325f6461726b2e706e67.png';
import newWhiteboardOption from '../../assets/images/blog/2024/11/Bildschirmfoto-vom-2024-11-27-16-22-16.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Whiteboard
 */
const post = {
  id: '22274',
  slug: 'nextcloud-whiteboard-kreatives-arbeiten-in-der-cloud',
  
  // Base data - same regardless of language
  date: "2024-11-27T15:46:55.000Z",
  readTime: 15,
  categories: ["Anleitungen", "Empfehlungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 22,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Whiteboard – Kreatives Arbeiten in der Cloud",
      formattedDate: "27. November 2024",
      excerpt: "Ob Brainstorming im Team, neue Ideen skizzieren oder eine Übersicht erstellen – mit Nextcloud Whiteboard kannst du kreativ in der Cloud arbeiten. In diesem Artikel wird die App vorgestellt und die wichtigsten Funktionen gezeigt.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Whiteboard - Kreatives Arbeiten in der Cloud</h1>
          
          <p>Ob Brainstorming im Team, neue Ideen skizzieren oder eine Übersicht erstellen – mit Nextcloud Whiteboard kannst du kreativ in der Cloud arbeiten. In diesem Artikel wird die App vorgestellt und die wichtigsten Funktionen gezeigt.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={whiteboardHeroImage} alt="Nextcloud Whiteboard" size="large" />
            </div>
            
          </div>
          
          <BlogHeading>Excalidraw</BlogHeading>
          
          <p>Nextcloud Whiteboard nutzt Excalidraw als Backend, um die leistungsstarken Zeichen- und Skizzierfunktionen direkt in die Nextcloud-Plattform zu integrieren. Dies ermöglicht die Nutzung der intuitiven und flexiblen Benutzeroberfläche von Excalidraw und die nahtlose Kombination von Whiteboard mit anderen Nextcloud-Diensten wie Dateien, Office und Talk.</p>
          
          <BlogImage src={excalidrawLogo} alt="Excalidraw Logo" size="large" className="my-6" />
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Die Installation von Nextcloud Whiteboard erfolgt in zwei Schritten. Melde dich zunächst mit einem Administrator-Account auf der Nextcloud-Weboberfläche an, klicke auf das Icon in der rechten oberen Ecke und dann auf Apps.<br />Gib dann in der Suchleiste „Whiteboard" ein und klicke auf „Herunterladen und aktivieren".</p>
          
          <p>Für den zweiten Schritt benötigst du einen direkten Zugang zu dem Server, auf dem deine Nextcloud läuft. Hier muss das Backend mit Docker installiert werden. Eine Anleitung dazu findest du hier:<br /><a href="https://github.com/nextcloud/whiteboard" target="_blank" rel="noopener">https://github.com/nextcloud/whiteboard</a></p>
          
          <p>Für alle PathConnect Kunden wird diese Installation selbstverständlich übernommen.</p>
          
          <p>Nach der vollständigen Installation kann ein neues Whiteboard erstellt werden, indem in der Dateiansicht auf „+ Neu" und dann auf „Neues Whiteboard" geklickt wird.</p>
          
          <BlogImage src={newWhiteboardOption} alt="Neues Whiteboard erstellen" size="large" className="my-6" />
          
          <BlogHeading>Funktionen</BlogHeading>
          
          <p>Nextcloud Whiteboard bietet zahlreiche Funktionen, von denen die wichtigsten im Folgenden vorgestellt werden.</p>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Teilen und Kollaboration</h3>
            <p>Um ein Whiteboard gemeinsam mit anderen bearbeiten zu können, musst du es zunächst freigeben. Klicke dazu auf die drei Punkte in der rechten oberen Ecke und dann auf „Seitenleiste öffnen". Es öffnet sich die übliche Seitenleiste mit allen Optionen, die du auch von anderen Dateien kennst. Klicke auf „Teilen" und gib entweder einen Benutzernamen ein oder erstelle einen öffentlichen Link.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Formen zeichnen</h3>
            <p>Nextcloud Whiteboard bietet neben Pfeilen und Strichen auch verschiedene Formen wie Quadrat, Raute und Kreis. Um eine neue Form hinzuzufügen, wähle das entsprechende Element aus und ziehe es mit der Maus in die gewünschte Größe auf eine freie Fläche.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Smart Picker</h3>
            <p>Mit dem Nextcloud Smart Picker hast du die Möglichkeit andere Inhalte aus deiner Nextcloud direkt in das Whiteboard einzufügen. Zum Beispiel kannst du ein Textdokument hinzufügen, indem du auf „Dateien" klickst und ein Dokument auswählst.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Laserpointer</h3>
            <p>Mit dem Laserpointer kannst du Inhalte in einer Präsentation hervorheben. Um den Laserpointer zu aktivieren, klicke auf das Symbol ganz rechts in der Werkzeugleiste und wähle „Laserpointer". Ein roter Strich erscheint auf der Fläche, über die du mit gedrückter linker Maustaste fährst.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Hintergrundfarbe ändern</h3>
            <p>Um die Hintergrundfarbe zu ändern, klicke auf die Balken in der linken oberen Ecke, um entweder eine vordefinierte Farbe auszuwählen oder eine individuelle Farbe über den Hex-Code einzugeben.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Bilder und Text einfügen</h3>
            <p>Mit Bildern und Texten kannst du dein Whiteboard ergänzen. Um einen Text einzufügen, klicke auf das „A"-Symbol in der Werkzeugleiste und für Bilder auf das „Bild"-Symbol.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Als Bild exportieren</h3>
            <p>Wenn du dein Whiteboard als Bild exportieren möchtest, kannst du dies tun, indem du auf die drei Striche in der oberen linken Ecke klickst und dann „Exportiere Bild..." auswählst.</p>
          </div>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Whiteboard – Kreatives Arbeiten in der Cloud",
      formattedDate: "27. November 2024",
      excerpt: "Ob Brainstorming im Team, neue Ideen skizzieren oder eine Übersicht erstellen – mit Nextcloud Whiteboard kannst du kreativ in der Cloud arbeiten. In diesem Artikel wird die App vorgestellt und die wichtigsten Funktionen gezeigt.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Nextcloud Whiteboard - Creative Work in the Cloud",
      formattedDate: "November 27, 2024",
      excerpt: "Whether brainstorming in a team, sketching new ideas, or creating an overview - with Nextcloud Whiteboard you can work creatively in the cloud. This article introduces the app and shows its most important features.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Whiteboard - Creative Work in the Cloud</h1>
          
          <p>Whether brainstorming in a team, sketching new ideas, or creating an overview - with Nextcloud Whiteboard you can work creatively in the cloud. This article introduces the app and shows its most important features.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={whiteboardHeroImage} alt="Nextcloud Whiteboard" size="large" />
            </div>
            
          </div>
          
          <BlogHeading>Excalidraw</BlogHeading>
          
          <p>Nextcloud Whiteboard uses Excalidraw as its backend to integrate powerful drawing and sketching functions directly into the Nextcloud platform. This enables the use of Excalidraw's intuitive and flexible user interface and the seamless combination of Whiteboard with other Nextcloud services such as Files, Office, and Talk.</p>
          
          <BlogImage src={excalidrawLogo} alt="Excalidraw Logo" size="large" className="my-6" />
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>The installation of Nextcloud Whiteboard is done in two steps. First, log in to the Nextcloud web interface with an administrator account, click on the icon in the upper right corner, and then on Apps.<br />Then enter "Whiteboard" in the search bar and click on "Download and activate".</p>
          
          <p>For the second step, you need direct access to the server on which your Nextcloud is running. Here, the backend must be installed with Docker. You can find instructions for this here:<br /><a href="https://github.com/nextcloud/whiteboard" target="_blank" rel="noopener">https://github.com/nextcloud/whiteboard</a></p>
          
          <p>For all PathConnect customers, this installation is of course taken care of.</p>
          
          <p>After the complete installation, a new whiteboard can be created by clicking on "+ New" in the file view and then on "New Whiteboard".</p>
          
          <BlogImage src={newWhiteboardOption} alt="Create new whiteboard" size="large" className="my-6" />
          
          <BlogHeading>Features</BlogHeading>
          
          <p>Nextcloud Whiteboard offers numerous features, the most important of which are presented below.</p>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Sharing and Collaboration</h3>
            <p>To be able to edit a whiteboard together with others, you must first share it. To do this, click on the three dots in the upper right corner and then on "Open sidebar". The usual sidebar opens with all the options that you also know from other files. Click on "Share" and either enter a username or create a public link.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Drawing Shapes</h3>
            <p>In addition to arrows and lines, Nextcloud Whiteboard also offers various shapes such as squares, diamonds, and circles. To add a new shape, select the corresponding element and drag it with the mouse to the desired size on a free area.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Smart Picker</h3>
            <p>With the Nextcloud Smart Picker, you have the option of inserting other content from your Nextcloud directly into the whiteboard. For example, you can add a text document by clicking on "Files" and selecting a document.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Laser Pointer</h3>
            <p>With the laser pointer, you can highlight content in a presentation. To activate the laser pointer, click on the symbol on the far right of the toolbar and select "Laser pointer". A red line appears on the area over which you move with the left mouse button pressed.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Changing Background Color</h3>
            <p>To change the background color, click on the bars in the upper left corner to either select a predefined color or enter a custom color using the hex code.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Inserting Images and Text</h3>
            <p>You can enhance your whiteboard with images and text. To insert text, click on the "A" symbol in the toolbar, and for images, click on the "Image" symbol.</p>
          </div>
          
          <div className="mb-8">
            <h3 className="text-xl font-bold mb-3">Export as Image</h3>
            <p>If you want to export your whiteboard as an image, you can do this by clicking on the three lines in the upper left corner and then selecting "Export image...".</p>
          </div>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open-source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Whiteboard - Creative Work in the Cloud",
      formattedDate: "27 November 2024",
      excerpt: "Whether brainstorming in a team, sketching new ideas, or creating an overview - with Nextcloud Whiteboard you can work creatively in the cloud. This article introduces the app and shows its most important features.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;