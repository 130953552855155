// src/data/blogPosts/thunderbird-e-mail-verschlusseln-und-signieren.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/thunderbird-e-mail-verschlusseln-und-signieren-featured.png';
import thunderbirdLogo from '../../assets/images/blog/2023/01/480px-Mozilla_Thunderbird_Logo.svg.png';
import kontoeinstellungenImg from '../../assets/images/blog/2024/08/Kontoeinstellungen1.png';
import kontoAuswaehlenImg from '../../assets/images/blog/2024/08/Konto-auswaehlen.png';
import neuerSchluesselImg from '../../assets/images/blog/2024/08/Neuen-Schluessel-hinzufuegen.png';
import openPGPSchluesselImg from '../../assets/images/blog/2024/08/OpenPGP-Schluessel.png';
import bestaetigenImg from '../../assets/images/blog/2024/08/Bestaetigen.png';
import digitalSignierenImg from '../../assets/images/blog/2024/08/Digital-signieren.png';
import erweiterteEinstellungenImg from '../../assets/images/blog/2024/08/Erweiterte-Einstellungen.png';

/**
 * Blog post about encrypting and signing emails in Thunderbird
 */
const post = {
  id: '21100',
  slug: 'thunderbird-e-mail-verschlusseln-und-signieren',
  
  // Base data - same regardless of language
  date: "2024-08-06T04:28:39.000Z",
  readTime: 11,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 3,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Thunderbird – E-Mail verschlüsseln und signieren",
      formattedDate: "6. August 2024",
      excerpt: "Thunderbird – E-Mail verschlüsseln und signieren ist der beste Weg, um die Sicherheit und Vertraulichkeit deiner Nachrichten zu gewährleisten. Eine E-Mail ist standardmäßig nicht verschlüsselt und kann daher leicht mitgelesen werden. In diesem Artikel zeigen wir dir, wie du deine E-Mails in Thunderbird Ende-zu-Ende verschlüsseln kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Thunderbird – E-Mail verschlüsseln und signieren</h1>
          
          <p>Thunderbird – E-Mail verschlüsseln und signieren ist der beste Weg, um die Sicherheit und Vertraulichkeit deiner Nachrichten zu gewährleisten. Eine E-Mail ist standardmäßig nicht verschlüsselt und kann daher leicht mitgelesen werden. In diesem Artikel zeigen wir dir, wie du deine E-Mails in Thunderbird Ende-zu-Ende verschlüsseln kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={thunderbirdLogo} alt="Mozilla Thunderbird Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Private und öffentliche Schlüssel</BlogHeading>
          
          <p>Bei der Ende-zu-Ende-Verschlüsselung werden zwei Arten von Schlüsseln verwendet: ein öffentlicher und ein privater Schlüssel.</p>
          
          <p><u>Öffentlicher Schlüssel</u><br />
          Der öffentliche Schlüssel kann, wie der Name schon sagt, öffentlich weitergegeben werden. Er wird verwendet, um Nachrichten zu verschlüsseln oder eine Signatur zu verifizieren. Das bedeutet, dass jeder, der dir eine verschlüsselte E-Mail schicken möchte, deinen öffentlichen Schlüssel verwenden kann, um die Nachricht zu verschlüsseln. Sobald die Nachricht mit deinem öffentlichen Schlüssel verschlüsselt wurde, kann sie von niemandem außer dem Besitzer des zugehörigen privaten Schlüssels, also dir, gelesen werden.</p>
          
          <p><u>Privater Schlüssel (Private Key)</u><br />
          Der private Schlüssel ist geheim zu halten und sicher aufzubewahren. Er wird verwendet, um verschlüsselte Nachrichten zu entschlüsseln, die mit dem zugehörigen öffentlichen Schlüssel verschlüsselt wurden. Nur der Besitzer des privaten Schlüssels kann die Nachricht lesen, wodurch die Vertraulichkeit und Sicherheit der Daten gewährleistet wird.</p>
          
          <div className="flex justify-center my-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" className="text-primary">
              <path d="M9 12.242v7.894l-4.291.864-.709-3.827 4.005-5.909c.331.382.352.46.995.978zm2 1.176v8.015l2.732 2.567 3.268-2.567-1.052-1.109 1.052-1.108-1.052-1.108 1.052-1.108v-3.583c-.941.381-1.955.583-3.001.583-1.045 0-2.059-.202-2.999-.582zm7.242-11.661c-2.131-2.131-5.424-2.25-7.687-.651-1.174.821-1.96 1.94-2.335 3.378-1.664-.087-2.72-.905-2.72-1.484 0-.6 1.128-1.46 2.898-1.494.42-.524.67-.822 1.42-1.36-.42-.086-.856-.146-1.318-.146-2.485 0-4.5 1.343-4.5 3 0 1.936 2.526 3 4.5 3 2.818 0 5.337-1.892 4.252-3.967.567-.912 1.682-.902 2.309-.275.975.975.24 2.625-1.146 2.544-.862 2.006-3.376 3-5.794 2.879.225 1.122.768 2.192 1.638 3.062 2.342 2.344 6.141 2.343 8.484 0 1.17-1.172 1.757-2.708 1.757-4.244 0-1.535-.586-3.07-1.758-4.242z"></path>
            </svg>
          </div>
          
          <BlogHeading>So funktioniert es in der Theorie</BlogHeading>
          
          <ul className="list-disc pl-6 mb-6">
            <li>
              <p>Um eine sichere E-Mail-Kommunikation aufzubauen, schickst du der anderen Person zunächst eine normale E-Mail. Deinen öffentlichen Schlüssel schickst du als Anhang mit.</p>
            </li>
            <li>
              <p>Die andere Person kann dann ihre Nachricht mit deinem öffentlichen Schlüssel verschlüsseln und dir die E-Mail mit ihrem öffentlichen Schlüssel als Anhang schicken.</p>
            </li>
            <li>
              <p>Danach haben beide Parteien ihre öffentlichen Schlüssel ausgetauscht und die sichere E-Mail Kommunikation ist aufgebaut</p>
            </li>
            <li>
              <p>Alternativ können beide Parteien ihre öffentlichen Schlüssel z.B. auf Ihrer Webseite oder auf <a href="https://keys.openpgp.org/">https://keys.openpgp.org/</a> veröffentlichen</p>
            </li>
          </ul>
          
          <BlogHeading>Thunderbird in der Praxis – E-Mail verschlüsseln und signieren</BlogHeading>
          
          <p>Das Ver- und Entschlüsseln übernimmt in der Praxis natürlich Thunderbird für dich. Du musst nur einmal ein paar Einstellungen vornehmen und schon hast du die Grundlage für eine sichere Kommunikation geschaffen.</p>
          
          <p>1. Klicke in Thunderbird oben rechts auf die drei Striche und dann auf „Kontoeinstellungen".</p>
          <p>2. Wähle das E-Mail-Konto aus, für das du die Verschlüsselung einrichten möchtest und klicke auf „Ende-zu-Ende-Verschlüsselung".</p>
          <p>3. Klicke unter „OpenPGP" auf die Schaltfläche „Schlüssel hinzufügen".</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={kontoeinstellungenImg} alt="Thunderbird Kontoeinstellungen" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={kontoAuswaehlenImg} alt="Thunderbird Konto auswählen" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={neuerSchluesselImg} alt="Thunderbird Neuen Schlüssel hinzufügen" size="medium" />
            </div>
          </div>
          
          <p>4. Im nächsten Schritt kannst du auswählen, wie lange dein Schlüssel gültig sein soll. Du kannst auch wählen, dass der Schlüssel überhaupt nicht abläuft.</p>
          <p>5. Klicke dann auf „Schlüssel erzeugen" und anschließend auf „Bestätigen".</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={openPGPSchluesselImg} alt="Thunderbird OpenPGP Schlüssel" size="large" />
            </div>
            <div className="text-center">
              <BlogImage src={bestaetigenImg} alt="Thunderbird Bestätigen" size="large" />
            </div>
          </div>
          
          <p>6. Nachdem du den Schlüssel erstellt hast, kannst du noch das Häkchen bei „Unverschlüsselte Nachrichten digital signieren" setzen, damit der Empfänger überprüfen kann, ob die E-Mail wirklich von dir stammt.</p>
          <p>7. In den erweiterten Einstellungen können alle Häkchen so belassen werden. Standardmäßig wird dein öffentlicher Schlüssel nun an jede E-Mail angehängt, so dass der Empfänger die nächste Nachricht sofort verschlüsselt an dich senden kann.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={digitalSignierenImg} alt="Thunderbird Digital signieren" size="large" />
            </div>
            <div className="text-center">
              <BlogImage src={erweiterteEinstellungenImg} alt="Thunderbird Erweiterte Einstellungen" size="large" />
            </div>
          </div>
          
          <BlogHeading>Zusammenfassung</BlogHeading>
          
          <p>In Thunderbird ist die Ende-zu-Ende-Verschlüsselung für deine E-Mails im Handumdrehen eingerichtet. Sobald die Möglichkeit zur Verschlüsselung besteht, weist dich Thunderbird darauf hin. Auch bei unverschlüsselten Nachrichten kann der Empfänger überprüfen, ob die Nachricht von dir stammt.</p>
          
          <p>Mit dieser einfachen Einrichtung erhöhst du die Sicherheit und das Vertrauen in deine E-Mails.</p>
          
          <div className="flex justify-center my-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" className="text-primary">
              <path d="M12 0c-2.995 2.995-7.486 4-11 4 0 8.582 5.068 16.097 11 20 5.932-3.903 11-11.418 11-20-3.514 0-8.005-1.005-11-4zm-8.912 5.894c2.455-.246 5.912-1.012 8.912-3.25v18.906c-4-3.063-8.254-8.604-8.912-15.656z"></path>
            </svg>
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Thunderbird – E-Mail verschlüsseln und signieren",
      formattedDate: "6. August 2024",
      excerpt: "Thunderbird – E-Mail verschlüsseln und signieren ist der beste Weg, um die Sicherheit und Vertraulichkeit deiner Nachrichten zu gewährleisten. Eine E-Mail ist standardmäßig nicht verschlüsselt und kann daher leicht mitgelesen werden. In diesem Artikel zeigen wir dir, wie du deine E-Mails in Thunderbird Ende-zu-Ende verschlüsseln kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;