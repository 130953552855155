// src/data/blogPosts/user-story-viktor-geht-auf-reisen.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/user-story-viktor-geht-auf-reisen-featured.png';
import userIcon from '../../assets/images/blog/2023/05/iconmonstr-user-20-240.png';
import travelIcon from '../../assets/images/blog/2021/11/travel.png';
import trackingIcon from '../../assets/images/blog/2022/02/tracking.png';

/**
 * Blog post about Viktor's travel user story
 */
const post = {
  id: '10366',
  slug: 'user-story-viktor-geht-auf-reisen',
  
  // Base data - same regardless of language
  date: "2023-01-17T06:18:35.000Z",
  readTime: 6,
  categories: ["User Story"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 26,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "User Story: Viktor – Geht auf Reisen",
      formattedDate: "17. Januar 2023",
      excerpt: "Viktor ist 26 Jahre alt und hat nach sieben Semestern endlichen seinen Bachelorabschluss in der Tasche. Er plant schon lange eine Reise nach Südamerika mit seiner Freundin.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">User Story: Viktor - Geht auf Reisen</h1>
          
          <p className="western">Im Format \"<strong>User Story</strong>\" stellt dir PathConnect <strong>beispielhaft</strong> vor, wie unterschiedliche Menschen Nextcloud in ihr Leben integrieren.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={userIcon} alt="Benutzer Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Person</BlogHeading>
          
          <p>Viktor ist 26 Jahre alt und hat nach sieben Semestern endlichen seinen <strong>Bachelorabschluss</strong> in der Tasche. Er plant schon lange eine <strong>Reise</strong> nach Südamerika mit seiner Freundin. In zwei Wochen geht es los und es stehen noch einige Punkte auf der <strong>To-do-Liste</strong>. Viktor liebt es <strong>Bilder und Videos</strong> zu machen und möchte gerne seine Verwandten und Freunde an der Reise teilhaben lassen. Es ist ihm auch sehr wichtig, dass keine Aufnahmen während der Reise verloren gehen.</p>
          
          <p>Im Studium hat er schon viel mit <strong>Nextcloud</strong> gearbeitet und möchte es nun auch für seine Reise nutzen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={travelIcon} alt="Reise Icon" size="small" />
          </div>
          
          <BlogHeading>Anwendungsfall</BlogHeading>
          
          <p>Damit er nicht alles allein von der To-do-Liste erledigen muss, hat er diese gleich mal mit seiner Freundin <strong>geteilt</strong>. Beide sehen, welche Punkte schon erledigt sind und können die Liste bearbeiten und erweitern. Für seine Verwandten und Freunde erstellt Viktor einen neuen Ordner mit dem Name „Südamerika Bilder" und generiert einen <strong>öffentlichen Link</strong> mit ausschließlich lesendem Zugriff. Seine Freundin bekommt schreibenden Zugriff, damit sie ebenfalls Bilder in den Ordner laden kann.<br />Diesen Link kann er dann direkt aus seiner Nextcloud per <strong>E-Mail</strong> verschicken. Mittlerweile ist Viktor mit seiner Freundin mitten in Südamerika und die beiden konnten schon viele tolle Aufnahmen machen und mit ihren Verwandten und Freunden teilen. Ganz nebenbei sind die Bilder jetzt auch <strong>gesichert,</strong> falls die Kamera oder das Handy kaputt oder verloren gehen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={trackingIcon} alt="Tracking Icon" size="small" />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "User Story: Viktor – Geht auf Reisen",
      formattedDate: "17. Januar 2023",
      excerpt: "Viktor ist 26 Jahre alt und hat nach sieben Semestern endlichen seinen Bachelorabschluss in der Tasche. Er plant schon lange eine Reise nach Südamerika mit seiner Freundin.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;