// src/data/blogPosts/nextcloud-benutzer-hinzufugen.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading 
} from '../../components/blog';

/**
 * Blog post about adding users to Nextcloud
 */
const post = {
  id: '21647',
  slug: 'nextcloud-benutzer-hinzufugen',
  
  // Base data - same regardless of language
  date: "2024-08-30T12:07:38.000Z",
  readTime: 12,
  categories: ["Anleitungen"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 20,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Benutzer hinzufügen",
      formattedDate: "30. August 2024",
      excerpt: "In Nextcloud kannst du ganz einfach weitere Benutzer hinzufügen, um die Zusammenarbeit zu erleichtern und den gemeinsamen Zugriff auf Dateien zu ermöglichen. In diesem Artikel stellen wir dir verschiedene Methoden vor, wie du neue Benutzer zu deiner Nextcloud einladen und hinzufügen kannst.",
      content: () => (
        <BlogContent>
          <p>In Nextcloud kannst du ganz einfach weitere Benutzer hinzufügen, um die Zusammenarbeit zu erleichtern und den gemeinsamen Zugriff auf Dateien zu ermöglichen. In diesem Artikel stellen wir dir verschiedene Methoden vor, wie du neue Benutzer zu deiner Nextcloud einladen und hinzufügen kannst.</p>
          
          <BlogHeading>Eine Cloud viele Benutzer</BlogHeading>
          
          <p>Egal ob Mitarbeiter, Teams, Familienmitglieder oder Vereinsmitglieder, mit Nextcloud kannst du alle möglichen Konstellationen verwalten und die Zusammenarbeit zwischen allen Beteiligten vereinfachen. Mit gemeinsamen Kalendern und Kontakten, geteilten Ordnern mit Dokumenten oder Bildern, einem Nextcloud Talk Room für Meetings, einer gemeinsamen Wissensdatenbank mit Collectives und vielem mehr macht die Zusammenarbeit im Team richtig Spaß!<br />Im Folgenden zeigen wir dir drei Möglichkeiten, wie du weitere Nutzer zu deiner Nextcloud hinzufügen kannst.</p>
          
          <BlogHeading>Nextcloud Registration App</BlogHeading>
          
          <p>Mit der App "Registration" gibst du anderen die Möglichkeit, sich einfach in deiner Nextcloud zu registrieren. Nach der Aktivierung erscheint auf der Login-Seite ein neuer Button „Registrieren".<br />Nach Eingabe einer E-Mail-Adresse erhält der Nutzer eine Registrierungs-E-Mail mit einem Registrierungscode. Nach erfolgreicher Eingabe kann der gewünschte Login-Name und ein Passwort vergeben werden.</p>
          
          <p>Unter Verwaltungseinstellungen – Verwaltung – Registrierung kannst du als Administrator noch einige Einstellungen für die Registrierung vornehmen.<br />Empfehlenswert ist hier, dass du als Administrator das neue Konto erst aktivieren musst, bevor der Benutzer es verwenden kann und welcher Gruppe das neue Konto zugeordnet werden soll.<br />Diese Methode hat den Vorteil, dass der neue Benutzer gleich seinen gewünschten Anmeldenamen eingeben kann.</p>
          
          <BlogHeading>Einladungs-E-Mail verschicken</BlogHeading>
          
          <p>Eine weitere Möglichkeit ist das direkte Hinzufügen eines neuen Benutzers. Klicke dazu auf das Icon in der rechten oberen Ecke – Benutzer und klicke dann auf den Button „+ Neues Konto".<br />Trage hier den Benutzernamen und die E-Mail-Adresse ein und klicke auf den Button „Neuen Benutzer hinzufügen". Optional kannst du noch die zugehörige Gruppe, den Anzeigenamen und das Speicherkontingent angeben.<br />Der neue Benutzer erhält daraufhin eine Einladungs-E-Mail mit der Möglichkeit, sein Passwort zu vergeben.</p>
          
          <BlogHeading>Nextcloud Guest App</BlogHeading>
          
          <p>Nach der Installation der App „Guest" gibt es im Teilen-Dialog die Option, externe Benutzer zu einer bestimmten Freigabe einzuladen.<br />Gib dazu die E-Mail-Adresse ein, die du zu dieser Freigabe einladen möchtest. Danach erscheint die Auswahl „Gast einladen".<br />Der Gast erhält dann eine E-Mail mit der Einladung zu dieser Freigabe.</p>
          
          <p>Diese Option stellt einen sehr eingeschränkten Zugriff dar und ist wirklich nur für Gäste und nicht aktive Nextcloud-Nutzer zu empfehlen.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Benutzer hinzufügen",
      formattedDate: "30. August 2024",
      excerpt: "In Nextcloud kannst du ganz einfach weitere Benutzer hinzufügen, um die Zusammenarbeit zu erleichtern und den gemeinsamen Zugriff auf Dateien zu ermöglichen. In diesem Artikel stellen wir dir verschiedene Methoden vor, wie du neue Benutzer zu deiner Nextcloud einladen und hinzufügen kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;