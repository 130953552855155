import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import Tooltip from './tooltip'
import Button from './button'

/**
 * Dropdown component that displays a list of options in a dropdown menu.
 *
 * @param {Object} props - The component props.
 * @param {string} props.prefix - The prefix for the option links.
 * @param {Array} props.options - The list of options to display in the dropdown.
 * @param {string} props.options[].name - The name of the option.
 * @param {string} props.options[].link - The link for the option.
 * @param {string} props.options[].tooltip - The tooltip text for the option.
 * @param {string} props.placeholder - The placeholder text for the dropdown button.
 * @param {Function} props.onOptionClick - The callback function to call when an option is clicked.
 * @param {string} props.className - Additional class names for the dropdown button.
 *
 * @returns {JSX.Element} The rendered Dropdown component.
 */
const Dropdown = ({
  prefix,
  options,
  placeholder,
  onOptionClick,
  className
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(!isOpen)
  }

  const closeDropdown = () => {
    setIsOpen(false)
  }

  const dropdownRef = useRef(null)

  useEffect(() => {
    /**
     * Handles the click event outside the dropdown component.
     * If the click is outside the dropdown, it triggers the closeDropdown function.
     *
     * @param {Event} event - The click event object.
     */
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        closeDropdown()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <Button
        type="button"
        className={`inline-flex flex-col items-center w-full ${className}
           ${
             window.location.pathname.includes(`${prefix}`)
               ? 'text-primary'
               : ''
           }
           `}
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true"
        onClick={toggleDropdown}
      >
        {placeholder}
        <svg
          className={`h-5 w-5 transform transition-transform text-black dark:text-white duration-300 ${
            isOpen ? 'rotate-180' : ''
          }`}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.23 7.21a.75.75 0 011.06.02L10 10.94l3.71-3.71a.75.75 0 111.06 1.06l-4 4a.75.75 0 01-1.06 0l-4-4a.75.75 0 01.02-1.06z"
            clipRule="evenodd"
          />
        </svg>
      </Button>

      {isOpen && (
        <ul
          className={`absolute z-10 w-full mt-2 bg-white dark:bg-gray-500
           border border-gray-200 rounded-md shadow-lg py-2 min-w-max
           ${
             options.length > 5 && window.innerWidth <= 768
               ? 'max-h-60 overflow-y-scroll overflow-x-hidden'
               : ''
           }`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          {options.map((option, index) => (
            <li key={index}>
              {option.link ? (
                option.tooltip ? (
                  <Tooltip text={option.tooltip}>
                    <Link
                      to={`${prefix}${option.link}`}
                      className="block w-full px-4 py-2 text-left hover:bg-primary"
                      role="menuitem"
                      onClick={() => {
                        onOptionClick && onOptionClick(option)
                        closeDropdown()
                      }}
                    >
                      {option.name}
                    </Link>
                  </Tooltip>
                ) : (
                  <Link
                    to={`${prefix}${option.link}`}
                    className="block w-full px-4 py-2 text-left hover:bg-primary"
                    role="menuitem"
                    onClick={() => {
                      onOptionClick && onOptionClick(option)
                      closeDropdown()
                    }}
                  >
                    {option.name}
                  </Link>
                )
              ) : (
                <div
                  className="block w-full px-4 py-2 text-left hover:bg-primary"
                  role="menuitem"
                  onClick={() => {
                    onOptionClick && onOptionClick(option)
                    closeDropdown()
                  }}
                >
                  {option.name}
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
Dropdown.propTypes = {
  prefix: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      link: PropTypes.string,
      tooltip: PropTypes.string
    })
  ).isRequired,
  placeholder: PropTypes.string.isRequired,
  onOptionClick: PropTypes.func,
  className: PropTypes.string
}

export default Dropdown
