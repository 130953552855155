import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Container from '../components/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faComments,
  faServer,
  faCode,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

/**
 * Enhanced ServicesSection component with compact design
 * 
 * @param {Object} props
 * @param {Function} props.openContactModal - Function to open the contact modal
 * @returns {JSX.Element}
 */
const ServicesSection = ({ openContactModal }) => {
  const { t } = useTranslation('home');
  
  // Icons for each service
  const serviceIcons = [
    faComments, // Professional consulting
    faServer,   // Hosting
    faCode      // Development
  ];
  
  // Action handlers for buttons
  const handleButtonClick = (template, link) => {
    switch (template) {
      case 'appointment':
        window.location.href = 'https://drive.pathconnect.de/apps/appointments/pub/eMgHf4y8FMdP5g%3D%3D/form';
        break;
      case 'request':
        openContactModal();
        break;
      default:
        return null;
    }
  };

  return (
    <section className="py-6 sm:py-8 md:py-10 relative">
      {/* Subtle background accent */}
      <div className="absolute top-1/3 left-1/4 w-32 h-32 bg-cyan-500 rounded-full opacity-3 blur-3xl"></div>
      
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5">
          {t('c2', { returnObjects: true }).map((service, index) => (
            <div 
              key={index} 
              className="group relative bg-gradient-to-br from-gray-800/80 to-gray-900 rounded-lg border border-gray-700 overflow-hidden transition-all duration-300 hover:-translate-y-1 hover:border-cyan-500/50 hover:shadow-md hover:shadow-cyan-500/5 h-[240px]"
            >
              {/* Top accent line */}
              <div className="absolute top-0 left-0 right-0 h-0.5 bg-gradient-to-r from-transparent via-cyan-500/40 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              
              <div className="p-4 pb-16 relative h-full">
                {/* Icon with enhanced styling - now on the left side */}
                <div className="flex items-start">
                  <div className="mr-4 flex-shrink-0">
                    <div className="w-12 h-12 rounded-full bg-cyan-900/20 border border-cyan-500/30 flex items-center justify-center group-hover:border-cyan-500/60 transition-all duration-300">
                      <FontAwesomeIcon 
                        icon={serviceIcons[index]} 
                        className="text-cyan-400 text-lg transition-all duration-300 group-hover:scale-110 group-hover:text-cyan-300"
                      />
                    </div>
                  </div>
                  
                  {/* Content area - with increased bottom padding */}
                  <div className="flex-grow">
                    <h3 className="text-lg font-bold text-cyan-400 mb-3 transition-colors duration-300 group-hover:text-cyan-300">
                      {service.title}
                    </h3>
                    
                    <p className="text-gray-300 text-sm leading-relaxed">
                      {service.description}
                    </p>
                  </div>
                </div>
                
                {/* Button - absolutely positioned at the bottom */}
                {/* Only show button for index 0 (Professional consulting) */}
                {index === 0 && (
                  <div className="absolute bottom-4 left-4 right-4">
                    {service.link ? (
                      <Link 
                        to={service.link}
                        className="group/btn inline-flex items-center py-2 px-3 bg-gray-800/50 border border-cyan-500/40 text-cyan-400 text-sm rounded transition-all duration-300 hover:bg-cyan-900/30 hover:border-cyan-400/60"
                      >
                        <span>{service.button}</span>
                        <FontAwesomeIcon 
                          icon={faArrowRight} 
                          className="ml-2 text-xs opacity-70 transform group-hover/btn:translate-x-0.5 transition-all duration-300"
                        />
                      </Link>
                    ) : (
                      <button
                        onClick={() => handleButtonClick(service.template)}
                        className="group/btn inline-flex items-center py-2 px-3 bg-gray-800/50 border border-cyan-500/40 text-cyan-400 text-sm rounded transition-all duration-300 hover:bg-cyan-900/30 hover:border-cyan-400/60"
                      >
                        <span>{service.button}</span>
                        <FontAwesomeIcon 
                          icon={faArrowRight} 
                          className="ml-2 text-xs opacity-70 transform group-hover/btn:translate-x-0.5 transition-all duration-300"
                        />
                      </button>
                    )}
                  </div>
                )}
              </div>
              
              {/* Side accent */}
              <div className="absolute top-0 bottom-0 right-0 w-0.5 bg-gradient-to-b from-transparent via-cyan-500/30 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
            </div>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default ServicesSection;