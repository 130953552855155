// src/data/blogPosts/privates-surfen-im-internet.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about private internet browsing
 */
const post = {
  id: '6442',
  slug: 'privates-surfen-im-internet',
  
  // Base data - same regardless of language
  date: "2022-12-09T09:53:10.000Z",
  readTime: 12,
  categories: ["Anleitungen"],
  thumbnail: "../images/blog/privates-surfen-im-internet-featured.png",
  thumbnailUrl: "../images/blog/privates-surfen-im-internet-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 37,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Privates Surfen im Internet",
      formattedDate: "9. Dezember 2022",
      excerpt: "PathConnect erklärt in diesem Blogpost wie privates Surfen im Internet möglich ist, dazu gibt es spannende Insider Tipps von einem Datenschutzbeauftragten. Damit du ab sofort immer sicher und privat unterwegs bist.",
      content: () => (
        <BlogContent>
          <p>PathConnect erklärt in diesem Blogpost wie privates Surfen im Internet möglich ist, dazu gibt es spannende <strong>Insider Tipps</strong> von einem Datenschutzbeauftragten. Damit du ab sofort immer sicher und privat unterwegs bist.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2021/11/connect.png" 
              alt="Connect Icon" 
              size="small" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Die Sache mit den Cookies</BlogHeading>
          
          <p>Wir alle haben schon einmal beim Surfen den Satz "<strong>Alle Cookies akzeptieren</strong>" gelesen.<br />
          Die meisten bestätigen dieses lästig auftauchende Popup, ohne sich im voraus durchgelesen zu haben, welche Daten wir damit freigeben.<br />
          Durch das Bestätigen der Cookies erteilen wir aber die Erlaubnis unsere <strong>Surfverhalten auszuwerten und uns zu tracken</strong>.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/cookie.png" 
              alt="Cookie Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Tracking</BlogHeading>
          
          <p>Laut einem <a href="https://www.startpage.com/nix-zu-verbergen/startpage-artikel/mehr-kontrolle-ueber-privatsphaere-cookies-und-tracker-neue-browser-erweiterung-von-startpage" target="_blank" rel="noopener">Blogpost</a> von Startpage.de, betreiben ca. <strong>87 % der populärsten Webseiten digitales Tracking</strong>.<br />
          Die Cookies die wir jeden Tag bei unseren Suchen im Netz akzeptieren, werden von großen Konzernen <strong>gesammelt und weiterverkauft</strong>. Völlig egal, was wir suchen, ob es harmlose Rezepte sind, Lebenratschläge in heiklen Situationen oder finanzielle Fragen. Alles wird gespeichert. Aus den vielen kleinen Informationen lässt sich ein <strong>sehr gutes Gesamtbild</strong> erstellen, wie das Experiment <strong><a href="https://www.madetomeasure.online/de/" target="_blank" rel="noopener">"Made to Measure"</a></strong> aufzeigt.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/tracking.png" 
              alt="Tracking Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Cookie-Monster</BlogHeading>
          
          <p>Dazu gehören nicht nur die herkömmlichen <strong>Suchmaschinen</strong> wie Bing oder Google, nein, sondern auch <strong>Social Media Plattformen</strong> wie Instagram, FaceBook, TikTok und Co. Der Handel mit digitalen Daten ist seit den letzten 14 Jahren rasant gestiegen und so wertvoll wie noch nie. Zum Glück gibt es einige Tools mit denen man sich davor schützen kann!</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/01/database.png" 
              alt="Database Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Die richtige Suchmaschine</BlogHeading>
          
          <p>PathConnect nutzt für Recherchen und sonstige Surchanfragen <strong><a href="https://www.startpage.com/de" target="_blank" rel="noopener">Startpage</a></strong>.<br />
          Die Suchmaschine bietet eine datenschutzfreundliche Alternative zu den bereits bekannten Diensten.</p>
          
          <ul className="list-disc pl-6 my-4 space-y-1">
            <li>Persönliche Daten und Suchverlauf werden nicht aufgezeichnet</li>
            <li>IP-Adresse wird vom Startpage Servern entfernt</li>
            <li>Blockiert Preis-Tracker</li>
            <li>Verhindert Retargeting-Anzeigen</li>
            <li>Völlig unpersonalisierte Suchergebnisse, die jedem Benutzer die gleichen Informationen liefern</li>
          </ul>
          
          <p>Startpage finanziert sich zwar auch durch Werbeeinnahmen, diese sind aber nicht personalisiert sondern werden einfach passend zu deiner aktuellen Suche angezeigt.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2021/11/iconmonstr-magnifier-11-240.png" 
              alt="Magnifier Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>DNS Server</BlogHeading>
          
          <p>Über den richtigen DNS Server können Werbeinhalte und Tracking Domains sehr effektiv blockiert werden, indem die Verbindung erst garnicht aufgebaut werden können.</p>
          
          <p>DNS Server wie von <strong>dnsforge</strong> haben eingebaute Filterlisten, die bestimmte Domains blockieren.</p>
          
          <p>Um den DNS Server in Firefox zu verwenden klickst du auf Einstellungen - Verbindungs-Einstellungen - DNS über HTTPS aktivieren - Benutzerdefiniert und trägst folgendes ein: <em>https://dnsforge.de/dns-query</em></p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/iconmonstr-server-7-240.png" 
              alt="Server Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Browser-Erweiterungen</BlogHeading>
          
          <p>Von Startpage gibt es die "<strong>Startpage Privatsphäre-Schutz</strong>" Erweiterung, mit der du eine genau Auflistung bekommst welche Cookies und Tracker von der besuchten Webseite eingesetzt und blockiert werden. Zusätzlich wird noch eine Datenschutzbewertung erstellt.</p>
          
          <p>Bei Browsererweiterung "<strong>uBlock Origin</strong>" handelt es sich um einen "Breitbandblocker", der Werbung, Tracker und Malware-Seiten blockiert.</p>
          
          <p><strong>Privacy Badger</strong> blockiert sichtbare oder unsichtbare Skripte und Bilder, die zur Verfolgung der Benutzer dienen.<br />
          Diese sogenannte "Tracking Pixel" stellen meist Verbindungen zu Social Media Plattformen wie Facebook und Instagram her.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/stop.png" 
              alt="Stop Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Die Wahl des Browsers</BlogHeading>
          
          <p>Welchen Browser wir nutzen, ist heutzutage eine Frage des Geschmacks und der Gewohnheit. Gängige Browser wie Chrome, Edge oder Firefox kennt jeder. Die Browser unterscheiden sich auf den ersten Blick durch das Aussehen.<br />
          Allerdings gibt es deutliche Unterschiede was die Privatspäre bei der Benutzung angehen.<br />
          Wir empfehlen den Firefox Browser, der sich mit nur wenigen Klicks sehr datenschutzfreundlich einstellen lässt.<br />
          Einen ausführlichen Bericht dazu gibt es von Mike Kuketz:<br />
          <a href="https://www.kuketz-blog.de/mozilla-firefox-datensendeverhalten-desktop-version-browser-check-teil20/" target="_blank" rel="noopener">https://www.kuketz-blog.de/mozilla-firefox-datensendeverhalten-desktop-version-browser-check-teil20/</a></p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/world.png" 
              alt="World Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Zusammenfassung</BlogHeading>
          
          <p>Mit den richtigen Tools und Einstellungen können wir unsere Privates Surfen im Internet möglich.</p>
          
          <p>Hier unsere Empfehlungen für privates Surfen im Internet auf einen Blick:</p>
          
          <ul className="list-disc pl-6 my-4 space-y-2">
            <li>
              <strong>Browser:</strong><br />
              <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener">Firefox</a>
            </li>
            <li>
              <strong>Suchmaschine:</strong><br />
              <a href="https://www.startpage.com" target="_blank" rel="noopener">Startpage</a>
            </li>
            <li>
              <strong>DNS-Server:</strong><br />
              <a href="https://dnsforge.de/" target="_blank" rel="noopener">dns-forge</a>
            </li>
            <li>
              <strong>Browser – Erweiterungen:</strong><br />
              <a href="http://add.startpage.com/de/protection" target="_blank" rel="noopener">Startpage Privatsphäre-Schutz</a><br />
              <a href="https://ublockorigin.com/de" target="_blank" rel="noopener">uBlock Origin</a><br />
              <a href="https://privacybadger.org/" target="_blank" rel="noopener">Privacy Badger</a>
            </li>
          </ul>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Privates Surfen im Internet",
      formattedDate: "9. Dezember 2022",
      excerpt: "PathConnect erklärt in diesem Blogpost wie privates Surfen im Internet möglich ist, dazu gibt es spannende Insider Tipps von einem Datenschutzbeauftragten. Damit du ab sofort immer sicher und privat unterwegs bist.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Private Browsing on the Internet",
      formattedDate: "December 9, 2022",
      excerpt: "PathConnect explains in this blog post how private browsing on the internet is possible, with exciting insider tips from a data protection officer. So you can always be safe and private online from now on.",
      content: () => (
        <BlogContent>
          <p>PathConnect explains in this blog post how private browsing on the internet is possible, with exciting <strong>insider tips</strong> from a data protection officer. So you can always be safe and private online from now on.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2021/11/connect.png" 
              alt="Connect Icon" 
              size="small" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>The Issue with Cookies</BlogHeading>
          
          <p>We've all seen the phrase "<strong>Accept All Cookies</strong>" while browsing.<br />
          Most people confirm this annoying popup without reading in advance what data they're releasing.<br />
          By accepting cookies, however, we give permission to <strong>analyze our browsing behavior and track us</strong>.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/cookie.png" 
              alt="Cookie Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Tracking</BlogHeading>
          
          <p>According to a <a href="https://www.startpage.com/nix-zu-verbergen/startpage-artikel/mehr-kontrolle-ueber-privatsphaere-cookies-und-tracker-neue-browser-erweiterung-von-startpage" target="_blank" rel="noopener">blog post</a> by Startpage.de, approximately <strong>87% of the most popular websites conduct digital tracking</strong>.<br />
          The cookies we accept every day during our internet searches are <strong>collected and resold</strong> by large corporations. No matter what we search for, whether it's harmless recipes, life advice in delicate situations, or financial questions. Everything is stored. From the many small bits of information, a <strong>very good overall picture</strong> can be created, as the experiment <strong><a href="https://www.madetomeasure.online/en/" target="_blank" rel="noopener">"Made to Measure"</a></strong> demonstrates.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/tracking.png" 
              alt="Tracking Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Cookie Monsters</BlogHeading>
          
          <p>This includes not just conventional <strong>search engines</strong> like Bing or Google, but also <strong>social media platforms</strong> like Instagram, Facebook, TikTok, and others. The trade in digital data has risen rapidly over the last 14 years and is more valuable than ever. Fortunately, there are several tools that can protect you from this!</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/01/database.png" 
              alt="Database Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>The Right Search Engine</BlogHeading>
          
          <p>PathConnect uses <strong><a href="https://www.startpage.com/en/" target="_blank" rel="noopener">Startpage</a></strong> for research and other search queries.<br />
          This search engine offers a privacy-friendly alternative to well-known services.</p>
          
          <ul className="list-disc pl-6 my-4 space-y-1">
            <li>Personal data and search history are not recorded</li>
            <li>IP address is removed from Startpage servers</li>
            <li>Blocks price trackers</li>
            <li>Prevents retargeting ads</li>
            <li>Completely unpersonalized search results that provide the same information to every user</li>
          </ul>
          
          <p>Startpage also finances itself through advertising revenue, but these ads are not personalized and are simply displayed to match your current search.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2021/11/iconmonstr-magnifier-11-240.png" 
              alt="Magnifier Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>DNS Server</BlogHeading>
          
          <p>Through the right DNS server, advertising content and tracking domains can be effectively blocked by preventing the connection from being established in the first place.</p>
          
          <p>DNS servers like those from <strong>dnsforge</strong> have built-in filter lists that block certain domains.</p>
          
          <p>To use the DNS server in Firefox, click on Settings - Connection Settings - Enable DNS over HTTPS - Custom and enter the following: <em>https://dnsforge.de/dns-query</em></p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/iconmonstr-server-7-240.png" 
              alt="Server Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Browser Extensions</BlogHeading>
          
          <p>Startpage offers the "<strong>Startpage Privacy Protection</strong>" extension, which gives you a detailed list of which cookies and trackers are used and blocked by the visited website. Additionally, a privacy assessment is created.</p>
          
          <p>The browser extension "<strong>uBlock Origin</strong>" is a "broadband blocker" that blocks ads, trackers, and malware sites.</p>
          
          <p><strong>Privacy Badger</strong> blocks visible or invisible scripts and images used to track users.<br />
          These so-called "tracking pixels" usually establish connections to social media platforms like Facebook and Instagram.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/stop.png" 
              alt="Stop Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>The Choice of Browser</BlogHeading>
          
          <p>Which browser we use today is a matter of taste and habit. Everyone knows common browsers like Chrome, Edge, or Firefox. At first glance, browsers differ in appearance.<br />
          However, there are significant differences when it comes to privacy during use.<br />
          We recommend the Firefox browser, which can be set up to be very privacy-friendly with just a few clicks.<br />
          A detailed report on this is available from Mike Kuketz:<br />
          <a href="https://www.kuketz-blog.de/mozilla-firefox-datensendeverhalten-desktop-version-browser-check-teil20/" target="_blank" rel="noopener">https://www.kuketz-blog.de/mozilla-firefox-datensendeverhalten-desktop-version-browser-check-teil20/</a></p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/02/world.png" 
              alt="World Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading level={3}>Summary</BlogHeading>
          
          <p>With the right tools and settings, we can make private browsing on the internet possible.</p>
          
          <p>Here are our recommendations for private browsing on the internet at a glance:</p>
          
          <ul className="list-disc pl-6 my-4 space-y-2">
            <li>
              <strong>Browser:</strong><br />
              <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener">Firefox</a>
            </li>
            <li>
              <strong>Search Engine:</strong><br />
              <a href="https://www.startpage.com" target="_blank" rel="noopener">Startpage</a>
            </li>
            <li>
              <strong>DNS Server:</strong><br />
              <a href="https://dnsforge.de/" target="_blank" rel="noopener">dns-forge</a>
            </li>
            <li>
              <strong>Browser Extensions:</strong><br />
              <a href="http://add.startpage.com/en/protection" target="_blank" rel="noopener">Startpage Privacy Protection</a><br />
              <a href="https://ublockorigin.com/en" target="_blank" rel="noopener">uBlock Origin</a><br />
              <a href="https://privacybadger.org/" target="_blank" rel="noopener">Privacy Badger</a>
            </li>
          </ul>
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offering includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-round service that includes installation, customization, and updates, allowing you to focus fully on your projects.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Private Browsing on the Internet",
      formattedDate: "9 December 2022",
      excerpt: "PathConnect explains in this blog post how private browsing on the internet is possible, with exciting insider tips from a data protection officer. So you can always be safe and private online from now on.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;