// src/data/blogPosts/nextcloud-e-mail.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-e-mail-featured.png';
import emailIcon from '../../assets/images/blog/2020/05/E-Mail.png';
import menuLeisteImage from '../../assets/images/blog/2023/02/obere-Menueleiste.png';
import accountEinrichtenImage from '../../assets/images/blog/2023/02/E-mail-Account-einrichten.png';
import posteingangImage from '../../assets/images/blog/2023/02/Posteingang.png';
import antwortenImage from '../../assets/images/blog/2023/02/Auswahlmoeglichkeiten-beim-Antworten.png';
import weitereOptionenImage from '../../assets/images/blog/2023/02/weitere-Optionen.png';
import neueNachrichtImage from '../../assets/images/blog/2023/02/Neue-Nachricht.png';
import nachrichtAnBerndImage from '../../assets/images/blog/2023/02/Nachricht-an-Bernd.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud E-Mail
 */
const post = {
  id: '10562',
  slug: 'nextcloud-e-mail',
  
  // Base data - same regardless of language
  date: "2023-02-03T10:02:36.000Z",
  readTime: 10,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 36,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud E-Mail",
      formattedDate: "3. Februar 2023",
      excerpt: "In diesem Blogartikel geht es um die E-Mail App von Nextcloud. Du erfährst, wie du deine E-Mail-Konten integrieren kannst, wie du die App verwendest und warum sie so praktisch ist.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud E-Mail</h1>
          
          <p>In diesem Blogartikel geht es um die <strong>E-Mail App</strong> von Nextcloud. Du erfährst, wie du deine E-Mail-Konten integrieren kannst, wie du die App verwendest und warum sie so praktisch ist.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={emailIcon} alt="E-Mail Icon" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <p>Nachdem du die App in deiner Nextcloud <strong>aktiviert</strong> hast, findest du das E-Mail-Symbol in der oberen Menüleiste.</p>
          
          <BlogImage src={menuLeisteImage} alt="Obere Menüleiste" size="large" className="my-6" />
          
          <p>Wenn du die App zum ersten Mal öffnest, erscheint ein Fenster, in dem du dein E-Mail-Konto einrichten kannst. Gib hier deinen Namen, deine <strong>E-Mail Adresse und dein Passwort</strong> ein. Bei den meisten E-Mail Anbietern werden die Einstellungen <strong>automatisch erkannt</strong>. Sollte dies nicht der Fall sein, kannst du auf den Reiter „Manuell" wechseln, um die Informationen zu Posteingangs- und Postausgangsserver manuell festzulegen. Natürlich kannst du auch weitere E-Mail-Konten hinzufügen. Falls du Hilfe bei der Einrichtung benötigst, <strong>schreibe uns</strong> einfach an <a href="mailto:info@pathconnect.de" target="_blank" rel="noopener">info@pathconnect.de</a>.</p>
          
          <BlogImage src={accountEinrichtenImage} alt="E-Mail Account einrichten" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Verwendung</BlogHeading>
          
          <p>Nach dem Einloggen erscheint der Posteingang. Hier bekommst du auf der linken Seite eine schöne Übersicht über alle eingegangenen <strong>E-Mails</strong> und auf der rechten Seite siehst du den <strong>Inhalt der Nachricht</strong>.</p>
          
          <BlogImage src={posteingangImage} alt="Posteingang" size="large" className="my-6" />
          
          <p>Du hast verschiedene Möglichkeiten auf die Nachricht zu reagieren. Neben der normalen Antwort kannst du die E-Mail auch <strong>favorisieren, löschen, weiterleiten, als Spam markieren</strong> usw.</p>
          
          <p>Wenn du auf „Weitere Aktionen" klickst, kannst du auch direkt <strong>einen Termin</strong> aus der Nachricht in deinen Nextcloud-Kalender eintragen.</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={antwortenImage} alt="Auswahlmöglichkeiten beim Antworten" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={weitereOptionenImage} alt="Weitere Optionen" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <p>Um eine neue E-Mail zu verfassen, klickst du oben links auf den Button „<strong>Neue Nachricht</strong>".</p>
          
          <BlogImage src={neueNachrichtImage} alt="Neue Nachricht" size="medium" className="mx-auto my-6" />
          
          <p>Danach kannst du entweder direkt einen Kontakt aus deiner <strong>Nextcloud-Kontaktliste</strong> auswählen oder eine neue E-Mail-Adresse eingeben.</p>
          
          <p>Wenn du auf die drei Punkte neben dem „Senden"-Button klickst, hast du die Möglichkeit, eine oder mehrere <strong>Dateien anzuhängen</strong>, indem du sie entweder hochlädst oder direkt aus deiner Nextcloud hinzufügst. Du kannst auch einen Freigabelink hinzufügen und die Nachricht zu einem <strong>späteren Zeitpunkt</strong> zum Versenden freizugeben.</p>
          
          <BlogImage src={nachrichtAnBerndImage} alt="Nachricht an Bernd" size="large" className="my-6" />
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p>Mit der E-Mail-Anwendung von Nextcloud musst du die <strong>Weboberfläche</strong> nicht mehr verlassen, um auf deine E-Mails zuzugreifen. Bei mehreren E-Mail Konten, sind diese alle <strong>an einem Ort</strong> vereint. Durch die Integration in Nextcloud kannst du direkt deine <strong>Kontakte auswählen und Kalendereinträge</strong> erstellen. Falls du mal an einem anderen Arbeitsplatz bist, kannst du dich einfach in deine Nextcloud einloggen, um deine E-Mails zu bearbeiten.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud E-Mail",
      formattedDate: "3. Februar 2023",
      excerpt: "In diesem Blogartikel geht es um die E-Mail App von Nextcloud. Du erfährst, wie du deine E-Mail-Konten integrieren kannst, wie du die App verwendest und warum sie so praktisch ist.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Email",
      formattedDate: "February 3, 2023",
      excerpt: "In this blog post, we'll discuss the Nextcloud Email app. You'll learn how to integrate your email accounts, how to use the app, and why it's so practical.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Email</h1>
          
          <p>In this blog post, we'll discuss the <strong>Email app</strong> of Nextcloud. You'll learn how to integrate your email accounts, how to use the app, and why it's so practical.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Email",
      formattedDate: "3 February 2023",
      excerpt: "In this blog post, we'll discuss the Nextcloud Email app. You'll learn how to integrate your email accounts, how to use the app, and why it's so practical.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;