// src/services/blogService.js with flexible language handling and enhanced search capabilities
import allPosts from '../data/blogPosts';
import i18n from '../i18n';

/**
 * Helper function to get current language code
 * Supports both full locale (de_DE) and simple language codes (de)
 */
const getCurrentLanguage = () => {
  // Get the full locale from i18n
  const fullLang = i18n.language || localStorage.getItem('i18nextLng') || 'de_DE';
  
  // For backward compatibility with existing posts that use simple codes
  const simpleLang = fullLang.split('_')[0];
  
  return { fullLang, simpleLang };
};

/**
 * BlogService provides methods to fetch and filter blog posts with i18n support
 * @namespace BlogService
 */
const BlogService = {
  /**
   * Cache for storing filtered results to improve performance
   * @private
   */
  _cache: {
    categories: null,
    categoryResults: {},
    searchResults: {}
  },

  /**
   * Get all blog posts
   * @returns {Promise<Array>} - Array of blog posts sorted by date (newest first)
   */
  getAllPosts: async () => {
    return Promise.resolve(allPosts);
  },

  /**
   * Get a blog post by slug
   * @param {string} slug - The slug of the post to fetch
   * @returns {Promise<Object>} - Blog post object
   * @throws {Error} - If post not found
   */
  getPostBySlug: async (slug) => {
    const post = allPosts.find(post => post.slug === slug);
    if (!post) {
      return Promise.reject(new Error(`Post with slug "${slug}" not found`));
    }
    return Promise.resolve(post);
  },

  /**
   * Get all available categories from blog posts
   * @returns {Promise<Array>} - Array of unique categories
   */
  getAllCategories: async () => {
    // Use cached categories if available
    if (BlogService._cache.categories) {
      return Promise.resolve(BlogService._cache.categories);
    }
    
    // Extract unique categories
    const allCategories = allPosts.flatMap(post => post.categories);
    const uniqueCategories = [...new Set(allCategories)].sort();
    
    // Cache for future use
    BlogService._cache.categories = uniqueCategories;
    
    return Promise.resolve(uniqueCategories);
  },

  /**
   * Get posts by category
   * @param {string} category - Category to filter by
   * @returns {Promise<Array>} - Array of filtered blog posts
   */
  getPostsByCategory: async (category) => {
    // Return all posts if category is 'all'
    if (category === 'all') {
      return Promise.resolve(allPosts);
    }
    
    // Use cached results if available
    if (BlogService._cache.categoryResults[category]) {
      return Promise.resolve(BlogService._cache.categoryResults[category]);
    }
    
    // Filter posts by category
    const filtered = allPosts.filter(post =>
      post.categories.includes(category)
    );
    
    // Cache for future use
    BlogService._cache.categoryResults[category] = filtered;
    
    return Promise.resolve(filtered);
  },

  /**
   * Search for blog posts in the current language
   * @param {string} query - Search query
   * @returns {Promise<Array>} - Array of matching blog posts
   */
  searchPosts: async (query) => {
    if (!query || query.trim() === '') {
      return Promise.resolve(allPosts);
    }
    
    const searchTerm = query.toLowerCase().trim();
    const { fullLang, simpleLang } = getCurrentLanguage();
    const cacheKey = `${fullLang}:${searchTerm}`;
    
    // Use cached results if available
    if (BlogService._cache.searchResults[cacheKey]) {
      return Promise.resolve(BlogService._cache.searchResults[cacheKey]);
    }
    
    // Search in title, excerpt, and content for the current language
    const results = allPosts.filter(post => {
      // Try to get content in the current language (try both formats)
      const translations = post.translations || {};
      let title, excerpt, searchableContent;
      
      // Try full locale first, then simple language code, then fall back to default
      if (translations[fullLang]) {
        title = translations[fullLang].title || '';
        excerpt = translations[fullLang].excerpt || '';
        
        // Use searchableContent property if available (for new format)
        searchableContent = translations[fullLang].searchableContent || '';
        
        // If searchableContent is not available, but content is a string, use that
        if (!searchableContent && typeof translations[fullLang].content === 'string') {
          searchableContent = translations[fullLang].content;
        }
      } else if (translations[simpleLang]) {
        title = translations[simpleLang].title || '';
        excerpt = translations[simpleLang].excerpt || '';
        
        // Use searchableContent property if available (for new format)
        searchableContent = translations[simpleLang].searchableContent || '';
        
        // If searchableContent is not available, but content is a string, use that
        if (!searchableContent && typeof translations[simpleLang].content === 'string') {
          searchableContent = translations[simpleLang].content;
        }
      } else {
        title = post.title || '';
        excerpt = post.excerpt || '';
        
        // Use searchableContent property if available (for new format)
        searchableContent = post.searchableContent || '';
        
        // If searchableContent is not available, but content is a string, use that
        if (!searchableContent && typeof post.content === 'string') {
          searchableContent = post.content;
        }
      }
      
      return title.toLowerCase().includes(searchTerm) ||
             (excerpt && excerpt.toLowerCase().includes(searchTerm)) ||
             (searchableContent && searchableContent.toLowerCase().includes(searchTerm));
    });
    
    // Cache for future use (limit cache size)
    if (Object.keys(BlogService._cache.searchResults).length > 20) {
      // Remove oldest entry if cache gets too large
      const oldestKey = Object.keys(BlogService._cache.searchResults)[0];
      delete BlogService._cache.searchResults[oldestKey];
    }
    BlogService._cache.searchResults[cacheKey] = results;
    
    return Promise.resolve(results);
  },

  /**
   * Get related posts based on categories and excluding current post
   * Results will be in the current language where available
   * @param {Object} currentPost - Current post to find related content for
   * @param {number} limit - Maximum number of related posts to return
   * @returns {Promise<Array>} - Array of related blog posts
   */
  getRelatedPosts: async (currentPost, limit = 3) => {
    if (!currentPost || !currentPost.categories || currentPost.categories.length === 0) {
      return Promise.resolve([]);
    }
    
    // Get posts that share categories with the current post
    const relatedPosts = allPosts.filter(post => 
      post.slug !== currentPost.slug && // Exclude current post
      post.categories.some(category => currentPost.categories.includes(category))
    );
    
    // Sort by the number of matching categories (most relevant first)
    relatedPosts.sort((a, b) => {
      const matchingCategoriesA = a.categories.filter(category => 
        currentPost.categories.includes(category)
      ).length;
      
      const matchingCategoriesB = b.categories.filter(category => 
        currentPost.categories.includes(category)
      ).length;
      
      return matchingCategoriesB - matchingCategoriesA;
    });
    
    // Return the specified number of related posts
    return Promise.resolve(relatedPosts.slice(0, limit));
  },

  /**
   * Get translated content for a post based on current language
   * @param {Object} post - The post to get translated content for
   * @returns {Object} - Translated content (title, excerpt, content, searchableContent)
   */
  getTranslatedContent: (post) => {
    if (!post) return null;
    
    const { fullLang, simpleLang } = getCurrentLanguage();
    
    // Try full locale format first (e.g., de_DE)
    if (post.translations && post.translations[fullLang]) {
      return {
        title: post.translations[fullLang].title,
        formattedDate: post.translations[fullLang].formattedDate,
        excerpt: post.translations[fullLang].excerpt,
        content: post.translations[fullLang].content,
        // Add searchableContent for new format posts
        searchableContent: post.translations[fullLang].searchableContent || 
                        (typeof post.translations[fullLang].content === 'string' 
                          ? post.translations[fullLang].content 
                          : '')
      };
    }
    
    // Try simple language code (e.g., de)
    if (post.translations && post.translations[simpleLang]) {
      return {
        title: post.translations[simpleLang].title,
        formattedDate: post.translations[simpleLang].formattedDate,
        excerpt: post.translations[simpleLang].excerpt,
        content: post.translations[simpleLang].content,
        // Add searchableContent for new format posts
        searchableContent: post.translations[simpleLang].searchableContent || 
                        (typeof post.translations[simpleLang].content === 'string' 
                          ? post.translations[simpleLang].content 
                          : '')
      };
    }
    
    // Fallback to default properties if translations not available
    return {
      title: post.title,
      formattedDate: post.formattedDate,
      excerpt: post.excerpt,
      content: post.content,
      // Add searchableContent for new format posts
      searchableContent: post.searchableContent || 
                        (typeof post.content === 'string' ? post.content : '')
    };
  },

  /**
   * Extract searchable text content from JSX/React components
   * This is a utility function to help create searchableContent for JSX content
   * 
   * @param {Function} contentFn - A function that returns React JSX content
   * @returns {string} - Plain text extracted from the JSX for searching
   */
  extractSearchableContent: (contentFn) => {
    // If content is not a function, return as is (for backward compatibility)
    if (typeof contentFn !== 'function') {
      return contentFn || '';
    }
    
    try {
      // For React component content, we need a way to extract text
      // This is a simple approach that stores the searchable text in a data attribute
      
      // Option 1: Add a searchableText property to your blog post objects
      // This would be a plain text version of the content that can be searched
      
      // Option 2: Generate searchable text from the components (more complex)
      // You would need a recursive function that extracts text from React elements
      
      // For now, we'll return an empty string as a placeholder
      // In a real implementation, you should implement one of the options above
      return '';
    } catch (error) {
      console.error('Error extracting searchable content:', error);
      return '';
    }
  },

  /**
   * Calculate estimated reading time for content
   * @param {string|function} content - Content to calculate reading time for
   * @returns {number} - Estimated reading time in minutes
   */
  calculateReadingTime: (content) => {
    // If content is a function (JSX), return a default value
    if (typeof content === 'function') {
      return 5; // Default for JSX content - can be refined
    }
    
    // For string content
    if (typeof content === 'string') {
      // Clean HTML tags to get just text
      const text = content.replace(/<[^>]*>/g, '');
      // Average reading speed: 225 words per minute
      const wordCount = text.split(/\s+/).length;
      return Math.ceil(wordCount / 225);
    }
    
    return 3; // Default fallback
  },

  /**
   * Clear the service cache
   * Useful when blog posts data changes
   */
  clearCache: () => {
    BlogService._cache = {
      categories: null,
      categoryResults: {},
      searchResults: {}
    };
  }
};

export default BlogService;