// src/data/blogPosts/nextcloud-account-absichern.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading 
} from '../../components/blog';

/**
 * Blog post about securing Nextcloud accounts
 */
const post = {
  id: '13159',
  slug: 'nextcloud-account-absichern',
  
  // Base data - same regardless of language
  date: "2023-10-12T16:33:35.000Z",
  readTime: 10,
  categories: ["Anleitungen"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 14,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Account absichern",
      formattedDate: "12. Oktober 2023",
      excerpt: "Neben den von uns bereitgestellten Sicherheitsvorkehrungen wie z.B. Firewall, Backup, getrennte Netze, verschlüsselte Verbindung, Updates etc. kannst du deinen Nextcloud Accout zusätzlich absichern. Welche Möglichkeiten es gibt und wie du diese einrichten kannst, erfährst du in diesem Artikel.",
      content: () => (
        <BlogContent>
          <p>Neben den von uns bereitgestellten Sicherheitsvorkehrungen wie z.B. Firewall, Backup, getrennte Netze, verschlüsselte Verbindung, Updates etc. kannst du deinen Nextcloud Accout zusätzlich absichern. Welche Möglichkeiten es gibt und wie du diese einrichten kannst, erfährst du in diesem Artikel.</p>
          
          <BlogHeading>Wieso zusätzlich Absichern?</BlogHeading>
          
          <p>Auch wenn diese Frage den meisten überflüssig erscheinen mag, ist es wichtig, sie zu erwähnen. Viele machen sich wenig Gedanken über die Sicherheit ihrer Daten. Mit einer eigenen Nextcloud ist schon ein sehr großer Schritt getan. In einem Satz: Zusätzliche Absicherung deines Accounts, erhöht die Sicherheit deiner Daten.</p>
          
          <BlogHeading>Möglichkeiten:</BlogHeading>
          
          <p>Mit folgenden Möglichkeiten kannst du die Sicherheit deiner Nextcloud zuästzlich erhöhen. Wir gehen auf jede Option im Detail ein.</p>
          
          <ul>
            <li>Starkes Passwort erzwingen</li>
            <li>Zwei-Faktor-Authentifizierung</li>
            <li>Brute-Force Protection</li>
            <li>Suspicious Login</li>
            <li>Ende-zu-Ende Verschlüsselung</li>
          </ul>
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <p><u>Starkes Passwort erzwingen</u><br />Ein starkes Passwort ist der erste Schritt, um die Sicherheit deines Kontos zu erhöhen.<br />Unter Einstellungen – Verwaltung – Sicherheit kannst du bestimmte Passwortrichtlinien für die Nutzer deiner Nextcloud festlegen. Standardmäßig ist die minimale Passwortlänge auf 10 Zeichen gesetzt, häufig verwendete Passwörter sind verboten und Passwörter werden gegen eine Liste von haveibeenpwned.com gehasht.<br />Wir empfehlen zusätzlich die Optionen „Groß- und Kleinschreibung erzwingen", „Zahlen erzwingen" und „Sonderzeichen erzwingen" zu setzen</p>
          
          <p><u>Zwei-Faktor-Authentifizierung</u><br />Nach dem Festlegen eines starken Passworts folgt der zweite Schritt. Die Zwei-Faktor-Authentifizierung. Hier wird nach der Eingabe des Passwortes ein zusätzlicher Code abgefragt.<br />Unter Einstellungen – Verwaltung – Sicherheit – „Zwei-Faktor-Authentifizierung." kannst du dies für alle Benutzer aktivieren.</p>
          
          <p><u>Brute-Force Protection</u><br />Die Anwendung „Brute-Force-settings" erkennt häufige Anmeldeversuche und sperrt daraufhin die IP-Adresse.<br />Nach der Aktivierung findest du wieder unter Einstellungen – Verwaltung – Sicherheit den Punkt „Brute-Force IP Whitelist". Hier hast du die Möglichkeit bestimmte IP-Adressen vom Brute-Force-Schutz auszuschließen. Wir empfehlen dir hier keine IP-Adressen einzutragen.</p>
          
          <p><u>Suspicious Login</u><br />Diese App zeichnet alle Anmeldungen in deiner Nextcloud auf und merkt sich die IP-Adresse und den Benutzer. Nach 60 Tagen kann damit ein maschinelles Lernmodell trainiert werden, um verdächtige Anmeldungen zu erkennen.<br />Es ist keine Einrichtung notwendig, du kannst die App in deiner Nextcloud herunterladen und aktivieren. Bei einer Anmeldung z.B. an einem neuen Standort wirst du direkt per Mail benachrichtigt.</p>
          
          <p><u>Ende-zu-Ende Verschlüsselung (E2EE)</u><br />Die Königsklasse unter den Sicherheitsmaßnahmen ist die Ende-zu-Ende-Verschlüsselung. Dabei werden die Daten sowohl auf dem Endgerät als auch auf dem Server verschlüsselt. Das hat den Vorteil, dass niemand ohne das Passwort die Daten lesen kann. Es hat aber auch den Nachteil, dass man die Daten nicht im Webinterface von Nextcloud einsehen kann. Für sensible Daten, mit denen nicht online gearbeitet werden soll, ist dies jedoch eine sehr gute Option.<br />Neben der in Nextcloud integrierten Ende-zu-Ende-Verschlüsselung kannst du deine Daten auch mit dem Tool „Cryptomator" verschlüsseln. Wir empfehlen die Verwendung von Cryptomator anstelle von Nextcloud E2EE.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Account absichern",
      formattedDate: "12. Oktober 2023",
      excerpt: "Neben den von uns bereitgestellten Sicherheitsvorkehrungen wie z.B. Firewall, Backup, getrennte Netze, verschlüsselte Verbindung, Updates etc. kannst du deinen Nextcloud Accout zusätzlich absichern. Welche Möglichkeiten es gibt und wie du diese einrichten kannst, erfährst du in diesem Artikel.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;