// src/data/blogPosts/stirling-pdf.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/stirling-pdf-featured.png';
import stirlingLogo from '../../assets/images/blog/2023/12/stirling.png';
import adobePdfLogo from '../../assets/images/blog/2023/12/sxpra.png';
import loginLight from '../../assets/images/blog/2023/12/login-light.png';
import stirlingHome from '../../assets/images/blog/2023/12/stirling-home.png';
import sponsorScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-15-11-44-30.png';

/**
 * Blog post about Stirling PDF
 */
const post = {
  id: '14297',
  slug: 'stirling-pdf',
  
  // Base data - same regardless of language
  date: "2023-12-14T16:30:01.000Z",
  readTime: 8,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 25,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Stirling PDF",
      formattedDate: "14. Dezember 2023",
      excerpt: "Stirling PDF bietet zahlreiche Funktionen zur Bearbeitung von PDF-Dateien. Wir stellen dieses Tool allen kostenlos zur Verfügung und zeigen in diesem Artikel, was damit alles möglich ist.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Stirling PDF</h1>
          
          <p>Stirling PDF bietet zahlreiche Funktionen zur Bearbeitung von PDF-Dateien. Wir stellen dieses Tool allen kostenlos zur Verfügung und zeigen in diesem Artikel, was damit alles möglich ist.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={stirlingLogo} alt="Stirling Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>PDF</BlogHeading>
          
          <p>Eine PDF-Datei (Portable Document Format) dient in erster Linie dem Austausch von Dokumenten zwischen Personen. Es ist ein plattformunabhängiges Dateiformat, das 1992 von Adobe entwickelt wurde. Es bietet die Möglichkeit, Dokumente aus verschiedenen Programmen in ein einheitliches Format umzuwandeln. Die PDF-Datei kann auf verschiedenen Plattformen und PDF-Readern angezeigt werden. Mittlerweile bietet jeder Browser die Möglichkeit, ein PDF-Dokument zu öffnen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={adobePdfLogo} alt="Adobe PDF Logo" size="small" />
          </div>
          
          <BlogHeading>Einer für alles</BlogHeading>
          
          <p>Mit Stirling-PDF kann man wirklich alles machen, was mit PDF zu tun hat. Der Funktionsumfang lässt sich in mehrere Kategorien einteilen. Für den privaten Gebrauch sind vor allem die Seitenbearbeitungsfunktionen von Vorteil, im geschäftlichen Umfeld das Signieren von PDF-Dokumenten.</p>
          
          <p><u>Sicherheit</u></p>
          <ul className="list-disc pl-6 mb-4">
            <li>PDF Dateien durch ein Passwort schützen</li>
            <li>Passwortschutz einer PDF Datei entfernen</li>
            <li>Berechtigungen für die Bearbeitung und Ansicht</li>
            <li>Wassermarke hinzufügen</li>
            <li>Digital mit einem Zertifikat signieren</li>
            <li>Entfernen von Javascript, Link und Metadaten</li>
            <li>Angabe von Text der aus der PDF Datei entfernt werden soll</li>
          </ul>
          
          <p><u>Seiten Operationen</u></p>
          <ul className="list-disc pl-6 mb-4">
            <li>Mehrere PDF Dokumenten zusammenführen</li>
            <li>Eine PDF Datei in mehrere Dateien aufteilen</li>
            <li>Einzelne Seiten löschen, hinzufügen und drehen</li>
            <li>Erstellung von Layouts</li>
            <li>Seiten skalieren und Zuschneiden</li>
            <li>Mehrteilige PDF zu einer langen PDF Datei umwandeln</li>
          </ul>
          
          <p><u>Zusätzliche Tools</u></p>
          <ul className="list-disc pl-6 mb-4">
            <li>Zeichenerkennung (OCR)</li>
            <li>Bilder hinzufügen</li>
            <li>Komprimierung</li>
            <li>Gescannte und eingebettete Bilder aus der PDF extrahieren</li>
            <li>Metadaten ändern</li>
            <li>PDF Dateien Unterschreiben</li>
            <li>Automatische Reparatur von kaputten PDF Dateien</li>
            <li>Seitenzahl hinzufügen</li>
          </ul>
          
          <p><u>Datei-Konvertierungen</u></p>
          <ul className="list-disc pl-6 mb-6">
            <li>Bild, Text, Tabelle, HTML zu PDF umwandeln</li>
            <li>PDF zu Bild, Text, Präsentation, HTML, XML umwandeln</li>
          </ul>
          
          <div className="flex flex-col gap-4 my-6">
            <div className="w-full text-center">
              <BlogImage src={loginLight} alt="Stirling PDF Login" size="large" />
            </div>
            <div className="w-full text-center">
              <BlogImage src={stirlingHome} alt="Stirling PDF Home" size="large" />
            </div>
          </div>
          
          <BlogHeading>Spenden</BlogHeading>
          
          <p>Das Open Source Tool ist frei verfügbar, aber es steckt eine Menge Arbeit dahinter und Unterstützung ist notwendig. Wenn euch Stirling PDF gefällt, spendet bitte an den Entwickler:<br />
          <a href="https://github.com/sponsors/Frooodle" target="_blank" rel="noopener">https://github.com/sponsors/Frooodle</a></p>
          
          <p>Natürlich haben wir auch eine Spende hinterlassen!</p>
          
          <div className="flex justify-center my-6">
            <BlogImage src={sponsorScreenshot} alt="Stirling PDF Sponsor" size="large" />
          </div>
          
          <BlogHeading>Bei PathConnect nutzen</BlogHeading>
          
          <p>Stirling PDF kann bei uns kostenlos genutzt werden. Das Tool ist unter <a href="https://pdf.pathconnect.de" target="_blank" rel="noopener">pdf.pathconnect.de</a> erreichbar und bietet alle oben beschriebenen Funktionen.</p>
          
          <p>Wenn du Stirling PDF nur für dich oder deine Firma auf einer eigenen Domain/Subdomain nutzen möchtest, bieten wir auch eine Managed Hosting Lösung an.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Stirling PDF",
      formattedDate: "14. Dezember 2023",
      excerpt: "Stirling PDF bietet zahlreiche Funktionen zur Bearbeitung von PDF-Dateien. Wir stellen dieses Tool allen kostenlos zur Verfügung und zeigen in diesem Artikel, was damit alles möglich ist.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;