// src/data/blogPosts/mediadc-duplikate-von-bildern-und-videos-finden.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading 
} from '../../components/blog';

/**
 * Blog post about MediaDC for finding duplicate images and videos
 */
const post = {
  id: '20457',
  slug: 'mediadc-duplikate-von-bildern-und-videos-finden',
  
  // Base data - same regardless of language
  date: "2024-06-11T12:09:04.000Z",
  readTime: 7,
  categories: ["Anleitungen", "Empfehlungen"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 35,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "MediaDC – Duplikate von Bildern und Videos finden",
      formattedDate: "11. Juni 2024",
      excerpt: "Die Nextcloud App MediaDC hilft dir, Speicherplatz zu schaffen und Ordnung in deine Bilder und Videos zu bringen. In diesem Artikel stellen wir dir die App vor und zeigen dir, wie du sie in deiner Nextcloud einrichten kannst.",
      content: () => (
        <BlogContent>
          <p>Die Nextcloud App MediaDC hilft dir, Speicherplatz zu schaffen und Ordnung in deine Bilder und Videos zu bringen. In diesem Artikel stellen wir dir die App vor und zeigen dir, wie du sie in deiner Nextcloud einrichten kannst.</p>
          
          <BlogHeading>MediaDC Installation</BlogHeading>
          
          <p>Vor der eigentlichen Installation der MediaDC App muss die App „Cloud Python API" installiert werden. <br />Um die App in deiner Nextcloud zu installieren, logge dich als Administrator ein, klicke auf das Icon in der rechten oberen Ecke und wähle „Apps".<br />In der Suche kannst du nun „Cloud Python API" eingeben und die App wird dir direkt angezeigt. Klicke dann auf „Herunterladen & Aktivieren". <br />Danach kann die MediaDC App auf die gleiche Weise aktiviert werden.</p>
          
          <BlogHeading>MediaDC Verwendung</BlogHeading>
          
          <p>Nach der Installation findest du ein neues Symbol in der oberen Menüleiste. Klicke auf das Symbol, um die Anwendung zu starten.</p>
          
          <p>Für die Suche nach Duplikaten in Bildern und Videos wird eine neue Aufgabe erstellt.</p>
          
          <ul>
            <li>
              <p><u>Zielverzeichnisse</u><br />Hier kann der Ordner ausgewählt werden, in dem nach Duplikaten gesucht werden soll.</p>
            </li>
            <li>
              <p><u>Verzeichnisse ausschließen</u><br />Wenn nicht alle Verzeichnisse des ausgewählten Ordners durchsucht werden sollen, können diese hier ausgeschlossen werden.</p>
            </li>
            <li>
              <p><u>Benutzerdefinierte Ausschlussmaske</u><br />Weitere Ausschlusskriterien können hier definiert werden</p>
            </li>
            <li>
              <p><u>Ziel-MIME-Typ</u><br />Wähle hier aus, ob du nur Fotos, nur Videos oder beides auf Duplikate prüfen möchtest.</p>
            </li>
            <li>
              <p><u>Ähnlichkeitsschwelle</u><br />Dieser Wert gibt an, ab wann zwei oder mehr Videos/Bilder als Duplikate erkannt werden sollen.</p>
            </li>
          </ul>
          
          <p>Starte die Suche, indem du auf „Neue Aufgabe ausführen" klickst. Auf der rechten Seite erscheint die Aufgabe mit dem Status „Pending". Sobald die Aufgabe erledigt ist, ändert sich der Status in „Erledigt".</p>
          
          <p>Klicke auf die Aufgabe, um zur Übersicht der Duplikate zu gelangen. Hier werden alle Duplikate in Gruppen angezeigt. Jede Gruppe enthält alle Duplikate eines Bildes. Du hast die Möglichkeit das Duplikat zu ignorieren, zu löschen oder zu behalten.</p>
          
          <p>Um den Ordner „Bilder" nach Duplikaten zu durchsuchen, könnte eine neue Aufgabe wie folgt aussehen:</p>
          
          <p>Theoretisch kannst du alle Gruppen markieren und dann auf „Datei löschen" klicken, um alle Duplikate mit einem Klick zu entfernen. Wir empfehlen dir jedoch, die Bilder vorher mindestens einmal zu überprüfen, um sicherzustellen, dass du nicht versehentlich Bilder löschst.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "MediaDC – Duplikate von Bildern und Videos finden",
      formattedDate: "11. Juni 2024",
      excerpt: "Die Nextcloud App MediaDC hilft dir, Speicherplatz zu schaffen und Ordnung in deine Bilder und Videos zu bringen. In diesem Artikel stellen wir dir die App vor und zeigen dir, wie du sie in deiner Nextcloud einrichten kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;