// src/data/blogPosts/paperless-ngx-oauth2-fur-microsoft365-einrichten.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about setting up OAuth2 for Microsoft365 in Paperless-ngx
 */
const post = {
  id: '22201',
  slug: 'paperless-ngx-oauth2-fur-microsoft365-einrichten',
  
  // Base data - same regardless of language
  date: "2024-11-12T14:58:57.000Z",
  readTime: 10,
  categories: ["Anleitungen"],
  thumbnail: "../images/blog/paperless-ngx-oauth2-fur-microsoft365-einrichten-featured.png",
  thumbnailUrl: "../images/blog/paperless-ngx-oauth2-fur-microsoft365-einrichten-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 6,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Paperless-ngx: oAuth2 für Microsoft365 einrichten",
      formattedDate: "12. November 2024",
      excerpt: "Paperless-ngx kann Anhänge aus E-Mail-Postfächern automatisch verarbeiten. Damit dies auch mit einem Microsoft365 Konto funktioniert, müssen einige Einstellungen vorgenommen werden. In diesem Artikel zeigen wir, was zu tun ist, damit die Anbindung funktioniert.",
      content: () => (
        <BlogContent>
          <p>Paperless-ngx kann Anhänge aus E-Mail-Postfächern automatisch verarbeiten. Damit dies auch mit einem Microsoft365 Konto funktioniert, müssen einige Einstellungen vorgenommen werden. In diesem Artikel zeigen wir, was zu tun ist, damit die Anbindung funktioniert.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/11/Paperless-ngx.png" 
              alt="Paperless-ngx Logo" 
              size="large" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>OAuth2</BlogHeading>
          
          <p>OAuth2 ist ein Autorisierungsprotokoll, das es einer Anwendung ermöglicht, im Namen eines Nutzers auf bestimmte Ressourcen zuzugreifen, ohne dass der Nutzer seine Zugangsdaten preisgeben muss. Das Verfahren besteht im Wesentlichen darin, dass der Benutzer einer Anwendung eine Autorisierung erteilt, indem er auf eine Bestätigungsseite des Anbieters (z. B. Microsoft) weitergeleitet wird. Sobald der Nutzer diese Erlaubnis erteilt hat, erhält die Anwendung einen Access Token.</p>
          
          <p>Dieser Access Token dient dann als Schlüssel für den Zugriff auf die freigegebenen Ressourcen. Wichtig dabei ist, dass die Anwendung nur die Berechtigungen erhält, die der Nutzer genehmigt hat, und dass der Access Token zeitlich begrenzt ist. Der Benutzer kann die erteilten Berechtigungen jederzeit widerrufen, wodurch der Access Token ungültig wird.</p>
          
          <div className="flex justify-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" className="text-gray-700">
              <path d="M10 17c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm3 0c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm3 0c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm2-7v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.795-4 4-4s4 1.794 4 4v4h-8v-4zm11 16h-14v-10h14v10z"></path>
            </svg>
          </div>
          
          <BlogHeading>Einrichtung in Azure</BlogHeading>
          
          <p>Melde dich mit deinem Microsoft Account bei Azure an und wähle „Microsoft Entra ID".</p>
          
          <p>Klicke auf „Hinzufügen – App-Registrierung".<br />
          Gib der App einen Namen und wähle einen Kontotyp aus. Je nachdem, wie weit du die Registrierung zulassen möchtest, kannst du „Konten in einem beliebigen Organisationsverzeichnis" auswählen.<br /><br />
          Für die Umleitungs-URIs wähle „Web" und gib diese Adresse ein:<br />
          <a href="https://deine.paperless-url.de/api/oauth/callback" target="_blank" rel="noopener">https://deine.paperless-url.de/api/oauth/callback</a></p>
          
          <p>Anschließend auf „Registrieren" klicken, um den Vorgang abzuschließen.</p>
          
          <p>Navigiere nun unter „Verwaltung" zu „Authentifizierung" und setze die Häkchen bei „Zufriffstoken" und „ID-Token". Danach klicke auf „Speichern".</p>
          
          <p>Im nächsten Schritt wird ein Clientschlüssel generiert. Klicke dazu auf „Zertifikate & Geheimnisse" und dann auf „Neuer geheimer Clientschlüssel".</p>
          
          <p>Gib eine beliebige Beschreibung ein und lege eine Gültigkeitsdauer fest. Mache am besten gleich einen Kalendereintrag, um den Schlüssel rechtzeitig zu erneuern.</p>
          
          <p>Kopiere den Wert in ein Textdokument, wir brauchen ihn später.</p>
          
          <p>Anschließend klicke auf den Menüpunkt „Übersicht" und kopiere die Anwendungs-ID (Client) in dein Textdokument.</p>
          
          <BlogGallery 
            images={[
              {
                src: "../images/blog/2024/11/Microsoft-Entra-ID.png",
                alt: "Microsoft Entra ID Ansicht"
              },
              {
                src: "../images/blog/2024/11/App-Registierung.png",
                alt: "App-Registrierung Menü"
              },
              {
                src: "../images/blog/2024/11/App-Name-und-Kontotyp.png",
                alt: "App Name und Kontotyp Einstellungen"
              },
              {
                src: "../images/blog/2024/11/Implizite-Genehmigung-und-Hybridflows.png",
                alt: "Implizite Genehmigung und Hybridflows Einstellungen"
              },
              {
                src: "../images/blog/2024/11/Clientschluessel.png",
                alt: "Clientschlüssel Erzeugung"
              }
            ]}
            columns={5}
            gap={10}
          />
          
          <BlogHeading>Einrichtung auf dem Server</BlogHeading>
          
          <p>Nachdem du dich auf deinem Server angemeldet hast, navigiere zum Paperless-ngx Installationsordner und öffne die Datei „docker-compose.env".<br />
          Hier gibst du die folgenden drei Zeilen ein:</p>
          
          <div className="bg-gray-100 p-4 rounded my-4 font-mono text-sm">
            <p>PAPERLESS_OAUTH_CALLBACK_BASE_URL=https://deine.paperless-domain.de</p>
            <p>PAPERLESS_OUTLOOK_OAUTH_CLIENT_ID='deine client id von azure'</p>
            <p>PAPERLESS_OUTLOOK_OAUTH_CLIENT_SECRET='dein client secret (Wert) von azure'</p>
          </div>
          
          <p>Danach die Paperless-ngx Container einmal neu starten:</p>
          
          <div className="bg-gray-100 p-4 rounded my-4 font-mono text-sm">
            <p>docker compose down</p>
            <p>docker compose up -d</p>
          </div>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/11/Bildschirmfoto-vom-2024-11-12-15-58-54.png" 
              alt="Paperless-ngx: oAuth2 Einrichtung für Microsoft365" 
              size="medium" 
            />
          </div>
          
          <BlogHeading>Einrichtung in Paperless-ngx</BlogHeading>
          
          <p>Melde dich im Webinterface von Paperless-ngx an und klicke im linken Menü auf „E-Mail". Es erscheint ein neuer Button „Outlook Konto verbinden". Du wirst direkt zur Microsoft Anmeldeseite weitergeleitet, wo du dich mit deinem Microsoft Konto anmelden kannst. Nach erfolgreicher Anmeldung gelangst du zurück zu Paperless. Hier musst du nur noch deine E-Mail-Adresse eingeben und schon bist du verbunden!</p>
          
          <p>Das war alles zu „Paperless-ngx: oAuth2 für Microsoft365 einrichten".</p>
          
          <p>In diesem Artikel erfährst du, wie du E-Mail-Regeln erstellen kannst, damit deine Anhänge direkt von Paperless-ngx verarbeitet werden.<br />
          <a href="/blog/e-mail-account-in-paperless-ngx-einbinden/" target="_blank" rel="noopener">https://pathconnect.de/blog/e-mail-account-in-paperless-ngx-einbinden/</a></p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/11/Outlook-Konto-verbinden.png" 
              alt="Outlook Konto verbinden Button in Paperless-ngx" 
              size="medium" 
            />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Paperless-ngx: oAuth2 für Microsoft365 einrichten",
      formattedDate: "12. November 2024",
      excerpt: "Paperless-ngx kann Anhänge aus E-Mail-Postfächern automatisch verarbeiten. Damit dies auch mit einem Microsoft365 Konto funktioniert, müssen einige Einstellungen vorgenommen werden. In diesem Artikel zeigen wir, was zu tun ist, damit die Anbindung funktioniert.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Paperless-ngx: Setting up oAuth2 for Microsoft365",
      formattedDate: "November 12, 2024",
      excerpt: "Paperless-ngx can automatically process attachments from email mailboxes. For this to work with a Microsoft365 account, some settings need to be configured. In this article, we'll show you what needs to be done to make the connection work.",
      content: () => (
        <BlogContent>
          <p>Paperless-ngx can automatically process attachments from email mailboxes. For this to work with a Microsoft365 account, some settings need to be configured. In this article, we'll show you what needs to be done to make the connection work.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/11/Paperless-ngx.png" 
              alt="Paperless-ngx Logo" 
              size="large" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>OAuth2</BlogHeading>
          
          <p>OAuth2 is an authorization protocol that enables an application to access specific resources on behalf of a user without the user having to disclose their credentials. The process essentially involves the user granting authorization to an application by being redirected to a confirmation page of the provider (e.g., Microsoft). Once the user has granted this permission, the application receives an access token.</p>
          
          <p>This access token then serves as the key for accessing the shared resources. It's important to note that the application only receives the permissions that the user has approved, and the access token is time-limited. The user can revoke the granted permissions at any time, which invalidates the access token.</p>
          
          <div className="flex justify-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" className="text-gray-700">
              <path d="M10 17c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm3 0c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm3 0c0 .552-.447 1-1 1s-1-.448-1-1 .447-1 1-1 1 .448 1 1zm2-7v-4c0-3.313-2.687-6-6-6s-6 2.687-6 6v4h-3v14h18v-14h-3zm-10-4c0-2.206 1.795-4 4-4s4 1.794 4 4v4h-8v-4zm11 16h-14v-10h14v10z"></path>
            </svg>
          </div>
          
          <BlogHeading>Setup in Azure</BlogHeading>
          
          <p>Sign in to Azure with your Microsoft account and select "Microsoft Entra ID".</p>
          
          <p>Click on "Add - App registration".<br />
          Give the app a name and select an account type. Depending on how broadly you want to allow registration, you can choose "Accounts in any organizational directory".<br /><br />
          For the redirect URIs, select "Web" and enter this address:<br />
          <a href="https://your.paperless-url.com/api/oauth/callback" target="_blank" rel="noopener">https://your.paperless-url.com/api/oauth/callback</a></p>
          
          <p>Then click "Register" to complete the process.</p>
          
          <p>Now navigate to "Authentication" under "Management" and check the boxes for "Access tokens" and "ID tokens". Then click "Save".</p>
          
          <p>The next step is to generate a client key. Click on "Certificates & Secrets" and then on "New client secret".</p>
          
          <p>Enter any description and set an expiry date. It's a good idea to make a calendar entry to renew the key in time.</p>
          
          <p>Copy the value into a text document; we'll need it later.</p>
          
          <p>Then click on the "Overview" menu item and copy the Application (client) ID into your text document.</p>
          
          <BlogGallery 
            images={[
              {
                src: "../images/blog/2024/11/Microsoft-Entra-ID.png",
                alt: "Microsoft Entra ID View"
              },
              {
                src: "../images/blog/2024/11/App-Registierung.png",
                alt: "App Registration Menu"
              },
              {
                src: "../images/blog/2024/11/App-Name-und-Kontotyp.png",
                alt: "App Name and Account Type Settings"
              },
              {
                src: "../images/blog/2024/11/Implizite-Genehmigung-und-Hybridflows.png",
                alt: "Implicit Grant and Hybrid Flows Settings"
              },
              {
                src: "../images/blog/2024/11/Clientschluessel.png",
                alt: "Client Key Generation"
              }
            ]}
            columns={5}
            gap={10}
          />
          
          <BlogHeading>Server Configuration</BlogHeading>
          
          <p>After logging into your server, navigate to the Paperless-ngx installation folder and open the "docker-compose.env" file.<br />
          Add the following three lines:</p>
          
          <div className="bg-gray-100 p-4 rounded my-4 font-mono text-sm">
            <p>PAPERLESS_OAUTH_CALLBACK_BASE_URL=https://your.paperless-domain.com</p>
            <p>PAPERLESS_OUTLOOK_OAUTH_CLIENT_ID='your client id from azure'</p>
            <p>PAPERLESS_OUTLOOK_OAUTH_CLIENT_SECRET='your client secret (value) from azure'</p>
          </div>
          
          <p>Then restart the Paperless-ngx containers:</p>
          
          <div className="bg-gray-100 p-4 rounded my-4 font-mono text-sm">
            <p>docker compose down</p>
            <p>docker compose up -d</p>
          </div>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/11/Bildschirmfoto-vom-2024-11-12-15-58-54.png" 
              alt="Paperless-ngx: oAuth2 Setup for Microsoft365" 
              size="medium" 
            />
          </div>
          
          <BlogHeading>Setting up in Paperless-ngx</BlogHeading>
          
          <p>Log in to the Paperless-ngx web interface and click on "Email" in the left menu. A new button "Connect Outlook Account" will appear. You will be redirected to the Microsoft login page, where you can sign in with your Microsoft account. After successful login, you will be returned to Paperless. Here you just need to enter your email address and you're connected!</p>
          
          <p>That's all there is to "Setting up oAuth2 for Microsoft365 in Paperless-ngx".</p>
          
          <p>In this article, you can learn how to create email rules so that your attachments can be processed directly by Paperless-ngx.<br />
          <a href="/blog/e-mail-account-in-paperless-ngx-einbinden/" target="_blank" rel="noopener">https://pathconnect.de/blog/e-mail-account-in-paperless-ngx-einbinden/</a></p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/11/Outlook-Konto-verbinden.png" 
              alt="Connect Outlook Account Button in Paperless-ngx" 
              size="medium" 
            />
          </div>
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offering includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-round service that includes installation, customization, and updates, allowing you to focus fully on your projects.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Paperless-ngx: Setting up oAuth2 for Microsoft365",
      formattedDate: "12 November 2024",
      excerpt: "Paperless-ngx can automatically process attachments from email mailboxes. For this to work with a Microsoft365 account, some settings need to be configured. In this article, we'll show you what needs to be done to make the connection work.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;