import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

Modal.setAppElement('#root')

const CustomModal = ({ isOpen, onRequestClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="bg-white dark:bg-dark-gradient-bg bg-gradient-bg p-6 rounded-lg shadow-lg 
      max-w-[80vw] w-full max-h-[80vh] overflow-y-auto"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center backdrop-blur-sm"
    >
      <div className="flex justify-end mt-4">
        <button
          type="button"
          onClick={onRequestClose}
          className="py-2 px-4 text-xl"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      {children}
    </Modal>
  )
}

CustomModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onRequestClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
}

export default CustomModal
