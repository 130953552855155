import React from 'react'
import Navbar from './navigation/Navbar'

const Header = () => {
  return (
    <header className="top-0 left-0 w-full mt-4">
      <div className="px-4 sm:px-6 lg:px-8 h-full">
        <div className="justify-between items-center h-full">
          <Navbar />
        </div>
      </div>
    </header>
  )
}

export default Header
