import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faRocket,
  faUsers,
  faShieldAlt,
  faUnlock
} from '@fortawesome/free-solid-svg-icons';
import Container from '../components/container';
import { Link } from 'react-router-dom';

const OpenSourceSection = () => {
  const { t } = useTranslation('home');
  
  // Get open source benefits from translation
  const openSourceData = t('c6.list', { returnObjects: true }) || [];
  
  // Enhanced data with icons
  const enhancedData = [
    { icon: faRocket, color: 'from-blue-400 to-cyan-500' },
    { icon: faUsers, color: 'from-green-400 to-teal-500' },
    { icon: faShieldAlt, color: 'from-purple-400 to-indigo-500' },
    { icon: faUnlock, color: 'from-orange-400 to-red-500' }
  ];
  
  return (
    <Container>
      <div className="w-full">
        <h2 className="text-3xl font-bold text-center text-cyan-400 mb-10">{t('c6.title')}</h2>
        
        {/* Cross layout with benefits */}
        <div className="relative mt-16 mb-16 flex justify-center">
          {/* Center dot with shorter lines - hidden on mobile */}
          <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 hidden md:block">
            <div className="relative">
              <div className="w-12 h-12 bg-cyan-400 rounded-full flex items-center justify-center animate-pulse">
                <div className="w-6 h-6 bg-gray-900 rounded-full"></div>
              </div>
              
              {/* Shorter horizontal line */}
              <div className="absolute top-1/2 left-1/2 w-[500px] h-px bg-gray-600 -translate-x-1/2 -translate-y-1/2 -z-10"></div>
              
              {/* Shorter vertical line */}
              <div className="absolute top-1/2 left-1/2 h-[500px] w-px bg-gray-600 -translate-x-1/2 -translate-y-1/2 -z-10"></div>
            </div>
          </div>
          
          {/* Grid for benefits */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-16 md:gap-24 max-w-5xl">
            {openSourceData.map((item, index) => (
              <div 
                key={index} 
                className={`p-6 rounded-lg transition-all duration-300 hover:scale-105 relative ${
                  index % 2 === 0 ? 'md:text-right' : 'md:text-left'
                }`}
              >
                {/* Background accent */}
                <div className={`absolute top-0 ${index % 2 === 0 ? 'right-0' : 'left-0'} w-16 h-16 rounded-full bg-gradient-to-br ${enhancedData[index].color} opacity-10 -z-10`}></div>
                
                {/* Title with icon */}
                <div className={`flex items-center mb-4 ${index % 2 === 0 ? 'justify-end' : 'justify-start'}`}>
                  {index % 2 === 1 && (
                    <div className={`w-10 h-10 rounded-full bg-gradient-to-br ${enhancedData[index].color} flex items-center justify-center mr-3 shadow-lg shadow-cyan-500/20 p-2.5`}>
                      <FontAwesomeIcon 
                        icon={enhancedData[index].icon} 
                        className="text-white text-lg" 
                      />
                    </div>
                  )}
                  <h3 className="text-xl font-bold text-cyan-400">{item.title}</h3>
                  {index % 2 === 0 && (
                    <div className={`w-10 h-10 rounded-full bg-gradient-to-br ${enhancedData[index].color} flex items-center justify-center ml-3 shadow-lg shadow-cyan-500/20 p-2.5`}>
                      <FontAwesomeIcon 
                        icon={enhancedData[index].icon} 
                        className="text-white text-lg" 
                      />
                    </div>
                  )}
                </div>
                
                {/* Description */}
                <p className="text-gray-300">{item.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default OpenSourceSection;