// src/pages/blogPost.jsx - Fully updated with mobile responsiveness improvements
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '../components/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCalendarAlt, faClock } from '@fortawesome/free-solid-svg-icons';
import BlogService from '../services/blogService';

const BlogPost = () => {
  const { slug } = useParams();
  const { t, i18n } = useTranslation('blog'); 
  
  // Get current language and handle both formats
  const getCurrentLanguage = () => {
    // Get the full locale from i18n
    const fullLang = i18n.language || localStorage.getItem('i18nextLng') || 'de_DE';
    
    // For backward compatibility with existing posts that use simple codes
    const simpleLang = fullLang.split('_')[0];
    
    return { fullLang, simpleLang };
  };
  
  const [post, setPost] = useState(null);
  const [relatedPosts, setRelatedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  
  // Add SEO metadata when post loads
  useEffect(() => {
    if (post) {
      const translatedContent = getTranslatedContent(post);
      
      // Update document title
      document.title = `${translatedContent.title} | Blog`;
      
      // Update meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', translatedContent.excerpt);
      } else {
        const meta = document.createElement('meta');
        meta.name = 'description';
        meta.content = translatedContent.excerpt;
        document.head.appendChild(meta);
      }
      
      // Add Open Graph tags
      const ogTags = [
        { property: 'og:title', content: translatedContent.title },
        { property: 'og:description', content: translatedContent.excerpt },
        { property: 'og:type', content: 'article' },
        { property: 'og:url', content: window.location.href }
      ];
      
      if (post.thumbnailUrl) {
        ogTags.push({ property: 'og:image', content: post.thumbnailUrl });
      }
      
      ogTags.forEach(tag => {
        let element = document.querySelector(`meta[property="${tag.property}"]`);
        if (element) {
          element.setAttribute('content', tag.content);
        } else {
          element = document.createElement('meta');
          element.setAttribute('property', tag.property);
          element.setAttribute('content', tag.content);
          document.head.appendChild(element);
        }
      });
    }
    
    // Cleanup
    return () => {
      document.title = 'Blog'; // Reset title on unmount
    };
  }, [post]);
  
  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString(i18n.language || navigator.language || 'de_DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      console.error('Error formatting date:', e);
      return dateString;
    }
  };

  // Fetch blog post
  useEffect(() => {
    const fetchPost = async () => {
      try {
        setLoading(true);
        const data = await BlogService.getPostBySlug(slug);
        setPost(data);
        
        // Get related posts by category
        if (data.categories && data.categories.length > 0) {
          const mainCategory = data.categories[0];
          const related = await BlogService.getPostsByCategory(mainCategory);
          // Filter out the current post and limit to 3 related posts
          setRelatedPosts(
            related
              .filter(relatedPost => relatedPost.slug !== slug)
              .slice(0, 3)
          );
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog post:', error);
        setError(error.message);
        setLoading(false);
      }
    };
    
    if (slug) {
      fetchPost();
      // Scroll to top when post changes
      window.scrollTo(0, 0);
    }
  }, [slug, i18n.language]); // Re-fetch when language changes

  // Get translated content based on current language
  const getTranslatedContent = (post) => {
    if (!post) return null;
    
    const { fullLang, simpleLang } = getCurrentLanguage();
    
    // Try full locale format first (e.g., de_DE)
    if (post.translations && post.translations[fullLang]) {
      return {
        title: post.translations[fullLang].title,
        formattedDate: post.translations[fullLang].formattedDate,
        excerpt: post.translations[fullLang].excerpt,
        content: post.translations[fullLang].content
      };
    }
    
    // Try simple language code (e.g., de)
    if (post.translations && post.translations[simpleLang]) {
      return {
        title: post.translations[simpleLang].title,
        formattedDate: post.translations[simpleLang].formattedDate,
        excerpt: post.translations[simpleLang].excerpt,
        content: post.translations[simpleLang].content
      };
    }
    
    // Fallback to default properties if translations not available
    return {
      title: post.title,
      formattedDate: post.formattedDate,
      excerpt: post.excerpt,
      content: post.content
    };
  };

  // Clean HTML content - remove unnecessary elementor attributes
  const cleanContent = (html) => {
    if (!html) return '';
    
    let cleaned = html;
    
    // Fix relative image paths
    cleaned = cleaned.replace(/src="\.\.\/images\/blog\//g, 'src="/images/blog/');
    
    // Remove WordPress/Elementor ID and data attributes
    cleaned = cleaned.replace(/data-id="[^"]*"/g, '');
    cleaned = cleaned.replace(/data-element_type="[^"]*"/g, '');
    cleaned = cleaned.replace(/data-widget_type="[^"]*"/g, '');
    cleaned = cleaned.replace(/data-settings="[^"]*"/g, '');
    
    // Clean up empty class attributes
    cleaned = cleaned.replace(/class=""/g, '');
    
    return cleaned;
  };

  if (loading) {
    return (
      <Container>
        <div className="flex justify-center items-center h-64">
          <div className="text-primary">{t('loading', 'Loading...')}</div>
        </div>
      </Container>
    );
  }

  if (error || !post) {
    return (
      <Container>
        <div className="flex justify-center items-center h-64">
          <div className="text-red-500 text-xl">
            {error || t('post-not-found', 'Beitrag nicht gefunden')}
          </div>
        </div>
      </Container>
    );
  }

  // Get translated content
  const translatedContent = getTranslatedContent(post);

  // Check if content is a function (JSX components) or string (HTML)
  const isJsxContent = typeof translatedContent.content === 'function';

  return (
    <Container>
      {/* Updated container with better padding for mobile */}
      <div className="w-full px-4 sm:px-0 max-w-4xl mx-auto py-4 sm:py-8">
        {/* Back button */}
        <div className="mb-4 sm:mb-6">
          <Link
            to="/blog"
            className="inline-flex items-center text-primary hover:text-button-dark transition-colors"
          >
            <FontAwesomeIcon icon={faChevronLeft} className="mr-2" />
            {t('back-to-blog', 'Zurück zum Blog')}
          </Link>
        </div>

        <article className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden">
          {/* Post header - improved for mobile */}
          <header className="p-4 sm:p-6 border-b border-gray-200 dark:border-gray-700">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold text-primary mb-4 font-heading break-words hyphens-auto leading-tight">
              {translatedContent.title}
            </h1>
            
            <div className="flex flex-wrap items-center text-sm text-gray-500 dark:text-gray-400 mb-4">
              <div className="flex items-center mr-4 mb-2">
                <FontAwesomeIcon icon={faCalendarAlt} className="mr-2" />
                {translatedContent.formattedDate || formatDate(post.date)}
              </div>
              
              {post.readTime && (
                <div className="flex items-center mb-2">
                  <FontAwesomeIcon icon={faClock} className="mr-2" />
                  {post.readTime} min {t('read-time', 'Lesezeit')}
                </div>
              )}
            </div>
            
            {/* Categories */}
            <div className="flex flex-wrap gap-2">
              {post.categories.map(category => (
                <Link
                  key={category}
                  to={`/blog?category=${category}`}
                  className="text-xs px-3 py-1 bg-gray-100 dark:bg-gray-700 text-primary rounded-full hover:bg-gray-200 dark:hover:bg-gray-600 transition-colors"
                >
                  {category}
                </Link>
              ))}
            </div>
          </header>

          {/* Featured image - responsive display */}
          {post.thumbnailUrl && post.showInPostHeader !== false && !isJsxContent && (
            <div className={`mx-auto px-4 sm:px-6 mt-6 overflow-hidden ${post.thumbnailSize === 'small' ? 'max-w-md' : 'w-full'}`}>
              <img
                src={post.thumbnailUrl}
                alt={translatedContent.title}
                className="w-full h-auto object-cover rounded-lg border-4 border-primary"
              />
            </div>
          )}

          {/* Post content - improved padding for mobile */}
          <div className="p-4 sm:p-6">
            {isJsxContent ? (
              // For JSX-based content (new format with components)
              translatedContent.content()
            ) : (
              // For HTML string-based content (legacy format)
              <div 
                dangerouslySetInnerHTML={{ 
                  __html: cleanContent(translatedContent.content) 
                }} 
                className="blog-content prose prose-lg max-w-none dark:prose-invert"
              />
            )}
          </div>
          
          {/* Related posts section - improved for mobile */}
          {relatedPosts.length > 0 && (
            <div className="px-4 sm:px-6 pt-4 sm:pt-6 pb-6 sm:pb-8 border-t border-gray-200 dark:border-gray-700">
              <h3 className="text-xl font-bold text-gray-800 dark:text-white mb-4 font-heading break-words">
                {t('related-posts', 'Ähnliche Beiträge')}
              </h3>
              
              {/* Updated grid to stack on mobile */}
              <div className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 md:grid-cols-3">
                {relatedPosts.map(relatedPost => {
                  // Get translated content for related post with same helper function
                  const { fullLang, simpleLang } = getCurrentLanguage();
                  const relatedTranslated = (relatedPost.translations?.[fullLang] || relatedPost.translations?.[simpleLang]) 
                    ? {
                        title: (relatedPost.translations[fullLang] || relatedPost.translations[simpleLang]).title,
                        excerpt: (relatedPost.translations[fullLang] || relatedPost.translations[simpleLang]).excerpt
                      }
                    : {
                        title: relatedPost.title,
                        excerpt: relatedPost.excerpt
                      };
                      
                  return (
                    <Link 
                      key={relatedPost.slug} 
                      to={`/blog/${relatedPost.slug}`}
                      className="group block bg-gray-50 dark:bg-gray-700 rounded-lg overflow-hidden shadow hover:shadow-md transition-shadow"
                    >
                      {relatedPost.thumbnailUrl && (
                        <div className="h-40 overflow-hidden">
                          <img 
                            src={relatedPost.thumbnailUrl} 
                            alt={relatedTranslated.title}
                            className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
                          />
                        </div>
                      )}
                      <div className="p-4">
                        <h4 className="font-bold text-gray-800 dark:text-white group-hover:text-primary transition-colors line-clamp-2 break-words">
                          {relatedTranslated.title}
                        </h4>
                        <p className="mt-2 text-sm text-gray-600 dark:text-gray-300 line-clamp-2">
                          {relatedTranslated.excerpt}
                        </p>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          )}
        </article>
      </div>
    </Container>
  );
};

export default BlogPost;