// src/data/blogPosts/baume-pflanzen-klimaschutz.js

// Import images
import treeIcon from '../../assets/images/blog/2023/04/iconmonstr-tree-19-240.png';
import serverIcon from '../../assets/images/blog/2022/02/iconmonstr-server-7-240.png';
import weatherIcon from '../../assets/images/blog/2023/04/iconmonstr-weather-91-240.png';
import pmtLogo from '../../assets/images/blog/2023/04/PMT-Partnerlogo_2023_de.png';
import certificate from '../../assets/images/blog/2023/04/2023-04-11_Urkunde_PathConnect.png';
import thumbnailImage from '../../assets/images/blog/baume-pflanzen-klimaschutz-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about tree planting and climate protection
 */
const post = {
  id: '11570',
  slug: 'baume-pflanzen-klimaschutz',
  
  // Base data - same regardless of language
  date: "2023-04-11T15:48:44.000Z",
  readTime: 7,
  categories: ["Informationen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'small',
  showInPostHeader: false,
  likes: 30,
  comments: [],
  
  // Language-specific data - using both formats for maximum compatibility
  translations: {
    // German content
    de: {
      title: "Bäume pflanzen – Klimaschutz",
      formattedDate: "11. April 2023",
      excerpt: "Ein sehr wichtiges Thema – den Klimaschutz. Du erfährst, wie wir uns dafür einsetzen und mit welchem Partner wir dabei zusammenarbeiten.",
      content: () => (
        <BlogContent>
          <p>Im heutigen Artikel geht es um ein sehr wichtiges Thema – den Klimaschutz. Du erfährst, wie wir uns dafür einsetzen und mit welchem Partner wir dabei zusammenarbeiten.</p>
          
          <BlogImage src={treeIcon} alt="Baum Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Stromverbrauch & Hardware</BlogHeading>
          
          <p>Als IT-Dienstleister für Open Source Cloud Lösungen betreiben wir viele Server und die dazugehörige Hardware wie Firewalls, Switche und Router. Diese Geräte laufen 24/7 und haben zusammen einen hohen Stromverbrauch. Auch die Herstellung dieser Geräte verbraucht Energie und Ressourcen. Wir benötigen also eine Möglichkeit den damit verbunden CO² Ausstoß wieder zu kompensieren.</p>
          
          <BlogImage src={serverIcon} alt="Server Icon" size="icon" className="mx-auto my-4" />
          
          <BlogHeading level={3}>CO²-Kompensierung</BlogHeading>
          
          <p>Eine sehr gute Möglichkeit, den CO2-Ausstoß zu kompensieren, ist das Pflanzen von Bäumen. Durchschnittlich kann ein Baum ca. 10 kg CO² pro Jahr binden. Genauer gesagt findet eine Photosynthese statt. Dabei nimmt der Baum Kohlendioxid aus der Atmosphäre auf und wandelt es mit Hilfe von Lichtenergie in Zucker um. Als Nebenprodukt der Photosynthese setzt der Baum Sauerstoff frei. So leistet jeder Baum einen kleinen Beitrag zum Klimaschutz und jeder Baum zählt.</p>
          
          <BlogImage src={weatherIcon} alt="Wetter Icon" size="icon" className="mx-auto my-4" />
          
          <BlogHeading level={3}>PLANT-MY-TREE</BlogHeading>
          
          <p>Um Bäume pflanzen zu können, werden entsprechende Flächen benötigt. Seit 2007 führt PLANT-MY-TREE deutschlandweit Aufforstungsprojekte zur CO2-Kompensation durch. Es werden Mischwälder gepflanzt, die eine hohe Artenvielfalt garantieren und so einen natürlichen Lebensraum für Insekten und Tiere schaffen. Die Baumpflanzungen erfolgen größtenteils auf eigenen Flächen und die Projekte haben eine Laufzeit von mindestens 99 Jahren, in denen keine Abholzung oder wirtschaftliche Nutzung erfolgt. Zudem wurde PLANT-MY-TREE Anfang 2020 vom TÜV Rheinland erfolgreich nach DIN ISO 9001:2015 zertifiziert.</p>
          
          <p><a href="https://plant-my-tree.de/" target="_blank" rel="noopener">https://plant-my-tree.de/</a></p>
          
          <BlogImage src={pmtLogo} alt="PLANT-MY-TREE Partner Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Bäume pflanzen bei PathConnect</BlogHeading>
          
          <p>Für den Anfang haben wir 100 Bäume gekauft und sind damit Bronze-Partner von PLANT-MY-TREE. Unser Ziel für das nächste Jahr ist es, 500 Bäume pflanzen zu lassen. Damit wollen wir unseren Beitrag für eine bessere Umwelt und ein gesünderes Klima leisten. Die ersten 100 Bäume werden in Hohengöhren gepflanzt. Die genauen Koordinaten der Fläche sind: 52°37'16.3"N 12°03'10.2"E</p>
          
          <BlogImage src={certificate} alt="Urkunde PathConnect" size="large" className="mx-auto my-6" />
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Bäume pflanzen – Klimaschutz",
      formattedDate: "11. April 2023",
      excerpt: "Ein sehr wichtiges Thema – den Klimaschutz. Du erfährst, wie wir uns dafür einsetzen und mit welchem Partner wir dabei zusammenarbeiten.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Planting Trees - Climate Protection",
      formattedDate: "April 11, 2023",
      excerpt: "A very important topic - climate protection. Learn how we are committed to it and which partner we work with.",
      content: () => (
        <BlogContent>
          <p>Today's article is about a very important topic - climate protection. You'll learn how we are committed to it and which partner we work with.</p>
          
          <BlogImage src={treeIcon} alt="Tree Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Power Consumption & Hardware</BlogHeading>
          
          <p>As an IT service provider for Open Source cloud solutions, we operate many servers and associated hardware such as firewalls, switches, and routers. These devices run 24/7 and together have a high power consumption. The manufacturing of these devices also consumes energy and resources. So we need a way to compensate for the associated CO² emissions.</p>
          
          <BlogImage src={serverIcon} alt="Server Icon" size="icon" className="mx-auto my-4" />
          
          <BlogHeading level={3}>CO² Compensation</BlogHeading>
          
          <p>A very good way to compensate for CO2 emissions is planting trees. On average, a tree can bind about 10 kg of CO² per year. More precisely, photosynthesis takes place. The tree absorbs carbon dioxide from the atmosphere and converts it into sugar with the help of light energy. As a byproduct of photosynthesis, the tree releases oxygen. Thus, each tree makes a small contribution to climate protection, and every tree counts.</p>
          
          <BlogImage src={weatherIcon} alt="Weather Icon" size="icon" className="mx-auto my-4" />
          
          <BlogHeading level={3}>PLANT-MY-TREE</BlogHeading>
          
          <p>To plant trees, appropriate areas are needed. Since 2007, PLANT-MY-TREE has been carrying out reforestation projects throughout Germany for CO2 compensation. Mixed forests are planted, which guarantee a high biodiversity and thus create a natural habitat for insects and animals. The tree planting is mostly done on their own land, and the projects have a duration of at least 99 years, during which no logging or economic use takes place. In addition, PLANT-MY-TREE was successfully certified by TÜV Rheinland according to DIN ISO 9001:2015 at the beginning of 2020.</p>
          
          <p><a href="https://plant-my-tree.de/" target="_blank" rel="noopener">https://plant-my-tree.de/</a></p>
          
          <BlogImage src={pmtLogo} alt="PLANT-MY-TREE Partner Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Planting Trees with PathConnect</BlogHeading>
          
          <p>To start, we have purchased 100 trees and are thus a Bronze Partner of PLANT-MY-TREE. Our goal for next year is to have 500 trees planted. With this, we want to make our contribution to a better environment and a healthier climate. The first 100 trees will be planted in Hohengöhren. The exact coordinates of the area are: 52°37'16.3"N 12°03'10.2"E</p>
          
          <BlogImage src={certificate} alt="PathConnect Certificate" size="large" className="mx-auto my-6" />
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customization, and updates, so you can focus entirely on your projects.</p>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Planting Trees - Climate Protection",
      formattedDate: "April 11, 2023",
      excerpt: "A very important topic - climate protection. Learn how we are committed to it and which partner we work with.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    },
    
    // French content placeholder (simple and full locale)
    fr: {
      title: "Planter des arbres - Protection du climat",
      formattedDate: "11 avril 2023",
      excerpt: "Un sujet très important - la protection du climat. Découvrez comment nous nous engageons et avec quel partenaire nous travaillons.",
      content: () => (
        <BlogContent>
          <p>L'article d'aujourd'hui porte sur un sujet très important - la protection du climat. Vous découvrirez comment nous nous engageons et avec quel partenaire nous travaillons.</p>
          
          {/* Rest of French content would go here */}
          <p>Contenu en français à compléter...</p>
        </BlogContent>
      )
    },
    
    fr_FR: {
      title: "Planter des arbres - Protection du climat",
      formattedDate: "11 avril 2023",
      excerpt: "Un sujet très important - la protection du climat. Découvrez comment nous nous engageons et avec quel partenaire nous travaillons.",
      get content() { return post.translations.fr.content; }
    },
    
    // Dutch content placeholder (simple and full locale)
    nl: {
      title: "Bomen planten - Klimaatbescherming",
      formattedDate: "11 april 2023",
      excerpt: "Een zeer belangrijk onderwerp - klimaatbescherming. Ontdek hoe wij ons inzetten en met welke partner wij samenwerken.",
      content: () => (
        <BlogContent>
          <p>Het artikel van vandaag gaat over een zeer belangrijk onderwerp - klimaatbescherming. U ontdekt hoe wij ons inzetten en met welke partner wij samenwerken.</p>
          
          {/* Rest of Dutch content would go here */}
          <p>Nederlandse inhoud nog aan te vullen...</p>
        </BlogContent>
      )
    },
    
    nl_NL: {
      title: "Bomen planten - Klimaatbescherming",
      formattedDate: "11 april 2023",
      excerpt: "Een zeer belangrijk onderwerp - klimaatbescherming. Ontdek hoe wij ons inzetten en met welke partner wij samenwerken.",
      get content() { return post.translations.nl.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;