// src/data/blogPosts/nextcloud-office-einrichten.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-office-einrichten-featured.png';
import collaboraOnlineImage from '../../assets/images/blog/2022/09/collabora-online.png';
import layerIcon from '../../assets/images/blog/2023/06/iconmonstr-layer-7-240.png';
import downloadIcon from '../../assets/images/blog/2023/06/iconmonstr-download-thin-240.png';
import serverSettingsImage from '../../assets/images/blog/2023/06/Nextcloud-Office-Server.png';
import wopiSettingsImage from '../../assets/images/blog/2023/06/Bildschirmfoto-vom-2023-09-29-14-52-34.png';
import toolsIcon from '../../assets/images/blog/2023/03/iconmonstr-tools-14-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about setting up Nextcloud Office
 */
const post = {
  id: '12022',
  slug: 'nextcloud-office-einrichten',
  
  // Base data - same regardless of language
  date: "2023-06-11T13:12:37.000Z",
  readTime: 9,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 10,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Office einrichten",
      formattedDate: "11. Juni 2023",
      excerpt: "Nextcloud Office einrichten. Mit Nextcloud Office kannst du Dokumente online direkt in deiner Nextcloud öffnen und bearbeiten. Auch die gemeinsame Bearbeitung mit anderen ist möglich.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Office einrichten</h1>
          
          <p>Mit Nextcloud Office kannst du Dokumente <strong>online direkt in deiner Nextcloud öffnen</strong> und bearbeiten. Auch die <strong>gemeinsame Bearbeitung</strong> mit anderen ist möglich. Wenn du mehr über Nextcloud Office erfahren möchtest, findest du hier einen weiteren Blogartikel dazu: <a href="https://pathconnect.de/blog/nextcloud-office/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-office/</a></p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={collaboraOnlineImage} alt="Collabora Online" size="large" />
            </div>
            
          </div>
          
          <BlogHeading>Collabora Online und Nextcloud Office</BlogHeading>
          
          <p>Damit Nextcloud Office funktioniert, muss eine Verbindung zu einem <strong>Collabora Online Server</strong> hergestellt werden. Als offizieller Partner von Collabora bieten wir das Nextcloud Office Paket an, egal ob mit einer Nextcloud von PathConnect, selbst gehostet oder von einem anderen Anbieter. <br />In diesem Artikel erklären wir dir, wie du Nextcloud Office <strong>in deiner Nextcloud einrichten</strong> kannst, nachdem du deine Bestellung bei PathConnect aufgegeben hast.</p>
          
          <BlogImage src={collaboraOnlineImage} alt="Collabora Online" size="large" className="my-6" />
          
          <BlogHeading level={3}>Nach deiner Bestellung</BlogHeading>
          
          <p>Nachdem du das Nextcloud Office Paket bei PathConnect bestellt hast, erhältst du von uns eine E-Mail, in der wir dich nach deiner Nextcloud Domain fragen. Wenn du bereits eine Nextcloud bei PathConnect hast, musst du nichts weiter tun. In diesem Fall übernehmen wir die Einrichtung für dich.</p>
          
          <BlogImage src={layerIcon} alt="Layer Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Nextcloud Office installieren</BlogHeading>
          
          <p>Sobald deine Domain für Nextcloud Office freigeschaltet ist, kannst du mit der Einrichtung beginnen. Melde dich dazu mit einem <strong>Administrator Account</strong> in deiner Nextcloud an und klicke oben rechts auf das runde Icon und dann auf "+ Apps". In der App-Übersicht angekommen, kannst du mit einem Klick auf die Lupe das Suchfeld öffnen und dort "<strong>Nextcloud Office</strong>" eingeben. Klicke dann auf "<strong>Herunterladen & Aktivieren</strong>".</p>
          
          <BlogImage src={downloadIcon} alt="Download Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Nextcloud Office verbinden</BlogHeading>
          
          <p>Nachdem du App heruntergeladen und aktiviert hast, kannst du dich mit dem Collabora Online Server von PathConnect verbinden. Klicke dazu wieder auf das runde Icon im rechten oberen Eck und dann auf "Verwaltungs-Einstellungen". In den Einstellungen angekommen, klickst du auf "Office" in der linken Leiste.</p>
          
          <p>Wähle hier "<strong>Verwende deinen eigenen Server</strong>" aus und füge die URL "<i>https://collabora.pathconnect.de</i>" ein und klicke auf speichern.</p>
          
          <p>Weiter unten in den Einstellungen findest du die Option "<strong>Allow list for WOPI requests</strong>". Hier fügst du die beiden IP-Adressen des Collabora Online Server ein: <br />2a12:79c0:100:230::2,193.105.29.106</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={serverSettingsImage} alt="Nextcloud Office Server Einstellungen" size="large" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={wopiSettingsImage} alt="WOPI Einstellungen" size="large" />
            </div>
          </div>
          
          <BlogHeading level={3}>Testen</BlogHeading>
          
          <p>Um sicherzugehen, dass alles funktioniert, kannst du ein Testdokument erstellen. Wenn etwas nicht wie erwartet funktioniert, schreibe uns einfach eine E-Mail an <a href="mailto:info@pathconnect.de" target="_blank" rel="noopener"><strong>info@pathconnect.de</strong></a> und wir werden uns darum kümmern.</p>
          
          <BlogImage src={toolsIcon} alt="Werkzeug Icon" size="small" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Office einrichten",
      formattedDate: "11. Juni 2023",
      excerpt: "Nextcloud Office einrichten. Mit Nextcloud Office kannst du Dokumente online direkt in deiner Nextcloud öffnen und bearbeiten. Auch die gemeinsame Bearbeitung mit anderen ist möglich.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Setting up Nextcloud Office",
      formattedDate: "June 11, 2023",
      excerpt: "Setting up Nextcloud Office. With Nextcloud Office, you can open and edit documents online directly in your Nextcloud. Collaborative editing with others is also possible.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Setting up Nextcloud Office</h1>
          
          <p>With Nextcloud Office, you can open and edit documents <strong>online directly in your Nextcloud</strong>. <strong>Collaborative editing</strong> with others is also possible. If you want to learn more about Nextcloud Office, you can find another blog article about it here: <a href="https://pathconnect.de/blog/nextcloud-office/" target="_blank" rel="noopener">https://pathconnect.de/blog/nextcloud-office/</a></p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Setting up Nextcloud Office",
      formattedDate: "11 June 2023",
      excerpt: "Setting up Nextcloud Office. With Nextcloud Office, you can open and edit documents online directly in your Nextcloud. Collaborative editing with others is also possible.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;