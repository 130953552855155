import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBars,
  faTimes,
  faContactCard,
  faDungeon,
  faInfoCircle,
  faBook
} from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ReactComponent as PathConnectLogo } from '../../../assets/images/pathconnect_logo.svg'
import LanguageSwitcher from '../../languageSwitcher'
import ThemeToggleButton from '../../themeToggleButton'

const NavigationSM = () => {
  const [menuOpen, setMenuOpen] = useState(false)
  const { t } = useTranslation('navigation')
  
  // Prevent background scrolling when menu is open
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = ''
    }
    return () => {
      document.body.style.overflow = ''
    }
  }, [menuOpen])
  
  const toggleMenu = () => setMenuOpen(!menuOpen)
  
  return (
    <nav className="relative z-30">
      {/* Top Bar */}
      <div className="flex items-center justify-between p-4 bg-white dark:bg-gray-900">
        {/* Larger Logo */}
        <Link to="/" className="flex items-center">
          <PathConnectLogo className="w-4/5 h-auto" />
        </Link>
        
        {/* Hamburger/Close Button */}
        <button
          onClick={toggleMenu}
          className="text-gray-800 dark:text-gray-200 text-3xl focus:outline-none transition-transform duration-300"
          aria-label={menuOpen ? "Close menu" : "Open menu"}
        >
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </button>
      </div>
      
      {/* Menu Overlay */}
      <div
        className={`fixed top-0 left-0 w-full h-screen dark:bg-gray-900 bg-white
          transition-transform duration-300 overflow-y-auto z-50 ${
            menuOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
      >
        {/* Close Button Inside Menu */}
        <div className="flex items-center justify-between p-4 border-b">
          <Link to="/" className="flex items-center" onClick={toggleMenu}>
            <PathConnectLogo className="w-4/5 h-auto" />
          </Link>
          <button
            onClick={toggleMenu}
            className="text-gray-800 dark:text-gray-200 text-3xl focus:outline-none"
            aria-label="Close menu"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        
        {/* Navigation Links */}
        <ul className="flex flex-col items-center justify-center pt-8 pb-16 space-y-8 text-xl">
          <li className="flex space-x-4">
            <LanguageSwitcher />
            <ThemeToggleButton />
          </li>
          <li className="w-full">
            <Link
              to="/portal"
              className="flex items-center justify-center space-x-3 py-4 hover:bg-gray-100 dark:hover:bg-gray-800 w-full"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faDungeon} />
              <span>{t('portal')}</span>
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/blog"
              className="flex items-center justify-center space-x-3 py-4 hover:bg-gray-100 dark:hover:bg-gray-800 w-full"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faBook} />
              <span>{t('blog')}</span>
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/about"
              className="flex items-center justify-center space-x-3 py-4 hover:bg-gray-100 dark:hover:bg-gray-800 w-full"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>{t('about')}</span>
            </Link>
          </li>
          <li className="w-full">
            <Link
              to="/contact"
              className="flex items-center justify-center space-x-3 py-4 hover:bg-gray-100 dark:hover:bg-gray-800 w-full"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faContactCard} />
              <span>{t('contact')}</span>
            </Link>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default NavigationSM