// src/components/blog/BlogSection.js
import React from 'react';

/**
 * A styled section component for blog posts
 * Updated with better content containment for mobile
 */
const BlogSection = ({
  title,
  children,
  className = '',
  titleColor = 'primary', // Options: primary, dark, light
  noDivider = false
}) => {
  // Map color options to Tailwind classes
  const colorClasses = {
    primary: 'text-primary',
    dark: 'text-gray-900 dark:text-white',
    light: 'text-white dark:text-gray-100'
  };
  
  const titleClass = colorClasses[titleColor] || colorClasses.primary;
  
  return (
    <section className={`my-8 w-full overflow-visible blog-section ${className}`}>
      {title && (
        <div className="mb-4">
          <h2 className={`text-xl sm:text-xl font-bold mt-4 mb-1 break-words hyphens-auto leading-tight ${titleClass}`}>
            {title}
          </h2>
          {!noDivider && (
            <div className="w-full border-b border-gray-200 dark:border-gray-700 mb-4"></div>
          )}
        </div>
      )}
      <div className="mt-4 prose max-w-full dark:prose-invert">
        {children}
      </div>
    </section>
  );
};

export default BlogSection;