// src/data/blogPosts/nextcloud-ai.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading 
} from '../../components/blog';

/**
 * Blog post about Nextcloud AI
 */
const post = {
  id: '11895',
  slug: 'nextcloud-ai',
  
  // Base data - same regardless of language
  date: "2023-05-24T18:54:09.000Z",
  readTime: 8,
  categories: ["Anleitungen"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 20,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud AI",
      formattedDate: "24. Mai 2023",
      excerpt: "Künstliche Intelligenz ist ein weit verbreitetes Thema, das große Begeisterung auslöst. Vor allem die Generierung von Texten und Bildern wird viel genutzt. In diesem Blogbeitrag zeigen wir dir, wie du Nextcloud AI einrichten und verwenden kannst.",
      content: () => (
        <BlogContent>
          <p><strong>Künstliche Intelligenz</strong> ist ein weit verbreitetes Thema, das große Begeisterung auslöst. Vor allem die Generierung von Texten und Bildern wird viel genutzt.<br />In diesem Blogbeitrag zeigen wir dir, wie du <strong>Nextcloud AI</strong> einrichten und verwenden kannst.</p>
          
          <BlogHeading>Was ist überhaupt AI?</BlogHeading>
          
          <p>Hinter dem Begriff AI (Artificial Intelligence) oder zu deutsch KI (Künstliche Intelligenz) verbergen sich <strong>neuronale Netze</strong>, die aus <strong>Milliarden von Parametern</strong> bestehen. Im Hintergrund werden viele <strong>mathematische Berechnungen</strong> durchgeführt, um z.B. Texte und Bilder zu generieren. Für die Textgenerierung wird ein LLM (Large Language Model) verwendet. Für das Training dieser Modelle, werden große Datenmengen benötigt.</p>
          
          <BlogHeading>Nextcloud AI Einrichtung</BlogHeading>
          
          <p>Aktuell kann nur <strong>ChatGPT</strong> und <strong>DALL-E</strong> für die Text- und Bildgenerierung eingesetzt werden. Für die Einbindung wird ein <strong>API-Key benötigt.</strong> Du kannst du dafür bei OpenAI registrieren.</p>
          
          <p>Nachdem du dich dort angemeldet hast, klickst auf deinen Benutzernamen in der rechten oberen Ecke und wählst „View API keys" aus. Hier kann ein neuer Key generiert werden.</p>
          
          <p>In deiner Nextcloud installierst du noch die App „<strong>OpenAI integration</strong>".<br />Danach kannst du in den Einstellungen unter „Verbundene Konten" deinen API Key von OpenAI eingeben.</p>
          
          <BlogHeading>Verwendung von Nextcloud AI</BlogHeading>
          
          <p>Die AI-Funktionen können über den <strong>Nextcloud Smart Picker</strong> aufgerufen werden. Dazu muss nur ein <strong>Schrägstrich</strong> eingegeben werden und schon werden die AI-Funktionen wie <strong>ChatGPT</strong> und <strong>DALL-E</strong> angezeigt. Die Funktion kann unter anderem in <strong>Nextcloud Deck, Collective, Talk, Notes und in Textdateien</strong> verwendet werden. <br />Ein guter Anwendungsfall bietet sich in der App „Collective", da hier die KI beim Schreiben von Anleitungen unterstützen kann. Bei der Erstellung von Bildern ist noch zu beachten, dass diese nach einer gewissen Zeit wieder verschwinden. Du kannst die Bilder aber herunterladen und damit sichern.</p>
          
          <BlogHeading>Kritik an Künstliche Intelligenz</BlogHeading>
          
          <p>Das Thema künstliche Intelligenz hat nicht nur positive Seiten. Gerade in Bezug auf <strong>Datenschutz & Privatsphäre gibt es viele Bedenken</strong>. Es ist nicht nachvollziehbar, was genau mit den eingegebenen Anfragen passiert und wo diese Daten landen. Wir empfehlen dir diese Tools mit Vorsicht zu wenden und <strong>niemals persönliche Daten einzugeben.</strong></p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud AI",
      formattedDate: "24. Mai 2023",
      excerpt: "Künstliche Intelligenz ist ein weit verbreitetes Thema, das große Begeisterung auslöst. Vor allem die Generierung von Texten und Bildern wird viel genutzt. In diesem Blogbeitrag zeigen wir dir, wie du Nextcloud AI einrichten und verwenden kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;