import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";

/**
 * Tooltip component that displays a tooltip with the specified text when hovered over.
 *
 * @param {Object} props - The properties object.
 * @param {React.ReactNode} children - The child elements that trigger the tooltip on hover.
 * @param {string} text - The text to display inside the tooltip.
 * @param {string} className - Additional class names to apply to the tooltip.
 *
 * @returns {JSX.Element} The Tooltip component.
 */
const Tooltip = ({ children, text, className }) => {
  const tooltipRef = useRef(null);
  const [position, setPosition] = useState("left-full ml-2"); // Default position

  useEffect(() => {
    /**
     * Adjusts the position of the tooltip to ensure it does not exceed the viewport.
     * If the tooltip's right edge exceeds the window's inner width, it repositions the tooltip to the left.
     * Otherwise, it positions the tooltip to the right.
     */
    const adjustPosition = () => {
      const tooltip = tooltipRef.current;
      if (tooltip) {
        const { right } = tooltip.getBoundingClientRect();
        // Adjust position if tooltip exceeds viewport
        if (right > window.innerWidth) {
          setPosition("right-full mr-2"); // Reposition to the left
        } else {
          setPosition("left-full ml-2");
        }
      }
    };

    adjustPosition();
    window.addEventListener("resize", adjustPosition);
    return () => window.removeEventListener("resize", adjustPosition);
  }, []);

  return (
    <div className="relative group inline-block w-full">
      {children}
      <div
        ref={tooltipRef}
        className={`absolute top-1/2 transform -translate-y-1/2 hidden group-hover:flex 
          items-center w-52 h-auto z-50 ${position} ${className}`}
      >
        {/* Tooltip Arrow */}
        <div
          className={`w-3 h-3 bg-button-light dark:bg-button-dark absolute 
        top-1/2 -translate-y-1/2 rotate-45 z-40 ${
          position.includes("left") ? "-ml-1" : "-mr-1"
        }`}
        ></div>
        {/* Tooltip Content */}
        <div className="bg-button-light dark:bg-button-dark text-xs rounded py-1 px-2 shadow-lg z-50">
          {text}
        </div>
      </div>
    </div>
  );
};
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Tooltip;
