import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBuilding,
  faUser,
  faUniversity,
  faStar,
  faArrowRight,
  faPlus,
  faEnvelope,
  faCheck
} from '@fortawesome/free-solid-svg-icons';
import Container from '../components/container';
import Button from '../components/button';

const SectorSection = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [expandedCase, setExpandedCase] = useState(null);
  const { t } = useTranslation('home');
  
  // Get sectors from translation
  const sectorData = t('c4', { returnObjects: true });
  
  // Map sector icons
  const sectorIcons = [faBuilding, faUser, faUniversity];
  
  // Professional color palette - maintaining the existing colors
  const sectorColors = [
    'from-blue-600 to-blue-700',
    'from-teal-600 to-teal-700',
    'from-indigo-600 to-indigo-700'
  ];

  // Toggle expanded state for a use case
  const toggleExpand = (index) => {
    if (expandedCase === index) {
      setExpandedCase(null);
    } else {
      setExpandedCase(index);
    }
  };
  
  return (
    <Container className="py-12">
      <div className="w-full max-w-7xl mx-auto">
        <h2 className="text-2xl md:text-3xl font-bold text-center mb-4 text-white">
          {t('sectorTitle', 'Maßgeschneiderte Lösungen für jeden Sektor')}
        </h2>
        
        <p className="text-center text-gray-300 mb-10 max-w-3xl mx-auto">
          Wir verstehen die individuellen Anforderungen unterschiedlicher Bereiche und bieten passgenaue IT-Lösungen mit höchster Qualität.
        </p>
        
        {/* Tabs - Subtle improvements */}
        <div className="flex flex-col md:flex-row w-full md:space-x-5 space-y-5 md:space-y-0 mb-12">
          {sectorData.map((sector, index) => {
            // Determine if this tab is active
            const isActive = activeTab === index;
            
            return (
              <div 
                key={index} 
                className="w-full md:w-1/3 cursor-pointer transition-all duration-300"
                onClick={() => setActiveTab(index)}
              >
                <div 
                  className={`h-52 rounded-lg p-5 text-white bg-gradient-to-br ${sectorColors[index % sectorColors.length]} 
                    shadow-md hover:shadow-lg transition-all duration-300 relative overflow-hidden
                    ${isActive ? 'ring-2 ring-cyan-400/30' : 'opacity-90 hover:opacity-100'}`}
                >
                  {/* Subtle background pattern */}
                  {isActive && (
                    <>
                      <div className="absolute top-0 right-0 w-24 h-24 bg-white opacity-5 rounded-full -translate-y-1/2 translate-x-1/2"></div>
                      <div className="absolute bottom-0 left-0 w-32 h-32 bg-white opacity-5 rounded-full translate-y-1/2 -translate-x-1/2"></div>
                    </>
                  )}
                  
                  <div className="flex flex-col items-center text-center relative z-10">
                    <div className={`${isActive ? 'bg-white/30' : 'bg-white/20'} p-3 rounded-full mb-4 transition-all duration-300`}>
                      <FontAwesomeIcon icon={sectorIcons[index % sectorIcons.length]} className="text-xl" />
                    </div>
                    <h3 className="text-xl font-semibold mb-2">{sector.header}</h3>
                    <p className="text-sm text-white/90 leading-snug">{sector.sumary}</p>
                  </div>

                  {/* Subtle indicator for active tab */}
                  {isActive && (
                    <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-5 h-5 rotate-45 bg-gray-800"></div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        
        {/* Content for Active Tab */}
        <div className="bg-gray-800 rounded-lg p-6 md:p-8 shadow-md border border-gray-700/50">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
            {/* Left Column */}
            <div>
              <div className="flex items-center mb-6">
                <div className={`p-3 rounded-full mr-4 bg-gradient-to-br ${sectorColors[activeTab % sectorColors.length]} text-white`}>
                  <FontAwesomeIcon 
                    icon={sectorIcons[activeTab % sectorIcons.length]} 
                    className="text-lg" 
                  />
                </div>
                <h3 className="text-xl font-bold text-white">
                  {sectorData[activeTab].header}
                </h3>
              </div>
              
              <div className="mb-6 text-gray-300">
                <p className="text-base leading-relaxed">
                  {sectorData[activeTab].content.split('. ').slice(0, 3).join('. ') + '.'}
                </p>
              </div>
              
              {/* Feature tags */}
              <div className="flex flex-wrap gap-3 mb-6">
                {['Skalierbarkeit', 'Zuverlässigkeit', 'Sicherheit'].map((feature, i) => (
                  <span key={i} className="inline-flex items-center px-3 py-1 rounded-md bg-gray-700/70 text-sm text-white">
                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-cyan-400 text-xs" />
                    {feature}
                  </span>
                ))}
              </div>
              
              <Link 
                to="/contact"
                className={`
                  inline-flex items-center text-white text-base font-medium px-5 py-3 rounded-lg 
                  bg-gradient-to-br ${sectorColors[activeTab % sectorColors.length]}
                  hover:shadow-lg transition-all duration-300 group
                `}
              >
                <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
                {t('contactUs', 'Kontakt aufnehmen')}
                <FontAwesomeIcon icon={faArrowRight} className="ml-3 opacity-0 group-hover:opacity-100 transition-all duration-300" />
              </Link>
            </div>
            
            {/* Right Column - Focus Areas */}
            <div>
              <h3 className="text-xl font-bold mb-5 text-white flex items-center">
                <FontAwesomeIcon icon={faStar} className="mr-3 text-cyan-400 text-sm" />
                {t('useCasesTitle', 'Schwerpunkte')}
              </h3>
              <div className="space-y-4">
                {sectorData[activeTab].list.slice(0, 3).map((item, i) => (
                  <div 
                    key={i} 
                    className={`p-4 rounded-lg border border-gray-700 hover:border-gray-600
                      transition-all duration-300 ${expandedCase === i ? 'bg-gray-700/30' : ''}`}
                  >
                    <div 
                      className="flex justify-between items-center cursor-pointer" 
                      onClick={() => toggleExpand(i)}
                    >
                      <h4 className="text-base font-bold flex items-center text-white">
                        <span className="flex items-center justify-center w-6 h-6 rounded-full mr-3 bg-gray-700 text-cyan-400 text-sm">
                          {i + 1}
                        </span>
                        {item.header}
                      </h4>
                      <FontAwesomeIcon 
                        icon={faPlus} 
                        className={`text-xs transition-transform duration-300 text-gray-400 
                          ${expandedCase === i ? 'transform rotate-45' : ''}`} 
                      />
                    </div>
                    
                    {/* Expandable content */}
                    <div className={`overflow-hidden transition-all duration-300 
                      ${expandedCase === i ? 'max-h-40 mt-3 opacity-100' : 'max-h-0 opacity-0'}`}
                    >
                      <p className="text-sm text-gray-300 pl-9">{item.body}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default SectorSection;