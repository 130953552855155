// src/data/blogPosts/cryptomator.js

// Import images
import thumbnailImage from '../../assets/images/blog/cryptomator-featured.png';
import cryptomatorLogoText from '../../assets/images/blog/2022/09/cryptomator-logo-text.png';
import cryptomatorLogo from '../../assets/images/blog/2022/09/cryptomator-logo.png';
import lockIcon from '../../assets/images/blog/2022/01/lock.png';
import keyIcon from '../../assets/images/blog/2022/01/key.png';
import moneyIcon from '../../assets/images/blog/2021/10/geld.png';
import processIcon from '../../assets/images/blog/2022/09/iconmonstr-process-1-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Cryptomator
 */
const post = {
  id: '8842',
  slug: 'cryptomator',
  
  // Base data - same regardless of language
  date: "2022-09-02T06:56:08.000Z",
  readTime: 9,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 13,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Cryptomator",
      formattedDate: "2. September 2022",
      excerpt: "Cryptomator für Ende-zu-Ende-Verschlüsselung. In diesem Blogartikel erklären wir dir um was sich bei der Software \"Cryptomator\" handelt, welche Vorteile das mit sich bringt und erklären dir kurz die Ende-zu-Ende-Verschlüsselung.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Cryptomator für Ende-zu-Ende-Verschlüsselung</h1>
          
          <p>In diesem Blogartikel erklären wir dir um was sich bei der Software "Cryptomator" handelt, welche Vorteile das mit sich bringt und erklären dir kurz die Ende-zu-Ende-Verschlüsselung.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={cryptomatorLogoText} alt="Cryptomator Logo mit Text" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Was ist Cryptomator?</BlogHeading>
          
          <p>Cryptomator ist eine Software die dir hilft deine Daten eigenständig und unabhängig zu schützen indem die Daten verschlüsselt werden.</p>
          <p>Dadurch kann außer dir niemand auf die Daten zugreifen. Wenn Cryptomator gestartet wird, erstelltst du einen sogenannten Tresor indem du ein Passwort eingibst und einen Ordner auswählst der verschlüsselt werden soll. Möchtest du auf die verschlüsselten Daten zugreifen wählst du einfach den erstellen Tresor aus, gibst dein Passwort ein und schon hast du wieder Zugriff.</p>
          <p>Cryptomator verschlüsselt sowohl die Dateien als auch die Dateinamen mit AES und 256-Bit-Schlüssellänge. <br />Wie du es bereits von PathConnect gewohnt bist, ist auch diese Software <a href="https://pathconnect.de/blog/open-source-software/" target="_blank" rel="noopener">Open Source</a>. Zu diesem Thema gibt es einen extra Beitrag auf unserem Blog.</p>
          
          <BlogImage src={cryptomatorLogo} alt="Cryptomator Logo" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Ende-zu-Ende Verschlüsselung</BlogHeading>
          
          <p>Um den Sinn dieser Anwendung nahezubringen müssen wir kurz klären was es mit der Ende-zu-Ende Verschlüsselung auf sich hat. Bei dieser Verschlüsselung sind die Daten sowohl auf dem Computer als auch auf der Cloud verschlüsselt. Das hat den Vorteil das weder ein Dritter noch der Cloudbetreiber Zugriff auf eure Daten haben kann. Sie sind also auf beiden Enden verschlüsselt und ohne das Passwort nicht zu lesen.</p>
          
          <BlogImage src={lockIcon} alt="Schloss Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Deshalb solltest du Cryptomator nutzen</BlogHeading>
          
          <p>Beschäftigt man sich mit dem Thema Datensicherheit kommt man um das Thema Verschlüsselung nicht herum. Es ist der optimale Schutz vor ungewollten Zugriff von Dritten. <br />Standardmäßig werden die Daten zwar zwischen Computer und Cloud verschlüsselt übertragen die eigentlichen Daten sind aber nicht verschlüsselt.<br />Durch Cryptomator werden die Daten Ende-zu-Ende verschlüsselt und können dann nur nach Eingaben eines Passworts eingesehen werden.</p>
          
          <BlogImage src={keyIcon} alt="Schlüssel Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Nachteile der Verschlüsselung</BlogHeading>
          
          <p>Da die Daten nur noch verschlüsselt auf der Cloud liegen kann man auf diese auch nicht mehr zugreifen, es können also auch keine Dateien geteilt werden die im Tresor liegen. Cryptomator lässt sich aber auf allen Endgeräte installieren und so können nach Eingabe des Passworts in Cryptomator wieder alle Dateien gelesen werden.</p>
          
          <BlogHeading level={3}>Kostet Cryptomator etwas?</BlogHeading>
          
          <p>Die Software ist Open Source, für Windows, macOS und Linux kostenlos.<br /><a href="https://cryptomator.org/de/downloads/" target="_blank" rel="noopener">https://cryptomator.org/de/downloads/</a><br />Die App für Android und iOS kosten eine kleine Gebühr. <br />Wenn einem die Software gefällt kann man auch eine Spende an die Entwickler geben:<br /><a href="https://cryptomator.org/de/donate/" target="_blank" rel="noopener">https://cryptomator.org/de/donate/</a></p>
          
          <BlogImage src={moneyIcon} alt="Geld Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Einrichtung</BlogHeading>
          
          <p>Die Einrichtung der Software ist nicht schwer und für jeden leicht verständlich gemacht. <br />In den kommenden Wochen werden wir dazu auch passende Anleitungen zur Verfügung stellen.</p>
          
          <BlogImage src={processIcon} alt="Prozess Icon" size="small" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Cryptomator",
      formattedDate: "2. September 2022",
      excerpt: "Cryptomator für Ende-zu-Ende-Verschlüsselung. In diesem Blogartikel erklären wir dir um was sich bei der Software \"Cryptomator\" handelt, welche Vorteile das mit sich bringt und erklären dir kurz die Ende-zu-Ende-Verschlüsselung.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Cryptomator",
      formattedDate: "September 2, 2022",
      excerpt: "Cryptomator for End-to-End Encryption. In this blog post, we explain what the 'Cryptomator' software is about, what advantages it offers, and briefly explain end-to-end encryption.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Cryptomator for End-to-End Encryption</h1>
          
          <p>In this blog post, we explain what the 'Cryptomator' software is about, what advantages it offers, and briefly explain end-to-end encryption.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Cryptomator",
      formattedDate: "2 September 2022",
      excerpt: "Cryptomator for End-to-End Encryption. In this blog post, we explain what the 'Cryptomator' software is about, what advantages it offers, and briefly explain end-to-end encryption.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;