// src/data/blogPosts/openproject-2.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about OpenProject
 */
const post = {
  id: '13993',
  slug: 'openproject-2',
  
  // Base data - same regardless of language
  date: "2023-12-01T16:58:45.000Z",
  readTime: 11,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: "../images/blog/openproject-2-featured.png",
  thumbnailUrl: "../images/blog/openproject-2-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 18,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "OpenProject",
      formattedDate: "1. Dezember 2023",
      excerpt: "Mit der Open Source Projektmanagement-Software können Unternehmen und Organisationen ihre Projekte planen, durchführen und steuern. In diesem Artikel stellen wir OpenProject kurz vor und gehen auf die wichtigsten Funktionen ein.",
      content: () => (
        <BlogContent>
          <p>Mit der Open Source Projektmanagement-Software können Unternehmen und Organisationen ihre Projekte planen, durchführen und steuern. In diesem Artikel stellen wir OpenProject kurz vor und gehen auf die wichtigsten Funktionen ein.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2023/11/openproject-logo-centered-color-86c2838b.png" 
              alt="OpenProject Logo" 
              size="medium" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Projektmanagement</BlogHeading>
          
          <p>Ein Projekt ist ein einmaliger Prozess mit einem festen Zeit- und Budgetrahmen. Um das Ziel zu erreichen, müssen Projekte sorgfältig geplant und gesteuert werden. Hierfür gibt es Projektmanagementsoftware wie OpenProject, mit der z.B. ein Zeitplan mit Arbeitspaketen, Meilensteinen und Aufgabenzuweisungen grafisch erstellt werden kann. Durch die gemeinsame Nutzung ist jedes Teammitglied über den aktuellen Stand informiert und kann schnell auf Probleme reagieren.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2023/12/iconmonstr-task-list-lined-240.png" 
              alt="Task Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading>Funktionen</BlogHeading>
          
          <p>OpenProject bietet eine Vielzahl von Funktionen, die in zukünftigen Artikeln detailliert beschrieben werden. Im Folgenden werden die wichtigsten Funktionen kurz erläutert.</p>
          
          <ul className="space-y-4 list-disc pl-6 my-4">
            <li>
              <p><u>Projektportfoliomanagement</u><br />
              Projektlisten und Projekthierarchien ermöglichen ein schnelles Auffinden und Strukturieren der laufenden Projekte. Über ein Dashboard können alle wichtigen Kennzahlen zu den einzelnen Projekten angezeigt werden. Projektvorlagen können erstellt werden und eine projektübergreifende Sicht ermöglicht einen Überblick über alle Projekte.</p>
            </li>
            
            <li>
              <p><u>Projektplanung</u><br />
              Mit einem Gantt-Diagramm kann ein Projektplan visualisiert werden. So kann leicht erkannt werden, welche Arbeitspakete und Meilensteine das Team bis wann liefern wird. Mit dem automatischen Planungsmodus kann ein genauer Zeitplan auf der Grundlage der Arbeitspakete erstellt und direkt im Kalender mit Terminerinnerungen überwacht werden.</p>
            </li>
            
            <li>
              <p><u>Aufgabenverwaltung</u><br />
              Dynamische Aufgabenlisten bieten einen guten Überblick über alle Aufgaben. Sie können gefiltert, sortiert und editiert werden. Durch die Zuweisung von Mitgliedern oder Gruppen als Bearbeiter, Verantwortlicher oder Beobachter können Arbeitspakete eindeutig verteilt werden. Aufgaben können mit Kommentaren versehen werden und durch die Nextcloud-Integration können Dateien mit einzelnen Arbeitspaketen verknüpft werden.</p>
            </li>
            
            <li>
              <p><u>Agile, Kanban & Scrum</u><br />
              Agile Arbeitsmethoden haben kurze Entwicklungszyklen, um schnell auf Feedback zu reagieren und die richtigen Dinge zu bauen. Verschiedene Boards wie Kanban, Versionsboards, Taskboards und Teamboards ermöglichen es Teams, ihren agilen Ansatz richtig umzusetzen.</p>
            </li>
            
            <li>
              <p><u>Zeit- und Kostenerfassung</u><br />
              Durch die Erfassung von Zeiten und Kosten können Budgetausgaben überprüft und kontrolliert werden. In OpenProject können Zeiten auf einzelne Arbeitspakete gebucht und die entsprechenden Personalkosten berechnet werden. Durch Zeit- und Kostenberichte können die Ausgaben genau nachvollzogen werden.</p>
            </li>
            
            <li>
              <p><u>Team Kollaboration</u><br />
              Es können Meetings geplant, Benachrichtigungen zu Kommentaren versendet und Aktivitäten zu den jeweiligen Projekten angezeigt werden. Zusätzlich gibt es ein Wiki zur Dokumentation und ein Forum zur Diskussion.</p>
            </li>
            
            <li>
              <p><u>Produktentwicklung und Release-Planung</u><br />
              Eine Roadmap gibt einen Überblick über den Fortschritt eines Produktes und durch die Einbindung des eigenen GIT-Repositories kann direkt auf den Quellcode zugegriffen werden.</p>
            </li>
          </ul>
          
          <BlogGallery 
            images={[
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-20-49.png",
                alt: "OpenProject Hauptseite"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-24-00.png",
                alt: "OpenProject Incident Übersicht"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-24-18.png",
                alt: "OpenProject Neues Projekt, Aufgabe, Meilenstein oder Phase hinzufügen"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-24-37.png",
                alt: "OpenProject Neues Projekt anlegen"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-25-10.png",
                alt: "OpenProject Neue Aufgabe"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-25-43.png",
                alt: "OpenProject Gantt Diagramm"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-26-59.png",
                alt: "OpenProject Kanban Board"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-27-39.png",
                alt: "OpenProject WIKI"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-28-07.png",
                alt: "OpenProject Wiki Eintrag"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-28-25.png",
                alt: "OpenProject Neue Kostenauswertung"
              }
            ]}
            columns={4}
            gap={10}
          />
          
          <BlogHeading>OpenProject bei PathConnect</BlogHeading>
          
          <p>Als weitere Managed Hosting Lösung bieten wir <a href="/openproject/" target="_blank" rel="noopener">OpenProject</a> sowohl in der Community als auch in der Enterprise Version an. Du erhältst eine OpenProject-Instanz auf deinem eigenen virtuellen Server, die von uns gewartet und gepflegt wird. Neben dem persönlichen Support übernehmen wir auch spezielle Konfigurationen wie z.B. die Anbindung an deine Nextcloud.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "OpenProject",
      formattedDate: "1. Dezember 2023",
      excerpt: "Mit der Open Source Projektmanagement-Software können Unternehmen und Organisationen ihre Projekte planen, durchführen und steuern. In diesem Artikel stellen wir OpenProject kurz vor und gehen auf die wichtigsten Funktionen ein.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "OpenProject",
      formattedDate: "December 1, 2023",
      excerpt: "With this open source project management software, companies and organizations can plan, implement, and control their projects. In this article, we briefly introduce OpenProject and go over the most important features.",
      content: () => (
        <BlogContent>
          <p>With this open source project management software, companies and organizations can plan, implement, and control their projects. In this article, we briefly introduce OpenProject and go over the most important features.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2023/11/openproject-logo-centered-color-86c2838b.png" 
              alt="OpenProject Logo" 
              size="medium" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Project Management</BlogHeading>
          
          <p>A project is a one-time process with a fixed time and budget framework. To achieve the goal, projects must be carefully planned and controlled. Project management software like OpenProject can be used to create a graphical schedule with work packages, milestones, and task assignments. Through collaborative use, each team member is informed about the current status and can react quickly to problems.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2023/12/iconmonstr-task-list-lined-240.png" 
              alt="Task Icon" 
              size="small" 
            />
          </div>
          
          <BlogHeading>Features</BlogHeading>
          
          <p>OpenProject offers a variety of features that will be described in detail in future articles. The most important features are briefly outlined below.</p>
          
          <ul className="space-y-4 list-disc pl-6 my-4">
            <li>
              <p><u>Project Portfolio Management</u><br />
              Project lists and project hierarchies enable quick finding and structuring of ongoing projects. A dashboard can display all important metrics for the individual projects. Project templates can be created, and a cross-project view provides an overview of all projects.</p>
            </li>
            
            <li>
              <p><u>Project Planning</u><br />
              A Gantt chart can visualize a project plan. This makes it easy to see which work packages and milestones the team will deliver by when. With the automatic planning mode, an exact schedule can be created based on the work packages and monitored directly in the calendar with appointment reminders.</p>
            </li>
            
            <li>
              <p><u>Task Management</u><br />
              Dynamic task lists provide a good overview of all tasks. They can be filtered, sorted, and edited. By assigning members or groups as editors, responsibles, or observers, work packages can be clearly distributed. Tasks can be commented on, and through Nextcloud integration, files can be linked to individual work packages.</p>
            </li>
            
            <li>
              <p><u>Agile, Kanban & Scrum</u><br />
              Agile working methods have short development cycles to respond quickly to feedback and build the right things. Various boards such as Kanban, version boards, task boards, and team boards enable teams to implement their agile approach correctly.</p>
            </li>
            
            <li>
              <p><u>Time and Cost Tracking</u><br />
              By recording times and costs, budget expenditures can be reviewed and controlled. In OpenProject, times can be booked to individual work packages and the corresponding personnel costs can be calculated. Through time and cost reports, expenditures can be tracked precisely.</p>
            </li>
            
            <li>
              <p><u>Team Collaboration</u><br />
              Meetings can be planned, notifications about comments can be sent, and activities for the respective projects can be displayed. Additionally, there is a wiki for documentation and a forum for discussion.</p>
            </li>
            
            <li>
              <p><u>Product Development and Release Planning</u><br />
              A roadmap provides an overview of a product's progress, and by integrating your own GIT repository, you can access the source code directly.</p>
            </li>
          </ul>
          
          <BlogGallery 
            images={[
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-20-49.png",
                alt: "OpenProject Main Page"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-24-00.png",
                alt: "OpenProject Incident Overview"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-24-18.png",
                alt: "OpenProject Add New Project, Task, Milestone or Phase"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-24-37.png",
                alt: "OpenProject Create New Project"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-25-10.png",
                alt: "OpenProject New Task"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-25-43.png",
                alt: "OpenProject Gantt Chart"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-26-59.png",
                alt: "OpenProject Kanban Board"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-27-39.png",
                alt: "OpenProject WIKI"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-28-07.png",
                alt: "OpenProject Wiki Entry"
              },
              {
                src: "../images/blog/2023/11/Bildschirmfoto-vom-2023-11-25-17-28-25.png",
                alt: "OpenProject New Cost Evaluation"
              }
            ]}
            columns={4}
            gap={10}
          />
          
          <BlogHeading>OpenProject at PathConnect</BlogHeading>
          
          <p>As another managed hosting solution, we offer <a href="/openproject/" target="_blank" rel="noopener">OpenProject</a> in both the Community and Enterprise versions. You receive an OpenProject instance on your own virtual server, which we maintain and care for. In addition to personal support, we also handle special configurations such as connecting to your Nextcloud.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "OpenProject",
      formattedDate: "1 December 2023",
      excerpt: "With this open source project management software, companies and organisations can plan, implement, and control their projects. In this article, we briefly introduce OpenProject and go over the most important features.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;