import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import i18n from '../i18n'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPaperPlane,
  faEnvelope,
  faPhone,
  faInfoCircle,
  faShield
} from '@fortawesome/free-solid-svg-icons'
import CustomModal from './modal'
import Privacy from '../pages/privacy'

const ContactForm = () => {
  const { t } = useTranslation('contact')
  // eslint-disable-next-line
  const API_ENDPOINT = process.env.REACT_APP_MAIL_API_ENDPOINT
  const [formData, setFormData] = useState({
    foa: '',
    firstname: '',
    lastname: '',
    mail: '',
    phone: '',
    sub: '',
    body: '',
    lang: '',
    privacy: false
  })
  const [dataProtectionModalOpen, setDataProtectionModalOpen] = useState(false)
  const [submitStatus, setSubmitStatus] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const toggleDataProtectionModal = () => {
    setDataProtectionModalOpen(!dataProtectionModalOpen)
  }

  useEffect(() => {
    const lang = i18n.language
    handleChange({ target: { name: 'lang', value: lang } })
    // eslint-disable-next-line
  }, [i18n.language])

  const handleChange = e => {
    const { name, value } = e.target
    setFormData({
      ...formData,
      [name]: value
    })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitStatus('sending')
    
    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      })
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const result = await response.json()
      console.log('Mail sent successfully:', result)
      setSubmitStatus('success')
      setFormSubmitted(true)
      setTimeout(() => setSubmitStatus(null), 5000)
    } catch (error) {
      console.error('Error sending mail:', error)
      setSubmitStatus('error')
      setTimeout(() => setSubmitStatus(null), 5000)
    }
  }

  // Success screen after form submission
  if (formSubmitted && submitStatus === 'success') {
    return (
      <div className="max-w-3xl mx-auto text-center">
        <div className="bg-gradient-to-r from-cyan-500 to-cyan-600 w-16 h-16 rounded-full mx-auto flex items-center justify-center mb-6">
          <FontAwesomeIcon icon={faEnvelope} className="text-white text-xl" />
        </div>
        <h2 className="text-2xl font-bold text-white mb-3">
          {t('success-title', 'Nachricht erfolgreich gesendet!')}
        </h2>
        <p className="text-gray-300 mb-6 text-base">
          {t('success-message', 'Vielen Dank für Ihre Nachricht. Wir werden uns so schnell wie möglich bei Ihnen melden.')}
        </p>
        <button 
          onClick={() => setFormSubmitted(false)}
          className="bg-gradient-to-r from-cyan-600 to-cyan-500 text-white py-2 px-6 rounded-md hover:from-cyan-500 hover:to-cyan-400 transition-all duration-300 shadow-md text-sm"
        >
          {t('send-another', 'Weitere Nachricht senden')}
        </button>
      </div>
    )
  }

  return (
    <div className="max-w-3xl mx-auto">
      {/* Contact information cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-6">
        <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700 hover:border-cyan-500 transition-all duration-300 text-center">
          <div className="w-10 h-10 bg-cyan-500/20 rounded-full flex items-center justify-center mx-auto mb-3">
            <FontAwesomeIcon icon={faEnvelope} className="text-cyan-400 text-base" />
          </div>
          <h3 className="text-white text-sm font-bold mb-1">E-Mail</h3>
          <a href="mailto:info@pathconnect.de" className="text-cyan-400 hover:underline text-sm">info@pathconnect.de</a>
        </div>
        
        <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700 hover:border-cyan-500 transition-all duration-300 text-center">
          <div className="w-10 h-10 bg-cyan-500/20 rounded-full flex items-center justify-center mx-auto mb-3">
            <FontAwesomeIcon icon={faPhone} className="text-cyan-400 text-base" />
          </div>
          <h3 className="text-white text-sm font-bold mb-1">Telefon</h3>
          <a href="tel:+4915154184909" className="text-cyan-400 hover:underline text-sm">+49 1515 4184909</a>
        </div>
      </div>

      <CustomModal
        isOpen={dataProtectionModalOpen}
        onRequestClose={toggleDataProtectionModal}
      >
        <Privacy />
      </CustomModal>
      
      {/* Main form box with enhanced styling */}
      <div className="bg-gray-800/40 backdrop-blur-sm rounded-lg border border-gray-700 p-5 md:p-6 shadow-xl">
        {/* Form header */}
        <div className="border-b border-gray-700 pb-4 mb-5">
          <h2 className="text-xl font-bold text-white">
            {t('form-title', 'Nachricht senden')}
          </h2>
          <p className="text-gray-400 mt-1 text-sm">
            {t('form-subtitle', 'Füllen Sie das Formular aus, und wir werden uns schnellstmöglich bei Ihnen melden.')}
          </p>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4">

          {/* Two column layout for personal info */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {/* Form of Address */}
            <div>
              <label className="flex items-center text-gray-300 mb-1 text-xs">
                {t('common:form-of-address')} 
                <span className="text-red-500 ml-1">*</span>
              </label>
              <select
                name="foa"
                value={formData.foa}
                onChange={handleChange}
                className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500/50 shadow-inner text-sm"
                required
              >
                <option value="" disabled>
                  {t('common:form-of-address')}
                </option>
                {t('common:forms-of-addresses', { returnObjects: true }).map(
                  (foaOption, index) => (
                    <option key={index} value={foaOption} className="bg-gray-900">
                      {foaOption}
                    </option>
                  )
                )}
              </select>
            </div>

            {/* Email */}
            <div>
              <label className="flex items-center text-gray-300 mb-1 text-xs">
                {t('common:mail')}
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="email"
                name="mail"
                value={formData.mail}
                onChange={handleChange}
                className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500/50 shadow-inner text-sm"
                required
                placeholder="ihre-email@beispiel.de"
              />
            </div>
          </div>

          {/* Two column layout for name */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            {/* First Name */}
            <div>
              <label className="flex items-center text-gray-300 mb-1 text-xs">
                {t('common:firstname')}
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="firstname"
                value={formData.firstname}
                onChange={handleChange}
                className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500/50 shadow-inner text-sm"
                required
                placeholder="Vorname"
              />
            </div>
          
            {/* Last Name */}
            <div>
              <label className="flex items-center text-gray-300 mb-1 text-xs">
                {t('common:lastname')}
                <span className="text-red-500 ml-1">*</span>
              </label>
              <input
                type="text"
                name="lastname"
                value={formData.lastname}
                onChange={handleChange}
                className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500/50 shadow-inner text-sm"
                required
                placeholder="Nachname"
              />
            </div>
          </div>

          {/* Phone in its own row */}
          <div className="mb-4">
            <label className="flex items-center text-gray-300 mb-1 text-xs">
              {t('common:phone')}
            </label>
            <PhoneInput
              country={'de'}
              value={formData.phone}
              onChange={phone => setFormData({ ...formData, phone })}
              preferredCountries={['de', 'fr', 'nl']}
              regions={'europe'}
              inputClass="w-full bg-gray-900/50 text-gray-200 text-sm"
              containerClass="phone-container"
              buttonClass="phone-dropdown-button"
              dropdownClass="phone-dropdown"
            />
          </div>

          {/* Subject */}
          <div className="mb-4">
            <label className="flex items-center text-gray-300 mb-1 text-xs">
              {t('common:subject')}
              <span className="text-red-500 ml-1">*</span>
            </label>
            <input
              type="text"
              name="sub"
              value={formData.sub}
              onChange={handleChange}
              className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500/50 shadow-inner text-sm"
              required
              placeholder="Betreff Ihrer Nachricht"
            />
          </div>

          {/* Message */}
          <div className="mb-4">
            <label className="flex items-center text-gray-300 mb-1 text-xs">
              {t('common:message')}
              <span className="text-red-500 ml-1">*</span>
            </label>
            <textarea
              name="body"
              value={formData.body}
              onChange={handleChange}
              className="w-full p-2 bg-gray-900/50 border border-gray-700 rounded-md text-gray-200 focus:border-cyan-500 focus:outline-none focus:ring-1 focus:ring-cyan-500/50 shadow-inner text-sm"
              rows="4"
              required
              placeholder="Ihre Nachricht an uns..."
            />
          </div>

          {/* Privacy Agreement with enhanced styling */}
          <div className="p-3 rounded-md bg-gray-900/30 border border-gray-700 mb-4">
            <div className="flex items-start">
              <div className="flex items-center h-5">
                <input
                  type="checkbox"
                  name="dataProtection"
                  id="dataProtection"
                  checked={formData.privacy || false}
                  onChange={e =>
                    setFormData({ ...formData, privacy: e.target.checked })
                  }
                  className="w-4 h-4 accent-cyan-500 cursor-pointer focus:ring-1 focus:ring-cyan-500/50"
                  required
                />
              </div>
              <div className="ml-3">
                <label htmlFor="dataProtection" className="text-gray-300 text-xs flex items-start">
                  <FontAwesomeIcon icon={faShield} className="text-cyan-400 mr-2 mt-1 text-xs" />
                  <div>
                    {t('privacy-agreement', 'Ich habe die Datenschutzerklärung gelesen und stimme zu.')}
                    <span className="text-red-500 ml-1">*</span>
                    <span 
                      className="block text-cyan-400 cursor-pointer hover:underline mt-1"
                      onClick={toggleDataProtectionModal}
                    >
                      {t('common:show-privacy-agreement', 'Datenschutzerklärung anzeigen')}
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>

          {/* Information note */}
          <div className="flex p-3 rounded-md bg-cyan-900/20 border border-cyan-700/30 mb-4">
            <FontAwesomeIcon icon={faInfoCircle} className="text-cyan-400 mr-2 mt-1 text-xs" />
            <p className="text-xs text-gray-300">
              {t('info-note', 'Wir verwenden Ihre Daten ausschließlich zur Bearbeitung Ihrer Anfrage und geben sie nicht an Dritte weiter.')}
            </p>
          </div>

          {/* Form Footer */}
          <div className="flex flex-col md:flex-row justify-between items-center pt-3 border-t border-gray-700">
            <span className="text-xs text-gray-400 mb-3 md:mb-0">
              {t('common:required-fields', 'Felder mit rotem Sternchen (*) sind Pflichtfelder')}
            </span>

            <button
              type="submit"
              disabled={!formData.privacy || submitStatus === 'sending'}
              className={`flex items-center justify-center px-5 py-2 rounded-md transition-all duration-300 w-full md:w-auto ${
                formData.privacy
                  ? 'bg-gradient-to-r from-cyan-600 to-cyan-500 text-white hover:from-cyan-500 hover:to-cyan-400 shadow-md'
                  : 'bg-gray-700 text-gray-400 cursor-not-allowed'
              }`}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="mr-2 text-sm" />
              <span className="text-sm">
                {submitStatus === 'sending' 
                  ? t('common:sending', 'Wird gesendet...') 
                  : t('common:send', 'Senden')
                }
              </span>
            </button>
          </div>
          
          {/* Success/Error Message */}
          {submitStatus && submitStatus !== 'sending' && (
            <div className={`mt-4 p-3 rounded-md flex items-center text-sm ${
              submitStatus === 'success' 
                ? 'bg-green-900/30 border border-green-500 text-green-400' 
                : 'bg-red-900/30 border border-red-500 text-red-400'
            }`}>
              <FontAwesomeIcon 
                icon={submitStatus === 'success' ? faPaperPlane : faInfoCircle} 
                className="mr-2 text-sm"
              />
              <div>
                <p className="font-bold text-xs">
                  {submitStatus === 'success' 
                    ? t('success-heading', 'Nachricht gesendet!')
                    : t('error-heading', 'Fehler')
                  }
                </p>
                <p className="text-xs">
                  {submitStatus === 'success' 
                    ? t('common:success-message', 'Ihre Nachricht wurde erfolgreich gesendet!')
                    : t('common:error-message', 'Beim Senden Ihrer Nachricht ist ein Fehler aufgetreten.')
                  }
                </p>
              </div>
            </div>
          )}
        </form>
      </div>
      
      {/* Custom styles for Phone Input */}
      <style jsx global>{`
        .phone-container {
          width: 100%;
          background-color: rgba(17, 24, 39, 0.5);
          border-radius: 0.5rem;
          overflow: hidden;
          border: 1px solid rgb(55, 65, 81);
          box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        }
        .phone-dropdown-button {
          background-color: rgba(17, 24, 39, 0.5) !important;
          border: none !important;
          border-right: 1px solid rgb(55, 65, 81) !important;
        }
        .phone-dropdown {
          background-color: rgb(17, 24, 39) !important;
          color: rgb(229, 231, 235) !important;
        }
        .react-tel-input .form-control {
          background-color: rgba(17, 24, 39, 0) !important;
          color: rgb(229, 231, 235) !important;
          border: none !important;
          width: 100% !important;
          height: 2.5rem !important;
          font-size: 0.875rem !important;
        }
        .flag-dropdown.open .selected-flag {
          background-color: rgba(17, 24, 39, 0.5) !important;
        }
        .flag-dropdown .selected-flag:hover, 
        .flag-dropdown .selected-flag:focus {
          background-color: rgba(17, 24, 39, 0.7) !important;
        }
        .country-list {
          background-color: rgb(17, 24, 39) !important;
          color: rgb(229, 231, 235) !important;
          border: 1px solid rgb(55, 65, 81) !important;
        }
        .country:hover {
          background-color: rgb(55, 65, 81) !important;
        }
        .country.highlight {
          background-color: rgb(14, 165, 233, 0.2) !important;
        }
      `}</style>
    </div>
  )
}

export default ContactForm