import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import HttpApi from 'i18next-http-backend'

// Import translation files
import common_dede from './locales/de/common.json'
import title_dede from './locales/de/title.json'
import templates_dede from './locales/de/templates.json'
import navigation_dede from './locales/de/navigation.json'
import home_dede from './locales/de/home.json'
import about_dede from './locales/de/about.json'
import contact_dede from './locales/de/contact.json'
import impressum_dede from './locales/de/impressum.json'
import agb_dede from './locales/de/agb.json'
import privacy_dede from './locales/de/privacy'
import footer_dede from './locales/de/footer.json'
import blog_dede from './locales/de/blog.json'
//
import common_engb from './locales/en/common.json'
import title_engb from './locales/en/title.json'
import templates_engb from './locales/en/templates.json'
import navigation_engb from './locales/en/navigation.json'
import home_engb from './locales/en/home.json'
import about_engb from './locales/en/about.json'
import contact_engb from './locales/en/contact.json'
import impressum_engb from './locales/en/impressum.json'
import agb_engb from './locales/en/agb.json'
import footer_engb from './locales/en/footer.json'
import blog_engb from './locales/en/blog.json'
//
import common_frfr from './locales/fr/common.json'
import title_frfr from './locales/fr/title.json'
import templates_frfr from './locales/fr/templates.json'
import navigation_frfr from './locales/fr/navigation.json'
import home_frfr from './locales/fr/home.json'
import about_frfr from './locales/fr/about.json'
import contact_frfr from './locales/fr/contact.json'
import impressum_frfr from './locales/fr/impressum.json'
import agb_frfr from './locales/fr/agb.json'
import footer_frfr from './locales/fr/footer.json'
import blog_frfr from './locales/fr/blog.json'
//
import common_nlnl from './locales/nl/common.json'
import title_nlnl from './locales/nl/title.json'
import templates_nlnl from './locales/nl/templates.json'
import navigation_nlnl from './locales/nl/navigation.json'
import home_nlnl from './locales/nl/home.json'
import about_nlnl from './locales/nl/about.json'
import contact_nlnl from './locales/nl/contact.json'
import impressum_nlnl from './locales/nl/impressum.json'
import agb_nlnl from './locales/nl/agb.json'
import footer_nlnl from './locales/nl/footer.json'
import blog_nlnl from './locales/nl/blog.json'

// Get the saved language from localStorage or fall back to default language
const savedLanguage = localStorage.getItem('language')

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi) // Allows loading translations from a backend or files
  .init({
    resources: {
      de_DE: {
        common: common_dede,
        title: title_dede,
        templates: templates_dede,
        home: home_dede,
        about: about_dede,
        contact: contact_dede,
        impressum: impressum_dede,
        agb: agb_dede,
        privacy: privacy_dede,
        footer: footer_dede,
        navigation: navigation_dede,
        blog: blog_dede
      },
      en_GB: {
        common: common_engb,
        title: title_engb,
        templates: templates_engb,
        home: home_engb,
        about: about_engb,
        contact: contact_engb,
        impressum: impressum_engb,
        agb: agb_engb,
        footer: footer_engb,
        navigation: navigation_engb,
        blog: blog_engb
      },
      fr_FR: {
        common: common_frfr,
        title: title_frfr,
        templates: templates_frfr,
        home: home_frfr,
        about: about_frfr,
        contact: contact_frfr,
        impressum: impressum_frfr,
        agb: agb_frfr,
        footer: footer_frfr,
        navigation: navigation_frfr,
        blog: blog_frfr
      },
      nl_NL: {
        common: common_nlnl,
        title: title_nlnl,
        templates: templates_nlnl,
        home: home_nlnl,
        about: about_nlnl,
        contact: contact_nlnl,
        impressum: impressum_nlnl,
        agb: agb_nlnl,
        footer: footer_nlnl,
        navigation: navigation_nlnl,
        blog: blog_nlnl
      }
    },
    ns: [
      'common',
      'title',
      'templates',
      'home',
      'about',
      'contact',
      'impressum',
      'agb',
      'privacy',
      'footer',
      'navigation',
      'blog'
    ],
    defaultNS: 'common',
    lng: savedLanguage || 'de_DE', // Set the initial language to the saved language or de if not available
    fallbackLng: 'de_DE', // Fallback language if the saved language is not available, using keys defined in resources
    interpolation: {
      escapeValue: false
    },
    returnNull: false
  })

// List of available languages and their flags
export const languages = [
  { code: 'en_GB', flag: '🇬🇧', name: 'English' },
  { code: 'de_DE', flag: '🇩🇪', name: 'Deutsch' },
  { code: 'fr_FR', flag: '🇫🇷', name: 'Français' },
  { code: 'nl_NL', flag: '🇳🇱', name: 'Nederlands' }
]

export default i18n
