// src/data/blogPosts/nextcloud-recognize.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-recognize-featured.png';
import recognizeInstallImage from '../../assets/images/blog/2024/06/Recognize-Herunterladen-und-Aktivieren.png';
import statusImage from '../../assets/images/blog/2024/06/Status.png';
import renamePersonImage from '../../assets/images/blog/2024/06/Person-umbennen.png';
import infoImage from '../../assets/images/blog/2024/06/Info.png';
import categoryManagementImage from '../../assets/images/blog/2024/06/Kategorie-hinzufuegen-oder-entfernen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Recognize
 */
const post = {
  id: '20517',
  slug: 'nextcloud-recognize',
  
  // Base data - same regardless of language
  date: "2024-06-19T08:56:50.000Z",
  readTime: 9,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 24,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Recognize",
      formattedDate: "19. Juni 2024",
      excerpt: "Nextcloud Recognize – Erkennen von Bildern. Mit Nextcloud App \"Recognize\" werden automatisch Gesichter, Objekte, Handlungen und Sehenswürdigkeiten auf Bildern erkannt. In diesem Artikel stellen wir die App vor und zeigen dir die Einrichtung.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Recognize – Erkennen von Bildern</h1>
          
          <p>Mit Nextcloud App "Recognize" werden automatisch Gesichter, Objekte, Handlungen und Sehenswürdigkeiten auf Bildern erkannt. In diesem Artikel stellen wir die App vor und zeigen dir die Einrichtung.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <div className="elementor-icon-wrapper">
                <div className="elementor-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" className="mx-auto">
                    <path d="M22 8.51v1.372h-2.538c.02-.223.038-.448.038-.681 0-.237-.017-.464-.035-.69h2.535zm-10.648-6.553v-1.957h1.371v1.964c-.242-.022-.484-.035-.726-.035-.215 0-.43.01-.645.028zm5.521 1.544l1.57-1.743 1.019.918-1.603 1.777c-.25-.297-.593-.672-.986-.952zm-10.738.952l-1.603-1.777 1.019-.918 1.57 1.743c-.392.28-.736.655-.986.952zm-1.597 5.429h-2.538v-1.372h2.535c-.018.226-.035.454-.035.691 0 .233.018.458.038.681zm9.462 9.118h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm0 2h-4c-.276 0-.5.224-.5.5s.224.5.5.5h4c.276 0 .5-.224.5-.5s-.224-.5-.5-.5zm.25 2h-4.5l1.188.782c.154.138.38.218.615.218h.895c.234 0 .461-.08.615-.218l1.187-.782zm3.75-13.799c0 3.569-3.214 5.983-3.214 8.799h-1.989c-.003-1.858.87-3.389 1.721-4.867.761-1.325 1.482-2.577 1.482-3.932 0-2.592-2.075-3.772-4.003-3.772-1.925 0-3.997 1.18-3.997 3.772 0 1.355.721 2.607 1.482 3.932.851 1.478 1.725 3.009 1.72 4.867h-1.988c0-2.816-3.214-5.23-3.214-8.799 0-3.723 2.998-5.772 5.997-5.772 3.001 0 6.003 2.051 6.003 5.772z"></path>
                  </svg>
                </div>
              </div>
            </div>
            
          </div>
          
          <BlogHeading>Maschinelles Lernen</BlogHeading>
          
          <p>Maschine Learning wird verwendet, um Gesichter, Tiere, Landschaften, Essen, Gebäude, Sehenswürdigkeiten und andere Dinge auf Bildern zu erkennen. Das Schöne an der Recognize App ist, dass das maschinelle Lernmodell auf deiner Nextcloud läuft und somit keine Informationen den Server verlassen.</p>
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Um die App in deiner Nextcloud zu installieren, logge dich als Administrator ein, klicke auf das Icon in der rechten oberen Ecke und wähle "Apps".<br />In der Suche kannst du nun "Recognize" eingeben und die App wird dir direkt angezeigt. Klicke dann auf "Herunterladen & Aktivieren".</p>
          
          <BlogImage src={recognizeInstallImage} alt="Recognize Herunterladen und Aktivieren" size="large" className="my-6" />
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <p>Nach der Installation findest du in den Verwaltungseinstellungen unter "Verwaltung" einen neuen Menüpunkt "Erkennen". Hier kannst du festlegen welche Erkennung du auf den Bildern haben möchtest. Für die Erkennung von Gesichtern setze ein Häkchen bei "Gesichtserkennung". Um das Machine Learning herunterzuladen, muss ein Befehl in der Kommandozeile ausgeführt werden. Als PathConnect Kunde übernehmen wir natürlich die Installation für dich. Du kannst aber auch die App "OCC Web" installieren, um den Befehl auszuführen.</p>
          
          <BlogImage src={statusImage} alt="Status der Erkennung" size="large" className="my-6" />
          
          <BlogHeading>Verwendung</BlogHeading>
          
          <p>Die Recognize App kommt am besten in der "Memories" App zum Vorschein. Hier findest du auf der linken Seite einen Eintrag für "Personen" und einen Eintrag für "Schlagwörter".<br />Unter Personen werden alle erkannten Personen mit der gefundenen Anzahl angezeigt. Klicke einmal darauf, um alle Bilder zu dieser Person zu sehen. Standardmäßig sind alle Personen mit "Unbenannte Person" beschriftet. Wenn du auf die Beschriftung klickst, kannst du den richtigen Namen der Person eingeben. Durch Umbenennen verschiedener Personenkategorien in einen Namen werden diese Bilder zusammengeführt.</p>
          
          <BlogImage src={renamePersonImage} alt="Person umbenennen" size="medium" className="mx-auto my-6" />
          
          <p>Unter "Schlagworte" werden verschiedene Kategorien angezeigt. Zum Beispiel, um welches Tier es sich handelt, welche Aktivität durchgeführt wurde, um welchen Gegenstand es sich handelt und vieles mehr. Klicke auf ein Bild und dann auf das kleine "i", um es einer neuen Kategorie zuzuordnen oder eine Kategorie zu entfernen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={infoImage} alt="Bild Info" size="large" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={categoryManagementImage} alt="Kategorie hinzufügen oder entfernen" size="large" />
            </div>
          </div>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Recognize",
      formattedDate: "19. Juni 2024",
      excerpt: "Nextcloud Recognize – Erkennen von Bildern. Mit Nextcloud App \"Recognize\" werden automatisch Gesichter, Objekte, Handlungen und Sehenswürdigkeiten auf Bildern erkannt. In diesem Artikel stellen wir die App vor und zeigen dir die Einrichtung.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Recognize",
      formattedDate: "June 19, 2024",
      excerpt: "Nextcloud Recognize – Image Recognition. With the Nextcloud app \"Recognize\", faces, objects, activities, and landmarks in photos are automatically recognized. In this article, we introduce the app and show you how to set it up.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Recognize – Image Recognition</h1>
          
          <p>With the Nextcloud app "Recognize", faces, objects, activities, and landmarks in photos are automatically recognized. In this article, we introduce the app and show you how to set it up.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Recognize",
      formattedDate: "19 June 2024",
      excerpt: "Nextcloud Recognize – Image Recognition. With the Nextcloud app \"Recognize\", faces, objects, activities, and landmarks in photos are automatically recognized. In this article, we introduce the app and show you how to set it up.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;