// src/data/blogPosts/nextcloud-talk.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-talk-featured.png';
import talkIcon from '../../assets/images/blog/2023/12/nextcloudtalkicon.webp';
import talkMenuIcon from '../../assets/images/blog/2022/11/Talk-Icon.png';
import newChatImage from '../../assets/images/blog/2022/11/neuer-Chat-1024x164.png';
import newConferenceImage from '../../assets/images/blog/2022/11/neue-Konferenz-1024x720.png';
import startCallButton from '../../assets/images/blog/2022/11/anruf-starten.png';
import settingsImage from '../../assets/images/blog/2022/11/Einstellungen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Talk
 */
const post = {
  id: '9810',
  slug: 'nextcloud-talk',
  
  // Base data - same regardless of language
  date: "2022-11-26T09:48:22.000Z",
  readTime: 9,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 2,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Talk",
      formattedDate: "26. November 2022",
      excerpt: "In diesem Blogartikel erklären dir was Nextcloud Talk ist, wie du es verwenden kannst und welche Vorteile du dadurch hast.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Talk</h1>
          
          <p>Mit Nextcloud Talk kannst du Videokonferenzen planen und durchführen. Verbinde dich sowohl mit Teilnehmern innerhalb deiner Nextcloud als auch mit externen Teilnehmern. Dank Ende-zu-Ende-Verschlüsselung bist du dabei immer auf der sicheren Seite.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={talkIcon} alt="Nextcloud Talk Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Was ist Nextcloud Talk?</BlogHeading>
          
          <p>Mit Nextcloud Talk kannst du <strong>Videokonferenzen</strong> direkt von deiner Nextcloud aus starten. Dabei ist es möglich entweder Benutzer hinzuzufügen die schon auf deiner Nextcloud angemeldet sind oder einen <strong>öffentlichen Link</strong> zu erstellen damit auch andere Leute beitreten können.</p>
          
          <p>Es ist auch möglich einfach nur zu <strong>chatten</strong>, <strong>Sprachnachrichten</strong> oder <strong>Dateien</strong> zu verschicken. Mit dem Update zu Nextcloud 25 können außerdem <strong>Dokumente</strong> direkt im Chat geöffnet und dann gemeinsam bearbeitet werden.</p>
          
          <BlogHeading level={3}>Verwendung</BlogHeading>
          
          <p>Um Nextcloud Talk zu starten klickst du einfach im Browser auf das <strong>"Talk Icon"</strong> in der Menüleiste.</p>
          
          <BlogImage src={talkMenuIcon} alt="Talk Icon in der Menüleiste" size="large" className="my-6" />
          
          <p>Hier kannst du dann einen <strong>Namen</strong> eingeben um direkt einen Chat zu starten. Bitte beachte, dass hier der gesuchte Name als Benutzer auf der Nextcloud angelegt sein muss.</p>
          
          <BlogImage src={newChatImage} alt="Neuen Chat starten" size="large" className="my-6" />
          
          <p>Möchtest du eine Konferenz mit <strong>mehreren Teilnehmer</strong> erstellen klickst du einfach auf das <strong>Plus</strong> rechts neben der Suchleiste und gibst einen Namen für die Gruppenunterhaltung ein. Hier hast du dann die Möglichkeit Gäste die <strong>Teilnahme per Link</strong> zu erlauben und weitere Benutzer hinzuzufügen.</p>
          
          <BlogImage src={newConferenceImage} alt="Neue Konferenz erstellen" size="large" className="my-6" />
          
          <p>Sobald die Unterhaltung erstellt ist kannst du einen Anrufe starten indem du auf den Button <strong>"Anruf starten"</strong> klickst.</p>
          
          <BlogImage src={startCallButton} alt="Anruf starten Button" size="medium" className="mx-auto my-6" />
          
          <p>Du hast außerdem die Möglichkeit verschiedene <strong>Einstellungen</strong> zu wie z.B. zu Benachrichtigungen und Moderation festzulegen.</p>
          
          <BlogImage src={settingsImage} alt="Einstellungen" size="large" className="my-6" />
          
          <BlogHeading level={3}>Vorteile</BlogHeading>
          
          <p>Da Talk direkt in Nextcloud <strong>integriert</strong> ist wird keine weitere zusätzliche Software benötigt, so hast du <strong>alles an einem Ort</strong> und musst die Anwedung nicht wechseln. <br />Schon in der Nextcloud gespeicherte Dateien oder Dokumente können direkt abgerufen und im Chat geteilt werden.</p>
          
          <p>Alle Unterhaltung sind <strong>Ende-zu-Ende verschlüsselt</strong> und somit komplett privat. Es gibt außerdem auch eine App für das <strong>Smartphone</strong>, so das du auch von Unterwegs aus an Unterhaltungen teilnehmen kannst.</p>
          
          <BlogHeading level={3}>Nextcloud Talk Smartphone App</BlogHeading>
          
          <p>Playstore<br />
            <a href="https://play.google.com/store/apps/details?id=com.nextcloud.talk2&hl=de_AT&gl=US" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://play.google.com/store/apps/details?id=com.nextcloud.talk2&hl=de_AT&gl=US
            </a>
          </p>
          
          <p>F-Droid<br />
            <a href="https://f-droid.org/packages/com.nextcloud.talk2/" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://f-droid.org/packages/com.nextcloud.talk2/
            </a>
          </p>
          
          <p>AppStore<br />
            <a href="https://apps.apple.com/us/app/nextcloud-talk/id1296825574" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://apps.apple.com/us/app/nextcloud-talk/id1296825574
            </a>
          </p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Talk",
      formattedDate: "26. November 2022",
      excerpt: "In diesem Blogartikel erklären dir was Nextcloud Talk ist, wie du es verwenden kannst und welche Vorteile du dadurch hast.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Talk",
      formattedDate: "November 26, 2022",
      excerpt: "In this blog post, we explain what Nextcloud Talk is, how you can use it, and what benefits it offers you.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Talk</h1>
          
          <p>With Nextcloud Talk, you can plan and conduct video conferences. Connect with participants within your Nextcloud as well as with external participants. Thanks to end-to-end encryption, you are always on the safe side.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Talk",
      formattedDate: "26 November 2022",
      excerpt: "In this blog post, we explain what Nextcloud Talk is, how you can use it, and what benefits it offers you.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;