// src/constants/partnerLogos.js
import xwikiLogo from '../assets/images/xwiki.png';
import openprojectLogo from '../assets/images/openproject.png';
import collaboraLogo from '../assets/images/collabora-online.png';
import nextcloudLogo from '../assets/images/nextcloud_logo.png';
import defaultLogo from '../assets/images/nextcloud_logo.png'; // Fallback logo

export const PARTNER_LOGOS = {
  'XWIKI': xwikiLogo,
  'OpenProject': openprojectLogo,
  'Collabora Online': collaboraLogo,
  'Nextcloud': nextcloudLogo
};

export const getPartnerLogo = (partnerName) => {
  return PARTNER_LOGOS[partnerName] || defaultLogo;
};