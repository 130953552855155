import React from 'react'

/**
 * Portal component displays a message indicating that the portal is under development.
 * It includes a button that allows the user to return to the previous page or redirect to the main site.
 *
 * @component
 * @example
 * return (
 *   <Portal />
 * )
 */
const Portal = () => {
  const handleReturn = () => {
    const referrer = document.referrer
    if (referrer.includes('pathconnect.de')) {
      window.history.back()
    } else {
      window.location.href = 'https://pathconnect.eu'
    }
  }

  return (
    <div className="text-center mt-12">
      <h1>Portal Under Development</h1>
      <p>
        We are working hard to bring you the best experience. Please check back
        later.
      </p>
      <br />
      <p>
        Got an idea for a feature you wish to have, use the contact form to tell
        us about it.
      </p>
      <br />
      <p>Thank you for your patience.</p>
      <button
        onClick={handleReturn}
        className="bg-blue-500 text-white rounded-lg py-2 px-4 mt-5 border-none cursor-pointer"
      >
        Return
      </button>
    </div>
  )
}

export default Portal
