// src/data/blogPosts/nextcloud-keeweb.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-keeweb-featured.png';
import keyIcon from '../../assets/images/blog/2022/01/key.png';
import trackingIcon from '../../assets/images/blog/2022/02/tracking.png';
import installationImage from '../../assets/images/blog/2023/10/Bildschirmfoto-vom-2023-10-07-16-37-27.png';
import dokumenteImage from '../../assets/images/blog/2023/10/Bildschirmfoto-vom-2023-10-07-16-40-34.png';
import keewebOverviewImage from '../../assets/images/blog/2023/10/Bildschirmfoto-vom-2023-10-05-10-09-53.png';
import keewebPasswordImage from '../../assets/images/blog/2023/10/Bildschirmfoto-vom-2023-10-05-10-13-51.png';
import abmeldenImage from '../../assets/images/blog/2023/10/Bildschirmfoto-vom-2023-10-07-16-46-11.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Keeweb
 */
const post = {
  id: '13114',
  slug: 'nextcloud-keeweb',
  
  // Base data - same regardless of language
  date: "2023-10-07T14:45:33.000Z",
  readTime: 8,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 13,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Keeweb",
      formattedDate: "7. Oktober 2023",
      excerpt: "Mit der Nextcloud App \"Keeweb\" kannst du deine Passwortdatenbank mit deiner Nextcloud synchronisieren und direkt im Webinterface öffnen. Wir zeigen dir, wie du die App installierst, konfigurierst und welche Vorteile sie dir bietet.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Keeweb</h1>
          
          <p>Mit der Nextcloud App "Keeweb" kannst du deine Passwortdatenbank mit deiner Nextcloud synchronisieren und direkt im Webinterface öffnen. Wir zeigen dir, wie du die App installierst, konfigurierst und welche Vorteile sie dir bietet.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={keyIcon} alt="Schlüssel Icon" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Keeweb</BlogHeading>
          
          <p>Mit der Anwendung Keeweb kannst du eine bestehende Passwortdatenbank (*.kdbx) direkt in der Weboberfläche von Nextcloud öffnen. Für die Erstellung einer solchen Passwortdatenbank kannst du einen Passwortmanager wie KeePassXC verwenden.</p>
          
          <p>Wenn du also nicht an deinem eigenen Computer bist und Zugriff auf deine Passwörter benötigst, kannst du dich einfach bei deiner Nextcloud anmelden und auf deine Passwörter zugreifen. Für die Anmeldung empfehlen wir dir, die Zwei-Faktor-Authentifizierung zu aktivieren.</p>
          
          <BlogImage src={trackingIcon} alt="Tracking Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Für die Installation loggst du dich mit einem <strong>Administrator-Account</strong> in deine Nextcloud ein, klickst auf das runde Icon oben rechts und dann auf „+Apps".<br />In der Suche kannst du einfach „Keeweb" eingeben und dann auf „Herunterladen und aktivieren" klicken.</p>
          
          <BlogImage src={installationImage} alt="Installation von Keeweb" size="large" className="my-6" />
          
          <BlogHeading>Verwendung</BlogHeading>
          
          <p>Um die App nutzen zu können, wird eine Passwortdatenbank benötigt. Wir empfehlen, die Passwortdatenbank auf deinem Computer in einem Ordner zu speichern, der mit deiner Nextcloud synchronisiert wird.<br />Im unteren Bild wurde die Passwortdatenbank im Ordner „Dokumente" gespeichert und mit der Nextcloud synchronisiert.<br />Wenn du auf die Datei klickst, öffnet sich Keeweb und du wirst aufgefordert, dein Master-Passwort einzugeben. Danach hast du Zugriff auf deine Passwörter und kannst diese wie gewohnt kopieren. Es ist auch möglich, neue Einträge zu erstellen und Einträge zu löschen.<br />Zum abmelden, kannst du rechts unten auf den Pfeil klicken, dadurch wird die Passwortdatenbank ordnungsgemäß geschlossen.</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={dokumenteImage} alt="Dokumente mit Passwortdatenbank" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={keewebOverviewImage} alt="Keeweb Übersicht" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={keewebPasswordImage} alt="Keeweb Passwort Manager" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={abmeldenImage} alt="Keeweb Abmelden" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Keeweb",
      formattedDate: "7. Oktober 2023",
      excerpt: "Mit der Nextcloud App \"Keeweb\" kannst du deine Passwortdatenbank mit deiner Nextcloud synchronisieren und direkt im Webinterface öffnen. Wir zeigen dir, wie du die App installierst, konfigurierst und welche Vorteile sie dir bietet.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Keeweb",
      formattedDate: "October 7, 2023",
      excerpt: "With the Nextcloud app 'Keeweb', you can synchronize your password database with your Nextcloud and open it directly in the web interface. We'll show you how to install and configure the app, and what benefits it offers.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Keeweb</h1>
          
          <p>With the Nextcloud app "Keeweb", you can synchronize your password database with your Nextcloud and open it directly in the web interface. We'll show you how to install and configure the app, and what benefits it offers.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Keeweb",
      formattedDate: "7 October 2023",
      excerpt: "With the Nextcloud app 'Keeweb', you can synchronize your password database with your Nextcloud and open it directly in the web interface. We'll show you how to install and configure the app, and what benefits it offers.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;