// src/data/blogPosts/nextcloud-und-paperless-ngx-im-multiuser-betrieb.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-und-paperless-ngx-im-multiuser-betrieb-featured.png';
import paperlessNextcloudImage from '../../assets/images/blog/2024/05/Paperless-ngx-Nextcloud.png';
import userGroupImage from '../../assets/images/blog/2024/05/User-group.png';
import createUserImage from '../../assets/images/blog/2024/05/Benutzer-erstellen.png';
import storagePath from '../../assets/images/blog/2024/05/Speicherpfad-Berechtitung.png';
import externalStorageConsume from '../../assets/images/blog/2024/05/Externer-Speicher-consume.png';
import nextcloudConsume from '../../assets/images/blog/2024/05/Nextcloud-consume.png';
import workflowScreenshot from '../../assets/images/blog/2024/05/Bildschirmfoto-vom-2024-05-15-10-20-28.png';
import workflowAction from '../../assets/images/blog/2024/05/Arbeitsablauf-Aktion.png';
import externalStorageSetup from '../../assets/images/blog/2024/05/Bildschirmfoto-vom-2024-05-15-10-26-54.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about using Nextcloud and Paperless-ngx in a multi-user environment
 */
const post = {
  id: '20228',
  slug: 'nextcloud-und-paperless-ngx-im-multiuser-betrieb',
  
  // Base data - same regardless of language
  date: "2024-05-15T08:48:46.000Z",
  readTime: 13,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 13,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud und Paperless-ngx im Multiuser-Betrieb",
      formattedDate: "15. Mai 2024",
      excerpt: "Die beiden Open-Source-Lösungen ergänzen sich im gemeinsamen Betrieb. Während die Dokumente von Paperless-ngx automatisch erfasst und verarbeitet werden, übernimmt Nextcloud die Synchronisation und Speicherung der Dokumente. In diesem Artikel wird die Einrichtung für den Mehrbenutzerbetrieb vorgestellt.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud und Paperless-ngx im Multiuser-Betrieb</h1>
          
          <p>Die beiden Open-Source-Lösungen ergänzen sich im gemeinsamen Betrieb. Während die Dokumente von Paperless-ngx automatisch erfasst und verarbeitet werden, übernimmt Nextcloud die Synchronisation und Speicherung der Dokumente. In diesem Artikel wird die Einrichtung für den Mehrbenutzerbetrieb vorgestellt.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={paperlessNextcloudImage} alt="Paperless-ngx und Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Grundvoraussetzung</BlogHeading>
          
          <p>Wie im letzten <a href="https://pathconnect.de/blog/paperless-ngx/" target="_blank" rel="noopener">Artikel</a> beschrieben, sollten Nextcloud und Paperless-ngx am besten auf dem gleichen Server betrieben werden. So können die Paperless-ngx Ordner als lokaler externer Speicher in die Nextcloud integriert werden. Nachfolgend wird die Einrichtung Schritt für Schritt durchgegangen.</p>
          
          <BlogHeading>1. Gruppe erstellen</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <div className="w-full md:w-1/2">
              <p>Erstelle zunächst eine neue Gruppe „Nutzer" in Paperless-ngx. Klicke dazu unten links auf „Benutzer & Gruppen – Gruppe hinzufügen".</p>
              <p>Die Benutzergruppe erhält keine Admin- und Gruppenrechte. Unter „User" muss nur der Haken bei „Anzeigen" gesetzt werden.</p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={userGroupImage} alt="Paperless-ngx User group" size="large" />
            </div>
          </div>
          
          <BlogHeading>2. Benutzer hinzufügen</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <div className="w-full md:w-1/2">
              <p>Erstelle die Benutzer für Paperless-ngx, am besten mit den gleichen Benutzernamen wie in deiner Nextcloud. Füge diese Benutzer der Gruppe "Nutzer" hinzu.</p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={createUserImage} alt="Paperless-ngx Benutzer erstellen" size="large" />
            </div>
          </div>
          
          <BlogHeading>3. Speicherpfad hinzufügen</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <div className="w-full md:w-1/2">
              <p>Klicken auf „Speicherpfade – Erstellen" im linken Menü, um einen neuen Speicherpfad zu definieren. Durch das Hinzufügen von sogenannten Platzhaltern kann die Ordnerstruktur festgelegt werden, die von Paperless-ngx verwendet werden soll. Wichtig ist, dass als erstes der Platzhalter <strong>{"{owner_username}/"}</strong> gesetzt wird, damit die Dokumente im entsprechenden Benutzerverzeichnis landen.</p>
              <p>Mit diesem Pfad wird im Benutzerverzeichnis ein neuer Ordner mit dem Dokumenttyp und darin das Dokument mit dem Titel als Name gespeichert.<br /><strong>{"{owner_username}/{document_type}/{title}"}</strong></p>
              <p>Unter Berechtigungen wird der Administrator als Eigentümer gesetzt. Die Gruppe „Nutzer" bekommt Anzeige-Rechte.</p>
              <p>Eine Liste mit allen Platzhaltern gibt es hier:<br /><a href="https://docs.paperless-ngx.com/advanced_usage/#placeholders" target="_blank" rel="noopener">https://docs.paperless-ngx.com/advanced_usage/#placeholders</a></p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={storagePath} alt="Speicherpfad Berechtigung" size="large" />
            </div>
          </div>
          
          <BlogHeading>4. Paperless-ngx consume Ordner einbinden</BlogHeading>
          
          <p>Für den nächsten Schritt müssen zunächst die entsprechenden Ordner im Ordner „consume" von Paperless-ngx angelegt werden.</p>
          <p>Dazu klickst du in deiner Nextcloud auf „Verwaltungseinstellungen – Externer Speicher" und fügst einen lokalen externen Speicher hinzu. Als Speicherpfad gibst du den consume-Ordner an. Z.B. /opt/paperless-ngx/consume/Demo.<br />(Der Pfad kann je nach Installation abweichen und der entsprechende Ordner muss bereits erstellt sein)</p>
          <p>Dieser Ordner sollte nur für den Administrator freigegeben werden.</p>
          
          <BlogImage src={externalStorageConsume} alt="Nextcloud Externer Speicher consume" size="large" className="my-6" />
          
          <p>Erstelle anschließend die Ordner für die Benutzer.</p>
          
          <BlogImage src={nextcloudConsume} alt="Nextcloud consume folder" size="large" className="my-6" />
          
          <BlogHeading>5. Arbeitsabläufe anlegen</BlogHeading>
          
          <p>Als nächstes wird für jeden Benutzer ein Arbeitsablauf erstellt. Dazu klickt man im linken Menü auf „Arbeitsabläufe – Arbeitsablauf hinzufügen". <br />Der Arbeitsablauf ist notwendig, damit auch Dokumente aus Nextcloud in Paperless-ngx geladen werden können.</p>
          <p>Als Namen gibst du am besten den jeweiligen Benutzernamen an.</p>
          
          <ul className="list-disc ml-6 mb-4">
            <li>Auslösertyp: Verarbeitung gestartet</li>
            <li>Pfad: /usr/src/paperless/consume/Demo/* <br />(Der Pfad kann je nach Installation abweichen und der entsprechende Ordner muss bereits erstellt sein)</li>
          </ul>
          
          <BlogImage src={workflowScreenshot} alt="Paperless-ngx Auslöser" size="large" className="my-6" />
          
          <p>Als Aktionstyp wird „Zuordnung" und als Speicherpfad der gerade angelegte Speicherpfad gewählt. Als Eigentümer, Anzeigeberechtigung und Bearbeitungsberechtigung wird der Benutzer ausgewählt, für den der Arbeitsablauf erstellt wird.</p>
          
          <BlogImage src={workflowAction} alt="Paperless-ngx Arbeitsablauf Aktion" size="large" className="my-6" />
          
          <BlogHeading>6. Externen Speicher für Benutzer hinzufügen</BlogHeading>
          
          <p>Damit jeder Benutzer seine Paperless-ngx Ordner in der Nextcloud sehen kann, müssen diese als externer Speicher eingebunden werden. Klicke dazu in deiner Nextcloud auf „Verwaltungseinstellungen – Externer Speicher". Erstelle einen Ordner „Paperless Dokumente" mit dem Speicherpfad /opt/paperless-ngx/media/documents/archive/Demo</p>
          <p>...und einen „Paperless Input" Ordner mit diesem Speicherpfad: /opt/paperless-ngx/consume/Demo</p>
          <p>Bitte beachte, dass die Speicherpfade je nach Installation abweichen können.</p>
          
          <BlogImage src={externalStorageSetup} alt="Nextcloud externe Speicher für Paperless" size="large" className="my-6" />
          
          <BlogHeading>Ergebnis</BlogHeading>
          
          <p>Nach erfolgreicher Einrichtung verfügt jeder Nextcloud Nutzer über zwei persönliche Paperless-ngx Ordner. Den „Consume" Ordner, in dem Dokumente einfach per Drag & Drop in Paperless-ngx geladen werden können und den „Paperless" Ordner, in dem alle Dokumente mit der Paperless-ngx Ordnerstruktur zu sehen sind. In Paperless-ngx hat jeder Benutzer seinen persönlichen Bereich mit seinen Dokumenten, die er hochladen und bearbeiten kann.</p>
          <p>Als Kunde von PathConnect kümmern wir uns für dich um die komplette Einrichtung und die spezifischen Einstellungen.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud und Paperless-ngx im Multiuser-Betrieb",
      formattedDate: "15. Mai 2024",
      excerpt: "Die beiden Open-Source-Lösungen ergänzen sich im gemeinsamen Betrieb. Während die Dokumente von Paperless-ngx automatisch erfasst und verarbeitet werden, übernimmt Nextcloud die Synchronisation und Speicherung der Dokumente. In diesem Artikel wird die Einrichtung für den Mehrbenutzerbetrieb vorgestellt.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Nextcloud and Paperless-ngx in Multi-user Operation",
      formattedDate: "May 15, 2024",
      excerpt: "The two open source solutions complement each other in joint operation. While Paperless-ngx automatically captures and processes documents, Nextcloud handles the synchronization and storage of documents. This article presents the setup for multi-user operation.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud and Paperless-ngx in Multi-user Operation</h1>
          
          <p>The two open source solutions complement each other in joint operation. While Paperless-ngx automatically captures and processes documents, Nextcloud handles the synchronization and storage of documents. This article presents the setup for multi-user operation.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={paperlessNextcloudImage} alt="Paperless-ngx and Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Basic Requirements</BlogHeading>
          
          <p>As described in the last <a href="https://pathconnect.de/blog/paperless-ngx/" target="_blank" rel="noopener">article</a>, Nextcloud and Paperless-ngx should ideally be operated on the same server. This allows Paperless-ngx folders to be integrated into Nextcloud as local external storage. The setup is explained step by step below.</p>
          
          <BlogHeading>1. Creating a Group</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <div className="w-full md:w-1/2">
              <p>First, create a new group called "Users" in Paperless-ngx. To do this, click on "Users & Groups - Add Group" at the bottom left.</p>
              <p>The user group does not receive admin and group rights. Under "User", only the "View" option needs to be checked.</p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={userGroupImage} alt="Paperless-ngx User group" size="large" />
            </div>
          </div>
          
          <BlogHeading>2. Adding Users</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <div className="w-full md:w-1/2">
              <p>Create users for Paperless-ngx, ideally with the same usernames as in your Nextcloud. Add these users to the "Users" group.</p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={createUserImage} alt="Paperless-ngx create user" size="large" />
            </div>
          </div>
          
          <BlogHeading>3. Adding a Storage Path</BlogHeading>
          
          <div className="flex flex-col md:flex-row gap-6 mb-6">
            <div className="w-full md:w-1/2">
              <p>Click on "Storage Paths - Create" in the left menu to define a new storage path. By adding placeholders, you can specify the folder structure to be used by Paperless-ngx. It's important to set the <strong>{"{owner_username}/"}</strong> placeholder first so that documents are stored in the respective user directory.</p>
              <p>With this path, a new folder with the document type is created in the user directory, and the document is saved with the title as the name.<br /><strong>{"{owner_username}/{document_type}/{title}"}</strong></p>
              <p>Under permissions, the administrator is set as the owner. The "Users" group receives view rights.</p>
              <p>A list of all placeholders can be found here:<br /><a href="https://docs.paperless-ngx.com/advanced_usage/#placeholders" target="_blank" rel="noopener">https://docs.paperless-ngx.com/advanced_usage/#placeholders</a></p>
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={storagePath} alt="Storage path permissions" size="large" />
            </div>
          </div>
          
          <BlogHeading>4. Integrating Paperless-ngx Consume Folder</BlogHeading>
          
          <p>For the next step, the corresponding folders must first be created in the "consume" folder of Paperless-ngx.</p>
          <p>To do this, click on "Administration Settings - External Storage" in your Nextcloud and add a local external storage. For the storage path, enter the consume folder, e.g., /opt/paperless-ngx/consume/Demo.<br />(The path may vary depending on the installation, and the corresponding folder must already be created)</p>
          <p>This folder should only be shared with the administrator.</p>
          
          <BlogImage src={externalStorageConsume} alt="Nextcloud External Storage consume" size="large" className="my-6" />
          
          <p>Then create the folders for the users.</p>
          
          <BlogImage src={nextcloudConsume} alt="Nextcloud consume folder" size="large" className="my-6" />
          
          <BlogHeading>5. Creating Workflows</BlogHeading>
          
          <p>Next, a workflow is created for each user. To do this, click on "Workflows - Add Workflow" in the left menu. <br />The workflow is necessary so that documents from Nextcloud can also be loaded into Paperless-ngx.</p>
          <p>It's best to use the respective username as the name.</p>
          
          <ul className="list-disc ml-6 mb-4">
            <li>Trigger type: Processing started</li>
            <li>Path: /usr/src/paperless/consume/Demo/* <br />(The path may vary depending on the installation, and the corresponding folder must already be created)</li>
          </ul>
          
          <BlogImage src={workflowScreenshot} alt="Paperless-ngx trigger" size="large" className="my-6" />
          
          <p>For the action type, select "Assignment" and choose the storage path you just created. For owner, view permissions, and edit permissions, select the user for whom the workflow is being created.</p>
          
          <BlogImage src={workflowAction} alt="Paperless-ngx workflow action" size="large" className="my-6" />
          
          <BlogHeading>6. Adding External Storage for Users</BlogHeading>
          
          <p>For each user to see their Paperless-ngx folders in Nextcloud, these must be integrated as external storage. To do this, click on "Administration Settings - External Storage" in your Nextcloud. Create a folder "Paperless Documents" with the storage path /opt/paperless-ngx/media/documents/archive/Demo</p>
          <p>...and a "Paperless Input" folder with this storage path: /opt/paperless-ngx/consume/Demo</p>
          <p>Please note that the storage paths may vary depending on the installation.</p>
          
          <BlogImage src={externalStorageSetup} alt="Nextcloud external storage for Paperless" size="large" className="my-6" />
          
          <BlogHeading>Result</BlogHeading>
          
          <p>After successful setup, each Nextcloud user has two personal Paperless-ngx folders. The "Consume" folder, in which documents can be easily loaded into Paperless-ngx via drag & drop, and the "Paperless" folder, in which all documents with the Paperless-ngx folder structure can be seen. In Paperless-ngx, each user has their personal area with their documents that they can upload and edit.</p>
          <p>As a PathConnect customer, we take care of the complete setup and specific settings for you.</p>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud and Paperless-ngx in Multi-user Operation",
      formattedDate: "15 May 2024",
      excerpt: "The two open source solutions complement each other in joint operation. While Paperless-ngx automatically captures and processes documents, Nextcloud handles the synchronization and storage of documents. This article presents the setup for multi-user operation.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;