// src/components/PartnersSection.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import Container from './container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faCheck, faCircle } from '@fortawesome/free-solid-svg-icons';
import { getPartnerLogo } from '../constants/partnerLogos';

const PartnersSection = () => {
  const { t } = useTranslation('home');
  
  // Get partners data from translation only
  const partners = t('partners.list', { returnObjects: true }) || [];
  
  return (
    <Container>
      <div className="w-full py-8">
        <div className="text-center mb-12 relative">
          {/* Background accent */}
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-20 h-20 bg-cyan-500/5 rounded-full blur-xl"></div>
          
          <div className="inline-flex items-center justify-center p-3 bg-gradient-to-br from-cyan-500/30 to-cyan-500/10 rounded-full mb-4 relative overflow-hidden shadow-inner">
            <div className="absolute inset-0 bg-gradient-to-br from-cyan-500/20 to-transparent rounded-full animate-pulse"></div>
            <FontAwesomeIcon icon={faHandshake} className="text-cyan-400 text-xl z-10 relative" />
          </div>
          <h2 className="text-2xl md:text-3xl font-bold text-center text-cyan-400 mb-3 relative">
            {t('partners.title')}
            <div className="absolute -bottom-2 left-1/2 transform -translate-x-1/2 w-24 h-1 bg-gradient-to-r from-cyan-500/0 via-cyan-500/40 to-cyan-500/0"></div>
          </h2>
          <p className="text-center max-w-2xl mx-auto text-gray-300 relative">
            {t('partners.subtitle')}
          </p>
        </div>
        
        {/* Balanced grid layout with enhanced design */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
          {partners.map((partner, index) => (
            <div
              key={index}
              className="group bg-gradient-to-br from-gray-800 to-gray-900 rounded-lg overflow-hidden border border-gray-700 hover:border-cyan-500/50 transition-all duration-500 shadow-md hover:shadow-cyan-900/30 hover:shadow-lg hover:-translate-y-1 transform"
            >
              {/* Decorative corner accent */}
              <div className="absolute top-0 right-0 w-12 h-12 overflow-hidden pointer-events-none">
                <div className="absolute top-0 right-0 transform translate-y-[-50%] translate-x-[50%] rotate-45 bg-cyan-500/20 w-12 h-6 group-hover:bg-cyan-500/40 transition-colors duration-500"></div>
              </div>
              
              {/* Logo header with animated background */}
              <div className="relative bg-gradient-to-br from-gray-800/90 to-gray-900 p-4 flex justify-center items-center h-24 border-b border-gray-700 overflow-hidden group-hover:from-gray-800 group-hover:to-gray-800 transition-all duration-500">
                {/* Animated background elements */}
                <div className="absolute inset-0 opacity-0 group-hover:opacity-20 transition-opacity duration-700">
                  <div className="absolute top-1/4 left-1/4 w-1 h-1 bg-cyan-400 rounded-full"></div>
                  <div className="absolute top-3/4 left-1/3 w-1.5 h-1.5 bg-cyan-500 rounded-full"></div>
                  <div className="absolute top-2/4 right-1/4 w-1 h-1 bg-cyan-400 rounded-full"></div>
                </div>
                <img
                  src={getPartnerLogo(partner.name)}
                  alt={`${partner.name} Logo`}
                  className="max-h-12 filter brightness-0 invert opacity-80 group-hover:opacity-100 transition-all duration-500 transform group-hover:scale-110"
                />
              </div>
              
              {/* Content area with hover effects */}
              <div className="p-5 relative">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-bold text-cyan-400 group-hover:text-cyan-300 transition-colors duration-300">{partner.name}</h3>
                  <span className="text-sm font-medium text-cyan-300/90 px-2 py-0.5 rounded-full bg-cyan-900/30 border border-cyan-500/20 group-hover:bg-cyan-800/40 group-hover:border-cyan-500/30 transition-all duration-300">
                    {t('partners.officialBadge')}
                  </span>
                </div>
                
                {/* Benefits with enhanced styling and hover effects */}
                <ul className="space-y-3 mb-3">
                  {(partner.benefits || []).slice(0, 3).map((benefit, i) => (
                    <li key={i} className="flex items-start group/item">
                      <span className="relative flex-shrink-0 w-5 h-5 mr-2.5 mt-0.5">
                        <FontAwesomeIcon icon={faCircle} className="absolute text-[8px] text-cyan-900 group-hover/item:text-cyan-800 transition-colors duration-300" />
                        <FontAwesomeIcon icon={faCheck} className="absolute text-[10px] text-cyan-400 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover/item:text-cyan-300 transition-colors duration-300" />
                      </span>
                      <span className="text-gray-300 text-sm group-hover/item:text-gray-200 transition-colors duration-300">{benefit}</span>
                    </li>
                  ))}
                </ul>
                
                {/* Animated bottom accent line */}
                <div className="absolute bottom-0 left-0 w-0 h-0.5 bg-gradient-to-r from-cyan-500/0 via-cyan-500/70 to-cyan-500/0 group-hover:w-full transition-all duration-700 ease-in-out"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default PartnersSection;