// src/components/blog/BlogGallery.js
import React, { useState } from 'react';
import ImageModal from './ImageModal';

/**
 * A grid layout for multiple images
 * Updated with lightbox functionality for each image
 */
const BlogGallery = ({ 
  images, 
  columns = 2,
  gap = 4,
  className = '',
  lightbox = true // Enable/disable lightbox functionality
}) => {
  const [modalData, setModalData] = useState({ isOpen: false, src: '', alt: '', caption: '' });
  
  // Map columns to responsive class names (stack on mobile)
  const columnsMap = {
    1: 'sm:grid-cols-1',
    2: 'sm:grid-cols-1 md:grid-cols-2',
    3: 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3',
    4: 'sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4'
  };
  
  // Map gap to fixed class names
  const gapMap = {
    2: 'gap-2',
    4: 'gap-4',
    6: 'gap-6',
    8: 'gap-8'
  };
  
  const columnsClass = columnsMap[columns] || 'sm:grid-cols-1 md:grid-cols-2';
  const gapClass = gapMap[gap] || 'gap-4';
  
  const openModal = (image) => {
    if (lightbox) {
      setModalData({
        isOpen: true,
        src: image.src,
        alt: image.alt,
        caption: image.caption
      });
    }
  };

  const closeModal = () => {
    setModalData({ ...modalData, isOpen: false });
  };
  
  return (
    <>
      <div className={`grid grid-cols-1 ${columnsClass} ${gapClass} my-4 ${className}`}>
        {images.map((image, index) => (
          <div key={index} className="text-center">
            <img 
              src={image.src} 
              alt={image.alt} 
              className={`mx-auto rounded-md shadow-sm hover:shadow-md transition-shadow w-full ${lightbox ? 'cursor-pointer' : ''}`}
              style={{ maxWidth: '100%', height: 'auto' }} 
              onClick={() => openModal(image)}
              title={lightbox ? "Click to enlarge" : ""}
            />
            {image.caption && (
              <p className="mt-2 text-sm text-gray-600 dark:text-gray-400">{image.caption}</p>
            )}
          </div>
        ))}
      </div>
      
      {/* Image Modal/Lightbox */}
      <ImageModal 
        src={modalData.src}
        alt={modalData.alt}
        caption={modalData.caption}
        isOpen={modalData.isOpen}
        onClose={closeModal}
      />
    </>
  );
};

export default BlogGallery;