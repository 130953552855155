// src/data/blogPosts/wechsel-von-einer-nextcloud-instanz-zu-einer-anderen.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/wechsel-von-einer-nextcloud-instanz-zu-einer-anderen-featured.png';
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import externalStorageActivate from '../../assets/images/blog/2024/08/external-storage-support-aktivieren.png';
import externalStorageSetup from '../../assets/images/blog/2024/08/Externer-Speicher-einbinden.png';
import externalFolder from '../../assets/images/blog/2024/08/Externer-Ordner.png';
import oldNextcloud from '../../assets/images/blog/2024/08/Meine-alte-Nextcloud.png';
import copyData from '../../assets/images/blog/2024/08/Daten-kopieren.png';
import duplicatedFolders from '../../assets/images/blog/2024/08/Doppelte-Ordner.png';

/**
 * Blog post about migrating from one Nextcloud instance to another
 */
const post = {
  id: '21602',
  slug: 'wechsel-von-einer-nextcloud-instanz-zu-einer-anderen',
  
  // Base data - same regardless of language
  date: "2024-08-20T09:13:20.000Z",
  readTime: 11,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 22,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Wechsel von einer Nextcloud-Instanz zu einer anderen",
      formattedDate: "20. August 2024",
      excerpt: "Beim Wechsel von einer Nextcloud-Instanz zu einer anderen steht man vor der Herausforderung, alle Daten zu übertragen. In diesem Artikel zeigen wir dir, wie du sowohl Daten als auch Profilinformationen, Kontakte und Kalender ohne großen Aufwand übertragen kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Wechsel von einer Nextcloud-Instanz zu einer anderen</h1>
          
          <p>Beim Wechsel von einer Nextcloud-Instanz zu einer anderen steht man vor der Herausforderung, alle Daten zu übertragen. In diesem Artikel zeigen wir dir, wie du sowohl Daten als auch Profilinformationen, Kontakte und Kalender ohne großen Aufwand übertragen kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Neue Nextcloud Instanz</BlogHeading>
          
          <p>Es gibt viele Gründe für den Wechsel zu einer neuen Nextcloud Instanz. Vielleicht wechselst du von Self Hosting zu einer Managed Nextcloud oder du möchtest einfach nur deinen Nextcloud Provider wechseln. Einer der Vorteile von Nextcloud ist, dass du nicht gebunden bist und jederzeit die Nextcloud Instanz mit deinem Profil und deinen Daten wechseln kannst.</p>
          
          <div className="flex justify-center my-4">
            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" className="text-primary">
              <path d="M4.069 13h-4.069v-2h4.069c-.041.328-.069.661-.069 1s.028.672.069 1zm3.034-7.312l-2.881-2.881-1.414 1.414 2.881 2.881c.411-.529.885-1.003 1.414-1.414zm11.209 1.414l2.881-2.881-1.414-1.414-2.881 2.881c.528.411 1.002.886 1.414 1.414zm-6.312-3.102c.339 0 .672.028 1 .069v-4.069h-2v4.069c.328-.041.661-.069 1-.069zm0 16c-.339 0-.672-.028-1-.069v4.069h2v-4.069c-.328.041-.661.069-1 .069zm7.931-9c.041.328.069.661.069 1s-.028.672-.069 1h4.069v-2h-4.069zm-3.033 7.312l2.88 2.88 1.415-1.414-2.88-2.88c-.412.528-.886 1.002-1.415 1.414zm-11.21-1.415l-2.88 2.88 1.414 1.414 2.88-2.88c-.528-.411-1.003-.885-1.414-1.414zm2.312-4.897c0 2.206 1.794 4 4 4s4-1.794 4-4-1.794-4-4-4-4 1.794-4 4zm10 0c0 3.314-2.686 6-6 6s-6-2.686-6-6 2.686-6 6-6 6 2.686 6 6z"></path>
            </svg>
          </div>
          
          <BlogHeading>External Storage App</BlogHeading>
          
          <p>Mit der App „External Storage Support" können externe Speicher in die Nextcloud eingebunden werden. Neben FTP, SFTP, SMB, WebDAV, Objekt- und lokalem Speicher gibt es auch die Möglichkeit eine andere Nextcloud als externen Speicher einzubinden. Diese Funktionen nutzen wir, um den gesamten Speicher deiner alten Nextcloud Instanz in deine neue Nextcloud Instanz einzubinden.<br />Um die App in deiner Nextcloud zu installieren, logge dich als Administrator ein, klicke auf das Icon in der rechten oberen Ecke und wähle „Apps".<br />In der Suche kannst du nun „External Storage" eingeben und die App wird dir direkt angezeigt. Klicke dann auf „Aktivieren".</p>
          
          <div className="w-full my-6">
            <BlogImage src={externalStorageActivate} alt="External Storage Support aktivieren" size="large" />
          </div>
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <p>Klicke wieder auf das Icon in der rechten oberen Ecke und dann auf „Verwaltungseinstellungen" und dann unter „Verwaltung" auf „Externer Speicher".<br />Unter „Ordnername" kannst du einen beliebigen Namen vergeben. Zum Beispiel „Meine alte Nextcloud". Als externen Speicher wählst du „Nextcloud". Die Authentifizierung bleibt bei „Login and password".<br />Im Feld „URL" gibst du die Adresse deiner alten Nextcloud ein. Wenn du den gesamten Speicher einbinden möchtest, kannst du bei „Entfernter Unterordner" einen Schrägstrich „/" eingeben. Bei Benutzername gibst du den Benutzernamen ein, mit dem du dich in deiner alten Nextcloud anmelden möchtest. Für das Passwort empfehlen wir dir ein App Passwort zu erstellen.<br />Klicke dazu in deiner alten Nextcloud Instanz auf „Sicherheit" – „Geräte & Sitzungen".<br />Um die Einstellungen zu speichern, klicke auf das Häkchen.</p>
          
          <div className="w-full my-6">
            <BlogImage src={externalStorageSetup} alt="Externer Speicher einbinden" size="large" />
          </div>
          
          <BlogHeading>Daten kopieren</BlogHeading>
          
          <p>Nachdem deine alte Nextcloud als externer Speicher in deine neue Nextcloud eingebunden wurde, siehst du einen neuen Ordner mit dem Namen, den du im vorherigen Schritt angegeben hast.</p>
          
          <p>Klicke in den Ordner und schon siehst du den gesamten Inhalt deiner alten Nextcloud Instanz.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={externalFolder} alt="Externer Ordner" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={oldNextcloud} alt="Meine alte Nextcloud" size="medium" />
            </div>
          </div>
          
          <p>Um die Daten in deine neue Nextcloud zu kopieren, wähle alle Ordner aus und klicke auf den Button „Verschieben oder Kopieren". Im nächsten Schritt wähle den Zielordner aus und klicke auf „kopieren".<br />Je nach Datenmenge kann dieser Vorgang einige Zeit in Anspruch nehmen. Lass das Fenster einfach geöffnet und warte ab.</p>
          
          <p>Nach Abschluss des Kopiervorgangs werden alle Ordner angezeigt. Für bereits vorhandene Ordner wird ein Duplikat mit dem Zusatz „kopiert" im Namen erstellt.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={copyData} alt="Daten kopieren" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={duplicatedFolders} alt="Doppelte Ordner" size="medium" />
            </div>
          </div>
          
          <BlogHeading>Profilinformationen, Kontakte und Kalender kopieren</BlogHeading>
          
          <p>Damit du nicht nur deine Daten, sondern auch deine Profilinformationen, Kontakte und Kalender auf deiner neuen Nextcloud-Instanz wiederfindest, empfehlen wir dir die App „User Migration". Den Artikel dazu findest du hier: <a href="https://pathconnect.de/blog/nextcloud-user-migration-app/" target="_blank" rel="noopener">Nextcloud User Migration App</a></p>
          
          <div className="flex justify-center my-4">
            <svg aria-hidden="true" className="w-12 h-12 text-primary" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
              <path d="M377.941 169.941V216H134.059v-46.059c0-21.382-25.851-32.09-40.971-16.971L7.029 239.029c-9.373 9.373-9.373 24.568 0 33.941l86.059 86.059c15.119 15.119 40.971 4.411 40.971-16.971V296h243.882v46.059c0 21.382 25.851 32.09 40.971 16.971l86.059-86.059c9.373-9.373 9.373-24.568 0-33.941l-86.059-86.059c-15.119-15.12-40.971-4.412-40.971 16.97z"></path>
            </svg>
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Wechsel von einer Nextcloud-Instanz zu einer anderen",
      formattedDate: "20. August 2024",
      excerpt: "Beim Wechsel von einer Nextcloud-Instanz zu einer anderen steht man vor der Herausforderung, alle Daten zu übertragen. In diesem Artikel zeigen wir dir, wie du sowohl Daten als auch Profilinformationen, Kontakte und Kalender ohne großen Aufwand übertragen kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;