// src/data/blogPosts/was-bedeutet-hosting.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/was-bedeutet-hosting-featured.png';
import helpIcon from '../../assets/images/blog/2023/11/iconmonstr-help-5-240.png';
import buildingIcon from '../../assets/images/blog/2023/11/iconmonstr-building-14-240.png';
import buildingLargeIcon from '../../assets/images/blog/2023/11/iconmonstr-building-19-240.png';
import serverIcon from '../../assets/images/blog/2023/11/iconmonstr-server-9-240.png';
import processIcon from '../../assets/images/blog/2022/09/iconmonstr-process-1-240.png';

/**
 * Blog post about what hosting means
 */
const post = {
  id: '13719',
  slug: 'was-bedeutet-hosting',
  
  // Base data - same regardless of language
  date: "2023-11-24T15:09:40.000Z",
  readTime: 8,
  categories: ["Informationen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 21,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Was bedeutet Hosting",
      formattedDate: "24. November 2023",
      excerpt: "Der Begriff Hosting wird oft verwendet, aber was genau verbirgt sich dahinter? In diesem Artikel gehen wir auf diese Frage ein und erklären, was es bedeutet.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Was bedeutet Hosting?</h1>
          
          <p>Der Begriff Hosting wird oft verwendet, aber was genau verbirgt sich dahinter? In diesem Artikel gehen wir auf diese Frage ein und erklären, was es bedeutet.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={helpIcon} alt="Fragezeichen Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Hosting</BlogHeading>
          
          <p>Im Deutschen könnte Hosting mit „Unterkunft geben" übersetzt werden. Es handelt sich also um die Bereitstellung einer Unterkunft für verschiedene Anwendungen, die über das Internet zugänglich sein sollen. Jede Webseite oder Cloud-Anwendung, die im Internet aufgerufen werden kann, muss auf einem Server betrieben werden.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={buildingIcon} alt="Haus Icon" size="small" />
          </div>
          
          <BlogHeading>Hosting Provider</BlogHeading>
          
          <p>Ein Unternehmen, das diese Server zur Verfügung stellt, wird als Hoster oder Hosting-Provider bezeichnet. Ein Hoster betreibt seine Server in einem Rechenzentrum mit redundanter Strom- und Internetversorgung. Er kümmert sich z.B. um den reibungslosen Betrieb der Server, tauscht Hardwarekomponenten aus und sorgt für eine optimale Netzwerkanbindung.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={buildingLargeIcon} alt="Gebäude Icon" size="small" />
          </div>
          
          <BlogHeading>Unterschiede beim Hosting</BlogHeading>
          
          <p>Es gibt viele verschiedene Arten von Hosting, deren Aufzählung den Rahmen dieses Artikels sprengen würde. Die wichtigsten werden im Folgenden kurz erläutert.<br /><br /></p>
          
          <ul className="list-disc pl-6 mb-4">
            <li>
              <p><u>Server Hosting</u><br />
              Ein Hosting-Provider kann einfach einen physischen oder virtuellen Server zur Verfügung stellen. Der Kunde kann dann selbst entscheiden, was er mit dem Server macht, solange es sich nicht um illegale Aktivitäten handelt. Er muss sich selbst um Updates, Sicherheit, Installation und Bereitstellung von Software kümmern.</p>
            </li>
            <li>
              <p><u>Managed Hosting</u><br />
              Es gibt auch Hosting-Provider, die so genannte „Managed Server" anbieten. Hier werden Dinge wie die Installation des Betriebssystems, Updates und Backups übernommen.</p>
            </li>
            <li>
              <p><u>Cloud Hosting</u><br />
              Hierbei handelt es sich um die Bereitstellung von Hosting-Diensten über eine verteilte und vernetzte Gruppe von Servern, die als „Cloud" bezeichnet wird.</p>
            </li>
            <li>
              <p><u>SaaS (Software as a Service)</u><br />
              Wird eine fertige Software in der Cloud zur Verfügung gestellt, spricht man von SaaS. Der Kunde muss sich nur noch anmelden und kann sofort loslegen.</p>
            </li>
          </ul>
          
          <div className="flex justify-center my-4">
            <BlogImage src={serverIcon} alt="Server Icon" size="small" />
          </div>
          
          <BlogHeading>Managed Open Source Hosting</BlogHeading>
          
          <p>Eine weitere Form des Hostings ist das „Managed SaaS". Hier wird nicht nur eine Anwendung in der Cloud bereitgestellt, sondern auch kundenspezifische Anpassungen vorgenommen und direkter Support angeboten. <br />Da wir bei PathConnect ausschließlich Open Source Software als Managed Service anbieten, wäre eine mögliche Bezeichnung „Managed Open Source Hosting".</p>
          
          <p>Am Beispiel von Nextcloud bedeutet dies, dass wir dir neben der Bereitstellung auch den Service bieten, verschiedene Nextcloud Apps wie z.B. Memories, Face Regocnition, OCR Workflow, etc. serverseitig zu konfigurieren. Die Nextcloud wird also an deine Bedürfnisse und Anforderungen angepasst. Dazu kommen Integration mit anderer Open Source Software wie z.B. Collabora Office und Open Project, die wir für dich übernehmen und einrichten. Es ist uns ein Anliegen, dass die Software auch wirklich richtig genutzt werden kann, daher stehen wir dir bei Fragen und Wünschen jederzeit zur Verfügung.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={processIcon} alt="Prozess Icon" size="small" />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Was bedeutet Hosting",
      formattedDate: "24. November 2023",
      excerpt: "Der Begriff Hosting wird oft verwendet, aber was genau verbirgt sich dahinter? In diesem Artikel gehen wir auf diese Frage ein und erklären, was es bedeutet.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;