// src/components/blog/ImageModal.js
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExpand, faCompress } from '@fortawesome/free-solid-svg-icons';

/**
 * A modal component for displaying images in a lightbox
 */
const ImageModal = ({ src, alt, caption, isOpen, onClose }) => {
  const [isZoomed, setIsZoomed] = React.useState(false);

  // Handle ESC key to close modal
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };
    
    if (isOpen) {
      document.addEventListener('keydown', handleEsc);
      // Prevent body scrolling when modal is open
      document.body.style.overflow = 'hidden';
    }
    
    return () => {
      document.removeEventListener('keydown', handleEsc);
      document.body.style.overflow = 'auto';
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  const toggleZoom = (e) => {
    e.stopPropagation();
    setIsZoomed(!isZoomed);
  };

  return (
    <div 
      className="fixed inset-0 z-50 bg-black bg-opacity-90 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <div 
        className="relative max-w-full max-h-full overflow-auto"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Close button */}
        <button 
          className="absolute top-2 right-2 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70 transition-all z-10"
          onClick={onClose}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
        
        {/* Zoom button */}
        <button 
          className="absolute top-2 left-2 text-white bg-black bg-opacity-50 rounded-full p-2 hover:bg-opacity-70 transition-all z-10"
          onClick={toggleZoom}
        >
          <FontAwesomeIcon icon={isZoomed ? faCompress : faExpand} />
        </button>
        
        {/* Image */}
        <img 
          src={src} 
          alt={alt} 
          className={`max-h-[80vh] transition-all ${isZoomed ? 'max-w-none cursor-zoom-out' : 'max-w-full cursor-zoom-in'}`}
          onClick={toggleZoom}
        />
        
        {/* Caption */}
        {caption && (
          <div className="bg-black bg-opacity-70 p-4 text-white text-center">
            {caption}
          </div>
        )}
      </div>
    </div>
  );
};

export default ImageModal;