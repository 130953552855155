// src/data/blogPosts/nextcloud-umfragen-polls.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-umfragen-polls-featured.png';
import helpIcon from '../../assets/images/blog/2023/08/iconmonstr-help-6-240.png';
import speechBubbleIcon from '../../assets/images/blog/2023/08/iconmonstr-speech-bubble-26-240.png';
import menuScreenshot from '../../assets/images/blog/2023/08/Bildschirmfoto-vom-2023-08-13-11-34-18.png';
import newPollScreenshot from '../../assets/images/blog/2023/08/Bildschirmfoto-vom-2023-08-13-11-37-52.png';
import datePickerScreenshot from '../../assets/images/blog/2023/08/Bildschirmfoto-vom-2023-08-13-11-39-48.png';
import sharingScreenshot from '../../assets/images/blog/2023/08/Bildschirmfoto-vom-2023-08-13-11-41-31.png';
import configScreenshot from '../../assets/images/blog/2023/08/Bildschirmfoto-vom-2023-08-13-11-43-52.png';
import downloadScreenshot from '../../assets/images/blog/2023/08/Bildschirmfoto-vom-2023-08-13-11-44-44.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Polls
 */
const post = {
  id: '12259',
  slug: 'nextcloud-umfragen-polls',
  
  // Base data - same regardless of language
  date: "2023-08-13T09:48:24.000Z",
  readTime: 11,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 36,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Umfragen (Polls)",
      formattedDate: "13. August 2023",
      excerpt: "Mit Nextcloud Polls kannst du Umfragen direkt in einer Nextcloud erstellen und mit anderen teilen. Das erleichtert zum Beispiel die Terminfindung für das nächste Treffen mit deinen Kollegen oder Freunden. In diesem Artikel erfährst du, wie du Nextcloud Polls installierst, konfigurierst und verwendest.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Umfragen (Polls)</h1>
          
          <p>Mit Nextcloud Polls kannst du Umfragen direkt in einer Nextcloud erstellen und mit anderen teilen. Das erleichtert zum Beispiel die <strong>Terminfindung</strong> für das nächste Treffen mit deinen Kollegen oder Freunden. In diesem Artikel erfährst du, wie du Nextcloud Polls installierst, konfigurierst und verwendest.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={helpIcon} alt="Hilfe Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Umfragen</BlogHeading>
          
          <p>In vielen Situationen lohnt es sich, eine <strong>Nextcloud Umfragen</strong> zu erstellen, um Termine oder Aktivitäten zwischen mehreren Personen abzustimmen. Es ist <strong>übersichtlicher</strong> und einfacher als in einem Gruppenchat über den Termin oder das nächste Ausflugsziel zu diskutieren.</p>
          
          <BlogImage src={speechBubbleIcon} alt="Sprechblase Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Installation</BlogHeading>
          
          <p>Um Nextcloud Umfragen zu installieren, meldest du dich mit einem Administrator-Account in deiner Nextcloud an, klickst auf das runde Icon in der rechten oberen Ecke und dann auf „Apps".<br />Hier kannst du in der Suche einfach „<strong>Polls</strong>" eingeben und dann auf „<strong>Herunterladen und aktivieren</strong>" klicken. In der oberen Menüleiste erscheint nun ein neues Icon mit einem Umfragesymbol.</p>
          
          <BlogImage src={menuScreenshot} alt="Menüleiste mit Polls Icon" size="large" className="my-6" />
          
          <BlogHeading level={3}>Einrichtung</BlogHeading>
          
          <p>Durch Klicken auf „Neue Umfrage hinzufügen" wird eine neue Umfrage gestartet. Du kannst zwischen einer <strong>Datums- und einer Textumfrage</strong> wählen. Hier eine kurze Erklärung:</p>
          
          <ul className="list-disc ml-6 mb-4">
            <li>
              <span style={{ textDecoration: "underline" }}>Datumsumfrage</span>: <br />
              Wann soll etwas stattfinden? Du kannst einen oder mehrere Termine hinzufügen. Es gibt auch die Möglichkeit, weitere Terminvorschläge von den Teilnehmern zuzulassen.
            </li>
            <li>
              <span style={{ textDecoration: "underline" }}>Textumfrage</span>: <br />
              Was soll gemacht werden? Hier kann abgestimmt werden, was in einer Gruppe unternommen werden soll. Es können mehrere Vorschläge vorgegeben werden, aber auch Vorschläge von den Teilnehmern zugelassen werden.<br />
              Da die Einrichtung für beide Arten von Umfragen gleich ist, erklären wir sie am Beispiel der Textumfrage.
            </li>
          </ul>
          
          <p>Nachdem du die Umfrage erstellt hast, erscheint dieses Fenster:</p>
          
          <BlogImage src={newPollScreenshot} alt="Neue Umfrage erstellen" size="large" className="my-6" />
          
          <p>Klicke auf die Schaltfläche „<strong>Datum hinzufügen</strong>", um mindestens ein Datum einzugeben. Wenn du auf „Datum hinzufügen" klickst, öffnet sich ein kleiner Kalender. Hier hast du die Möglichkeit einen oder mehrere Termine einzutragen.</p>
          
          <p>Du kannst zwischen der <strong>Kachelansicht und der Listenansicht</strong> wechseln, um einen besseren Überblick zu bekommen.</p>
          
          <BlogImage src={datePickerScreenshot} alt="Datumsauswahl" size="large" className="my-6" />
          
          <p>Auf der rechten Seite hat sich der Reiter automatisch auf „<strong>Optionen</strong>" verschoben. Hier kannst du, wie oben beschrieben, den Vorschlag der Teilnehmer zulassen. Zusätzlich kannst du festlegen, wie weit die Termine verschoben werden dürfen.</p>
          
          <p>Einen Reiter weiter kannst du schließlich deine <strong>Umfrage freigeben</strong>. Entweder nur für die Nutzer, die sich in deiner Nextcloud befinden oder über einen öffentlichen Link, so dass jeder darauf zugreifen kann.<br />Bei der Freigabe über einen öffentlichen Link kannst du auch gleich einen QR-Code generieren und festlegen, ob für die Anmeldung eine E-Mail-Adresse benötigt wird.</p>
          
          <BlogImage src={sharingScreenshot} alt="Freigabeoptionen" size="large" className="my-6" />
          
          <p>Unter dem Reiter „<strong>Konfiguration</strong>" gibt es weitere Einstellungsmöglichkeiten, wie z.B. das Zulassen von <strong>Kommentaren, Vielleicht-Stimmen, Anonyme Umfragen</strong>, etc.<br />Hier kann die Umfrage auch wieder geschlossen oder zeitlich begrenzt werden.</p>
          
          <p>Die Ergebnisse siehst du immer direkt in der Übersicht. Du hast aber auch die Möglichkeit, die Ergebnisse deiner <strong>Umfrage herunterzuladen</strong>. Klicke dazu einfach auf den „Download Button" oben rechts.</p>
          
          <BlogGallery 
            images={[
              { src: configScreenshot, alt: "Konfiguration" },
              { src: downloadScreenshot, alt: "Download der Ergebnisse" }
            ]}
          />
          
          <BlogHeading level={3}>Übersicht</BlogHeading>
          
          <p>Gehen wir noch einmal einen Schritt zurück und schauen uns die Struktur der App an. Auf der linken Seite findest du unter „<strong>Meine Umfragen</strong>" die soeben erstellte Terminumfrage. Die anderen Kategorien sind ebenfalls selbsterklärend.<br />Weiter unten findest du noch das <strong>Archiv</strong>, in dem alle abgeschlossenen oder gelöschten Umfragen landen. Außerdem gibt es die Möglichkeit, mehrere Terminumfragen zu kombinieren. Ganz unten kannst du allgemeine Einstellungen für die Umfragen vornehmen. Zum Beispiel kannst du hier festlegen, ob der Konflikt mit deinem Kalender direkt angezeigt werden soll.</p>
          
          <p>In der Mitte des Bildschirms wird die ausgewählte Erhebung angezeigt, und auf der rechten Seite befinden sich alle Einstellungsmöglichkeiten für diese Erhebung.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Umfragen (Polls)",
      formattedDate: "13. August 2023",
      excerpt: "Mit Nextcloud Polls kannst du Umfragen direkt in einer Nextcloud erstellen und mit anderen teilen. Das erleichtert zum Beispiel die Terminfindung für das nächste Treffen mit deinen Kollegen oder Freunden. In diesem Artikel erfährst du, wie du Nextcloud Polls installierst, konfigurierst und verwendest.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Nextcloud Polls",
      formattedDate: "August 13, 2023",
      excerpt: "With Nextcloud Polls, you can create polls directly in Nextcloud and share them with others. This makes it easier, for example, to find dates for the next meeting with your colleagues or friends. In this article, you'll learn how to install, configure, and use Nextcloud Polls.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Polls</h1>
          
          <p>With Nextcloud Polls, you can create polls directly in Nextcloud and share them with others. This makes it easier, for example, to find <strong>dates</strong> for the next meeting with your colleagues or friends. In this article, you'll learn how to install, configure, and use Nextcloud Polls.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={helpIcon} alt="Help Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Polls</BlogHeading>
          
          <p>In many situations, it's worth creating a <strong>Nextcloud poll</strong> to coordinate dates or activities between multiple people. It's <strong>more organized</strong> and easier than discussing the date or the next destination in a group chat.</p>
          
          <BlogImage src={speechBubbleIcon} alt="Speech Bubble Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Installation</BlogHeading>
          
          <p>To install Nextcloud Polls, log in to your Nextcloud with an administrator account, click on the round icon in the upper right corner, and then on "Apps".<br />Here you can simply enter "<strong>Polls</strong>" in the search and then click on "<strong>Download and enable</strong>". A new icon with a poll symbol now appears in the top menu bar.</p>
          
          <BlogImage src={menuScreenshot} alt="Menu bar with Polls icon" size="large" className="my-6" />
          
          <BlogHeading level={3}>Setup</BlogHeading>
          
          <p>Clicking on "Add new poll" starts a new poll. You can choose between a <strong>date poll and a text poll</strong>. Here's a brief explanation:</p>
          
          <ul className="list-disc ml-6 mb-4">
            <li>
              <span style={{ textDecoration: "underline" }}>Date poll</span>: <br />
              When should something take place? You can add one or more dates. There is also the option to allow participants to suggest additional dates.
            </li>
            <li>
              <span style={{ textDecoration: "underline" }}>Text poll</span>: <br />
              What should be done? Here, you can vote on what to do in a group. Multiple suggestions can be provided, but suggestions from participants can also be allowed.<br />
              Since the setup is the same for both types of polls, we'll explain it using the text poll as an example.
            </li>
          </ul>
          
          <p>After creating the poll, this window appears:</p>
          
          <BlogImage src={newPollScreenshot} alt="Create new poll" size="large" className="my-6" />
          
          <p>Click on the "<strong>Add date</strong>" button to enter at least one date. When you click on "Add date", a small calendar opens. Here you have the option to enter one or more dates.</p>
          
          <p>You can switch between the <strong>tile view and the list view</strong> to get a better overview.</p>
          
          <BlogImage src={datePickerScreenshot} alt="Date selection" size="large" className="my-6" />
          
          <p>On the right side, the tab has automatically moved to "<strong>Options</strong>". Here you can allow participants to make suggestions, as described above. Additionally, you can define how far the dates can be moved.</p>
          
          <p>One tab further, you can finally <strong>share your poll</strong>. Either only with users who are in your Nextcloud or via a public link so that anyone can access it.<br />When sharing via a public link, you can also generate a QR code and specify whether an email address is required for registration.</p>
          
          <BlogImage src={sharingScreenshot} alt="Sharing options" size="large" className="my-6" />
          
          <p>Under the "<strong>Configuration</strong>" tab, there are more setting options, such as allowing <strong>comments, maybe votes, anonymous polls</strong>, etc.<br />Here the poll can also be closed or time-limited.</p>
          
          <p>You can always see the results directly in the overview. But you also have the option to <strong>download the results</strong> of your poll. Simply click on the "Download button" in the upper right corner.</p>
          
          <BlogGallery 
            images={[
              { src: configScreenshot, alt: "Configuration" },
              { src: downloadScreenshot, alt: "Download results" }
            ]}
          />
          
          <BlogHeading level={3}>Overview</BlogHeading>
          
          <p>Let's take a step back and look at the structure of the app. On the left side, under "<strong>My Polls</strong>", you'll find the date poll you just created. The other categories are also self-explanatory.<br />Further down you'll find the <strong>Archive</strong>, where all completed or deleted polls end up. There's also the option to combine multiple date polls. At the very bottom, you can make general settings for the polls. For example, you can specify whether conflicts with your calendar should be displayed directly.</p>
          
          <p>The selected poll is displayed in the middle of the screen, and all setting options for this poll are on the right side.</p>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Polls",
      formattedDate: "13 August 2023",
      excerpt: "With Nextcloud Polls, you can create polls directly in Nextcloud and share them with others. This makes it easier, for example, to find dates for the next meeting with your colleagues or friends. In this article, you'll learn how to install, configure, and use Nextcloud Polls.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;