// src/data/blogPosts/civicrm-das-open-source-crm-fur-organisationen.js

// Import images
import thumbnailImage from '../../assets/images/blog/civicrm-das-open-source-crm-fur-organisationen-featured.png';
import civiCrmLogo from '../../assets/images/blog/2024/07/CiviCRM-logo-2019-F2-200px.png';
import contactOverview from '../../assets/images/blog/2024/07/Kontakt-hinzufuegen-Uebersicht.png';
import contactDetails from '../../assets/images/blog/2024/07/Kontakt-hinzufuegen-Details.png';
import contributionOverview from '../../assets/images/blog/2024/07/Zuwendungen-Uebersicht.png';
import newContribution from '../../assets/images/blog/2024/07/Neue-Zuwendung-erstellen.png';
import eventManagement from '../../assets/images/blog/2024/07/Veranstaltungen-verwalten.png';
import createEvent from '../../assets/images/blog/2024/07/Veranstaltung-erstellen.png';
import membershipOverview from '../../assets/images/blog/2024/07/Mitgliedschaften-Uebersciht.png';
import findMembers from '../../assets/images/blog/2024/07/Mitglieder-finden.png';
import circularOverview from '../../assets/images/blog/2024/07/Rundschreiben-uebersicht.png';
import createCircular from '../../assets/images/blog/2024/07/Rundschreiben-erstellen.png';
import reportsOverview from '../../assets/images/blog/2024/07/Berichte-Uebersicht.png';
import allReports from '../../assets/images/blog/2024/07/Alle-Berichte.png';
import systemStatus from '../../assets/images/blog/2024/07/CiviCRM-Systemstatus.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Blog post about CiviCRM
 */
const post = {
  id: '20616',
  slug: 'civicrm-das-open-source-crm-fur-organisationen',
  
  // Base data - same regardless of language
  date: "2024-07-05T13:00:49.000Z",
  readTime: 14,
  categories: ["Anleitungen", "Empfehlungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 0,
  comments: [],
  
  // Add searchable content for the entire post
  searchableContent: `CiviCRM Das Open Source CRM für Organisationen CiviCRM im Non-Profit-Sektor Mitgliederverwaltung NGO NPO 
    Vereine Open-Source-Management-Software Drupal WordPress Joomla Kontaktdatenbank Spendenverwaltung Veranstaltungsmanagement 
    Newsletter Kampagnenmodul Reporting Kontaktmanagement Profile Beitragsmanagement Zuwendungen Spendenkampagnen Zahlungen
    Eventmanagement Anmeldungen Teilnahmegebühren Erinnerungen Mitgliederverwaltung Mitgliedsbeiträge Mitgliedschaftsstufen
    Kommunikation automatisieren E-Mail SMS Marketing-Tool Newsletter Berichte Berichterstattung Installation PlugIn`,
  
  // Language-specific data
  translations: {
    // German content as default
    de: {
      title: "CiviCRM – Das Open Source CRM für Organisationen",
      formattedDate: "5. Juli 2024",
      excerpt: "CiviCRM – Das Open Source CRM im Non-Profit-Sektor Die Open Source Software CiviCRM dient der Mitgliederverwaltung im Non-Profit-Sektor wie NGOs, NPOs und Vereinen. In diesem Artikel stellen wir die Software vor und zeigen die wichtigsten Funktionen.",
      searchableContent: `CiviCRM Das Open Source CRM für Organisationen CiviCRM im Non-Profit-Sektor Mitgliederverwaltung NGO NPO 
        Vereine Open-Source-Management-Software Drupal WordPress Joomla Kontaktdatenbank Spendenverwaltung Veranstaltungsmanagement 
        Newsletter Kampagnenmodul Reporting Kontaktmanagement Profile Beitragsmanagement Zuwendungen Spendenkampagnen Zahlungen
        Eventmanagement Anmeldungen Teilnahmegebühren Erinnerungen Mitgliederverwaltung Mitgliedsbeiträge Mitgliedschaftsstufen
        Kommunikation automatisieren E-Mail SMS Marketing-Tool Newsletter Berichte Berichterstattung Installation PlugIn`,
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">CiviCRM – Das Open Source CRM im Non-Profit-Sektor</h1>
          
          <p>Die Open Source Software CiviCRM dient der Mitgliederverwaltung im Non-Profit-Sektor wie NGOs, NPOs und Vereinen. In diesem Artikel stellen wir die Software vor und zeigen die wichtigsten Funktionen.</p>

          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={civiCrmLogo} alt="CiviCRM Logo" size="small" />
            </div>
            
          </div>
          
          <BlogHeading>CiviCRM – Mehr als nur Mitgliederverwaltung</BlogHeading>
          
          <p>CiviCRM ist eine Open-Source-Management-Software, die speziell für Non-Profit-Organisationen, gemeinnützige Organisationen und zivilgesellschaftliche Gruppen entwickelt wurde. Die Software ist vollständig anpassbar und kann je nach Bedarf konfiguriert werden. CiviCRM kann einfach zu WordPress, Joomla oder Drupal hinzugefügt werden, eine separate Installation ist nicht erforderlich.</p>
          
          <BlogHeading>Hauptfunktionen</BlogHeading>
          
          <p>Ausgehend von einer Kontaktdatenbank kann die Software mit zahlreichen Modulen erweitert und um viele interessante Funktionen ergänzt werden. Neben der Mitglieder- und Spendenverwaltung gibt es ein Veranstaltungsmanagement, ein Newsletter- und Kampagnenmodul sowie ein umfangreiches Reporting für alle Vorgänge.</p>
          
          <BlogSection title="Kontaktmanagement">
            <p>CiviCRM bietet umfangreiche Funktionen zur Verwaltung von Kontakten. Für Personen und Organisationen können detaillierte Profile angelegt und verwaltet werden. Spezifische Datenfelder können hinzugefügt und angepasst werden, um besonderen Anforderungen gerecht zu werden.</p>
            
            <BlogGallery 
              images={[
                { src: contactOverview, alt: "Kontakt-Übersicht" },
                { src: contactDetails, alt: "Kontakt-Details" }
              ]}
            />
          </BlogSection>
          
          <BlogSection title="Beitragsmanagement">
            <p>Eine weitere zentrale Funktion ist die Verwaltung von Spenden und anderen finanziellen Zuwendungen. Es können Spendenkampagnen eingerichtet, Zahlungen über verschiedene Kanäle entgegengenommen und umfassende Berichte erstellt werden.</p>
            
            <BlogGallery 
              images={[
                { src: contributionOverview, alt: "Zuwendungen-Übersicht" },
                { src: newContribution, alt: "Neue Zuwendung erstellen" }
              ]}
            />
          </BlogSection>
          
          <BlogSection title="Eventmanagement">
            <p>Mit CiviCRM können Veranstaltungen sauber geplant und durchgeführt werden. Veranstaltungen können im System erstellt, Anmeldungen verwaltet, Teilnahmegebühren festgelegt und automatische Erinnerungen und Nachfassaktionen versendet werden.</p>
            
            <BlogGallery 
              images={[
                { src: eventManagement, alt: "Veranstaltungen verwalten" },
                { src: createEvent, alt: "Veranstaltung erstellen" }
              ]}
            />
          </BlogSection>
          
          <BlogSection title="Mitgliederverwaltung">
            <p>Das Modul Mitgliederverwaltung ermöglicht es deiner Organisation, ihre Mitglieder zu verwalten, Mitgliedsbeiträge zu überwachen, Mitgliedschaftsstufen zu definieren und die Kommunikation zu automatisieren.</p>
            
            <BlogGallery 
              images={[
                { src: membershipOverview, alt: "Mitgliedschaften-Übersicht" },
                { src: findMembers, alt: "Mitglieder finden" }
              ]}
            />
          </BlogSection>
          
          <BlogSection title="Kommunikation">
            <p>Das integrierte E-Mail- und SMS-Marketing-Tool ermöglicht den gezielten und personalisierten Versand von Nachrichten an Kontakte und Mitglieder. Diese Funktion kann z.B. für einen regelmäßigen Newsletter, spezielle Ankündigungen oder Kampagnen-Updates genutzt werden.</p>
            
            <BlogGallery 
              images={[
                { src: circularOverview, alt: "Rundschreiben-Übersicht" },
                { src: createCircular, alt: "Rundschreiben erstellen" }
              ]}
            />
          </BlogSection>
          
          <BlogSection title="Berichterstattung">
            <p>Das System bietet eine detaillierte Berichtsfunktion, mit der Daten analysiert und individuell anpassbare Berichte erstellt werden können. Diese Berichte können verwendet werden, um den Erfolg von Kampagnen zu messen, Spendentrends zu analysieren und strategische Entscheidungen zu treffen.</p>
            
            <BlogGallery 
              images={[
                { src: reportsOverview, alt: "Berichte-Übersicht" },
                { src: allReports, alt: "Alle Berichte" }
              ]}
            />
          </BlogSection>
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Für eine bestehende WordPress Installation kann das PlugIn einfach von der CiviCRM Webseite heruntergeladen und in WordPress installiert werden.<br />
          <a href="https://civicrm.org/download" target="_blank" rel="noopener">https://civicrm.org/download</a></p>
          
          <p>Danach erscheint auf der rechten Seite des WordPress-Dashboards ein neues Icon mit „CiviCRM".</p>
          
          <p>Unter „Administration – Verwaltungskonsole – Systemstatus" werden alle Warnungen und Probleme mit der zugehörigen Lösung angezeigt. Dies könnte z.B. so aussehen:</p>
          
          <BlogImage src={systemStatus} alt="CiviCRM Systemstatus" size="large" />
          
          <p>Unter „Administration – Systemeinstellungen – Erweiterungen" kann CiviCRM um weitere Funktionen erweitert werden. So können z.B. Zahlungsdienstleister eingebunden werden, um Mitgliedsbeiträge automatisch einziehen zu können.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "CiviCRM – Das Open Source CRM für Organisationen",
      formattedDate: "5. Juli 2024",
      excerpt: "CiviCRM – Das Open Source CRM im Non-Profit-Sektor Die Open Source Software CiviCRM dient der Mitgliederverwaltung im Non-Profit-Sektor wie NGOs, NPOs und Vereinen. In diesem Artikel stellen wir die Software vor und zeigen die wichtigsten Funktionen.",
      searchableContent: `CiviCRM Das Open Source CRM für Organisationen CiviCRM im Non-Profit-Sektor Mitgliederverwaltung NGO NPO 
        Vereine Open-Source-Management-Software Drupal WordPress Joomla Kontaktdatenbank Spendenverwaltung Veranstaltungsmanagement 
        Newsletter Kampagnenmodul Reporting Kontaktmanagement Profile Beitragsmanagement Zuwendungen Spendenkampagnen Zahlungen`,
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation - placeholder, would need a complete translation
    en: {
      title: "CiviCRM – The Open Source CRM for Organizations",
      formattedDate: "July 5, 2024",
      excerpt: "CiviCRM – The Open Source CRM for the Non-Profit Sector. The open source software CiviCRM is used for membership management in the non-profit sector such as NGOs, NPOs, and associations. In this article, we introduce the software and show its most important functions.",
      searchableContent: `CiviCRM The Open Source CRM for Organizations Non-Profit Sector membership management NGO NPO 
        associations open source management software Drupal WordPress Joomla contact database donation management event management 
        newsletter campaign module reporting contact management profiles contribution management payments`,
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">CiviCRM – The Open Source CRM for the Non-Profit Sector</h1>
          
          <p>The open source software CiviCRM is used for membership management in the non-profit sector such as NGOs, NPOs, and associations. In this article, we introduce the software and show its most important functions.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={civiCrmLogo} alt="CiviCRM Logo" size="small" />
            </div>
            
          </div>
          
          {/* Full English translation would continue here */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "CiviCRM – The Open Source CRM for Organisations",
      formattedDate: "5 July 2024",
      excerpt: "CiviCRM – The Open Source CRM for the Non-Profit Sector. The open source software CiviCRM is used for membership management in the non-profit sector such as NGOs, NPOs, and associations. In this article, we introduce the software and show its most important functions.",
      searchableContent: `CiviCRM The Open Source CRM for Organisations Non-Profit Sector membership management NGO NPO 
        associations open source management software Drupal WordPress Joomla contact database donation management event management 
        newsletter campaign module reporting contact management profiles contribution management payments`,
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;