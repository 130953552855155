// src/data/blogPosts/libresign-elektronische-signatur.js

// Import images
import libreSignLogo from '../../assets/images/blog/2024/05/LireSign-Logo.png';
import libresignDownload from '../../assets/images/blog/2024/05/LibreSign-Herunterlden-und-aktivieren.png';
import libresignAdmin from '../../assets/images/blog/2024/05/LibreSign-Verwaltungseinstellungen.png';
import libresignSignRequest from '../../assets/images/blog/2024/05/Anforerung-zum-Signieren-erlauben.png';
import libresignSignatureCustomize from '../../assets/images/blog/2024/05/Passe-deine-Signatur-an.png';
import libresignCreateCertificate from '../../assets/images/blog/2024/05/Zertifikat-erstellen.png';
import libresignExternalSign from '../../assets/images/blog/2024/05/Externe-Person-signieren.png';
import libresignPlaceSignature from '../../assets/images/blog/2024/05/Unterschrift-platzieren.png';
import thumbnailImage from '../../assets/images/blog/libresign-elektronische-signatur-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about LibreSign electronic signatures
 */
const post = {
  id: '20380',
  slug: 'libresign-elektronische-signatur',
  
  // Base data - same regardless of language
  date: "2024-05-28T14:54:55.000Z",
  readTime: 11,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 0,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "LibreSign – Elektronische Signatur",
      formattedDate: "28. Mai 2024",
      excerpt: "Mit LibreSign können Dokumente elektronisch signiert werden. In diesem Artikel gehen wir auf die Vorteile ein und zeigen dir, wie du die elektronische Signatur mit LibreSign in deiner Nextcloud einrichten und nutzen kannst.",
      content: () => (
        <BlogContent>
          <p>Mit LibreSign können Dokumente elektronisch signiert werden. In diesem Artikel gehen wir auf die Vorteile ein und zeigen dir, wie du die elektronische Signatur mit LibreSign in deiner Nextcloud einrichten und nutzen kannst.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={libreSignLogo} alt="LibreSign Logo" size="medium" />
          </div>
          
          <BlogHeading>Elektronische Signatur</BlogHeading>
          
          <p>Eine elektronische Signatur ist eine digitale Form der Unterschrift, mit der Dokumente unterzeichnet werden können. Sie wird verwendet, um die Zustimmung und Identität des Unterzeichners digital zu bestätigen, ähnlich einer handschriftlichen Unterschrift auf Papier. Elektronische Signaturen sind rechtlich anerkannt und werden häufig in Geschäftsprozessen, Vertragsabschlüssen und anderen rechtlichen Transaktionen verwendet, um Dokumente schnell und sicher digital zu validieren.</p>
          
          <div className="flex justify-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" className="text-gray-600">
              <path d="M15.812 4.819c-.33-.341-.312-.877.028-1.207l3.469-3.365c.17-.164.387-.247.603-.247.219 0 .438.085.604.257l-4.704 4.562zm-5.705 8.572c-.07.069-.107.162-.107.255 0 .194.158.354.354.354.089 0 .178-.033.247-.1l.583-.567-.493-.509-.584.567zm4.924-6.552l-1.994 1.933c-1.072 1.039-1.619 2.046-2.124 3.451l.881.909c1.419-.461 2.442-.976 3.514-2.016l1.994-1.934-2.271-2.343zm5.816-5.958l-5.137 4.982 2.586 2.671 5.138-4.98c.377-.366.566-.851.566-1.337 0-1.624-1.968-2.486-3.153-1.336zm-11.847 12.119h-4v1h4v-1zm9-1.35v1.893c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h12.629l2.062-2h-16.691v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-4.687l-2 1.951z"></path>
            </svg>
          </div>
          
          <BlogHeading>LibreSign Grundeinrichtung</BlogHeading>
          
          <p>Um die App in deiner Nextcloud zu installieren, logge dich als Administrator ein, klicke auf das Icon in der rechten oberen Ecke und wähle „Apps".<br />In der Suche kannst du nun „LibreSign" eingeben und die App wird dir direkt angezeigt. Klicke dann auf „Herunterladen & Aktivieren".</p>
          
          <BlogImage src={libresignDownload} alt="LibreSign herunterladen und aktivieren" size="large" />
          
          <p>Danach klicke erneut auf das Icon in der rechten oberen Ecke und wähle „Verwaltungseinstellungen". Unter „Verwaltung" findest du einen neuen Eintrag mit dem Namen „LibreSign".</p>
          
          <p>Klicke auf den Button „Binärdateien herunterladen", um alle Abhängigkeiten zu installieren. Erstelle anschließend ein neues Root-Zertifikat, indem du auf den Button „Root-Zertifikat erstellen" klickst.</p>
          
          <BlogImage src={libresignAdmin} alt="LibreSign Verwaltungseinstellungen" size="large" />
          
          <p>Damit auch Nutzer außer der Administrator Dokumente signieren können, wird bei „Anforderung zum Signieren erlauben" die entsprechende Benutzergruppe ausgewählt. Falls du noch keine extra Gruppe für die Nutzer angelegt hast, musst du zuerst eine erstellen.</p>
          
          <BlogImage src={libresignSignRequest} alt="Anforderung zum Signieren erlauben" size="large" />
          
          <BlogHeading>LibreSign Benutzereinrichtung</BlogHeading>
          
          <p>Nach der Grundeinrichtung kann jeder Benutzer in deiner Nextcloud seine persönliche Signatur erstellen. Klicke dazu in der oberen Menüleiste auf das „LibreSign Symbol" und dann auf „Einstellungen – Benutzerkonto".<br />Als erstes wird eine neue Signatur erstellt. Klicke dazu auf den Button mit der Aufschrift „Keine Signatur, klicke hier um eine neue zu erstellen".<br />Du hast die Möglichkeit, die Signatur selbst zu zeichnen, ein Bild hochzuladen oder eine die Unterschrift über die Tastatur einzugeben.<br />Im letzten Schritt wird das Zertifikat erstellt. Klicke dazu auf „Zertifikat erstellen".</p>
          
          <div className="flex flex-col md:flex-row gap-6 my-6">
            <BlogImage src={libresignSignatureCustomize} alt="Passe deine Signatur an" size="medium" />
            <BlogImage src={libresignCreateCertificate} alt="Zertifikat erstellen" size="medium" />
          </div>
          
          <BlogHeading>Dokument signieren</BlogHeading>
          
          <p>Ab sofort können Dokumente im Handumdrehen signiert werden. Klicke dazu bei einer PDF-Datei auf die drei Punkte und wähle „In LibrSign öffnen". In der Seitenleiste klicke auf „Unterzeichner hinzufügen".<br />Wenn du das Dokument selbst signieren möchtest, gib einfach deinen Benutzernamen in die Suche ein und trage deinen Namen ein.<br />Natürlich kannst du auch Personen zum Signieren einladen, die keinen Account in deiner Nextcloud haben. Gib dazu einfach die E-Mail-Adresse ein.</p>
          
          <BlogImage src={libresignExternalSign} alt="Externe Person signieren" size="medium" className="mx-auto" />
          
          <p>Nachdem alle Personen hinzugefügt wurden, die unterschreiben sollen, klicke auf „Weiter".</p>
          
          <p>Das Dokument öffnet sich und die Platzhalter für die Unterschriften können gesetzt werden.</p>
          
          <BlogImage src={libresignPlaceSignature} alt="Unterschrift platzieren" size="medium" className="mx-auto" />
          
          <p>Anschließend auf „Anfragen" klicken. Eine E-Mail mit einem Link wird an die externe Person gesendet, damit sie das Dokument signieren kann. Du selbst kannst einfach auf „Signieren" klicken.</p>
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p><u>Effizienzsteigerung</u><br />Elektronische Signaturen reduzieren den Zeitaufwand für die Erstellung und Übermittlung von Dokumenten erheblich. Dokumente können innerhalb weniger Minuten signiert und versendet werden, was den Workflow beschleunigt und die Effizienz steigert.</p>
          
          <p><u>Kosteneinsparungen</u><br />Die Umstellung auf eine papierlose Umgebung spart Kosten für Druck, Papier und Versand.</p>
          
          <p><u>Sicherheit</u><br />Elektronische Signaturen bieten verbesserte Sicherheitsfunktionen wie Verschlüsselung und Zeitstempel, die sicherstellen, dass Dokumente nicht manipuliert werden können. Außerdem kann die Identität der Unterzeichner besser überprüft werden.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "LibreSign – Elektronische Signatur",
      formattedDate: "28. Mai 2024",
      excerpt: "Mit LibreSign können Dokumente elektronisch signiert werden. In diesem Artikel gehen wir auf die Vorteile ein und zeigen dir, wie du die elektronische Signatur mit LibreSign in deiner Nextcloud einrichten und nutzen kannst.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "LibreSign – Electronic Signature",
      formattedDate: "May 28, 2024",
      excerpt: "With LibreSign, documents can be electronically signed. In this article, we explain the benefits and show you how to set up and use electronic signatures with LibreSign in your Nextcloud.",
      content: () => (
        <BlogContent>
          <p>With LibreSign, documents can be electronically signed. In this article, we explain the benefits and show you how to set up and use electronic signatures with LibreSign in your Nextcloud.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={libreSignLogo} alt="LibreSign Logo" size="medium" />
          </div>
          
          <BlogHeading>Electronic Signature</BlogHeading>
          
          <p>An electronic signature is a digital form of signature used to sign documents. It is used to digitally confirm the consent and identity of the signer, similar to a handwritten signature on paper. Electronic signatures are legally recognized and commonly used in business processes, contract closings, and other legal transactions to quickly and securely validate documents digitally.</p>
          
          <div className="flex justify-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 24 24" className="text-gray-600">
              <path d="M15.812 4.819c-.33-.341-.312-.877.028-1.207l3.469-3.365c.17-.164.387-.247.603-.247.219 0 .438.085.604.257l-4.704 4.562zm-5.705 8.572c-.07.069-.107.162-.107.255 0 .194.158.354.354.354.089 0 .178-.033.247-.1l.583-.567-.493-.509-.584.567zm4.924-6.552l-1.994 1.933c-1.072 1.039-1.619 2.046-2.124 3.451l.881.909c1.419-.461 2.442-.976 3.514-2.016l1.994-1.934-2.271-2.343zm5.816-5.958l-5.137 4.982 2.586 2.671 5.138-4.98c.377-.366.566-.851.566-1.337 0-1.624-1.968-2.486-3.153-1.336zm-11.847 12.119h-4v1h4v-1zm9-1.35v1.893c0 4.107-6 2.457-6 2.457s1.518 6-2.638 6h-7.362v-20h12.629l2.062-2h-16.691v24h10.189c3.163 0 9.811-7.223 9.811-9.614v-4.687l-2 1.951z"></path>
            </svg>
          </div>
          
          <BlogHeading>LibreSign Basic Setup</BlogHeading>
          
          <p>To install the app in your Nextcloud, log in as an administrator, click on the icon in the upper right corner, and select "Apps".<br />In the search, enter "LibreSign" and the app will be displayed directly. Then click on "Download & Activate".</p>
          
          <BlogImage src={libresignDownload} alt="Download and activate LibreSign" size="large" />
          
          <p>After that, click again on the icon in the upper right corner and select "Administration Settings". Under "Administration", you will find a new entry named "LibreSign".</p>
          
          <p>Click on the "Download binaries" button to install all dependencies. Then create a new root certificate by clicking on the "Create root certificate" button.</p>
          
          <BlogImage src={libresignAdmin} alt="LibreSign administration settings" size="large" />
          
          <p>So that users other than the administrator can sign documents, select the appropriate user group at "Allow signing request". If you haven't created a special group for the users yet, you need to create one first.</p>
          
          <BlogImage src={libresignSignRequest} alt="Allow signing request" size="large" />
          
          <BlogHeading>LibreSign User Setup</BlogHeading>
          
          <p>After the basic setup, each user in your Nextcloud can create their personal signature. To do this, click on the "LibreSign symbol" in the top menu bar and then on "Settings - User account".<br />First, create a new signature. Click on the button labeled "No signature, click here to create a new one".<br />You have the option to draw the signature yourself, upload an image, or enter the signature via the keyboard.<br />In the last step, the certificate is created. Click on "Create certificate" to do this.</p>
          
          <div className="flex flex-col md:flex-row gap-6 my-6">
            <BlogImage src={libresignSignatureCustomize} alt="Customize your signature" size="medium" />
            <BlogImage src={libresignCreateCertificate} alt="Create certificate" size="medium" />
          </div>
          
          <BlogHeading>Signing a Document</BlogHeading>
          
          <p>From now on, documents can be signed in no time. To do this, click on the three dots next to a PDF file and select "Open in LibreSign". In the sidebar, click on "Add signer".<br />If you want to sign the document yourself, simply enter your username in the search and enter your name.<br />Of course, you can also invite people who don't have an account in your Nextcloud to sign. Simply enter the email address for this.</p>
          
          <BlogImage src={libresignExternalSign} alt="External person signing" size="medium" className="mx-auto" />
          
          <p>After all people who should sign have been added, click on "Continue".</p>
          
          <p>The document opens and the placeholders for the signatures can be placed.</p>
          
          <BlogImage src={libresignPlaceSignature} alt="Place signature" size="medium" className="mx-auto" />
          
          <p>Then click on "Request". An email with a link is sent to the external person so they can sign the document. You yourself can simply click on "Sign".</p>
          
          <BlogHeading>Benefits</BlogHeading>
          
          <p><u>Increased Efficiency</u><br />Electronic signatures significantly reduce the time required for creating and transmitting documents. Documents can be signed and sent within minutes, which accelerates the workflow and increases efficiency.</p>
          
          <p><u>Cost Savings</u><br />Switching to a paperless environment saves costs for printing, paper, and shipping.</p>
          
          <p><u>Security</u><br />Electronic signatures offer enhanced security features such as encryption and timestamps, ensuring that documents cannot be tampered with. Additionally, the identity of signers can be better verified.</p>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "LibreSign – Electronic Signature",
      formattedDate: "28 May 2024",
      excerpt: "With LibreSign, documents can be electronically signed. In this article, we explain the benefits and show you how to set up and use electronic signatures with LibreSign in your Nextcloud.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;