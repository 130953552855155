import {
  faFileContract,
  faContactCard,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

const Footer = () => {
  const { t } = useTranslation('footer')
  const location = useLocation()
  
  // Function to check if a link is active
  const isActive = (path) => {
    return location.pathname.includes(path) ? 'text-cyan-400' : 'text-gray-400 hover:text-cyan-400';
  }
  
  return (
    <footer className="bg-gray-900 py-8 border-t border-gray-800 mt-12">
      <div className="container mx-auto px-4">
        {/* Footer Links Section - responsive grid for better mobile layout */}
        <div className="flex flex-col md:flex-row justify-center items-center md:items-start gap-6 md:gap-12 mb-8">
          <Link 
            to="/agb" 
            className={`flex items-center transition-colors duration-300 ${isActive('/agb')}`}
          >
            <FontAwesomeIcon icon={faFileContract} className="mr-2" />
            <span>{t('agb')}</span>
          </Link>
          
          <Link 
            to="/impressum" 
            className={`flex items-center transition-colors duration-300 ${isActive('/impressum')}`}
          >
            <FontAwesomeIcon icon={faContactCard} className="mr-2" />
            <span>{t('impressum')}</span>
          </Link>
          
          <Link 
            to="/privacy" 
            className={`flex items-center transition-colors duration-300 ${isActive('/privacy')}`}
          >
            <FontAwesomeIcon icon={faShieldAlt} className="mr-2" />
            <span>{t('privacy-agreement')}</span>
          </Link>
        </div>
        
        {/* Copyright Section */}
        <div className="text-center text-gray-400 text-sm">
          © {new Date().getFullYear()} PathConnect GmbH
        </div>
      </div>
    </footer>
  )
}

export default Footer