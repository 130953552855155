// src/data/blogPosts/cloud-act-was-steckt-dahinter.js

// Import images
import letterIcon from '../../assets/images/blog/2022/01/letter.png';
import worldIcon from '../../assets/images/blog/2022/02/world.png';
import monitoringIcon from '../../assets/images/blog/2021/11/iconmonstr-monitoring-6-240.png';
import stopIcon from '../../assets/images/blog/2022/02/stop.png';
import cloudIcon from '../../assets/images/blog/2022/04/cloud.png';
import thumbnailImage from '../../assets/images/blog/cloud-act-was-steckt-dahinter-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Blog post about the CLOUD Act and its implications
 */
const post = {
  id: '7867',
  slug: 'cloud-act-was-steckt-dahinter',
  
  // Base data - same regardless of language
  date: "2022-04-29T11:45:23.000Z",
  readTime: 7,
  categories: ["Informationen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 30,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "CLOUD Act – Was steckt dahinter?",
      formattedDate: "29. April 2022",
      excerpt: "In diesem Blogartikel erklären wir euch was hinter dem sogenannten CLOUD Act steht, was er für uns bedeutet und warum er ein Problem darstellt.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">CLOUD Act – Was steckt dahinter?</h1>
          
          <p>In diesem Blogartikel erklären wir euch was hinter dem sogenannten CLOUD Act steht, was er für uns bedeutet und warum er ein Problem darstellt.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={letterIcon} alt="Brief Icon" size="icon" />
            </div>
            
          </div>
          
          <BlogHeading>Was ist der CLOUD Act?</BlogHeading>
          
          <p>ClOUD Act steht für "Clarifying Lawful Overseas Use of Data Act", das Gesetz regelt den Umgang mit Unternehmensdaten, die außerhalb der USA gespeichert sind.</p>
          
          <BlogImage src={worldIcon} alt="Welt Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Was beinhaltet CLOUD Act?</BlogHeading>
          
          <p>US-Behörden sind dazu berechtigt auf sämtliche Daten zuzugreifen, auch wenn sich diese nicht in den USA, sondern in Europa wie z.B. Deutschland befinden.<br />Für die Offenlegung sämtlicher Daten bedarf es keinen richterlichen Beschluss, die Anfrage einer ermächtigten US-Behörde ist allein schon ausreichend. Auch besteht hier keinerlei Informationspflicht gegenüber den Inhabern. Das bedeutet, dass im Fall einer Dateneinsicht die betroffene Personen oder die betroffenen Unternehmen nicht dazu informiert werden müssen.</p>
          
          <BlogImage src={monitoringIcon} alt="Überwachung Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Welche Probleme ergeben sich dadurch?</BlogHeading>
          
          <p>Persönliche Daten, Telemetriedaten, Unternehmensdaten, wirtschaftliche Informationen, Geschäftsgeheimnisse, geistiges Eigentum usw. können von den US-Behörden eingesehen werden, sobald diese durch ein US-Unternehmen gespeichert wurden.<br />Damit kollidiert CLOUD Act mit den deutschen Gesetzen wie z.B. der DSGVO.</p>
          
          <BlogImage src={stopIcon} alt="Stop-Schild" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Was bedeutet das für Cloud-Dienste mit Sitz in den USA?</BlogHeading>
          
          <p>Durch den Widerspruch von CLOUD-Act und DSGVO verstoßen die Cloud-Dienstleister mit Sitz in den USA entweder gegen die DSGVO oder dem CLOUD Act</p>
          
          <BlogHeading>Welche Maßnahmen können deutsche Unternehmen treffen?</BlogHeading>
          
          <p>Bei der Auswahl eines Cloud Anbieters sollte darauf geachtet werden ein Unternehmen mit Sitz in Deutschland zu wählen. Denn diese unterliegen ausschließlich der DSGVO und nicht dem CLOUD Act.</p>
          
          <BlogImage src={cloudIcon} alt="Cloud Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "CLOUD Act – Was steckt dahinter?",
      formattedDate: "29. April 2022",
      excerpt: "In diesem Blogartikel erklären wir euch was hinter dem sogenannten CLOUD Act steht, was er für uns bedeutet und warum er ein Problem darstellt.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation 
    en: {
      title: "CLOUD Act – What's behind it?",
      formattedDate: "April 29, 2022",
      excerpt: "In this blog post, we explain what the CLOUD Act is, what it means for us, and why it presents a problem.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">CLOUD Act – What's behind it?</h1>
          
          <p>In this blog post, we explain what the CLOUD Act is, what it means for us, and why it presents a problem.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={letterIcon} alt="Letter Icon" size="icon" />
            </div>
            
          </div>
          
          <BlogHeading>What is the CLOUD Act?</BlogHeading>
          
          <p>CLOUD Act stands for "Clarifying Lawful Overseas Use of Data Act," a law that regulates the handling of corporate data stored outside the United States.</p>
          
          <BlogImage src={worldIcon} alt="World Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>What does the CLOUD Act cover?</BlogHeading>
          
          <p>US authorities are entitled to access all data, even if it is not located in the USA but in Europe, e.g., Germany.<br />The disclosure of all data does not require a court order; the request of an authorized US authority is already sufficient. There is also no obligation to inform the owners. This means that in the case of data access, the affected persons or the affected companies do not have to be informed.</p>
          
          <BlogImage src={monitoringIcon} alt="Monitoring Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>What problems arise from this?</BlogHeading>
          
          <p>Personal data, telemetry data, company data, economic information, trade secrets, intellectual property, etc. can be viewed by US authorities as soon as they are stored by a US company.<br />This puts the CLOUD Act in conflict with German laws such as the GDPR.</p>
          
          <BlogImage src={stopIcon} alt="Stop Sign" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>What does this mean for cloud services based in the USA?</BlogHeading>
          
          <p>Due to the contradiction between CLOUD Act and GDPR, cloud service providers based in the USA violate either the GDPR or the CLOUD Act.</p>
          
          <BlogHeading>What measures can German companies take?</BlogHeading>
          
          <p>When selecting a cloud provider, it is important to choose a company based in Germany. These are exclusively subject to the GDPR and not to the CLOUD Act.</p>
          
          <BlogImage src={cloudIcon} alt="Cloud Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open-source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customization, and updates, so you can focus entirely on your projects.</p>
          
          
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "CLOUD Act – What's behind it?",
      formattedDate: "29 April 2022",
      excerpt: "In this blog post, we explain what the CLOUD Act is, what it means for us, and why it presents a problem.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;