// src/components/blog/BlogImage.js
import React, { useState } from 'react';
import ImageModal from './ImageModal';

/**
 * A consistently styled image component for blog posts
 * Updated to include lightbox functionality when clicked
 */
const BlogImage = ({
  src,
  alt,
  caption,
  className = '',
  size = 'medium', // Options: 'icon', 'small', 'medium', 'large', 'full'
  center = true,
  rounded = true,
  shadow = true,
  lightbox = true // Enable/disable lightbox functionality
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  
  // Map size options to percentage-based widths for better responsiveness
  const sizeMap = {
    icon: '25%',
    small: '50%',
    medium: '75%',
    large: '100%',
    full: '100%'
  };
  
  const maxWidth = sizeMap[size] || sizeMap.medium;
  const centerClass = center ? 'mx-auto' : '';
  const roundedClass = rounded ? 'rounded-md' : '';
  const shadowClass = shadow ? 'shadow-sm hover:shadow-md transition-shadow' : '';
  
  const handleImageClick = () => {
    if (lightbox) {
      setModalOpen(true);
    }
  };
  
  return (
    <>
      <figure className={`image-container my-4 ${center ? 'text-center' : ''} ${className}`}>
        <img 
          src={src} 
          alt={alt} 
          className={`${centerClass} ${roundedClass} ${shadowClass} w-full ${lightbox ? 'cursor-pointer' : ''}`}
          style={{ maxWidth, height: 'auto' }} 
          onClick={handleImageClick}
          title={lightbox ? "Click to enlarge" : ""}
        />
        {caption && (
          <figcaption className="mt-2 text-sm text-gray-600 dark:text-gray-400">
            {caption}
          </figcaption>
        )}
      </figure>
      
      {/* Image Modal/Lightbox */}
      <ImageModal 
        src={src}
        alt={alt}
        caption={caption}
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
};

export default BlogImage;