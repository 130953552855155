// src/data/blogPosts/keepassxc.js

// Import images
import keepassxcLogo from '../../assets/images/blog/2022/12/KeePassXC.png';
import lockIcon from '../../assets/images/blog/2022/01/lock.png';
import syncIcon from '../../assets/images/blog/2022/03/sync.png';
import donateIcon from '../../assets/images/blog/2022/12/iconmonstr-banknote-14-240.png';
import welcomeScreen from '../../assets/images/blog/2022/12/welcome_screen-1024x748.png';
import newDbWizard1 from '../../assets/images/blog/2022/12/new_db_wizard_1-1024x636.png';
import newDbWizard2 from '../../assets/images/blog/2022/12/new_db_wizard_2-1024x780.png';
import newDbWizard3 from '../../assets/images/blog/2022/12/new_db_wizard_3-1024x772.png';
import editEntry from '../../assets/images/blog/2022/12/edit_entry-1024x754.png';
import passwordGenerator from '../../assets/images/blog/2022/12/password_generator_advanced-1024x748.png';
import thumbnailImage from '../../assets/images/blog/keepassxc-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about KeePassXC password manager
 */
const post = {
  id: '10005',
  slug: 'keepassxc',
  
  // Base data - same regardless of language
  date: "2022-12-07T11:59:24.000Z",
  readTime: 13,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 9,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "KeePassXC",
      formattedDate: "7. Dezember 2022",
      excerpt: "In diesem Blogartikel geht um den Passwortmanager KeePassXC. Du erfährst was ein Passwortmanager ist, wie du KeePassXC einrichtest, deine Passwörter in Nextcloud öffnen kannst und welche Vorteile du dadurch hast.",
      content: () => (
        <BlogContent>
          <p className="western">In diesem Blogartikel geht um den <b>Passwortmanager KeePassXC</b>. Du erfährst was ein Passwortmanager ist, wie du KeePassXC einrichtest, deine Passwörter in Nextcloud öffnen kannst und welche Vorteile du dadurch hast.</p>
          
          <p>Ein Video dazu findest du hier: <br />
          <a href="https://www.youtube.com/watch?v=jRJnvy9wMcg" target="_blank" rel="noopener">https://www.youtube.com/watch?v=jRJnvy9wMcg</a></p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={keepassxcLogo} alt="KeePassXC Logo" size="small" />
          </div>
          
          <BlogHeading>Passwortmanager</BlogHeading>
          
          <p className="western">Die meisten von uns haben so <b>viele Accounts</b>, dass wir diese schon lange nicht mehr an einer Hand abzählen können. Mittlerweile weiß auch jeder, dass immer ein <b>anderes Passwort mit ausreichender Länge und Komplexität</b> verwendet werden sollte.</p>
          
          <p className="western">Aber wie merkt man sich denn so vielen Passwörter und woher weiß ich ob es komplex genug und sicher ist?</p>
          
          <p className="western">Hier kommt ein Passwortmanager wie KeePassXC ins Spiel, mit dem du für jeden Account einfach ein eigenes <b>Passwort generieren</b> und dann abspeichern kannst.</p>
          
          <BlogHeading level={3}>Installation und Einrichtung</BlogHeading>
          
          <p className="western">KeePassXC ist für Linux, macOS und Windows verfügbar und kann direkt von der ofiziellen Seiten herunterladen werden.</p>
          
          <p className="western"><a href="https://keepassxc.org/download/" target="_blank" rel="noopener">https://keepassxc.org/download/</a></p>
          
          <p className="western">Nach der Installation wird eine neue Passwortdatenbank angelegt. Klicke dazu auf „<b>Neue Datenbank erstellen</b>". Im folgenden Fenster kannst du einen Datenbanknamen eingeben und optional noch eine kurze Beschreibung hinzufügen.</p>
          
          <BlogGallery 
            images={[
              { src: welcomeScreen, alt: "KeePassXC Willkommensbildschirm" },
              { src: newDbWizard1, alt: "Neue Datenbank erstellen - Schritt 1" }
            ]}
          />
          
          <p className="western">Im nächsten Schritt können verschiedene Verschlüsselungseinstellung gesetzt werden, die du aber so in den Standardeinstellungen belassen kannst.<br />Danach kommt der wichtigste Teil in dem du dein <b>Masterpasswort</b> festlegst. Achte darauf, dass dieses Passwort sehr <b>komplex und möglichst lange</b> ist da du damit Zugriff auf sämtliche Passwörter bekommst.</p>
          
          <BlogGallery 
            images={[
              { src: newDbWizard2, alt: "Verschlüsselungseinstellungen" },
              { src: newDbWizard3, alt: "Masterpasswort festlegen" }
            ]}
          />
          
          <p className="western">Und schon bist du bereit um neue <b>Einträge zu erstellen</b> und diese in Ordner einsortieren. Im Passwortgenerator kannst du z.B. festlegen <b>wieviele Zeichen</b> deine Passwörter haben sollen. Wir empfehlen hier eine Passwortlänge von mindestens 32 Zeichen mit Groß- Kleinschreibung und Zahlen.</p>
          
          <BlogGallery 
            images={[
              { src: editEntry, alt: "Eintrag bearbeiten" },
              { src: passwordGenerator, alt: "Passwortgenerator Einstellungen" }
            ]}
          />
          
          <BlogHeading level={3}>Passwortdatenbank in Nextcloud</BlogHeading>
          
          <p className="western">Damit du an mehreren Gerät auf deine Passwörter zugreifen kannst, gibt es die Möglichkeit die Passwortdatenbank mit deiner <b>Nextcloud zu synchronisieren</b>. Mit der App „<b>KeeWeb</b>" kannst du diese sogar direkt in der Nextcloud Weboberfläche öffnen.</p>
          
          <BlogImage src={syncIcon} alt="Synchronisations-Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Vorteile</BlogHeading>
          
          <p className="western">Mit einem Passwortmanager wie KeePassXC kannst du für <b>jeden Account ein eigenes Passwort in beliebiger Komplexität und Länge</b> generieren. Dadurch wird zum einen die Sicherheit deiner Accounts deutlich erhöht, zum anderen musst du dir nur noch dein Masterpasswort merken.<br />Dank der Synchronisation mit deiner Nextcoud hast du deine Passwörter immer und überall mit dabei.</p>
          
          <p className="western">Wie du deinen Nextcloud Account durch eine 2-Faktor Authentifizierung zusätzlich absichern kannst, erfährst du in diesem Artikel:</p>
          
          <p className="western"><a href="/blog/2-faktor-authentifizierung" target="_blank" rel="noopener">/blog/2-faktor-authentifizierung</a></p>
          
          <BlogImage src={lockIcon} alt="Schloss-Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Spenden</BlogHeading>
          
          <p className="western">KeePassXC ist ein Open Source Projekt, wenn dir das Programm gefällt kannst du gerne ein <b>Spende an die Entwickler schicken</b>.</p>
          
          <p className="western"><a href="https://keepassxc.org/donate/" target="_blank" rel="noopener">https://keepassxc.org/donate/</a></p>
          
          <BlogImage src={donateIcon} alt="Spenden-Icon" size="small" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "KeePassXC",
      formattedDate: "7. Dezember 2022",
      excerpt: "In diesem Blogartikel geht um den Passwortmanager KeePassXC. Du erfährst was ein Passwortmanager ist, wie du KeePassXC einrichtest, deine Passwörter in Nextcloud öffnen kannst und welche Vorteile du dadurch hast.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "KeePassXC",
      formattedDate: "December 7, 2022",
      excerpt: "This blog article is about the KeePassXC password manager. You'll learn what a password manager is, how to set up KeePassXC, how to access your passwords in Nextcloud, and what benefits you'll gain.",
      content: () => (
        <BlogContent>
          <p className="western">This blog article is about the <b>KeePassXC password manager</b>. You'll learn what a password manager is, how to set up KeePassXC, how to access your passwords in Nextcloud, and what benefits you'll gain.</p>
          
          <p>You can find a video about this here: <br />
          <a href="https://www.youtube.com/watch?v=jRJnvy9wMcg" target="_blank" rel="noopener">https://www.youtube.com/watch?v=jRJnvy9wMcg</a></p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src={keepassxcLogo} alt="KeePassXC Logo" size="small" />
          </div>
          
          <BlogHeading>Password Manager</BlogHeading>
          
          <p className="western">Most of us have so <b>many accounts</b> that we can no longer count them on one hand. By now, everyone knows that we should always use a <b>different password with sufficient length and complexity</b>.</p>
          
          <p className="western">But how do you remember so many passwords, and how do you know if they're complex enough and secure?</p>
          
          <p className="western">This is where a password manager like KeePassXC comes into play, allowing you to easily <b>generate a unique password</b> for each account and save it.</p>
          
          <BlogHeading level={3}>Installation and Setup</BlogHeading>
          
          <p className="western">KeePassXC is available for Linux, macOS, and Windows, and can be downloaded directly from the official website.</p>
          
          <p className="western"><a href="https://keepassxc.org/download/" target="_blank" rel="noopener">https://keepassxc.org/download/</a></p>
          
          <p className="western">After installation, create a new password database. Click on "<b>Create new database</b>". In the following window, you can enter a database name and optionally add a brief description.</p>
          
          <BlogGallery 
            images={[
              { src: welcomeScreen, alt: "KeePassXC welcome screen" },
              { src: newDbWizard1, alt: "Create new database - Step 1" }
            ]}
          />
          
          <p className="western">In the next step, you can set various encryption settings, but you can leave them at their default values.<br />Next comes the most important part, where you establish your <b>master password</b>. Make sure this password is very <b>complex and as long as possible</b> since it provides access to all your passwords.</p>
          
          <BlogGallery 
            images={[
              { src: newDbWizard2, alt: "Encryption settings" },
              { src: newDbWizard3, alt: "Set master password" }
            ]}
          />
          
          <p className="western">And now you're ready to <b>create new entries</b> and organize them into folders. In the password generator, you can specify <b>how many characters</b> your passwords should have. We recommend a password length of at least 32 characters with upper and lower case letters and numbers.</p>
          
          <BlogGallery 
            images={[
              { src: editEntry, alt: "Edit entry" },
              { src: passwordGenerator, alt: "Password generator settings" }
            ]}
          />
          
          <BlogHeading level={3}>Password Database in Nextcloud</BlogHeading>
          
          <p className="western">To access your passwords on multiple devices, you can <b>synchronize the password database with your Nextcloud</b>. With the "<b>KeeWeb</b>" app, you can even open it directly in the Nextcloud web interface.</p>
          
          <BlogImage src={syncIcon} alt="Synchronization icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Benefits</BlogHeading>
          
          <p className="western">With a password manager like KeePassXC, you can generate a <b>unique password of any complexity and length for each account</b>. This significantly increases the security of your accounts, and you only need to remember your master password.<br />Thanks to synchronization with your Nextcloud, you have your passwords with you anytime, anywhere.</p>
          
          <p className="western">To learn how you can additionally secure your Nextcloud account with two-factor authentication, check out this article:</p>
          
          <p className="western"><a href="/blog/2-faktor-authentifizierung" target="_blank" rel="noopener">/blog/2-faktor-authentifizierung</a></p>
          
          <BlogImage src={lockIcon} alt="Lock icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Donations</BlogHeading>
          
          <p className="western">KeePassXC is an open source project. If you like the program, you're welcome to <b>send a donation to the developers</b>.</p>
          
          <p className="western"><a href="https://keepassxc.org/donate/" target="_blank" rel="noopener">https://keepassxc.org/donate/</a></p>
          
          <BlogImage src={donateIcon} alt="Donation icon" size="small" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "KeePassXC",
      formattedDate: "7 December 2022",
      excerpt: "This blog article is about the KeePassXC password manager. You'll learn what a password manager is, how to set up KeePassXC, how to access your passwords in Nextcloud, and what benefits you'll gain.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;