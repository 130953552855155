import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faContactCard,
  faDungeon,
  faInfoCircle,
  faBook
} from '@fortawesome/free-solid-svg-icons'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as PathConnectLogo } from '../../../assets/images/pathconnect_logo.svg'
import LanguageSwitcher from '../../languageSwitcher'
import ThemeToggleButton from '../../themeToggleButton'
import Button from '../../button'

const NavigationMD = () => {
  const location = useLocation()
  const { t } = useTranslation('navigation')
  
  // Button CSS base classes
  const buttonClasses =
    'hover:underline px-4 py-2 rounded-lg transition-all duration-300 hover:text-hover text-lg'
    
  const isActive = path => location.pathname === path
  
  return (
    <nav className="flex items-center h-full px-4">
      {/* Left Side: Logo - reduced width */}
      <div className="flex items-center w-1/5">
        <Button
          className={buttonClasses + `${isActive('/') ? ' text-primary' : ''}`}
        >
          <Link to="/">
            <PathConnectLogo className="w-4/5" />
          </Link>
        </Button>
      </div>
      
      {/* Middle Section: Navigation Links - pushed more to the right */}
      <div className="flex items-center justify-end space-x-6 ml-auto w-3/5 pr-4">
        {/* Portal */}
        <Button
          className={
            buttonClasses + `${isActive('/portal') ? ' text-primary' : ''}`
          }
        >
          <Link to="/portal" className="flex items-center">
            <FontAwesomeIcon icon={faDungeon} className="text-xl" />
            <span
              className={`ml-2 overflow-hidden transition-all duration-300 ${
                isActive('/portal')
                  ? 'opacity-100 max-w-xs h-auto'
                  : 'opacity-0 max-w-0 h-0'
              }`}
            >
              {t('portal')}
            </span>
          </Link>
        </Button>
        
        {/* Blog */}
        <Button
          className={
            buttonClasses + `${isActive('/blog') ? ' text-primary' : ''}`
          }
        >
          <Link to="/blog" className="flex items-center">
            <FontAwesomeIcon icon={faBook} className="text-xl" />
            <span
              className={`ml-2 overflow-hidden transition-all duration-300 ${
                isActive('/blog')
                  ? 'opacity-100 max-w-xs h-auto'
                  : 'opacity-0 max-w-0 h-0'
              }`}
            >
              {t('blog')}
            </span>
          </Link>
        </Button>
        
        {/* About Us */}
        <Button
          className={
            buttonClasses + `${isActive('/about') ? ' text-primary' : ''}`
          }
        >
          <Link to="/about" className="flex items-center">
            <FontAwesomeIcon icon={faInfoCircle} className="text-xl" />
            <span
              className={`ml-2 overflow-hidden transition-all duration-300 ${
                isActive('/about')
                  ? 'opacity-100 max-w-xs h-auto'
                  : 'opacity-0 max-w-0 h-0'
              }`}
            >
              {t('about')}
            </span>
          </Link>
        </Button>
        
        {/* Contact */}
        <Button
          className={
            buttonClasses + `${isActive('/contact') ? ' text-primary' : ''}`
          }
        >
          <Link to="/contact" className="flex items-center">
            <FontAwesomeIcon icon={faContactCard} className="text-xl" />
            <span
              className={`ml-2 overflow-hidden transition-all duration-300 ${
                isActive('/contact')
                  ? 'opacity-100 max-w-xs h-auto'
                  : 'opacity-0 max-w-0 h-0'
              }`}
            >
              {t('contact')}
            </span>
          </Link>
        </Button>
      </div>
      
      {/* Right Section: Language & Theme Toggles */}
      <div className="flex items-center w-1/5 justify-end space-x-2">
        <LanguageSwitcher className={buttonClasses} />
        <ThemeToggleButton className={buttonClasses} />
      </div>
    </nav>
  )
}

export default NavigationMD