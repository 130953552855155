// src/data/blogPosts/open-source-software.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Open Source Software
 */
const post = {
  id: '5553',
  slug: 'open-source-software',
  
  // Base data - same regardless of language
  date: "2024-04-02T13:39:44.000Z",
  readTime: 11,
  categories: ["Informationen", "Open Source Software"],
  thumbnail: "../images/blog/open-source-software-featured.jpg",
  thumbnailUrl: "../images/blog/open-source-software-featured.jpg",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 5,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Open Source Software",
      formattedDate: "2. April 2024",
      excerpt: "Bei Open Source Software ist der Quellcode frei verfügbar. In diesem Artikel erfährst du, welche Vorteile das hat, warum Open Source Software eingesetzt wird und wie du davon profitieren kannst.",
      content: () => (
        <BlogContent>
          <p>Bei Open Source Software ist der Quellcode frei verfügbar. In diesem Artikel erfährst du, welche Vorteile das hat, warum Open Source Software eingesetzt wird und wie du davon profitieren kannst.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/open-source.png" alt="Open Source Software" size="large" />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Proprietäre Software</BlogHeading>
          
          <p>Bei proprietärer Software kann der Quellcode nicht eingesehen werden. Hier hat eine Firma die Rechte an der Software. (z.B. Microsoft mit dem Office-Paket MS-Office oder Google mit „Google Drive"). Was diese Software wirklich im Hintergrund macht, bleibt das Geheimnis der Firma.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/closed_.png" alt="Closed Source Software" size="medium" />
          </div>
          
          <BlogHeading>Wieso Open Source Software?</BlogHeading>
          
          <p>Open-Source-Software nutzt die kollektive Intelligenz und die Zusammenarbeit einer weltweiten Gemeinschaft von Entwicklern, um Softwarelösungen zu schaffen, die frei zugänglich, anpassbar und von jedermann weiterentwickelt werden können. Die Grundidee von Open Source ist es, Software frei von den Beschränkungen traditioneller Lizenzmodelle für jedermann zugänglich zu machen.</p>
          
          <p>Durch die Offenlegung des Quellcodes fördert Open Source eine Kultur des Teilens und Lernens, um robuste, sichere und effiziente Software zu entwickeln.</p>
          
          <p>Diese Praxis fördert eine schnelle Fehlerbehebung und Funktionsverbesserung, da Entwickler auf der ganzen Welt zur Codebasis beitragen können.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/open.png" alt="Open Source Shield" size="medium" />
          </div>
          
          <BlogHeading>Ist Open Source Software kostenlos?</BlogHeading>
          
          <p>Open-Source-Projekte stellen ihre Basissoftware oft kostenlos zur Verfügung, akzeptieren aber freiwillige Spenden. Diese Spenden unterstützen die kontinuierliche Entwicklung, Wartung und Verbesserung der Software, ohne die allgemeine Zugänglichkeit oder Funktionalität zu beeinträchtigen. Darüber hinaus werden häufig kostenpflichtige Erweiterungen, Premium-Features und professioneller Support für die Nutzer angeboten. Diese Einnahmen helfen, die Projekte langfristig zu unterstützen und weiterzuentwickeln.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/geld.png" alt="Monetarisierung von Open Source" size="medium" />
          </div>
          
          <BlogHeading>Open Source Software auf dem Smartphone</BlogHeading>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">Fossify</h3>
              <p>Die Kalender App bietet alles Funktionen die benötigt werden. Die App ist kostenlos und ohne Werbung.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://f-droid.org/de/packages/org.fossify.calendar/" target="_blank" rel="noopener">F-Droid</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=org.fossify.calendar" target="_blank" rel="noopener">Play Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">FairEmail</h3>
              <p>Der E-Mal Client für Android lässt sich sehr individuell anpassen, schützt deine Privatsphäre und verbrauch wenig Akku.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/fairemail/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=eu.faircode.email&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/eu.faircode.email/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Signal Messenger</h3>
              <p>Mit Signal bekommst du einen tollen Messenger der ähnliche Funktionen wie WhatsApp bietet, aber deine Privatsphäre respektiert und keine Daten von dir preisgibt.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=org.thoughtcrime.securesms&hl=gsw&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://apps.apple.com/de/app/signal-sicherer-messenger/id874139669" target="_blank" rel="noopener">App Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud</h3>
              <p>Mit der Nextcloud App kannst du auf deine persönliche Cloud zugreifen, Bilder automatisch hochladen und Dokumente bearbeiten.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/nextcloud/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.nextcloud.client&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/com.nextcloud.client/">F-Droid</a></li>
                <li><a href="https://apps.apple.com/de/app/nextcloud/id1125420102" target="_blank" rel="noopener">App Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">KeePassDX</h3>
              <p>Mit dem Passwortmanager für Android kannst du auf Passwörter sowohl lokal als auch in der Cloud zugreifen und ertellen.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/keepassdx-android-app/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.free&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/com.kunzisoft.keepass.libre/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Authenticator Pro</h3>
              <p>Mit dieser App kannst du OTP-Codes für die <a href="/blog/2-faktor-authentifizierung/" target="_blank" rel="noopener">Zwei-Faktor-Authentifizierung</a> erstellen.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=me.jmh.authenticatorpro&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud Memories</h3>
              <p>Mit Nextcloud Memories werden deine Fotos in Nextcloud übersichtlich mit tolle Funktionen wie z.B. eine Foto-Zeitleiste und eine Fotokarte dargestellt.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/open-source-software-blog/?srch=memories" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=gallery.memories&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/gallery.memories/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Organic Maps</h3>
              <p>Organic Maps ist eine Karten-App für Reisende, Touristen, Wanderer und Radfahrer, die auf den Daten von OpenStreetMap basiert.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=app.organicmaps&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://apps.apple.com/de/app/organic-maps-offline-karten/id1567437057" target="_blank" rel="noopener">App Store</a></li>
                <li><a href="https://f-droid.org/de/packages/gallery.memories/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud News</h3>
              <p>Diese News-App zeigt die von Nextcloud abonnierten RSS-Feeds an.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/nextcloud-news/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=de.luhmer.owncloudnewsreader&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/de.luhmer.owncloudnewsreader/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">NewPipe</h3>
              <p>Mit NewPipe kannst du Youtube-Videos ohne Werbung und Tracking anschauen.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/android-app-newpipe/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://f-droid.org/de/packages/org.schabi.newpipe/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud Deck</h3>
              <p>Diese App bringt das Kanban Board von Nextcloud auf dein Smartphone.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=it.niedermann.nextcloud.deck.play&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/it.niedermann.nextcloud.deck/" target="_blank" rel="noopener">F-Droid</a></li>
                <li><a href="https://apps.apple.com/de/app/nextcloud-deck/id1570892788" target="_blank" rel="noopener">App Store</a></li>
              </ul>
            </div>
          </div>
          
          <BlogHeading>Open Source Software auf deinem Computer</BlogHeading>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">Thunderbird</h3>
              <p>Mit dem E-Mail-Client kann man sich in verschiedene Konten einloggen, E-Mails lesen, schreiben und verschlüsseln.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/thunderbird/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://www.thunderbird.net/de/" target="_blank" rel="noopener">Webseite</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Firefox</h3>
              <p>Der Internet-Browser Firefox ist schnell, sicher und respektiert deine Privatsphäre.</p>
              <p><a href="https://www.mozilla.org/de/firefox/new/" target="_blank" rel="noopener">Webseite</a></p>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">KeePassXC</h3>
              <p>Mit diesem Tool können komplexe Passwörter generiert, gespeichert und abgerufen werden.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/keepassxc/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://keepassxc.org/" target="_blank" rel="noopener">Webseite</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">LibreOffice</h3>
              <p>Mit LibreOffice steht dir ein komplettes und quelloffenes Office-Paket zur Verfügung</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/libreoffice/" target="_blank" rel="noopener">Blogartikel</a></li>
                <li><a href="https://de.libreoffice.org/download/download/" target="_blank" rel="noopener">Webseite</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud Client</h3>
              <p>Der Nextcloud Client synchronisiert ausgewählte Ordner mit der Cloud und implementiert eine Ende-zu-Ende-Verschlüsselung.</p>
              <p><a href="https://nextcloud.com/de/install/" target="_blank" rel="noopener">Webseite</a></p>
            </div>
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Open Source Software",
      formattedDate: "2. April 2024",
      excerpt: "Bei Open Source Software ist der Quellcode frei verfügbar. In diesem Artikel erfährst du, welche Vorteile das hat, warum Open Source Software eingesetzt wird und wie du davon profitieren kannst.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Open Source Software",
      formattedDate: "April 2, 2024",
      excerpt: "In open source software, the source code is freely available. In this article, you'll learn about the advantages, why open source software is used, and how you can benefit from it.",
      content: () => (
        <BlogContent>
          <p>In open source software, the source code is freely available. In this article, you'll learn about the advantages, why open source software is used, and how you can benefit from it.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/open-source.png" alt="Open Source Software" size="large" />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Proprietary Software</BlogHeading>
          
          <p>With proprietary software, the source code cannot be viewed. Here, a company owns the rights to the software (e.g., Microsoft with the MS-Office suite or Google with "Google Drive"). What this software actually does in the background remains the company's secret.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/closed_.png" alt="Closed Source Software" size="medium" />
          </div>
          
          <BlogHeading>Why Open Source Software?</BlogHeading>
          
          <p>Open source software leverages the collective intelligence and collaboration of a global community of developers to create software solutions that are freely accessible, customizable, and can be further developed by anyone. The basic idea of open source is to make software accessible to everyone, free from the constraints of traditional licensing models.</p>
          
          <p>By disclosing the source code, open source promotes a culture of sharing and learning to develop robust, secure, and efficient software.</p>
          
          <p>This practice encourages rapid bug fixing and feature improvements, as developers around the world can contribute to the codebase.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/open.png" alt="Open Source Shield" size="medium" />
          </div>
          
          <BlogHeading>Is Open Source Software Free?</BlogHeading>
          
          <p>Open source projects often make their basic software available for free but accept voluntary donations. These donations support the continuous development, maintenance, and improvement of the software without affecting general accessibility or functionality. Additionally, paid extensions, premium features, and professional support are frequently offered to users. This revenue helps support and develop the projects in the long term.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage src="../images/blog/2021/10/geld.png" alt="Monetization of Open Source" size="medium" />
          </div>
          
          <BlogHeading>Open Source Software on Your Smartphone</BlogHeading>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">Fossify</h3>
              <p>The calendar app offers all the features you need. The app is free and without ads.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://f-droid.org/de/packages/org.fossify.calendar/" target="_blank" rel="noopener">F-Droid</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=org.fossify.calendar" target="_blank" rel="noopener">Play Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">FairEmail</h3>
              <p>The email client for Android can be customized extensively, protects your privacy, and uses little battery power.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/fairemail/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=eu.faircode.email&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/eu.faircode.email/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Signal Messenger</h3>
              <p>With Signal, you get a great messenger that offers similar features to WhatsApp but respects your privacy and doesn't share your data.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=org.thoughtcrime.securesms&hl=gsw&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://apps.apple.com/de/app/signal-sicherer-messenger/id874139669" target="_blank" rel="noopener">App Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud</h3>
              <p>With the Nextcloud app, you can access your personal cloud, automatically upload images, and edit documents.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/nextcloud/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.nextcloud.client&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/com.nextcloud.client/">F-Droid</a></li>
                <li><a href="https://apps.apple.com/de/app/nextcloud/id1125420102" target="_blank" rel="noopener">App Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">KeePassDX</h3>
              <p>With this password manager for Android, you can access and create passwords both locally and in the cloud.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/keepassdx-android-app/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.free&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/com.kunzisoft.keepass.libre/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Authenticator Pro</h3>
              <p>With this app, you can create OTP codes for <a href="/blog/2-faktor-authentifizierung/" target="_blank" rel="noopener">two-factor authentication</a>.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=me.jmh.authenticatorpro&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud Memories</h3>
              <p>With Nextcloud Memories, your photos in Nextcloud are displayed clearly with great features such as a photo timeline and a photo map.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/open-source-software-blog/?srch=memories" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=gallery.memories&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/gallery.memories/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Organic Maps</h3>
              <p>Organic Maps is a map app for travelers, tourists, hikers, and cyclists, based on OpenStreetMap data.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=app.organicmaps&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://apps.apple.com/de/app/organic-maps-offline-karten/id1567437057" target="_blank" rel="noopener">App Store</a></li>
                <li><a href="https://f-droid.org/de/packages/gallery.memories/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud News</h3>
              <p>This news app displays RSS feeds subscribed to in Nextcloud.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/nextcloud-news/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://play.google.com/store/apps/details?id=de.luhmer.owncloudnewsreader&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/de.luhmer.owncloudnewsreader/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">NewPipe</h3>
              <p>With NewPipe, you can watch YouTube videos without ads and tracking.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/android-app-newpipe/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://f-droid.org/de/packages/org.schabi.newpipe/" target="_blank" rel="noopener">F-Droid</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud Deck</h3>
              <p>This app brings Nextcloud's Kanban board to your smartphone.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="https://play.google.com/store/apps/details?id=it.niedermann.nextcloud.deck.play&hl=de&gl=US" target="_blank" rel="noopener">Play Store</a></li>
                <li><a href="https://f-droid.org/de/packages/it.niedermann.nextcloud.deck/" target="_blank" rel="noopener">F-Droid</a></li>
                <li><a href="https://apps.apple.com/de/app/nextcloud-deck/id1570892788" target="_blank" rel="noopener">App Store</a></li>
              </ul>
            </div>
          </div>
          
          <BlogHeading>Open Source Software on Your Computer</BlogHeading>
          
          <div className="space-y-6">
            <div>
              <h3 className="text-lg font-semibold">Thunderbird</h3>
              <p>With this email client, you can log into different accounts, read, write, and encrypt emails.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/thunderbird/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://www.thunderbird.net/en-US/" target="_blank" rel="noopener">Website</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Firefox</h3>
              <p>The Firefox web browser is fast, secure, and respects your privacy.</p>
              <p><a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener">Website</a></p>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">KeePassXC</h3>
              <p>With this tool, complex passwords can be generated, saved, and retrieved.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/keepassxc/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://keepassxc.org/" target="_blank" rel="noopener">Website</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">LibreOffice</h3>
              <p>LibreOffice provides you with a complete and open source office suite.</p>
              <ul className="list-disc pl-5 mt-2">
                <li><a href="/blog/libreoffice/" target="_blank" rel="noopener">Blog article</a></li>
                <li><a href="https://www.libreoffice.org/download/download/" target="_blank" rel="noopener">Website</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-lg font-semibold">Nextcloud Client</h3>
              <p>The Nextcloud client synchronizes selected folders with the cloud and implements end-to-end encryption.</p>
              <p><a href="https://nextcloud.com/install/" target="_blank" rel="noopener">Website</a></p>
            </div>
          </div>
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offering includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-round service that includes installation, customization, and updates, allowing you to focus fully on your projects.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Open Source Software",
      formattedDate: "2 April 2024",
      excerpt: "In open source software, the source code is freely available. In this article, you'll learn about the advantages, why open source software is used, and how you can benefit from it.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;