// src/data/blogPosts/thunderbird.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/thunderbird-featured.png';
import thunderbirdLogo from '../../assets/images/blog/2023/01/480px-Mozilla_Thunderbird_Logo.svg.png';
import emailIcon from '../../assets/images/blog/2023/01/iconmonstr-email-15-240.png';
import lockIcon from '../../assets/images/blog/2022/01/lock.png';
import stopIcon from '../../assets/images/blog/2022/02/stop.png';
import addressBookImg from '../../assets/images/blog/2023/01/Bildschirmfoto-vom-2023-01-06-21-28-21.png';
import cardDavImg from '../../assets/images/blog/2023/01/Bildschirmfoto-vom-2023-01-06-21-41-33.png';
import cardDavSetupImg from '../../assets/images/blog/2023/01/Bildschirmfoto-vom-2023-01-06-21-41-58.png';
import calendarImg from '../../assets/images/blog/2023/01/Bildschirmfoto-vom-2023-01-06-21-45-17.png';
import calendarSetup1Img from '../../assets/images/blog/2023/01/Bildschirmfoto-vom-2023-01-06-21-47-20.png';
import calendarSetup2Img from '../../assets/images/blog/2023/01/Bildschirmfoto-vom-2023-01-06-21-47-34.png';

/**
 * Blog post about Thunderbird email client
 */
const post = {
  id: '10269',
  slug: 'thunderbird',
  
  // Base data - same regardless of language
  date: "2023-01-06T20:35:48.000Z",
  readTime: 13,
  categories: ["Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 27,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Thunderbird",
      formattedDate: "6. Januar 2023",
      excerpt: "Im heutigen Blogartikel stellen wir dir das Open Source Programm \"Thunderbird\" vor. Wir erläutern kurz den Hintergrund zu Thunderbird, welche Funktionen das Programm bietet und wie du deine Nextcloud Kalender und Kontakte hinzufügen kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Thunderbird</h1>
          
          <p className="western">Im heutigen Blogartikel stellen wir dir das Open Source Programm "Thunderbird" vor. Wir erläutern kurz den Hintergrund zu Thunderbird, welche Funktionen das Programm bietet und wie du deine Nextcloud Kalender und Kontakte hinzufügen kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={thunderbirdLogo} alt="Thunderbird Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Thunderbird – Was soll das denn sein?</BlogHeading>
          
          <p className="western">Bei Thunderbird handelt es sich um ein Open Source E-Mail-, Newsfeed-, Chat- und Kalenderprogramm. Die Hauptfunktion besteht aber im Anzeigen, Erhalten und Versenden von E-Mails. Das Programm lässt sich sowohl auf Windows als auch auf Linux und macOS installieren und kann somit auf allen gängigen Betriebssystemen eingesetzt werden.</p>
          
          <p>Du kannst dir Thunderbird von der offziellen Seite herunterladen:<br />
          <a href="https://www.thunderbird.net/de/" target="_blank" rel="noopener">www.thunderbird.net</a></p>
          
          <BlogHeading>Verwaltung deiner E-Mail Konten</BlogHeading>
          
          <p className="western">Die meisten von uns haben mindestens ein E-Mail Konto, aber eher zwei, drei oder noch mehr. Um hier den Überblick nicht zu verlieren bietet es sich an, alle Konten in einem E-Mail Client zu verwalten. Dadurch verpasst du keine E-Mails und du musst dich nicht bei jedem einzelnen Anbieter ständig einloggen. Einfach das Programm starten und schon hast du alle Emails vor dir.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={emailIcon} alt="Email Icon" size="small" />
          </div>
          
          <BlogHeading>Gesicherte Kommunikation und Verschlüsselung</BlogHeading>
          
          <p className="western">Standardmäßig sind E-Mails nicht verschlüsselt und werden somit im Klartext versendet. Mit Thunderbird hast du die Möglichkeit deine E-Mail mit OpenPGP Ende-zu-Ende verschlüsseln.</p>
          
          <p className="western">Klicke dazu in der Thunderbird-Menüleiste auf das Menü "Extras" und dann auf "Konto-Einstellungen" Hier kannst du auf den Eintrag "Ende-zu-Ende-Verschlüsselung" auswählen und anschließend auf die Schaltfläche "Schlüssel hinzufügen". Um die Verschlüsselung zu aktivieren muss durch Klick auf "Neuen OpenPGP Schlüssel erzeugen" ein neuer Schlüssel erzeugt werden.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={lockIcon} alt="Lock Icon" size="small" />
          </div>
          
          <BlogHeading>Thunderbird Spamfilter</BlogHeading>
          
          <p className="western">Spam E-Mails sind nicht nur nervig sonder auch gefährlich. Aus Versehen auf einen falschen Link geklickt und schon hat man sich etwas eingefangen. Thunderbird kommt mit einem eingebauten Spam-Filter der die lästigen Spams vom Hals hält. Das Feature ist standardmäßig für alle Konten aktiviert kann aber auch für einzelnen Konten separat konfiguriert werden.</p>
          
          <p className="western">Um den Spam-Filter zu "trainieren" kannst bei jeder E-Mail entscheiden ob es sich wirklich um eine echte E-Mail handelt oder um Spam.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={stopIcon} alt="Stop Icon" size="small" />
          </div>
          
          <BlogHeading>Kontakte und Kalender aus Nextcloud hinzufügen</BlogHeading>
          
          <p className="western">Du kannst auch deine schon bestehenen Kontakte und Kalender aus deiner Nextcloud zu Thunderbird hinzufügen. Das ist besonders dann praktisch, wenn du auch die E-Mail Adressen von deinen Kontakten eingespeichert hast.</p>
          
          <p className="western">Um die Kontakte hinzuzufügen klickst du auf "Neues Adressbuch" und dann auf "CardDAV-Adressbuch hinzufügen". <br />Im folgenden Fenster kannst du die Serveradresse, sowie deinen Benutzername und Passwort eingeben. Wir empfehlen dir hier ein App-Passwort in der Nextcloud Weboberfläche zu erstellen.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={addressBookImg} alt="Adressbuch in Thunderbird" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={cardDavImg} alt="CardDAV hinzufügen" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={cardDavSetupImg} alt="CardDAV Setup" size="medium" />
            </div>
          </div>
          
          <p>Für den Kalender benötigen wir die richtige Adresse. Diese findest du in der Nextcloud Weboberfläche, hier kannst du einfach auf "Primäre CalDAV-Adresse kopieren" klicken. Im Fall von der Nextcloud Testinstanz lautet die Adresse: <em>https://test.pathconnect.de<strong>/remote.php/dav</strong></em><br />
          Entscheidend ist das "<em><strong>/remote.php/dav</strong></em>" am Ende.</p>
          
          <p>In Thunderbird machst du einen Rechtsklick im Kalendermenü und klickst auf "Neuen Kalender...". Als nächstes wählst du "Im Netzwerk" aus und gibst deinen Benutzernamen und die eben kopiert Adresse ein. Du wirst wieder aufgefordert ein Passwort einzugeben. Bitte erzeuge auch hier ein App-Passwort.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-6">
            <div className="text-center">
              <BlogImage src={calendarImg} alt="Kalender in Thunderbird" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={calendarSetup1Img} alt="Kalender Setup 1" size="medium" />
            </div>
            <div className="text-center">
              <BlogImage src={calendarSetup2Img} alt="Kalender Setup 2" size="medium" />
            </div>
          </div>
          
          <BlogHeading>Spenden</BlogHeading>
          
          <p>Du kannst Thunderbird sowohl privat als auch geschäftlich kostenlos nutzen. Das Programm ist Open Source und wird durch Spenden finanziert. Wenn es dir gefällt und du Nutzen daran hast kannst du den Entwicklern gerne eine Spende zukommen lassen.</p>
          
          <p><a href="https://give.thunderbird.net/de/?utm_source=thunderbird.net&utm_medium=referral&utm_content=header" target="_blank" rel="noopener">https://give.thunderbird.net</a></p>
          
          <p>Auch PathConnect spendet einen monatlichen Betrag, da wir das Programm täglich nutzen.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Thunderbird",
      formattedDate: "6. Januar 2023",
      excerpt: "Im heutigen Blogartikel stellen wir dir das Open Source Programm \"Thunderbird\" vor. Wir erläutern kurz den Hintergrund zu Thunderbird, welche Funktionen das Programm bietet und wie du deine Nextcloud Kalender und Kontakte hinzufügen kannst.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;