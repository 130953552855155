// src/data/blogPosts/nextcloud-kollektiv.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-kollektiv-featured.png';
import nextcloudHubLogo from '../../assets/images/blog/2022/01/Nextcloud-Hub-logo.png';
import ideaIcon from '../../assets/images/blog/2022/12/iconmonstr-idea-14-240.png';
import monitoringIcon from '../../assets/images/blog/2021/11/iconmonstr-monitoring-6-240.png';
import downloadActivateImage from '../../assets/images/blog/2023/07/Herunterladen_und_Aktivieren.png';
import circleImage from '../../assets/images/blog/2023/07/Circle.png';
import newCollectiveImage from '../../assets/images/blog/2023/07/Neues-Kollektiv.png';
import firstCollectiveImage from '../../assets/images/blog/2023/07/Erstes-Kollektiv.png';
import addMembersImage from '../../assets/images/blog/2023/07/Mitglieder-hinzufuegen.png';
import addPeopleImage from '../../assets/images/blog/2023/07/Personen_hinzufuegen.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Collective
 */
const post = {
  id: '12151',
  slug: 'nextcloud-kollektiv',
  
  // Base data - same regardless of language
  date: "2023-07-22T16:00:13.000Z",
  readTime: 11,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 0,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Kollektiv",
      formattedDate: "22. Juli 2023",
      excerpt: "Nextcloud Kollektiv. Im heutigen Beitrag geht es um die Nextcloud App \"Collectives\". Wir zeigen dir, wie du die App in deiner Nextcloud installierst und welche tollen Funktionen sie bietet.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Kollektiv</h1>
          
          <p>Im heutigen Beitrag geht es um die Nextcloud App "Collectives". Wir zeigen dir, wie du die App in deiner Nextcloud installierst und welche tollen Funktionen sie bietet.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudHubLogo} alt="Nextcloud Hub Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Was ist Collectives?</BlogHeading>
          
          <p>Mit der App "Collectives" kannst du eine umfangreiche Wissensdatenbank aufbauen und mit anderen teilen. Das ist besonders praktisch, wenn in einem Team gearbeitet wird und jeder seinen Teil zur Wissensdatenbank beiträgt. In Collectives kannst du verschiedene Kategorien anlegen und deine Informationen in Form von Texten und Bildern ansprechend präsentieren. Mit der integrierten KI-Funktion können z.B. Beschreibungen mit einem Klick automatisch erstellt werden.</p>
          
          <BlogImage src={ideaIcon} alt="Idee Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Wissensdatenbank</BlogHeading>
          
          <p>Mit einer Wissensdatenbank kannst du FAQs, Handbücher, Anleitungen zur Fehlerbehebung und andere Informationen an einem Ort speichern. Durch die Freigabe für einzelne Personen oder Gruppen kannst du auch anderen den Zugang ermöglichen. Eine Wissensdatenbank lebt davon, dass sie mit Inhalten gefüllt wird, um so viel Wissen wie möglich zu speichern und zugänglich zu machen.</p>
          
          <BlogImage src={monitoringIcon} alt="Monitoring Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Installation</BlogHeading>
          
          <p>Um Collectives zu installieren, meldest du dich mit einem Administrator-Account in deiner Nextcloud an, klickst auf das runde Icon in der oberen rechten Ecke und dann auf „Apps".<br />Hier kannst du in der Suche einfach „Collectives" eingeben und dann auf Herunterladen und Aktivieren klicken. Collectives benötigt zusätzlich die App „Circles", die du auf die gleiche Weise installieren kannst.</p>
          
          <BlogImage src={downloadActivateImage} alt="Herunterladen und Aktivieren" size="large" className="my-6" />
          <BlogImage src={circleImage} alt="Circle App" size="large" className="my-6" />
          
          <BlogHeading level={3}>Einrichtung</BlogHeading>
          
          <p>Du findest die Anwendung in der oberen Menüleiste mit dem Stern als Symbol. Wenn du die App zum ersten Mal öffnest, wird dir bereits ein Beispielkollektiv angezeigt. Um ein neues Kollektiv zu erstellen, klicke auf die drei waagerechten Balken auf der linken Seite und dann auf „+ Neues Kollektiv". Ein Kollektiv kann als Bezeichnung für ein bestimmtes Thema verstanden werden. So könnte man z.B. ein neues Kollektiv mit dem Namen „Nextcloud" erstellen und dort alle wichtigen Dokumente, Anleitungen und Informationen ablegen, die etwas mit Nextcloud zu tun haben.</p>
          <p>Die Überschrift für das Kollektiv und für die Unterseiten kann zusätzlich mit einem Icon versehen werden, um die Wissensdatenbank übersichtlich aufzubauen. Mit verschiedenen Formatierungsmöglichkeiten für die Texte können die Seiten übersichtlich gestaltet werden. Durch Eingabe des Schrägstrichs öffnet sich der Nextcloud Smartpicker, mit dem du Texte generieren lassen kannst. (Für die Einrichtung von Nextcloud AI haben wir einen eigenen Artikel erstellt: <a href="https://pathconnect.de/blog/nextcloud-ai/" target="_blank" rel="noopener">Nextcloud AI)</a></p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={newCollectiveImage} alt="Neues Kollektiv" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={firstCollectiveImage} alt="Erstes Kollektiv" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Teilen</BlogHeading>
          
          <p>Bei der Erstellung eines neuen Kollektivs können direkt Personen hinzugefügt werden, es gibt aber auch die Möglichkeit, später weitere Personen hinzuzufügen.<br />Hier kommt die zuvor installierte App „Circle" zum Einsatz. Für jedes Kollektiv wird automatisch ein „Circle" erstellt, der aus mehreren Personen oder einem Team bestehen kann.</p>
          <p>Klicke auf die drei Punkte neben einem Kollektiv und dann auf „Mitglieder verwalten". Wenn du auf „+ Mitglieder hinzufügen" klickst, kannst du weitere Benutzer zu diesem Kollektiv hinzufügen. Es gibt noch einige Einstellungen zu den Zugriffsrechten und zum Login.</p>
          <p>Für Personen, die keinen Account auf deiner Nextcloud haben, kannst du wieder auf die drei Punkte neben einem Kollektiv klicken und dann auf „Link teilen". Hier kannst du zusätzlich festlegen, ob das Kollektiv nur angesehen werden kann oder ob auch eine Bearbeitung möglich sein soll.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={addMembersImage} alt="Mitglieder hinzufügen" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={addPeopleImage} alt="Personen hinzufügen" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Zusammenfassung</BlogHeading>
          
          <p>Mit der Nextcloud App "Kollektiv" kannst du für dich und dein Team eine Wissensdatenbank aufbauen. Durch das gemeinsame Erstellen und Teilen von Beiträgen werden alle relevanten Informationen an einem Ort gesammelt und können so schnell wiedergefunden werden.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Kollektiv",
      formattedDate: "22. Juli 2023",
      excerpt: "Nextcloud Kollektiv. Im heutigen Beitrag geht es um die Nextcloud App \"Collectives\". Wir zeigen dir, wie du die App in deiner Nextcloud installierst und welche tollen Funktionen sie bietet.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Collective",
      formattedDate: "July 22, 2023",
      excerpt: "Nextcloud Collective. In today's post, we're taking a look at the Nextcloud app 'Collectives'. We'll show you how to install the app in your Nextcloud and what great features it offers.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Collective</h1>
          
          <p>In today's post, we're taking a look at the Nextcloud app "Collectives". We'll show you how to install the app in your Nextcloud and what great features it offers.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Collective",
      formattedDate: "22 July 2023",
      excerpt: "Nextcloud Collective. In today's post, we're taking a look at the Nextcloud app 'Collectives'. We'll show you how to install the app in your Nextcloud and what great features it offers.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;