// src/data/blogPosts/user-story-anna-studentin.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/user-story-anna-studentin-featured.png';
import womanIcon from '../../assets/images/blog/2022/04/woman.png';
import stopIcon from '../../assets/images/blog/2022/02/stop.png';
import ideaIcon from '../../assets/images/blog/2022/12/iconmonstr-idea-14-240.png';

/**
 * Blog post about a student user story (Anna)
 */
const post = {
  id: '10150',
  slug: 'user-story-anna-studentin',
  
  // Base data - same regardless of language
  date: "2022-12-16T09:52:06.000Z",
  readTime: 7,
  categories: ["User Story"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 32,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "User Story: Anna - Studentin",
      formattedDate: "16. Dezember 2022",
      excerpt: "Die 23 jährige Anna studiert im 4. Semester an der Uni in Karlsruhe Betriebswirtschaftslehre.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">User Story: Anna - Studentin</h1>
          
          <p className="western">Im Format "User Story" stellt dir PathConnect <strong>beispielhaft</strong> vor, wie unterschiedliche Menschen mit Nextcloud sich ihren <strong>Alltag erleichtern</strong>.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={womanIcon} alt="Frau Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Person</BlogHeading>
          
          <p className="western">Die 23 jährige Anna <strong>studiert</strong> im 4. Semester an der Uni in Karlsruhe Betriebswirtschaftslehre. Sie kann mit Computern umgehen ist aber <strong>keine IT-Expertin</strong> und möchte einfach, dass alles funktioniert. In den Vorlesungen schreibt sie sich gerne ein paar Stichpunkte auf ihr <strong>Tablet</strong> auf, damit sie sich diese später noch einmal durchlesen kann. In den zahlreichen <strong>Gruppenarbeiten</strong> sollen meistens <strong>Präsentationen</strong> und <strong>Ausarbeitungen</strong> verfasst werden.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={womanIcon} alt="Frau Icon" size="small" />
          </div>
          
          <BlogHeading>Problem</BlogHeading>
          
          <p className="western">Das größte Problem für ist, dass sie auf allen Geräten <strong>unterschiedliche Dateien</strong> hat, einfach das pure Chaos. In den Gruppenarbeiten weiß sie irgendwann auch nicht mehr <strong>welche Datei jetzt die Neuste ist</strong> und wer etwas bearbeitet hat. Am Ende hat meisten jeder seine eigene Version die dann kurz vor knapp noch irgendwie zusammengetragen werde müssen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={stopIcon} alt="Stop Icon" size="small" />
          </div>
          
          <BlogHeading>Lösung</BlogHeading>
          
          <p className="western">Mit Nextcloud kann sie ihre Aufschriebe aus den Vorlesungen einfach auf ihren Laptop <strong>synchronisieren</strong> und dann dort weitermachen. Für die Präsentationen muss sie nicht die 100. E-Mail mit viel zu großen Anhang an ihre Kommilitonen schicken. Sie erstellt einfach einen <strong>Link</strong> auf den<strong> alle Zugreifen</strong> und dann auch gleich dort die Dokumente mit ihr<strong> gemeinsam bearbeiten</strong> können. Wenn die Gruppenmitlieder möchten, können sie sich auch direkt auf der Nextcloud von Anna anmelden.</p>
          
          <p className="western">Durch gemeinsame <strong>Kalendereinträge</strong> können künftige Gruppentermine eingetragen mit Erinnerungen versehen werden. Eine <strong>Task-Liste</strong> zum Abarbeiten der Aufgabenpunkte hat sie auch gleich erstellt. <br />Dank <strong>Nextcloud Talk</strong> lässt sich das nächsten Meeting auch bequem aus dem Wohnzimmer aus starten. Einfach das Dokument gemeinsam öffnen oder den Bildschirm teilen und schon kann es los gehen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={ideaIcon} alt="Idee Icon" size="small" />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "User Story: Anna - Studentin",
      formattedDate: "16. Dezember 2022",
      excerpt: "Die 23 jährige Anna studiert im 4. Semester an der Uni in Karlsruhe Betriebswirtschaftslehre.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;