// src/data/blogPosts/nextcloud-und-openproject-integration.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-und-openproject-integration-featured.png';
import nextcloudLogo from '../../assets/images/blog/2023/11/nextcloud-logo.png';
import openprojectLogo from '../../assets/images/blog/2023/11/openproject-logo-centered-color-86c2838b.png';
import menuScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-15-57-21.png';
import attachFileScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-16-04-13.png';
import selectFileScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-16-04-58.png';
import docLinkScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-16-14-48.png';
import projectModuleScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-16-00-24.png';
import nextcloudSetupScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-16-00-49.png';
import loginScreenshot from '../../assets/images/blog/2023/12/Bildschirmfoto-vom-2023-12-08-16-03-29.png';
import serverIcon from '../../assets/images/blog/2023/11/iconmonstr-server-9-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud and OpenProject integration
 */
const post = {
  id: '14218',
  slug: 'nextcloud-und-openproject-integration',
  
  // Base data - same regardless of language
  date: "2023-12-08T15:24:36.000Z",
  readTime: 12,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 8,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud und OpenProject Integration",
      formattedDate: "8. Dezember 2023",
      excerpt: "Die Integration von Nextcloud in OpenProject ermöglicht es dir, deine projektbezogenen Dateien direkt in deiner Nextcloud zu speichern und mit den einzelnen Arbeitspaketen zu verknüpfen. In diesem Artikel zeigen wir dir die Einrichtung und die Funktionen, die durch die Integration möglich sind.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud und OpenProject Integration</h1>
          
          <p>Die Integration von Nextcloud in OpenProject ermöglicht es dir, deine projektbezogenen Dateien direkt in deiner Nextcloud zu speichern und mit den einzelnen Arbeitspaketen zu verknüpfen. In diesem Artikel zeigen wir dir die Einrichtung und die Funktionen, die durch die Integration möglich sind.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <div className="flex flex-row justify-center gap-4">
                <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="small" />
                <BlogImage src={openprojectLogo} alt="OpenProject Logo" size="small" />
              </div>
            </div>
            
          </div>
          
          <BlogHeading>Nextcloud und OpenProject</BlogHeading>
          
          <p>Mit OpenProject kannst du deine Projekte planen, durchführen und verwalten. Deine Nextcloud dient dir neben vielen weiteren Funktionen als sicherer Speicherort für deine Dateien. Durch die Kombination kannst du die Vorteile beider Open Source Lösungen nutzen. Durch die nahtlose Integration können sowohl in Nextcloud als auch in OpenProject Arbeitspakete mit den dazugehörigen Dateien verwaltet werden.</p>
          
          <BlogHeading>Funktionen</BlogHeading>
          
          <p><u>Icon in der Nextcloud Menüleiste</u><br />Über ein zusätzliches Icon in der Menüleiste von Nextcloud gelangt man direkt zu OpenProject.</p>
          
          <BlogImage src={menuScreenshot} alt="Nextcloud Menüleiste" size="large" className="my-6" />
          
          <p><u>Verknüpfung von Arbeitspaketen mit Dateien aus OpenProject heraus</u><br />In OpenProject kannst du in den Arbeitspaketen neue Dateien auf deinen Nextcloud-Speicher hochladen oder bereits vorhandene Dateien verlinken.<br />Wähle dazu ein Arbeitspaket aus und klicke auf Dateien. Unter dem Namen deiner Nextcloud kannst du entweder eine neue Datei hochladen oder eine bereits vorhandene Datei aus deiner Nextcloud verknüpfen. Mit einem Klick auf die Datei gelangst du direkt an die richtige Stelle in deiner Nextcloud.</p>
          
          <BlogGallery 
            images={[
              { src: attachFileScreenshot, alt: "OpenProject Datei anhängen" },
              { src: selectFileScreenshot, alt: "Datei auswählen Nextcloud" }
            ]}
          />
          
          <p><u>Verknüpfung von Dateien mit Arbeitspaketen aus Nextcloud heraus</u><br />Du kannst Dateien auch direkt aus deiner Nextcloud mit einem Arbeitspaket in OpenProject verknüpfen und darauf zugreifen.<br />Klicke dazu auf die drei Punkte neben einer Datei oder einem Ordner und wähle „OpenProject". Im Suchfeld kannst du das gewünschte Arbeitspaket suchen und anschließend verknüpfen. Danach wird dir das Arbeitspaket mit Status und Beschreibung direkt in deiner Nextcloud angezeigt. Mit einem Klick auf das Arbeitspaket gelangst du direkt zum entsprechenden Arbeitspaket in OpenProject.</p>
          
          <BlogImage src={docLinkScreenshot} alt="Demokokument Nextcloud" size="large" className="my-6" />
          
          <BlogHeading>Grundeinrichtung</BlogHeading>
          
          <p>Für die Verbindung von Nextcloud und OpenProject sind einige Schritte notwendig. Da eine detaillierte Anleitung den Umfang dieses Artikels überschreiten würde, werden die einzelnen Schritte nur kurz erwähnt. Wenn du Kunde von PathConnect bist, übernehmen wir auf Wunsch kostenlos die komplette Einrichtung.</p>
          
          <ol className="list-decimal ml-6 mb-4">
            <li>Melde dich sowohl bei OpenProject als auch bei Nextcloud mit einem Administrator-Account an.</li>
            <li>Die App „OpenProject Integration" in Nextcloud installieren</li>
            <li>In den Administratoreinstellungen auf „OpenProject-Integration" klicken und Serveradresse der OpenProject-Instanz eingeben</li>
            <li>In OpenProject bei den Administratoreinstellungen auf „Dateispeicher" klicken, bei Provider „Nextcloud" auswählen und die Serveradresse deiner Nextcloud eintragen.</li>
            <li>Kopiere die oAuth Client ID und das oAuth Client Secret aus OpenProject und füge diese in deine Nextcloud ein</li>
            <li>oAuth client ID und oAuth client secret aus Nextcloud kopieren und in OpenProject einfügen</li>
            <li>Kopiere das Anwendungspasswort aus Nextcloud und füge es in OpenProject ein</li>
          </ol>
          
          <BlogHeading>Projektkonfiguration</BlogHeading>
          
          <p>In der Projektkonfiguration unter „Module" den Haken bei „Datei-Speicher" setzen. Danach kann die Nextcloud Verbindung für dieses Projekt eingerichtet werden.</p>
          
          <BlogGallery 
            images={[
              { src: projectModuleScreenshot, alt: "Open Project Datei-Speicher" },
              { src: nextcloudSetupScreenshot, alt: "Open Project Nextcloud Speicher hinzufügen" }
            ]}
          />
          
          <BlogHeading>Kontoeinrichtung</BlogHeading>
          
          <p>Nachdem die Verbindung eingerichtet wurde, muss sich jeder OpenProject-Nutzer mit seinem Nextcloud-Account verbinden. Die grundlegenden Schritte sind im Folgenden beschrieben:</p>
          
          <ol className="list-decimal ml-6 mb-4">
            <li>In einem beliebigen Arbeitspaket auf „Dateien" klicken und anschließend auf „Nextcloud Login"</li>
            <li>Falls noch nicht geschehen, bei Nextcloud anmelden und Zugriff von OpenProject bestätigen</li>
          </ol>
          
          <BlogImage src={loginScreenshot} alt="Open Project bei Nextcloud anmelden" size="large" className="my-6" />
          
          <BlogHeading>OpenProject bei PathConnect</BlogHeading>
          
          <p>Als weitere Managed Hosting Lösung bieten wir <a href="https://pathconnect.de/openproject/" target="_blank" rel="noopener">OpenProject</a> sowohl in der Community als auch in der Enterprise Version an. Du erhältst eine OpenProject-Instanz auf deinem eigenen virtuellen Server, die von uns gewartet und gepflegt wird. Neben dem persönlichen Support übernehmen wir auch spezielle Konfigurationen wie z.B. die Anbindung an deine Nextcloud.</p>
          
          <BlogImage src={serverIcon} alt="Server Icon" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud und OpenProject Integration",
      formattedDate: "8. Dezember 2023",
      excerpt: "Die Integration von Nextcloud in OpenProject ermöglicht es dir, deine projektbezogenen Dateien direkt in deiner Nextcloud zu speichern und mit den einzelnen Arbeitspaketen zu verknüpfen. In diesem Artikel zeigen wir dir die Einrichtung und die Funktionen, die durch die Integration möglich sind.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation
    en: {
      title: "Nextcloud and OpenProject Integration",
      formattedDate: "December 8, 2023",
      excerpt: "The integration of Nextcloud into OpenProject allows you to store your project-related files directly in your Nextcloud and link them to individual work packages. In this article, we show you the setup and functions made possible by the integration.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud and OpenProject Integration</h1>
          
          <p>The integration of Nextcloud into OpenProject allows you to store your project-related files directly in your Nextcloud and link them to individual work packages. In this article, we show you the setup and functions made possible by the integration.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <div className="flex flex-row justify-center gap-4">
                <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="small" />
                <BlogImage src={openprojectLogo} alt="OpenProject Logo" size="small" />
              </div>
            </div>
            
          </div>
          
          <BlogHeading>Nextcloud and OpenProject</BlogHeading>
          
          <p>With OpenProject, you can plan, execute, and manage your projects. Your Nextcloud serves as a secure storage location for your files among many other functions. By combining them, you can take advantage of both open source solutions. Through seamless integration, work packages can be managed with their associated files in both Nextcloud and OpenProject.</p>
          
          <BlogHeading>Features</BlogHeading>
          
          <p><u>Icon in the Nextcloud menu bar</u><br />An additional icon in the Nextcloud menu bar provides direct access to OpenProject.</p>
          
          <BlogImage src={menuScreenshot} alt="Nextcloud menu bar" size="large" className="my-6" />
          
          <p><u>Linking work packages with files from within OpenProject</u><br />In OpenProject, you can upload new files to your Nextcloud storage or link existing files within work packages.<br />Select a work package and click on Files. Under the name of your Nextcloud, you can either upload a new file or link an existing file from your Nextcloud. With a click on the file, you'll be taken directly to the right location in your Nextcloud.</p>
          
          <BlogGallery 
            images={[
              { src: attachFileScreenshot, alt: "OpenProject attach file" },
              { src: selectFileScreenshot, alt: "Select file from Nextcloud" }
            ]}
          />
          
          <p><u>Linking files with work packages from within Nextcloud</u><br />You can also link files directly from your Nextcloud to a work package in OpenProject and access it.<br />Click on the three dots next to a file or folder and select "OpenProject". In the search field, you can search for the desired work package and then link it. Afterward, the work package with status and description will be displayed directly in your Nextcloud. By clicking on the work package, you'll go directly to the corresponding work package in OpenProject.</p>
          
          <BlogImage src={docLinkScreenshot} alt="Demo document in Nextcloud" size="large" className="my-6" />
          
          <BlogHeading>Basic Setup</BlogHeading>
          
          <p>Several steps are necessary to connect Nextcloud and OpenProject. Since a detailed guide would exceed the scope of this article, the individual steps are only briefly mentioned. If you are a PathConnect customer, we will handle the complete setup for you at no additional cost.</p>
          
          <ol className="list-decimal ml-6 mb-4">
            <li>Log in to both OpenProject and Nextcloud with an administrator account.</li>
            <li>Install the "OpenProject Integration" app in Nextcloud</li>
            <li>In the administrator settings, click on "OpenProject Integration" and enter the server address of the OpenProject instance</li>
            <li>In OpenProject, go to administrator settings, click on "File storage", select "Nextcloud" as the provider, and enter the server address of your Nextcloud.</li>
            <li>Copy the oAuth Client ID and oAuth Client Secret from OpenProject and paste them into your Nextcloud</li>
            <li>Copy the oAuth client ID and oAuth client secret from Nextcloud and paste them into OpenProject</li>
            <li>Copy the application password from Nextcloud and paste it into OpenProject</li>
          </ol>
          
          <BlogHeading>Project Configuration</BlogHeading>
          
          <p>In the project configuration under "Modules", check the box for "File storage". After that, the Nextcloud connection can be set up for this project.</p>
          
          <BlogGallery 
            images={[
              { src: projectModuleScreenshot, alt: "OpenProject file storage" },
              { src: nextcloudSetupScreenshot, alt: "Add Nextcloud storage in OpenProject" }
            ]}
          />
          
          <BlogHeading>Account Setup</BlogHeading>
          
          <p>After the connection has been established, each OpenProject user must connect with their Nextcloud account. The basic steps are described below:</p>
          
          <ol className="list-decimal ml-6 mb-4">
            <li>Click on "Files" in any work package and then on "Nextcloud Login"</li>
            <li>If not already done, log in to Nextcloud and confirm access from OpenProject</li>
          </ol>
          
          <BlogImage src={loginScreenshot} alt="Log in to Nextcloud from OpenProject" size="large" className="my-6" />
          
          <BlogHeading>OpenProject at PathConnect</BlogHeading>
          
          <p>As another managed hosting solution, we offer <a href="https://pathconnect.de/openproject/" target="_blank" rel="noopener">OpenProject</a> in both the Community and Enterprise versions. You get an OpenProject instance on your own virtual server, which we maintain and care for. In addition to personal support, we also handle special configurations such as the connection to your Nextcloud.</p>
          
          <BlogImage src={serverIcon} alt="Server Icon" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud and OpenProject Integration",
      formattedDate: "8 December 2023",
      excerpt: "The integration of Nextcloud into OpenProject allows you to store your project-related files directly in your Nextcloud and link them to individual work packages. In this article, we show you the setup and functions made possible by the integration.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;