// src/data/blogPosts/kalender-und-kontakt-mit-android-synchronisieren.js

// Import images
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import syncIcon from '../../assets/images/blog/2022/03/sync.png';
import calendarIcon from '../../assets/images/blog/2022/03/calendar.png';
import customerIcon from '../../assets/images/blog/2022/01/Verbraucher.png';
import thumbnailImage from '../../assets/images/blog/kalender-und-kontakt-mit-android-synchronisieren-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about syncing calendar and contacts with Android
 */
const post = {
  id: '7578',
  slug: 'kalender-und-kontakt-mit-android-synchronisieren',
  
  // Base data - same regardless of language
  date: "2022-03-30T12:31:04.000Z",
  readTime: 9,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 28,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Kalender und Kontakt mit Android synchronisieren",
      formattedDate: "30. März 2022",
      excerpt: "Nextcloud ist eine umfangreiche Cloud-Lösung, die mehr bietet als Dateien in die Cloud zu laden. Eine weitere Stärke ist die Kalender- und Kontaktverwaltung, die mittels CalDAV/CardDAV synchronisiert werden können. In diesem Blogpost erklären wir euch, wie ihr eure Kalender und Kontakte mir eurem Android Smartphone synchronisieren könnt.",
      content: () => (
        <BlogContent>
          <p>Nextcloud ist eine umfangreiche Cloud-Lösung, die mehr bietet als Dateien in die Cloud zu laden.<br />Eine weitere Stärke ist die <strong>Kalender- und Kontaktverwaltung</strong>, die mittels CalDAV/CardDAV synchronisiert werden können. In diesem Blogpost erklären wir euch, wie ihr eure Kalender und Kontakte mir eurem <strong>Android Smartphone</strong> synchronisieren könnt<strong>.</strong></p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogImage src={syncIcon} alt="Synchronisations-Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading>Kalender und Kontake mit Android synchronisieren</BlogHeading>
          
          <ol>
            <li>Installiere dir die App &#8222;DAVx&#8220; auf deinem Smartphone<br />Du erhälst die App entweder kostenlos über den F-Droid Store:<br /><em><a href="https://f-droid.org/en/packages/at.bitfire.davdroid/" target="_blank" rel="noopener">https://f-droid.org/en/packages/at.bitfire.davdroid/</a></em><br />Oder gegen eine kleine Gebühr über den PlayStore:<br /><em><a href="https://play.google.com/store/apps/details?id=at.bitfire.davdroid&hl=en&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=at.bitfire.davdroid&hl=en&gl=US</a></em></li>
            <li>Öffne deine Nextcloud – App auf dem Smartphone</li>
            <li>Tippe rechts oben auf die drei Striche</li>
            <li>Wähle &#8222;Einstellungen&#8220; aus</li>
            <li>Unter dem Punk &#8222;Mehr&#8220; auf &#8222;Kalender & Kontakte synchronisieren&#8220; tippen</li>
            <li>Tippe auf &#8222;CalDAV-Account hinzufügen&#8220;</li>
            <li>Es öffnen sich ein Browserfenster in dem du auf den Button &#8222;Anmelden&#8220; tippst</li>
            <li>Melde dich dort mit deinen Anmeldedaten an und tippe auf &#8222;Zugriff gewähren&#8220;</li>
            <li>Das Browserfenster kann jetzt wieder geschlossen werden</li>
            <li>Es öffnet sich automatisch die eben installierte App &#8222;DAVx&#8220;<br />Tippe hier auf &#8222;Konto anlegen&#8220;</li>
            <li>Über den Reiter &#8222;CALDAV&#8220; kannst du deine Kontakte synchronisieren</li>
            <li>Über den Reiter &#8222;CALDAV&#8220; hast du die Möglichkeit deine Kalender zu synchronisieren</li>
          </ol>
          
          <BlogImage src={calendarIcon} alt="Kalender Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Deine Kontakte von Google zu Nextcloud importieren</BlogHeading>
          
          <p>Standardmäßig werden deine ganzen Kontakte auf Google Server geladen. Da du jetzt die Möglichkeit hast die Privatsphäre von dir und deinen Kontakten zu schützen empfehlen wir an dieser Stelle, einmal alle Kontakte aus deinem Google Konto zu exportieren und dann in deine Nextcloud zu importieren.</p>
          
          <BlogImage src={customerIcon} alt="Verbraucher Icon" size="small" className="mx-auto my-6" />
          
          <ol>
            <li>Melde dich an deinem Google Konto an</li>
            <li>Unter diesem Link gelangst du zu deinen Kontakte:<br /><em><a href="https://contacts.google.com" target="_blank" rel="noopener">https://contacts.google.com</a></em></li>
            <li>Klicke links unten auf &#8222;Exportieren&#8220; und wähle vCard aus</li>
            <li>Melde dich bei der deiner Nextcloud an und klicke auf Kontakte</li>
            <li>Klicke links unten auf &#8222;Einstellungen&#8220; und danach auf &#8222;Kontakte importieren&#8220;</li>
            <li>Wähle die eben heruntergeladene vCard Datei aus</li>
            <li>Fertig! Deine Kontakte liegen jetzt in der Nextcloud und können mit deinem Smartphone synchronisiert werden</li>
            <li>Tipp: In der DAVx App musst du die Kontakte einmal neue einlesen und dann auswählen</li>
          </ol>
          
          <BlogHeading level={3}>Kalender App</BlogHeading>
          
          <p>Die meisten Android Benutzer verwenden den Google Kalender. Auch wenn deine Termine jetzt über deine Nextcloud synchronisiert werden empfehlen wir dennoch auf eine andere Kalender App umzusteigen.</p>
          
          <p>Wir empfehlen dir die Open Source App &#8222;Fossify Calendar &#8222;<br />
          <a href="https://f-droid.org/de/packages/org.fossify.calendar/" target="_blank" rel="noopener">F-Droid</a><br />
          <a href="https://play.google.com/store/apps/details?id=org.fossify.calendar" target="_blank" rel="noopener">Play Store</a></p>
          
          <BlogHeading level={3}>Zusammenfassung</BlogHeading>
          
          <p>Mit Nextcloud könnte ihr problemlos eure Kontakte und Kalender auf euer Smartphone synchronisieren.</p>
          
          <p><strong><u>Vorteile:</u></strong></p>
          
          <ul>
            <li>Schützt eure Privatsphäre</li>
            <li>Eure Kontakte und Kalendereinträge bleiben unter eurer Kontrolle und landen nicht bei Google</li>
            <li>Kalender -und Kontakteinträge von der Nextcloud Weboberfläche werden automatisch auf euer Smartphone synchronisiert</li>
            <li>Kein Verlust von Kalendereinträgen und Kontakten</li>
            <li>DSGVO konform</li>
          </ul>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Kalender und Kontakt mit Android synchronisieren",
      formattedDate: "30. März 2022",
      excerpt: "Nextcloud ist eine umfangreiche Cloud-Lösung, die mehr bietet als Dateien in die Cloud zu laden. Eine weitere Stärke ist die Kalender- und Kontaktverwaltung, die mittels CalDAV/CardDAV synchronisiert werden können. In diesem Blogpost erklären wir euch, wie ihr eure Kalender und Kontakte mir eurem Android Smartphone synchronisieren könnt.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Synchronize Calendar and Contacts with Android",
      formattedDate: "March 30, 2022",
      excerpt: "Nextcloud is a comprehensive cloud solution that offers more than just uploading files to the cloud. Another strength is the calendar and contact management, which can be synchronized using CalDAV/CardDAV. In this blog post, we'll explain how to synchronize your calendars and contacts with your Android smartphone.",
      content: () => (
        <BlogContent>
          <p>Nextcloud is a comprehensive cloud solution that offers more than just uploading files to the cloud.<br />Another strength is the <strong>calendar and contact management</strong>, which can be synchronized using CalDAV/CardDAV. In this blog post, we'll explain how to synchronize your calendars and contacts with your <strong>Android smartphone</strong>.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogImage src={syncIcon} alt="Synchronization Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading>Synchronize Calendar and Contacts with Android</BlogHeading>
          
          <ol>
            <li>Install the "DAVx" app on your smartphone<br />You can get the app either for free from the F-Droid Store:<br /><em><a href="https://f-droid.org/en/packages/at.bitfire.davdroid/" target="_blank" rel="noopener">https://f-droid.org/en/packages/at.bitfire.davdroid/</a></em><br />Or for a small fee from the PlayStore:<br /><em><a href="https://play.google.com/store/apps/details?id=at.bitfire.davdroid&hl=en&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=at.bitfire.davdroid&hl=en&gl=US</a></em></li>
            <li>Open your Nextcloud app on your smartphone</li>
            <li>Tap on the three lines in the top right</li>
            <li>Select "Settings"</li>
            <li>Under the "More" section, tap on "Synchronize calendar & contacts"</li>
            <li>Tap on "Add CalDAV account"</li>
            <li>A browser window will open where you tap on the "Login" button</li>
            <li>Log in with your credentials and tap on "Grant access"</li>
            <li>The browser window can now be closed</li>
            <li>The "DAVx" app you just installed will open automatically<br />Tap on "Create account" here</li>
            <li>Via the "CARDDAV" tab, you can synchronize your contacts</li>
            <li>Via the "CALDAV" tab, you have the option to synchronize your calendars</li>
          </ol>
          
          <BlogImage src={calendarIcon} alt="Calendar Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Import Your Contacts from Google to Nextcloud</BlogHeading>
          
          <p>By default, all your contacts are uploaded to Google servers. Since you now have the opportunity to protect your privacy and that of your contacts, we recommend exporting all contacts from your Google account and importing them into your Nextcloud.</p>
          
          <BlogImage src={customerIcon} alt="Consumer Icon" size="small" className="mx-auto my-6" />
          
          <ol>
            <li>Log in to your Google account</li>
            <li>Use this link to access your contacts:<br /><em><a href="https://contacts.google.com" target="_blank" rel="noopener">https://contacts.google.com</a></em></li>
            <li>Click on "Export" at the bottom left and select vCard</li>
            <li>Log in to your Nextcloud and click on Contacts</li>
            <li>Click on "Settings" at the bottom left and then on "Import contacts"</li>
            <li>Select the vCard file you just downloaded</li>
            <li>Done! Your contacts are now in Nextcloud and can be synchronized with your smartphone</li>
            <li>Tip: In the DAVx app, you need to reload the contacts once and then select them</li>
          </ol>
          
          <BlogHeading level={3}>Calendar App</BlogHeading>
          
          <p>Most Android users use Google Calendar. Even though your appointments are now synchronized via your Nextcloud, we still recommend switching to a different calendar app.</p>
          
          <p>We recommend the open source app "Fossify Calendar"<br />
          <a href="https://f-droid.org/de/packages/org.fossify.calendar/" target="_blank" rel="noopener">F-Droid</a><br />
          <a href="https://play.google.com/store/apps/details?id=org.fossify.calendar" target="_blank" rel="noopener">Play Store</a></p>
          
          <BlogHeading level={3}>Summary</BlogHeading>
          
          <p>With Nextcloud, you can easily synchronize your contacts and calendars to your smartphone.</p>
          
          <p><strong><u>Benefits:</u></strong></p>
          
          <ul>
            <li>Protects your privacy</li>
            <li>Your contacts and calendar entries remain under your control and don't end up with Google</li>
            <li>Calendar and contact entries from the Nextcloud web interface are automatically synchronized to your smartphone</li>
            <li>No loss of calendar entries and contacts</li>
            <li>GDPR compliant</li>
          </ul>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customization, and updates, so you can focus entirely on your projects.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Synchronise Calendar and Contacts with Android",
      formattedDate: "30 March 2022",
      excerpt: "Nextcloud is a comprehensive cloud solution that offers more than just uploading files to the cloud. Another strength is the calendar and contact management, which can be synchronised using CalDAV/CardDAV. In this blog post, we'll explain how to synchronise your calendars and contacts with your Android smartphone.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;