// src/data/blogPosts/nextcloud-news.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-news-featured.png';
import newsLogo from '../../assets/images/blog/2021/11/2021-11-20_15h03_24.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud News
 */
const post = {
  id: '5751',
  slug: 'nextcloud-news',
  
  // Base data - same regardless of language
  date: "2021-11-20T14:03:26.000Z",
  readTime: 6,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 26,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud News",
      formattedDate: "20. November 2021",
      excerpt: "Nextcloud News. In diesem Blogbeitrag geht es darum, wie du deine Lieblingsnachrichten aus verschiedenen Kanälen in deine Nextcloud bekommst und welche Vorteile dir das bringt.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud News</h1>
          
          <p>In diesem Blogbeitrag geht es darum, wie du deine Lieblingsnachrichten aus verschiedenen Kanälen in deine Nextcloud bekommst und welche Vorteile dir das bringt.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={newsLogo} alt="Nextcloud News Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Einrichtung</BlogHeading>
          
          <ul className="list-disc pl-6 mb-4">
            <li>Auf den Reiter <strong>News</strong> in der Weboberfläche klicken</li>
            <li>Über <strong>Abonnieren</strong> den entsprechenden Link hinzufügen</li>
          </ul>
          
          <p>Wir haben auch ein Video in dem wir ausführlich auf die Einrichtung eingehen:<br />
            <a href="https://www.youtube.com/watch?v=fHHovgtHQoc" target="_blank" rel="noopener" className="underline">
              https://www.youtube.com/watch?v=fHHovgtHQoc
            </a>
          </p>
          
          <BlogHeading level={3}>Von unterwegs</BlogHeading>
          
          <p>Die passende App gibt es natürlich auch für dein <strong>Smartphone</strong>!</p>
          
          <p>
            iOS: <a href="https://apps.apple.com/us/app/nextnews/id1573041539" target="_blank" rel="noopener" className="underline">AppStore</a>
          </p>
          
          <p>
            Android: <a href="https://play.google.com/store/apps/details?id=de.luhmer.owncloudnewsreader&hl=de&gl=US" target="_blank" rel="noopener" className="underline">Playstore</a>
          </p>
          
          <p>Beim Öffnen der App wirst du gefragt, ob du dich mit deinem Nextcloud Account anmelden möchtest. Bestätigen und fertig. Schon hast du deine Nachrichten auch unterwegs immer dabei.</p>
          
          <BlogHeading level={3}>Vorteile</BlogHeading>
          
          <p>Mit Nextcloud News ist es möglich, sich Nachrichten aus verschiedenen Kanälen zusammenzustellen, ohne sich durch die einzelnen Seiten der Anbieter klicken zu müssen.</p>
          
          <p>Viele Nachrichtenanbieter bieten auch Links zu <strong>bestimmten Themen</strong> an, so dass du Inhalte, die dich nicht interessieren, von vornherein herausfiltern kannst.</p>
          
          <p>Der Zugriff auf die gesammelten Nachrichten erfolgt entweder direkt über die <strong>Weboberfläche</strong> von Nextcloud oder über die App auf dem <strong>Smartphone</strong>, so dass man auch unterwegs jederzeit auf seine Nachrichten zugreifen kann.</p>
          
          <p>Du bestimmst, was du sehen möchtest – durch die klare <strong>Auswahl der Inhalte</strong> bekommst du genau das angezeigt, was du ausgewählt hast, nicht mehr und nicht weniger.</p>
          
          <p><strong>Privatsphäre</strong> – Es geht niemanden etwas an, welche Nachrichten du am liebsten liest oder welche Themen dich am meisten interessieren. Mit Nextcloud News bleibt es allein deine Sache, so wie es sein soll!</p>
          
          <BlogHeading>Zusammenfassung</BlogHeading>
          
          <p>Mit Nextcloud News kannst du die <strong>Nachrichten deiner Wahl</strong> zusammenstellen, sie auf verschiedenen Geräten lesen und dabei deine Privatsphäre schützen.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud News",
      formattedDate: "20. November 2021",
      excerpt: "Nextcloud News. In diesem Blogbeitrag geht es darum, wie du deine Lieblingsnachrichten aus verschiedenen Kanälen in deine Nextcloud bekommst und welche Vorteile dir das bringt.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud News",
      formattedDate: "November 20, 2021",
      excerpt: "Nextcloud News. In this blog post, we'll show you how to get your favorite news from different channels into your Nextcloud and what advantages this brings you.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud News</h1>
          
          <p>In this blog post, we'll show you how to get your favorite news from different channels into your Nextcloud and what advantages this brings you.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud News",
      formattedDate: "20 November 2021",
      excerpt: "Nextcloud News. In this blog post, we'll show you how to get your favorite news from different channels into your Nextcloud and what advantages this brings you.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;