import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ThemeContext } from './themeProvider'
import { faLightbulb as faSolidLightbulb } from '@fortawesome/free-solid-svg-icons'
import Button from './button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

/**
 * ThemeToggleButton component.
 *
 * This component renders a button that toggles the theme between light and dark modes.
 * It uses the ThemeContext to get the current theme state and the function to toggle the theme.
 *
 * @component
 * @example
 * return (
 *   <ThemeToggleButton />
 * )
 */
const ThemeToggleButton = ({ className }) => {
  const { toggleTheme } = useContext(ThemeContext)

  return (
    <Button onClick={toggleTheme} className={`${className}`}>
      <FontAwesomeIcon icon={faSolidLightbulb} />
    </Button>
  )
}
ThemeToggleButton.propTypes = {
  className: PropTypes.string
}

export default ThemeToggleButton
