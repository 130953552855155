// src/data/blogPosts/nextcloud-kalender-in-ubuntu-und-windows-einbinden.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-kalender-in-ubuntu-und-windows-einbinden-featured.png';
import nextcloudLogo from '../../assets/images/blog/2020/08/Nextcloud_Logo.svg.png';
import neuerKalenderImage from '../../assets/images/blog/2024/03/Neuer-Kalender-erstellen.png';
import onlineKontenUbuntuImage from '../../assets/images/blog/2024/03/Online-Konten-Ubuntu-Kalender.png';
import ubuntuOnlineKontoImage from '../../assets/images/blog/2024/03/Ubuntu-Online-Konto.png';
import newAppPasswordImage from '../../assets/images/blog/2024/03/New-App-Password.png';
import oneCalendarImage from '../../assets/images/blog/2024/03/One-Calendar-herunterlade.png';
import caldavAuswaehlenImage from '../../assets/images/blog/2024/03/Caldav-auswaehlen.png';
import kalendereinstellungenImage from '../../assets/images/blog/2024/03/Kalendereinstellungen.png';
import caldavAnmeldedatenImage from '../../assets/images/blog/2024/03/caldav-anmeldedaten.png';
import calendarIcon from '../../assets/images/blog/2022/03/calendar.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about integrating Nextcloud Calendar in Ubuntu and Windows
 */
const post = {
  id: '19053',
  slug: 'nextcloud-kalender-in-ubuntu-und-windows-einbinden',
  
  // Base data - same regardless of language
  date: "2024-03-20T09:23:55.000Z",
  readTime: 10,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 39,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Kalender in Ubuntu und Windows integrieren",
      formattedDate: "20. März 2024",
      excerpt: "Der Nextcloud-Kalender dient als zentrale Terminverwaltung und kann sowohl im Web abgerufen als auch auf dem Desktop und dem Smartphone (Android und iOS) eingebunden werden. In diesem Artikel erfährst du, wie du den Kalender unter Ubuntu und Windows integrieren kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Kalender in Ubuntu und Windows integrieren</h1>
          
          <p>Der Nextcloud-Kalender dient als zentrale Terminverwaltung und kann sowohl im Web abgerufen als auch auf dem Desktop und dem Smartphone (Android und iOS) eingebunden werden. In diesem Artikel erfährst du, wie du den Kalender unter Ubuntu und Windows integrieren kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Nextcloud Kalender erstellen</BlogHeading>
          
          <p>Erstelle zunächst einen Kalender in deiner Nextcloud, falls du noch keinen erstellt hast. Navigiere dazu auf das Kalender-Symbol in der oberen Menüleiste und klicke auf „+ Neuen Kalender".</p>
          
          <BlogImage src={neuerKalenderImage} alt="Neuer Kalender erstellen" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Nextcloud Kalender in Ubuntu integrieren</BlogHeading>
          
          <p>Öffne den Kalender in Ubuntu und klicke auf die drei Striche in der rechten oberen Ecke.</p>
          
          <BlogImage src={onlineKontenUbuntuImage} alt="Online Konten Ubuntu Kalender" size="medium" className="mx-auto my-6" />
          
          <p>Die Einstellungen für die Onlinekonten öffnen sich. Hier wählst du „Nextcloud" aus und gibst deine Anmeldedaten ein.<br />Als Passwort empfehlen wir dir, ein App-Passwort zu erstellen. Navigiere dazu in deiner Netcloud unter Einstellungen – Sicherheit – Geräte und Sitzungen.<br />Hier kannst du einen App-Namen eingeben und das App-Passwort generieren und kopieren.</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={ubuntuOnlineKontoImage} alt="Ubuntu Online Konto" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={newAppPasswordImage} alt="New App Password" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <p>Im nächsten Schritt kannst du auswählen, ob das Online-Konto auch für Kontakte und Dateien verwendet werden soll.</p>
          <p>Fertig! Dein Nextcloud Kalender ist nun in Ubuntu integriert und synchronisiert.</p>
          
          <BlogHeading>Nextcloud Kalender in Windows integrieren</BlogHeading>
          
          <p>Um den Nextcloud-Kalender in Windows zu integrieren, kannst du den „<a href="https://apps.microsoft.com/detail/9wzdncrdr0sf" target="_blank" rel="noopener">One Calendar</a>" aus dem Microsoft Store herunterladen.</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={oneCalendarImage} alt="One Calendar herunterladen" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={caldavAuswaehlenImage} alt="CalDAV auswählen" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <p>Als Kontoart wählst du „CalDAV" und gibst deine Zugangsdaten ein. Auch hier empfiehlt es sich, ein Passwort für die App in der Nextcloud einzurichten.</p>
          <p>Klicke anschließend auf &#8222;Verbinden" und &#8222;Beenden".</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={kalendereinstellungenImage} alt="Kalendereinstellungen" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={caldavAnmeldedatenImage} alt="CalDAV Anmeldedaten" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p>Die Integration des Nextcloud-Kalenders in Ubuntu oder Windows zentralisiert Termine und verbessert die Zugänglichkeit. Sie ermöglicht eine einfache Synchronisierung zwischen Geräten, bietet mehr Sicherheit und erlaubt personalisierte Erinnerungen. Durch die nahtlose Integration in andere Anwendungen wird das Zeitmanagement effizienter und sicherer, was die Produktivität steigert.</p>
          
          <BlogImage src={calendarIcon} alt="Kalender Icon" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software">
            <p>Entdecke unsere große Auswahl an Open Source Software. Du kannst fertige Pakete auswählen oder dein ganz individuelles Paket selbst zusammenstellen.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Kalender in Ubuntu und Windows integrieren",
      formattedDate: "20. März 2024",
      excerpt: "Der Nextcloud-Kalender dient als zentrale Terminverwaltung und kann sowohl im Web abgerufen als auch auf dem Desktop und dem Smartphone (Android und iOS) eingebunden werden. In diesem Artikel erfährst du, wie du den Kalender unter Ubuntu und Windows integrieren kannst.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Integrating Nextcloud Calendar in Ubuntu and Windows",
      formattedDate: "March 20, 2024",
      excerpt: "The Nextcloud calendar serves as a central appointment management system and can be accessed both on the web and integrated into desktop and smartphone (Android and iOS). In this article, you'll learn how to integrate the calendar in Ubuntu and Windows.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Integrating Nextcloud Calendar in Ubuntu and Windows</h1>
          
          <p>The Nextcloud calendar serves as a central appointment management system and can be accessed both on the web and integrated into desktop and smartphone (Android and iOS). In this article, you'll learn how to integrate the calendar in Ubuntu and Windows.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Integrating Nextcloud Calendar in Ubuntu and Windows",
      formattedDate: "20 March 2024",
      excerpt: "The Nextcloud calendar serves as a central appointment management system and can be accessed both on the web and integrated into desktop and smartphone (Android and iOS). In this article, you'll learn how to integrate the calendar in Ubuntu and Windows.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;