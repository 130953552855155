// src/data/blogPosts/nextcloud-photos-app-fuer-android.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-photos-app-fuer-android-featured.webp';
import nextcloudLogo from '../../assets/images/blog/2023/11/nextcloud-logo.png';
import serverConnectionImage from '../../assets/images/blog/2023/02/Screenshot_20230209_103932_Photos.jpg';
import browserLoginImage1 from '../../assets/images/blog/2023/02/Screenshot_20230209_104013_Firefox.jpg';
import browserLoginImage2 from '../../assets/images/blog/2023/02/Screenshot_20230209_104022_Firefox.jpg';
import foldersViewImage from '../../assets/images/blog/2023/02/Screenshot_20230209_105400_Photos.jpg';
import albumsViewImage from '../../assets/images/blog/2023/02/Screenshot_20230209_131903_Photos.jpg';
import settingsViewImage from '../../assets/images/blog/2023/02/Screenshot_20230209_105432_Photos.jpg';
import newAlbumImage from '../../assets/images/blog/2023/02/Neues-Album-erstellen.jpg';
import selectFolderImage from '../../assets/images/blog/2023/02/Ordner-auswaehlen.jpg';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Photos App for Android
 */
const post = {
  id: '11039',
  slug: 'nextcloud-photos-app-fuer-android',
  
  // Base data - same regardless of language
  date: "2023-02-09T13:25:08.000Z",
  readTime: 14,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 34,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Photos App für Android​",
      formattedDate: "9. Februar 2023",
      excerpt: "In diesem Blogbeitrag stellen wir dir die App Nextcloud Photo für Android vor. Du erfährst, wie du die App auf deinem Smartphone einrichten kannst und welche Vorteile sie dir bietet.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Photos App für Android</h1>
          
          <p>In diesem Blogbeitrag stellen wir dir die App Nextcloud Photo für Android vor. Du erfährst, wie du die App auf deinem Smartphone einrichten kannst und welche Vorteile sie dir bietet.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={nextcloudLogo} alt="Nextcloud Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading level={3}>Installation</BlogHeading>
          
          <p>Nextcloud Photos App für Android​ kann einfach im PlayStore heruntergeladen werden. Es gibt eine kostenlose Version mit Werbung:<br />
            <a href="https://play.google.com/store/apps/details?id=com.nkming.nc_photos&hl=en&gl=US" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://play.google.com/store/apps/details?id=com.nkming.nc_photos&hl=en&gl=US
            </a>
          </p>
          
          <p>Und eine kostenpflichtige Version (4,49 €), die dann frei von Werbung ist:<br />
            <a href="https://play.google.com/store/apps/details?id=com.nkming.nc_photos.paid&hl=en&gl=US" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://play.google.com/store/apps/details?id=com.nkming.nc_photos.paid&hl=en&gl=US
            </a>
          </p>
          
          <BlogHeading level={3}>Einrichtung</BlogHeading>
          
          <p>Nach dem Start der App wirst du nach der Serveradresse deiner Nextcloud gefragt. Zum Testen verbinden wir uns in diesem Beispiel mit <em>test.pathconnect.de</em>.</p>
          
          <p>Nach dem Tippen auf "Verbinden" öffnet sich dein Browser und du wirst aufgefordert, dich bei deiner Nextcloud anzumelden. Bestätige noch einmal, dass die App auf dein Konto zugreifen darf und schon bist du fertig.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/3">
              <BlogImage src={serverConnectionImage} alt="Server Verbindung" size="medium" />
            </div>
            <div className="w-full md:w-1/3">
              <BlogImage src={browserLoginImage1} alt="Browser Login" size="medium" />
            </div>
            <div className="w-full md:w-1/3">
              <BlogImage src={browserLoginImage2} alt="App Zugriff bestätigen" size="medium" />
            </div>
          </div>
          
          <p>Du erhältst nun eine Übersicht über die Ordner mit Fotos, die sich in deinem Nextcloud-Account befinden. Hier kannst du einen oder mehrere Ordner auswählen. Wenn du auf "Bestätigen" tippst, verbindet sich die App mit dem Server und deine Bilder werden angezeigt.</p>
          
          <p>Im unteren Bereich kannst du durch Tippen auf die Lupe nach Bildern suchen und durch Tippen auf "Alben" erhältst du eine Übersicht über deine erstellten Fotoalben.</p>
          
          <p>Oben rechts hast du die Möglichkeit, die Bildgröße für die Anzeige zu ändern, den Dark-Modus ein- und auszuschalten und durch Tippen auf die drei Punkte gelangst du zu den Einstellungen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/3">
              <BlogImage src={foldersViewImage} alt="Ordner Übersicht" size="medium" />
            </div>
            <div className="w-full md:w-1/3">
              <BlogImage src={albumsViewImage} alt="Alben Übersicht" size="medium" />
            </div>
            <div className="w-full md:w-1/3">
              <BlogImage src={settingsViewImage} alt="Einstellungen" size="medium" />
            </div>
          </div>
          
          <p>Im oberen Bereich kann die Sprache eingestellt und die EXIF-Unterstützung aktiviert werden. EXIF steht für Exchangeable Image File Format und enthält Metainformationen wie GPS und Zeitstempel der Bilder. Für eine bessere Sortierung und Zuordnung der Bilder empfiehlt es sich diese zu aktivieren. Um dein Datenvolumen zu schonen, solltest du diese Informationen zu deinen Bildern aber nur über WLAN laden.</p>
          
          <p><span className="underline">Hier sind die wichtigsten weiteren Einstellungsmöglichkeiten:</span></p>
          
          <p><strong>Account</strong>:</p>
          <ul className="list-disc pl-6 mb-4">
            <li>Label(Name) für deinen Account, der in der Foto-Übersicht angezeigt wird</li>
            <li>Bildordner auswählen</li>
            <li>Ordner für freigegebene Bilder wählen</li>
            <li>Gesichtserkennung aktivieren, um deine Bilder nach Personen zu sortieren<br />(muss auch in deiner Nextcloud aktiviert sein)</li>
          </ul>
          
          <p><strong>Fotos</strong></p>
          <ul className="list-disc pl-6 mb-4">
            <li>Memories und Zeitraum einstellen, es werden automatisch Bilder angezeigt, die z.B. im gleichen Monat vor einem Jahr aufgenommen wurden.</li>
          </ul>
          
          <p><strong>Album</strong></p>
          <ul className="list-disc pl-6 mb-4">
            <li>Hier kannst du die Bilder nach Datum sortieren lassen. Aktiviere diese Option aber nur, wenn die Bilder im Ordner sowieso schon richtig sortiert sind. Wenn deine Bilder im Ordner nicht richtig sortiert sind, lass diese Option deaktiviert. Nextcloud Photos sortiert die Bilder dann automatisch nach Aufnahmedatum.</li>
          </ul>
          
          <BlogHeading level={3}>Alben einrichten</BlogHeading>
          
          <p>Für eine übersichtliche Darstellung können Alben angelegt werden. Es gibt die Möglichkeit neue Alben zu erstellen oder bereits in der Nextcloud erstelle Ordner als Alben zu verwenden.</p>
          
          <p>Tippe dazu in der unteren Menüleiste auf "Alben" und dann auf "Neues Album hinzufügen".</p>
          
          <p>Bei Name kannst du den den Namen für das Album eingeben. Für den Ordner der deine Handybilder enthählt könntest du das Album dazu auch "Handybilder" nennen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={newAlbumImage} alt="Neues Album erstellen" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={selectFolderImage} alt="Ordner auswählen" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Vorteile und Nutzen</BlogHeading>
          
          <p>Mit der Nextcloud Photos App für Android​ hast du einen tollen Überblick über deine Fotosammlung. Alle Bilder werden chronologisch sortiert, auch wenn es in deinem Ordner mal etwas unübersichtlich geworden ist. Mit der "Memories Funktion" kommen alte, schöne Erinnerung wieder zum Vorschein und mit der Kategorisierung nach Orten und Personen lassen sich Fotos schnell wiederfinden. Und das Beste ist natürlich, dass alles über deine eigene Nextcloud läuft, deine Bilder also nicht gescannt und keine Daten weitergegeben werden.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Photos App für Android​",
      formattedDate: "9. Februar 2023",
      excerpt: "In diesem Blogbeitrag stellen wir dir die App Nextcloud Photo für Android vor. Du erfährst, wie du die App auf deinem Smartphone einrichten kannst und welche Vorteile sie dir bietet.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Photos App for Android",
      formattedDate: "February 9, 2023",
      excerpt: "In this blog post, we introduce you to the Nextcloud Photo app for Android. You'll learn how to set up the app on your smartphone and what benefits it offers you.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Photos App for Android</h1>
          
          <p>In this blog post, we introduce you to the Nextcloud Photo app for Android. You'll learn how to set up the app on your smartphone and what benefits it offers you.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Photos App for Android",
      formattedDate: "9 February 2023",
      excerpt: "In this blog post, we introduce you to the Nextcloud Photo app for Android. You'll learn how to set up the app on your smartphone and what benefits it offers you.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;