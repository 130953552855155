// src/components/blog/BlogHeading.js
import React from 'react';

/**
 * Consistent heading component for blog posts
 * Improved for better text wrapping on mobile
 */
const BlogHeading = ({ children, className = '', noDivider = false }) => {
  return (
    <>
      <h2 className={`text-xl sm:text-xl font-bold mt-4 mb-1 break-words hyphens-auto leading-tight ${className}`}>
        {children}
      </h2>
      {!noDivider && (
        <div className="w-full border-b border-gray-200 dark:border-gray-700 mb-4"></div>
      )}
    </>
  );
};

export default BlogHeading;