// src/data/blogPosts/buchempfehlung-dann-haben-die-halt-meine-daten-na-un.js

// Import images
import bookIcon from '../../assets/images/blog/2022/04/book.png';
import womanIcon from '../../assets/images/blog/2022/04/woman.png';
import stopIcon from '../../assets/images/blog/2022/02/stop.png';
import wayIcon from '../../assets/images/blog/2022/04/way.png';
import thumbnailImage from '../../assets/images/blog/buchempfehlung-dann-haben-die-halt-meine-daten-na-un-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about the book recommendation
 */
const post = {
  id: '7852',
  slug: 'buchempfehlung-dann-haben-die-halt-meine-daten-na-un',
  
  // Base data - same regardless of language
  date: "2022-04-29T11:29:25.000Z",
  readTime: 8,
  categories: ["Empfehlungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 13,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Buchempfehlung – Dann haben die halt meine Daten. Na un?!",
      formattedDate: "29. April 2022",
      excerpt: "In diesem Blogartikel möchten wir euch das Buch \"Dann haben die halt meine Daten. Na und?!\" vorstellen. Das Buch richtet sich an alle die nichts zu verbergen haben, sich für Datenschutz und Privatsphäre interessieren oder einfach noch mehr über das Thema erfahren möchten.",
      content: () => (
        <BlogContent>
          <p>In diesem Blogartikel möchten wir euch das Buch "Dann haben die halt meine Daten. Na und?!" vorstellen.</p>
          <p>Das Buch richtet sich an alle die nichts zu verbergen haben, sich für Datenschutz und Privatsphäre interessieren oder einfach noch mehr über das Thema erfahren möchten.</p>
          
          <BlogImage src={bookIcon} alt="Buchcover" size="small" />
          
          {/* All headings should have dividers for consistency */}
          <BlogHeading>Über die Autorin:</BlogHeading>
          
          <p>Klaudia Zotzmann-Koch ist Autorin, Podcasterin und Datenschutzexpertin. Neben Krimis, historischen Romanen und Sci-Fi schreibt sie auch Sachbücher über kreative Themen und Datenschutz. Außerdem hält sie Vorträge und Workshops zu "digitaler Selbstverteidigung", Medienkompetenz, Technikforen, Social Media, Datenschutz, kreatives Schreiben und Podcasting.</p>
          <p>Ende 2015 kam sie zum Chaos Computer Club und machte eine Ausbildung zum Datenschutzbeauftragten mit einer anschließenden Prüfung der Wirtschaftskammer Österreich zur Datenschutzexpertin. Danach sammelte sie NGO-Erfahrungen und war in einer Agentur für Unternehmensberatung im Bereich Datenschutz tätig.</p>
          <p>Seit 2016 hält sie mehrfach im Jahr Workshops und Vorträge.</p>
          
          <BlogImage src={womanIcon} alt="Frau Icon" size="small" />
          
          <BlogHeading>Über das Buch:</BlogHeading>
          
          <p>In ihrem Buch "Dann haben die halt meine Daten. Na und?!" erklärt sie mit anschaulichen Metaphern und zahlreichen Anekdoten den Leser:innen, wie ihr Weg zu mehr Datensicherheit verlaufen ist und was sie in den vergangen fünf Jahren gelernt hat. Sie zeigt, dass auch ganz normale Menschen es schaffen können, ein daten-bewussteres Leben zu führen und gibt ihnen leicht umzusetzende Anleitungen dafür an die Hand.</p>
          <p>Es werden brandaktuelle Themen wie das Verbot von Google Analytics, Google Fonts und mehrseitigen Cookiebanner behandelt. Man muss kein IT-Nerd oder Experte sein um dieses Buch zu lesen, alle Begriffe werden sehr gut erklärt und erläutert.</p>
          
          <BlogImage src={bookIcon} alt="Buchcover" size="small" />
          
          <BlogHeading>Aufklärungen im Buch</BlogHeading>
          
          <p>Auf was lasse ich mich eigentlich ein, wenn ich Dienste von Google verwende? Die meisten werden sich nicht die Datenschutzerklärungen durchgelesen haben und selbst, wenn man es versucht, sind diese über mehrere Stellen verteilt und nur sehr schwer zu verstehen.<br />Im Buch wird aufgeklärt was in diesen Datenschutzerklärungen steht und in verständliche Sprache übersetzt. Und so wird einem schnell klar das die Rechte an den Inhalten wie Fotos, Videos, Dokumente etc. an Google übertragen werden, wenn man diese Dienste nutzt.<br />Hier ein paar Beispiele: Ihr schreibt für die Uni eine wissenschaftliche Arbeit in Google-Docs, damit übergebt ihr die Rechte daran an Google. <br />Das gleiche gilt übrigens auch für die Urlaubsfotos in eurem G-Drive oder die E-Mails in G-Mail.<br />Übrigens dürfen eure Bilder von Google für immer behalten werden sobald ihr diese einmal mit einer Person geteilt habt.<br />Google holt sich außerdem die Rechte ein, eure Inhalte zu analysieren. <br />Durch solche Aufklärungen wird den Lesern vorgezeigt welche Auswirkung die Nutzung solcher Dienste haben.</p>
          
          <BlogImage src={stopIcon} alt="Stop-Schild" size="small" />
          
          <BlogHeading>Es geht auch anders</BlogHeading>
          
          <p>Das Buch geht nicht nur darauf ein wieso man gewisse Dienste nicht nutzen sollte. sondern zeigt auch auf wie es anders geht. Mit konkreten Beispielen für datenschutzfreundlichen Alternativen wird es dem Leser leicht gemacht seine Privatsphäre wieder zurück zu bekommen.</p>
          <p>Einige davon wie z.B. die Suchmaschine Startpage oder den Firefox Browser haben wir bereits in einer unseren Blogartikeln erwähnt. Auch Nextcloud mit seinen vielfältigen Funktionen wird als datenschutzfreundliche Alternative genannt.</p>
          <p>Hier der Link zum Buch:<br /><a href="https://www.zotzmann-koch.com/book/na-und/" target="_blank" rel="noopener noreferrer">https://www.zotzmann-koch.com/book/na-und/</a></p>
          
          <BlogImage src={wayIcon} alt="Wegweiser" size="small" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Buchempfehlung – Dann haben die halt meine Daten. Na un?!",
      formattedDate: "29. April 2022",
      excerpt: "In diesem Blogartikel möchten wir euch das Buch \"Dann haben die halt meine Daten. Na und?!\" vorstellen. Das Buch richtet sich an alle die nichts zu verbergen haben, sich für Datenschutz und Privatsphäre interessieren oder einfach noch mehr über das Thema erfahren möchten.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Book Recommendation – So They Have My Data. So What?!",
      formattedDate: "April 29, 2022",
      excerpt: "In this blog post, we'd like to introduce you to the book \"So They Have My Data. So What?!\" This book is aimed at all those who have nothing to hide, are interested in data protection and privacy, or simply want to learn more about the topic.",
      content: () => (
        <BlogContent>
          <p>In this blog post, we'd like to introduce you to the book "So They Have My Data. So What?!"</p>
          <p>This book is aimed at all those who have nothing to hide, are interested in data protection and privacy, or simply want to learn more about the topic.</p>
          
          <BlogImage src={bookIcon} alt="Book cover" size="small" />
          
          {/* All headings should have dividers for consistency */}
          <BlogHeading>About the Author:</BlogHeading>
          
          <p>Klaudia Zotzmann-Koch is an author, podcaster, and data protection expert. In addition to crime novels, historical novels, and sci-fi, she also writes non-fiction books on creative topics and data protection. She also gives lectures and workshops on "digital self-defense," media literacy, technology forums, social media, data protection, creative writing, and podcasting.</p>
          <p>At the end of 2015, she joined the Chaos Computer Club and trained as a data protection officer, followed by an examination by the Austrian Chamber of Commerce to become a data protection expert. She then gained NGO experience and worked in a consulting agency in the field of data protection.</p>
          <p>Since 2016, she has been conducting workshops and lectures several times a year.</p>
          
          <BlogImage src={womanIcon} alt="Woman icon" size="small" />
          
          <BlogHeading>About the Book:</BlogHeading>
          
          <p>In her book "So They Have My Data. So What?!", she uses vivid metaphors and numerous anecdotes to explain to readers how her journey to greater data security has gone and what she has learned over the past five years. She shows that even ordinary people can manage to lead a more data-conscious life and gives them easy-to-implement instructions to do so.</p>
          <p>The book covers current topics such as the ban on Google Analytics, Google Fonts, and multi-page cookie banners. You don't have to be an IT nerd or expert to read this book; all terms are very well explained and illustrated.</p>
          
          <BlogImage src={bookIcon} alt="Book cover" size="small" />
          
          <BlogHeading>Revelations in the Book</BlogHeading>
          
          <p>What am I actually getting into when I use Google services? Most people will not have read the privacy policies, and even if you try, they are spread across multiple locations and very difficult to understand.<br />The book explains what these privacy policies say and translates them into understandable language. And so it quickly becomes clear that the rights to content such as photos, videos, documents, etc. are transferred to Google when you use these services.<br />Here are a few examples: You write a scientific paper for university in Google Docs, thereby transferring the rights to it to Google. <br />The same applies to your vacation photos in your G-Drive or emails in Gmail.<br />By the way, your images may be kept by Google forever once you've shared them with someone.<br />Google also obtains the rights to analyze your content. <br />Such revelations show readers the impact of using such services.</p>
          
          <BlogImage src={stopIcon} alt="Stop sign" size="small" />
          
          <BlogHeading>There is Another Way</BlogHeading>
          
          <p>The book not only explains why you shouldn't use certain services but also shows how to do it differently. With concrete examples of privacy-friendly alternatives, it makes it easy for readers to regain their privacy.</p>
          <p>We have already mentioned some of them, such as the Startpage search engine or the Firefox browser, in one of our blog articles. Nextcloud with its diverse functions is also mentioned as a privacy-friendly alternative.</p>
          <p>Here's the link to the book:<br /><a href="https://www.zotzmann-koch.com/book/na-und/" target="_blank" rel="noopener noreferrer">https://www.zotzmann-koch.com/book/na-und/</a></p>
          
          <BlogImage src={wayIcon} alt="Signpost" size="small" />
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Book Recommendation – So They Have My Data. So What?!",
      formattedDate: "April 29, 2022",
      excerpt: "In this blog post, we'd like to introduce you to the book \"So They Have My Data. So What?!\" This book is aimed at all those who have nothing to hide, are interested in data protection and privacy, or simply want to learn more about the topic.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;