import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

/**
 * PageTitle component sets the page title using the provided translation key.
 *
 * @param {Object} props - The component props.
 * @param {string} props.titleKey - The key used for translation to set the page title.
 * @returns {JSX.Element} The Helmet component with the translated title.
 */
const PageTitle = ({ titleKey }) => {
  const { t } = useTranslation('title')

  return (
    <Helmet>
      <title>{t(titleKey)}</title>
    </Helmet>
  )
}
PageTitle.propTypes = {
  titleKey: PropTypes.string.isRequired
}

export default PageTitle
