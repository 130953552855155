// src/data/blogPosts/keepassdx-android-app.js

// Import images
import keyIcon from '../../assets/images/blog/2022/01/key.png';
import appScreenshot1 from '../../assets/images/blog/2024/01/Screenshot_20240119_150100_KeePassDX.webp';
import appScreenshot2 from '../../assets/images/blog/2024/01/Screenshot_20240119_150111_Files.webp';
import appScreenshot3 from '../../assets/images/blog/2024/01/Screenshot_20240119_150116_Files.webp';
import thumbnailImage from '../../assets/images/blog/keepassdx-android-app-featured.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about KeePassDX Android App
 */
const post = {
  id: '15559',
  slug: 'keepassdx-android-app',
  
  // Base data - same regardless of language
  date: "2024-01-19T14:27:17.000Z",
  readTime: 13,
  categories: ["Anleitungen", "Empfehlungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 29,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "KeePassDX Android App",
      formattedDate: "19. Januar 2024",
      excerpt: 'Mit der App "KeePassDX" für Android kannst du auch unterwegs schnell und sicher auf deine Passwörter zugreifen. Dieser Artikel zeigt dir, wie du die App installierst und dich mit deiner Nextcloud verbindest.',
      content: () => (
        <BlogContent>
          <p>Mit der App "KeePassDX" für Android kannst du auch unterwegs schnell und sicher auf deine Passwörter zugreifen. Dieser Artikel zeigt dir, wie du die App installierst und dich mit deiner Nextcloud verbindest.</p>
          
          <h1>KeePassDX Android App</h1>
          
          <BlogHeading>Passwortmanager</BlogHeading>
          
          <p>Mit einem Passwortmanager musst du dir nicht mehr lange Passwörter für jeden Zugang merken. Es gibt ein komplexes Master-Passwort, mit dem du deinen Passwortmanager freischalten kannst. Das Tolle daran ist, dass du nur eine einzige Passwortdatenbank benötigst, die du auf deinem Computer, deinem Smartphone und in der Cloud öffnen kannst.</p>
          
          <p>Hier findest du weitere Artikel zu diesem Thema:<br />
          <a href="/blog/nextcloud-keeweb/" target="_blank" rel="noopener">Nextcloud KeeWeb</a><br />
          <a href="/blog/keepassxc/" target="_blank" rel="noopener">KeePassXC</a></p>
          
          <BlogImage src={keyIcon} alt="Schlüssel Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading>KeePassDX</BlogHeading>
          
          <p>Die Android-App KeePassDX ist Open Source und kann entweder im PlayStore oder im F-Droid heruntergeladen und installiert werden.</p>
          
          <p><a href="https://f-droid.org/de/packages/com.kunzisoft.keepass.libre/" target="_blank" rel="noopener">https://f-droid.org/de/packages/com.kunzisoft.keepass.libre/</a></p>
          
          <p><a href="https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.free&hl=de&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.free&hl=de&gl=US</a></p>
          
          <p>Die App ist frei von Werbung und Tracking und ermöglicht es, eine neue Passwortdatenbank zu erstellen oder eine bestehende zu öffnen. Die Passwortdatenbank ist eine normale Datei mit der Endung .kdb oder .kdbx.</p>
          
          <BlogHeading>Nextcloud Integration</BlogHeading>
          
          <p>Open Source bedeutet Freiheit. So kannst du auch hier frei wählen, wo und mit welchem Programm du deine Passwortdatenbank öffnen möchtest. Eine tolle Möglichkeit deine Passwörter auf verschiedenen Geräten zu synchronisieren oder direkt darauf zuzugreifen, bietet dir Nextcloud.</p>
          
          <p>Damit der Zugriff mit KeePassDX funktioniert, musst du bereits die Nextcloud App installiert und dich mit deinem Nextcloud Account angemeldet haben.</p>
          
          <p>Öffne dann die KeePassDX App und tippe auf das "Vorhandene Datenbank öffnen". Nun öffnet sich der Dateimanager auf deinem Smartphone. Hier kannst du auf der linken Seite deine Nextcloud auswählen und zur Passwortdatenbank navigieren.</p>
          
          <p>Nach Eingabe des Masterpasswortes erhältst du Zugang zu deinen Passwörtern. Es gibt auch die Möglichkeit, die Datenbank mit Hilfe von Smartphone-Login-Daten oder Biometrie wie dem Fingerabdruck zu entsperren. Dies bringt natürlich einen gewissen Komfort, aber auch Sicherheitseinbußen mit sich. Ob diese Option aktiviert werden soll, muss jeder selbst entscheiden.</p>
          
          <BlogGallery 
            images={[
              { src: appScreenshot1, alt: "KeePassDX - Startbildschirm" },
              { src: appScreenshot2, alt: "KeePassDX - Nextcloud hinzufügen" },
              { src: appScreenshot3, alt: "KeePassDX - Dateiauswahl" }
            ]}
          />
          
          <BlogHeading>Spenden und Pro Version</BlogHeading>
          
          <p>Die App ist für jeden frei zugänglich und werbefrei. Allerdings steckt viel Arbeit in der Entwicklung und die App wird ständig weiterentwickelt und gepflegt.</p>
          
          <p>Es gibt daher die Möglichkeit, entweder die Pro-Version aus dem PlayStore zu kaufen:<br />
          <a href="https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.pro&hl=en&gl=US">https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.pro&hl=en&gl=US</a></p>
          
          <p>Oder durch eine Spende zum Erhalt der Pro Version:<br />
          <a href="https://www.keepassdx.com/#donation">https://www.keepassdx.com/#donation</a></p>
          
          <p>Die Pro Version schaltet Designfeatures und Protokollfunktionen frei. Am wichtigsten ist jedoch die Unterstützung bei der Entwicklung der App.</p>
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "KeePassDX Android App",
      formattedDate: "19. Januar 2024",
      excerpt: 'Mit der App "KeePassDX" für Android kannst du auch unterwegs schnell und sicher auf deine Passwörter zugreifen. Dieser Artikel zeigt dir, wie du die App installierst und dich mit deiner Nextcloud verbindest.',
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "KeePassDX Android App",
      formattedDate: "January 19, 2024",
      excerpt: "With the KeePassDX app for Android, you can quickly and securely access your passwords while on the go. This article shows you how to install the app and connect it to your Nextcloud.",
      content: () => (
        <BlogContent>
          <p>With the KeePassDX app for Android, you can quickly and securely access your passwords while on the go. This article shows you how to install the app and connect it to your Nextcloud.</p>
          
          <h1>KeePassDX Android App</h1>
          
          <BlogHeading>Password Manager</BlogHeading>
          
          <p>With a password manager, you no longer need to remember long passwords for each account. There is one complex master password that unlocks your password manager. The great thing about this is that you only need a single password database that you can open on your computer, your smartphone, and in the cloud.</p>
          
          <p>Here are more articles on this topic:<br />
          <a href="/blog/nextcloud-keeweb/" target="_blank" rel="noopener">Nextcloud KeeWeb</a><br />
          <a href="/blog/keepassxc/" target="_blank" rel="noopener">KeePassXC</a></p>
          
          <BlogImage src={keyIcon} alt="Key Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading>KeePassDX</BlogHeading>
          
          <p>The Android app KeePassDX is open source and can be downloaded and installed either from the PlayStore or F-Droid.</p>
          
          <p><a href="https://f-droid.org/de/packages/com.kunzisoft.keepass.libre/" target="_blank" rel="noopener">https://f-droid.org/de/packages/com.kunzisoft.keepass.libre/</a></p>
          
          <p><a href="https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.free&hl=de&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.free&hl=de&gl=US</a></p>
          
          <p>The app is free from ads and tracking and allows you to create a new password database or open an existing one. The password database is a normal file with the extension .kdb or .kdbx.</p>
          
          <BlogHeading>Nextcloud Integration</BlogHeading>
          
          <p>Open source means freedom. Here too, you can freely choose where and with which program you want to open your password database. Nextcloud offers a great way to synchronize your passwords across different devices or access them directly.</p>
          
          <p>For access with KeePassDX to work, you must have already installed the Nextcloud app and logged in with your Nextcloud account.</p>
          
          <p>Then open the KeePassDX app and tap on "Open existing database". The file manager will now open on your smartphone. Here you can select your Nextcloud on the left side and navigate to the password database.</p>
          
          <p>After entering the master password, you will gain access to your passwords. There is also the option to unlock the database using smartphone login data or biometrics such as fingerprint. This of course brings a certain comfort, but also compromises security. Whether this option should be activated is up to each individual to decide.</p>
          
          <BlogGallery 
            images={[
              { src: appScreenshot1, alt: "KeePassDX - Home screen" },
              { src: appScreenshot2, alt: "KeePassDX - Add Nextcloud" },
              { src: appScreenshot3, alt: "KeePassDX - File selection" }
            ]}
          />
          
          <BlogHeading>Donations and Pro Version</BlogHeading>
          
          <p>The app is freely accessible to everyone and ad-free. However, a lot of work goes into development, and the app is constantly being improved and maintained.</p>
          
          <p>Therefore, there is the option to either purchase the Pro version from the PlayStore:<br />
          <a href="https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.pro&hl=en&gl=US">https://play.google.com/store/apps/details?id=com.kunzisoft.keepass.pro&hl=en&gl=US</a></p>
          
          <p>Or to receive the Pro version through a donation:<br />
          <a href="https://www.keepassdx.com/#donation">https://www.keepassdx.com/#donation</a></p>
          
          <p>The Pro version unlocks design features and logging functions. However, the most important aspect is supporting the development of the app.</p>
          
          <BlogSection title="Open Source Software at PathConnect">
            <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offer includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-around service that includes installation, customizations, and updates, so you can focus entirely on your projects.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "KeePassDX Android App",
      formattedDate: "19 January 2024",
      excerpt: "With the KeePassDX app for Android, you can quickly and securely access your passwords while on the go. This article shows you how to install the app and connect it to your Nextcloud.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;