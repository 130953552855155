// src/data/blogPosts/nextcloud-forms-umfragen-sicher-leicht-erstellen.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-forms-umfragen-sicher-leicht-erstellen-featured.png';
import clipboardIcon from '../../assets/images/blog/2023/11/iconmonstr-clipboard-6-240.png';
import helpIcon from '../../assets/images/blog/2023/08/iconmonstr-help-6-240.png';
import installationImage from '../../assets/images/blog/2023/11/Bildschirmfoto-vom-2023-11-16-10-40-03.png';
import newFormImage from '../../assets/images/blog/2023/11/Bildschirmfoto-vom-2023-11-16-14-03-09.png';
import newQuestionImage from '../../assets/images/blog/2023/11/Bildschirmfoto-vom-2023-11-16-14-07-36.png';
import settingsImage from '../../assets/images/blog/2023/11/Bildschirmfoto-vom-2023-11-16-14-08-58.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Forms
 */
const post = {
  id: '13623',
  slug: 'nextcloud-forms-umfragen-sicher-leicht-erstellen',
  
  // Base data - same regardless of language
  date: "2023-11-16T13:04:52.000Z",
  readTime: 9,
  categories: ["Anleitungen", "Informationen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 0,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Forms – Umfragen sicher & leicht erstellen",
      formattedDate: "16. November 2023",
      excerpt: "Mit der App \"Nextcloud Forms\" können Umfragen zu beliebigen Themen erstellt werden. In diesem Blogbeitrag wird erklärt, wie die App installiert, konfiguriert und verwendet werden kann.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Forms – Umfragen sicher & leicht erstellen</h1>
          
          <p>Mit der App "Nextcloud Forms" können Umfragen zu beliebigen Themen erstellt werden. In diesem Blogbeitrag wird erklärt, wie die App installiert, konfiguriert und verwendet werden kann.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={clipboardIcon} alt="Clipboard Icon" size="medium" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Umfragen</BlogHeading>
          
          <p>Es gibt viele Situationen, in denen es sich lohnt, eine Umfrage durchzuführen. Im geschäftlichen Bereich kann dies z.B. eine Umfrage zur Kundenzufriedenheit sein und im privaten Bereich kann eine Umfrage zur Freizeitgestaltung gestartet werden. Aber auch im Bildungsbereich sind Umfragen ein beliebtes Mittel, um die Meinungen verschiedener Personen einzuholen.</p>
          
          <BlogImage src={helpIcon} alt="Help Icon" size="medium" className="mx-auto my-6" />
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Um die App in deiner Nextcloud zu installieren, melde dich als Administrator an, klicke auf das Icon in der rechten oberen Ecke und wähle „Apps".<br />In der Suche kannst du nun „Form" eingeben und die App wird dir direkt angezeigt. Klicke dann auf „Herunterladen & aktivieren".</p>
          
          <BlogImage src={installationImage} alt="Forms App Installation" size="large" className="my-6" />
          
          <BlogHeading>Umfragen erstellen</BlogHeading>
          
          <p>In der oberen Menüleiste findest du ein neues Icon, über das du in die Anwendung Nextcloud Forms gelangst. <br />Mit einem Klick auf „+ Neues Formular" wird ein neues Formular erstellt. Als erstes kannst du einen Titel und eine kurze Beschreibung eingeben. Durch klicken auf „+ Frage hinzufügen" erstellst du deine erste Frage. Dabei stehen dir verschiedene Antworttypen zur Auswahl, die im Folgenden kurz erläutert werden.</p>
          
          <ul className="list-disc pl-6 mb-6">
            <li>
              <p><u>Kontrollkästchen</u>:<br />Bei Fragen mit mehreren Antwortmöglichkeiten.</p>
            </li>
            <li>
              <p><u>Optionsfelder</u><br />Wenn nur eine Antwort möglich sein soll.</p>
            </li>
            <li>
              <p><u>Aufklappmenü</u><br />Wie beim Optionsfeld gibt es auch hier nur eine Antwortmöglichkeit, allerdings sind die Optionen hier aufklappbar.</p>
            </li>
            <li>
              <p><u>Kurze Antwort</u><br />Die Kurzantwort ist ein Textfeld, in das eine Antwort von der Länge des Textfeldes eingegeben werden kann.</p>
            </li>
            <li>
              <p><u>Langer Text</u><br />Wie bei der Kurzantwort kann auch hier ein Text eingegeben werden. Statt einer Zeile steht jedoch ein ganzes Textfeld zur Verfügung.</p>
            </li>
            <li>
              <p><u>Datum</u><br />Als Antwortmöglichkeit kann über einen Kalender ein bestimmtes Datum eingegeben werden.</p>
            </li>
            <li>
              <p><u>Zeit</u><br />Diese Option kann für die Abfrage einer bestimmten Uhrzeit gewählt werden.</p>
            </li>
          </ul>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={newFormImage} alt="Nextcloud Forms Neues Formular hinzufügen" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={newQuestionImage} alt="Nextcloud Forms Neue Frage hinzufügen" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <BlogHeading>Menüleiste</BlogHeading>
          
          <p>Die obere Menüleiste der Nextcloud Forms-Anwendung bietet die Möglichkeit, zwischen der Bearbeitung der Fragen und der Benutzeransicht zu wechseln. Außerdem ist es möglich, die Umfrageergebnisse einzusehen und die Umfrage mit anderen zu teilen. <br />Außerdem können die Antworten anonym gespeichert, mehrere Antworten von einer Person zugelassen und mit einem Ablaufdatum versehen werden.</p>
          
          <BlogImage src={settingsImage} alt="Nextcloud Forms Einstellungen" size="large" className="my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Forms – Umfragen sicher & leicht erstellen",
      formattedDate: "16. November 2023",
      excerpt: "Mit der App \"Nextcloud Forms\" können Umfragen zu beliebigen Themen erstellt werden. In diesem Blogbeitrag wird erklärt, wie die App installiert, konfiguriert und verwendet werden kann.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Forms – Create Surveys Securely & Easily",
      formattedDate: "November 16, 2023",
      excerpt: "With the Nextcloud Forms app, surveys on any topic can be created. This blog post explains how to install, configure, and use the app.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Forms – Create Surveys Securely & Easily</h1>
          
          <p>With the Nextcloud Forms app, surveys on any topic can be created. This blog post explains how to install, configure, and use the app.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Forms – Create Surveys Securely & Easily",
      formattedDate: "16 November 2023",
      excerpt: "With the Nextcloud Forms app, surveys on any topic can be created. This blog post explains how to install, configure, and use the app.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;