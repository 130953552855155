// src/data/blogPosts/nextcloud-kalender-und-aufgaben-in-thunderbird-integrieren.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-kalender-und-aufgaben-in-thunderbird-integrieren-featured.png';
import thunderbirdNextcloudImage from '../../assets/images/blog/2024/10/Thunderbird-plu-Nextcloud.png';
import kalenderHinzufuegenImage from '../../assets/images/blog/2024/10/Kalender-hinzufuegen.png';
import meinKalenderImage from '../../assets/images/blog/2024/10/Mein-Kalender.png';
import primaereCalDAVAdresseImage from '../../assets/images/blog/2024/10/Primaere-CalDAV-Adresse.png';
import appPasswortImage from '../../assets/images/blog/2024/10/App-Passwort.png';
import neuenKalenderErstellenImage from '../../assets/images/blog/2024/10/Neuen-Kalender-erstellen.png';
import thumbUpIcon from '../../assets/images/blog/2023/03/iconmonstr-thumb-10-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about integrating Nextcloud Calendar and Tasks in Thunderbird
 */
const post = {
  id: '21816',
  slug: 'nextcloud-kalender-und-aufgaben-in-thunderbird-integrieren',
  
  // Base data - same regardless of language
  date: "2024-10-02T12:07:28.000Z",
  readTime: 9,
  categories: ["Anleitungen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 11,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Kalender und Aufgaben in Thunderbird integrieren",
      formattedDate: "2. Oktober 2024",
      excerpt: "Thunderbird ist nicht nur ein hervorragender E-Mail-Client, sondern auch eine tolle Lösung, um Kalender und Aufgaben zu verwalten und zu organisieren. In diesem Artikel zeigen wir dir wie du deinen Nextcloud Kalender und Aufgaben in Thunderbird integrieren kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Kalender und Aufgaben in Thunderbird integrieren</h1>
          
          <p>Thunderbird ist nicht nur ein hervorragender E-Mail-Client, sondern auch eine tolle Lösung, um Kalender und Aufgaben zu verwalten und zu organisieren.<br />In diesem Artikel zeigen wir dir wie du deinen Nextcloud Kalender und Aufgaben in Thunderbird integrieren kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={thunderbirdNextcloudImage} alt="Thunderbird und Nextcloud" size="large" />
            </div>
            <div className="w-full md:w-1/2 text-center">
              
            </div>
          </div>
          
          <BlogHeading>Nextcloud Kalender erstellen</BlogHeading>
          
          <p>Erstelle zunächst einen Kalender in deiner Nextcloud. Klicke dazu auf das Kalendersymbol in der oberen Menüleiste und anschließend auf das Plus-Symbol. Wähle „Neuen Kalender mit Aufgabenliste" aus um die Aufgaben Unterstützung im Kalender zu aktivieren. Gebe einen Namen ein und bestätige diesen durch Klick auf de Pfeil.</p>
          
          <div className="flex flex-col md:flex-row justify-between my-6 gap-4">
            <div className="w-full md:w-1/2">
              <BlogImage src={kalenderHinzufuegenImage} alt="Nextcloud Kalender hinzufügen" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={meinKalenderImage} alt="Nextcloud Mein Kalender" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <BlogHeading>Nextcloud Deck oder Tasks einrichten</BlogHeading>
          
          <p>Sowohl in Nextcloud Deck als auch in Nextcloud Task erstellte Aufgaben können mit Thunderbird synchronisiert werden. Für ein neues Board in Nextcloud Deck wird eine Art neuer Kalender erstellt. In der Task App können Aufgaben auch direkt in einen bestehenden Kalender eingetragen werden.<br />Für die Verwendung in Thunderbird ist jedoch die Verwendung der Nextcloud Task App besser geeignet, da die Aufgaben auch in Nextcloud bearbeitet werden können. Bei den Karten von Nextcloud Deck ist dies nicht möglich.</p>
          
          <BlogHeading>Kalender zu Thunderbird hinzufügen</BlogHeading>
          
          <p>In Thunderbird klicke auf das Kalendersymbol auf der linken Seite und dann auf „Neuer Kalender" in der linken unteren Ecke. Es öffnet sich ein neues Fenster, wähle „Im Netzwerk" und klicke auf „Weiter".</p>
          
          <p>Dann gib deinen Nextcloud Benutzernamen und die Serveradresse ein. Die richtige Serveradresse findest du im Nextcloud Kalender unter „Kalendereinstellungen". Kopiere hier die „Primäre CalDAV Adresse".<br />Klicke anschließend auf den Button „Kalender suchen".</p>
          
          <p>Im nächsten Schritt ist die Eingabe eines Passwortes erforderlich. Erstelle dazu in deiner Nextcloud unter Einstellungen – Sicherheit – Geräte & Sitzungen ein neues App-Passwort und kopiere es in die Zwischenablage.</p>
          
          <p>Nach erfolgreicher Anmeldung werden alle verfügbaren Kalender angezeigt. Wähle als Kalendertyp „CalDAV" aus. Über „Eigenschaften" kannst du z.B. den Namen und die Farbe des Kalenders ändern.<br />Klicke auf „Abonnieren", um den Kalender in Thunderbird zu integrieren.</p>
          
          <div className="flex flex-col md:flex-row flex-wrap justify-between my-6 gap-4">
            <div className="w-full md:w-1/2 lg:w-1/3">
              <BlogImage src={primaereCalDAVAdresseImage} alt="Primäre CalDAV Adresse" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3">
              <BlogImage src={appPasswortImage} alt="Nextcloud App Passwort" size="medium" className="mx-auto" />
            </div>
            <div className="w-full md:w-1/2 lg:w-1/3">
              <BlogImage src={neuenKalenderErstellenImage} alt="Neuen Kalender erstellen" size="medium" className="mx-auto" />
            </div>
          </div>
          
          <BlogHeading>Aufgaben direkt im Kalender anzeigen lassen</BlogHeading>
          
          <p>Standardmäßig werden Aufgaben nicht direkt im Kalender angezeigt, sondern nur in der rechten Sidebar. Um dies zu ändern, aktiviere zunächst die Menüleiste von Thunderbird, indem du mit der rechten Maustaste in den oberen Bereich klickst und dann den Haken bei „Menüleiste" setzt. In der Menüleiste klicke auf „Ansicht" – „Kalender" – „Aktuelle Ansicht" – „Aufgaben in Ansicht". Jetzt werden deine Aufgaben auch im Kalender angezeigt.</p>
          
          <BlogHeading>Vorteile</BlogHeading>
          
          <p>Durch die Integration des Nextcloud-Kalenders in Thunderbird kannst du auch lokal auf deinem Rechner Termine und Aufgaben anlegen, die mit deiner Nextcloud und den damit verbundenen Geräten synchronisiert werden. Besonders praktisch ist das direkte Bearbeiten von Terminen und Aufgaben in Thunderbird. So kannst du zum Beispiel den Status einer Aufgabe von „In Bearbeitung" auf „Erledigt" ändern, den prozentualen Fortschritt anpassen und auch einen neuen Tag zuweisen. Alle Änderungen werden direkt mit deiner Nextcloud synchronisiert und angepasst.</p>
          
          <BlogImage src={thumbUpIcon} alt="Daumen hoch" size="medium" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Kalender und Aufgaben in Thunderbird integrieren",
      formattedDate: "2. Oktober 2024",
      excerpt: "Thunderbird ist nicht nur ein hervorragender E-Mail-Client, sondern auch eine tolle Lösung, um Kalender und Aufgaben zu verwalten und zu organisieren. In diesem Artikel zeigen wir dir wie du deinen Nextcloud Kalender und Aufgaben in Thunderbird integrieren kannst.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Integrating Nextcloud Calendar and Tasks in Thunderbird",
      formattedDate: "October 2, 2024",
      excerpt: "Thunderbird is not only an excellent email client but also a great solution for managing and organizing calendars and tasks. In this article, we'll show you how to integrate your Nextcloud calendar and tasks in Thunderbird.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Integrating Nextcloud Calendar and Tasks in Thunderbird</h1>
          
          <p>Thunderbird is not only an excellent email client but also a great solution for managing and organizing calendars and tasks.<br />In this article, we'll show you how to integrate your Nextcloud calendar and tasks in Thunderbird.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Integrating Nextcloud Calendar and Tasks in Thunderbird",
      formattedDate: "2 October 2024",
      excerpt: "Thunderbird is not only an excellent email client but also a great solution for managing and organizing calendars and tasks. In this article, we'll show you how to integrate your Nextcloud calendar and tasks in Thunderbird.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;