// src/data/blogPosts/mastodon.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading 
} from '../../components/blog';

/**
 * Blog post about Mastodon social network
 */
const post = {
  id: '9553',
  slug: 'mastodon',
  
  // Base data - same regardless of language
  date: "2022-11-14T13:06:00.000Z",
  readTime: 11,
  categories: ["Open Source Software"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 36,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Mastodon",
      formattedDate: "14. November 2022",
      excerpt: "Im heutigen Blogartikel möchten wir euch das soziale Netzwerk Mastodon vorstellen. Wir erklären euch wie das Netzwerk aufgebaut ist, welche Vorteile es bringt und wie ihr daran teilnehmen könnt.",
      content: () => (
        <BlogContent>
          <p>Mastodon ist ein dezentrales soziales Netzwerk bei dem jede Instanz mit allen anderen kommunizieren kann.</p>
          
          <BlogHeading>Zentrale und dezentrale Netzwerke</BlogHeading>
          
          <p>Um das Prinzip von Mastodon zu verstehen, wollen wir kurz den Unterschied von einem zentralen zu einem dezentralen Netzwerk erklären. <br />Ganz einfach ausgedrückt: <br />In zentralen Netzwerken gibt es einen zentralen Punkt auf den alle zugreifen. Bei einem dezentralen Netzwerk hingegen gibt es viele Punkte die alle miteinander verbunden sind.</p>
          
          <p>Bei zentralen Netzwerken gibt außerdem immer eine Partei die über das Netzwerk entscheiden kann, das ist bei dezentralen Netzwerken nicht der Fall.</p>
          
          <div className="flex flex-wrap gap-8 my-6">
            <div className="w-full md:w-5/12">
              <h3 className="text-xl font-bold mb-4">Zentral</h3>
              {/* Central network diagram would go here */}
            </div>
            <div className="w-full md:w-5/12">
              <h3 className="text-xl font-bold mb-4">Dezentral</h3>
              {/* Decentralized network diagram would go here */}
            </div>
          </div>
          
          <BlogHeading>Was ist Mastodon?</BlogHeading>
          
          <p>Mastodon ist ein dezentrales soziales Netzwerk. Es gibt viele Server, sogenannte Instanzen die alle miteinander verbunden sind und untereinander kommunizieren können. Meldet man sich bei einer beliebigen Instanz an, ist es möglich mit allen anderen Teilnehmern des Netzwerks in Kontakt zu treten. Theoretisch kann jeder seine eigene Instanz betreiben und so dem Netzwerk beitreten. Es gibt aber natürlich auch die Möglichkeit sich bei einer bereits bestehenden Instanz anzumelden.</p>
          
          <BlogHeading>Parallelen zu Twitter</BlogHeading>
          
          <p>Tweets heißen Tröts oder Toots, diese können sowohl an einen offenen Personenkreis oder auch direkt an einen Kontakt geschickt werden. Die Beiträge von anderen können ebenfalls wieder gepostet werden, das nennt sich bei Mastodon aber nicht Retweet sonder Boosten.<br />Beiträge lassen sich favorisieren also liken. Neben normalen Textnachrichten können auch Videos, Bilder und Audio gepostet werden.</p>
          
          <BlogHeading>Vorteile von Mastodon</BlogHeading>
          
          <ul>
            <li>
              <p>Keine zentrale Partei die über das Netzwerk bestimmt. Jede Instanz kann ihre eigene Regeln definieren.</p>
            </li>
            <li>
              <p>Keine Werbung! Ihr bekommt keine nervige Werbung angezeigt die euch dazu bringen soll etwas zu kaufen.</p>
            </li>
            <li>
              <p>Kostenlos – aber wirklich. Bei den klassischen sozialen Netzwerken wie Twitter, Facebook, Instagram seid ihr das Produkt. <br />Auch wenn diese Dienste „kostenlos" zu nutzen sind, wird hier sehr viel Geld mit euren Daten verdient die an Werbefirmen verkauft werden.</p>
            </li>
            <li>
              <p>Kein Algorithmus der euch in eine bestimmte Bubble steckt. Algorithmen bei Twitter und Co. entscheiden welche Inhalte ihr sehen sollt und welche nicht, welche Beiträge ganz oben in eurem Newsfeed und welche lieber ganz unten. Jede Interaktion wird getrackt und ausgewertet. Bei Mastodon dürft ihr euch eure eigene Bubble erstellen, der Newsfeed wird zeitlich sortiert ohne Tracking und ohne Werbung.</p>
            </li>
          </ul>
          
          <BlogHeading>Teilnahme bei Mastodon</BlogHeading>
          
          <p>Wir haben eine PathConnect Mastodon Instanz erstellt an der ihr euch kostenlos anmelden könnt und somit bei Mastadon mit dabei seid.</p>
          
          <p>Einfach einen Benutzername, E-Mail Adresse und Passwort eingeben und schon kann es los gehen.</p>
          
          <BlogHeading>Apps fürs Smartphone</BlogHeading>
          
          <p>Natürlich kann man auch vom Smartphone aus auf Mastodon zugreifen:</p>
          
          <p>Android:<br />
          <a href="https://play.google.com/store/apps/details?id=org.joinmastodon.android&hl=de&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=org.joinmastodon.android&hl=de&gl=US</a></p>
          
          <p>iOS:<br />
          <a href="https://apps.apple.com/de/app/mastodon/id1571998974" target="_blank" rel="noopener">https://apps.apple.com/de/app/mastodon/id1571998974</a></p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Mastodon",
      formattedDate: "14. November 2022",
      excerpt: "Im heutigen Blogartikel möchten wir euch das soziale Netzwerk Mastodon vorstellen. Wir erklären euch wie das Netzwerk aufgebaut ist, welche Vorteile es bringt und wie ihr daran teilnehmen könnt.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;