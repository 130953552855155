import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

/**
 * GlassCard creates a glass-like card. All text is centered by default.
 *
 * @param {children} children to show inside the glass card
 * @param {color} optional background color, defaults to glass style
 * @param {className} optional additional classes to apply
 * @returns a card style object that looks like a glass panel
 */
const Container = forwardRef(
  (
    { children, className = 'flex flex-col justify-center items-center' },
    ref
  ) => {
    return (
      <div
        ref={ref}
        className={`px-10 my-5 sm:w-11/12 md:w-4/5 lg:w-7/10 mx-auto ${className}`}
      >
        {children}
      </div>
    )
  }
)

Container.displayName = 'Container'
Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
}

export default Container
