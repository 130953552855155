// src/data/blogPosts/mailcow-postfach-migrieren.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading
} from '../../components/blog';

/**
 * Blog post about migrating Mailcow mailboxes
 */
const post = {
  id: '21892',
  slug: 'mailcow-postfach-migrieren',
  
  // Base data - same regardless of language
  date: "2024-10-17T11:25:06.000Z",
  readTime: 13,
  categories: ["Anleitungen"],
  thumbnail: null,
  thumbnailUrl: null,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 31,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Mailcow Postfach migrieren",
      formattedDate: "17. Oktober 2024",
      excerpt: "Der Open Source Mailserver Mailcow bietet eine gute Möglichkeit, bestehende Postfächer von einem anderen Mailserver oder Provider zu migrieren. In diesem Artikel zeigen wir dir alle notwendigen Schritte für einen reibungslosen Ablauf.",
      content: () => (
        <BlogContent>
          <p>Der Open Source Mailserver Mailcow bietet eine gute Möglichkeit, bestehende Postfächer von einem anderen Mailserver oder Provider zu migrieren. In diesem Artikel zeigen wir dir alle notwendigen Schritte für einen reibungslosen Ablauf.</p>
          
          <BlogHeading>Mailcow</BlogHeading>
          
          <p>Mailcow ist eine auf Docker basierende Open Source Mailserver-Suite zur einfachen Verwaltung von E-Mail-Diensten. Mit einer übersichtlichen Weboberfläche bietet Mailcow umfangreiche Funktionen wie die Verwaltung von Domains, Postfächern, Spamfiltern und Kalenderdiensten. Durch die Verwendung moderner Technologien wie Dovecot, Postfix und SoGo ist Mailcow nicht nur flexibel, sondern auch sicher und performant.</p>
          
          <BlogHeading>Sync Job</BlogHeading>
          
          <p>In Mailcow gibt es sogenannte Sync Jobs, mit denen man seine bestehenden Postfächer in Mailcow migrieren kann. Ein solcher Sync Job kann sowohl vom Administrator als auch von einem normalen Benutzer erstellt werden.<br />Damit diese Option auch den Benutzern angezeigt wird, musst du sie als Administrator aktivieren.<br />Um den Sync Job für einzelne Postfächer sichtbar zu machen, navigiere im Mailcow Webinterface zu E-Mail – Konfiguration – Mailboxen.<br />Klicke auf "Bearbeiten" und dann im oberen Menü auf "ACL (Berechtigungen)".</p>
          
          <BlogHeading>Vorbereitung auf die Migration</BlogHeading>
          
          <p>Damit die Mailboxen auf den neuen Mailcow Server migriert werden können, müssen wir zuerst die Domain hinzufügen und die Mailboxen neu erstellen.</p>
          
          <p><strong>1. Domain hinzufügen</strong><br />Klicke unter „E-Mail – Konfiguration" auf „Domain hinzufügen" und gib den Namen deiner Domain ein. Scrolle dann nach unten und klicke auf „Domain hinzufügen und SOGo neu starten".</p>
          
          <p><strong>2. Postfach anlegen</strong><br />Klicke in der oberen Menüleiste auf „Postfächer" und füge ein neues Postfach hinzu, indem du auf den Button „Postfach hinzufügen" klickst.<br />Es wird empfohlen, den gleichen Mailboxnamen wie bei den alten Mailboxen zu verwenden. Der Mailbox Name ist der Teil der vor der Domain steht. mailboxname@domain.de</p>
          
          <p>Das Passwort kannst du entweder neu vergeben oder dein altes Passwort verwenden. Unter dem Punkt „ACL" kann direkt der Sync Job für diese Mailbox aktiviert werden.<br />Die restlichen Einstellungen kannst du so belassen. Klicke auf „Hinzufügen", um das Postfach anzulegen.</p>
          
          <BlogHeading>Mailcow Postfach als Administrator migrieren</BlogHeading>
          
          <p>Diese Option eignet sich, wenn du als Administrator Zugriff auf alle Postfächer deiner Organisation hast oder sowieso nur dein eigenes Postfach verschieben möchtest. <br />Navigiere dazu im Mailcow Webinterface zu „E-Mail – Konfiguration – Synchronisation".<br />Klicke auf „Neuen Sync Job erstellen" und gib die Daten deines alten Postfachs an.</p>
          
          <p>Unten siehst du den Umzug deines E-Mail Postfachs von Strato.</p>
          
          <p><span style={{textDecoration: "underline"}}>Benutzername:</span> <br />Wähle das soeben erstellte Postfach aus. In dieses Postfach werden die alten E-Mails synchronisiert.<br />
          <span style={{textDecoration: "underline"}}>Host:</span> <br />Der IMAP-Host deines alten E-Mail-Providers. Bei Strato ist das imap.strato.de.<br />
          <span style={{textDecoration: "underline"}}>Port:</span> <br />Der IMAP Port ist 993<br />Benutzername: Deine E-Mail Adresse<br />
          <span style={{textDecoration: "underline"}}>Passwort:</span> <br />Das Passwort deiner alten E-Mail Adresse</p>
          
          <p>Die restlichen Werte können unverändert bleiben. Klicke auf „Änderungen speichern", um die Synchronisation zu starten. Je nach Größe des Postfachs kann die Synchronisation einige Zeit dauern.</p>
          
          <BlogHeading>Mailcow Postfach als Nutzer migrieren</BlogHeading>
          
          <p>Um auch als Benutzer einen Sync Job starten zu können, muss der Administrator wie oben beschrieben die entsprechenden Berechtigungen vergeben.<br />Melde dich mit deiner E-Mail-Adresse und deinem Passwort im Mailcow Webinterface an und klicke in der oberen Menüleiste auf „Sync Jobs".<br />Danach kannst du den Synchronisationsjob erstellen und starten, wie im Administratorbereich beschrieben.</p>
          
          <BlogHeading>Mailcow Postfach auf den Endgeräten neu einrichten</BlogHeading>
          
          <p>Da sich durch die Migration die Adresse des E-Mail-Servers geändert hat, musst du deine Postfächer auf den Endgeräten wie Computer und Smartphone neu einrichten.<br />Um dein Postfach in Thunderbird neu einzurichten, klicke auf das Zahnradsymbol unten links und dann auf „Kontoeinstellungen". Wähle dein altes Postfach aus und klicke unter „Konto-Aktionen" auf „Konto entfernen".</p>
          
          <p>Danach kannst du wieder unter „Konto-Aktionen" auf „E-Mail-Konto hinzufügen" klicken. Gib hier deine E-Mail-Adresse und dein Passwort ein und schon ist dein neues Postfach eingerichtet.</p>
          
          <BlogHeading>DNS Einstellungen anpassen</BlogHeading>
          
          <p>Nachdem die Mailboxen migriert wurden, müssen noch die DNS-Einstellungen angepasst werden. Klicke dazu auf E-Mail – Domains – DNS. Hier werden alle DNS-Einträge angezeigt, die eingestellt werden müssen.</p>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Mailcow Postfach migrieren",
      formattedDate: "17. Oktober 2024",
      excerpt: "Der Open Source Mailserver Mailcow bietet eine gute Möglichkeit, bestehende Postfächer von einem anderen Mailserver oder Provider zu migrieren. In diesem Artikel zeigen wir dir alle notwendigen Schritte für einen reibungslosen Ablauf.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;