// src/data/blogPosts/user-story-handwerkersbetrieb.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/user-story-handwerkersbetrieb-featured.png';
import taskListIcon from '../../assets/images/blog/2023/04/iconmonstr-task-list-lined-240.png';
import calendarIcon from '../../assets/images/blog/2022/03/calendar.png';
import syncIcon from '../../assets/images/blog/2022/03/sync.png';
import smartphoneIcon from '../../assets/images/blog/2022/01/smartphone.png';
import monitoringIcon from '../../assets/images/blog/2021/11/iconmonstr-monitoring-6-240.png';

/**
 * Blog post about a craftsman's business user story
 */
const post = {
  id: '11490',
  slug: 'user-story-handwerkersbetrieb',
  
  // Base data - same regardless of language
  date: "2023-04-01T09:24:48.000Z",
  readTime: 6,
  categories: ["User Story"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 24,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "User Story: Handwerksbetrieb",
      formattedDate: "1. April 2023",
      excerpt: "Im Format \"User Story\" stellt dir PathConnect beispielhaft vor, wie unterschiedliche Menschen Nextcloud in ihr Leben integrieren. Heute geht in um die Integration in einen Handwerksbetrieb.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">User Story: Handwerksbetrieb</h1>
          
          <p>Im Format \"<strong>User Story</strong>\" stellt dir PathConnect <strong>beispielhaft</strong> vor, wie unterschiedliche Menschen Nextcloud in ihr Leben integrieren. Heute geht in um die Integration in einen Handwerksbetrieb</p>
          
          <BlogHeading>Person</BlogHeading>
          
          <p className="western">Jürgen ist 52 Jahre alt und führt seit vielen Jahren einen <strong>Handwerksbetrieb</strong>. Mittlerweile ist das Team auf 8 Mitarbeiter angewachsen. Um die Abläufe im Betrieb zu verbessern, sucht er nach einem Werkzeug, mit dem er seine <strong>Prozesse optimieren</strong> kann.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={taskListIcon} alt="Task List Icon" size="small" />
          </div>
          
          <BlogHeading>Anwendungsfall</BlogHeading>
          
          <p>Von anderen Handwerkersbetrieben hat er gehört, dass Nextcloud eingesetzt wird. Im Internet findet er einen Anbieter, der ihn bei der Einrichtung unterstützt. Als erstes werden die <strong>Accounts für alle Mitarbeiter</strong> eingerichtet. Zur besseren Koordination wird ein <strong>Nextcloud-Kalender</strong> für den Betrieb angelegt und mit dem Smartphone synchronisiert. Hier können dann Termine wie Baustellen oder Abnahmen eingetragen und für einzelne Mitarbeiter freigegeben werden. Um keine Termine mehr zu verpassen, können <strong>Benachrichtigungen</strong> aktiviert werden.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={calendarIcon} alt="Kalender Icon" size="small" />
          </div>
          
          <p>Die Nextcloud App wird auf den vorhandenen Tablets installiert, um die Notizen und Bilder von den Baustellenbesuchen <strong>automatisch hochzuladen</strong> und anschließend mit dem Computer im Büro zu <strong>synchronisieren</strong>.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={syncIcon} alt="Synchronisieren Icon" size="small" />
          </div>
          
          <p className="western">Für die <strong>Erfassung der Arbeitszeiten</strong> wird für jeden Mitarbeiter ein Tabellen-Dokument erstellt. In dieses Dokument können die Mitarbeiter dann ihre Arbeitszeiten eingetragen. Das spart nicht nur Papier sondern auch viel Zeit beim Übermitteln an das Personalbüro.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={smartphoneIcon} alt="Smartphone Icon" size="small" />
          </div>
          
          <p className="western">Die <strong>E-Mail-Anwendung</strong> in Nextcloud wird ebenfalls aktiviert und das E-Mail-Konto eingerichtet. Dank der Suchfunktion in Nextcloud lassen sich <strong>Dokumente, Kalendereinträge und E-Mails einfach wiederfinden</strong>.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={monitoringIcon} alt="Monitoring Icon" size="small" />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "User Story: Handwerksbetrieb",
      formattedDate: "1. April 2023",
      excerpt: "Im Format \"User Story\" stellt dir PathConnect beispielhaft vor, wie unterschiedliche Menschen Nextcloud in ihr Leben integrieren. Heute geht in um die Integration in einen Handwerksbetrieb.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;