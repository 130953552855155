// src/data/blogPosts/nextcloud-notes.js

// Import images
import thumbnailImage from '../../assets/images/blog/nextcloud-notes-featured.png';
import noteIcon from '../../assets/images/blog/2023/03/iconmonstr-note-23-240.png';
import appInstallImage from '../../assets/images/blog/2023/03/App-installieren.png';
import appFirstOpenImage from '../../assets/images/blog/2023/03/App-first-open.png';
import categoriesImage from '../../assets/images/blog/2023/03/Kategorien.png';
import smartphoneScreen1 from '../../assets/images/blog/2023/03/Screenshot_20230314_152056_Nextcloud-Notes.jpg';
import smartphoneScreen2 from '../../assets/images/blog/2023/03/Screenshot_20230314_152104_Android-System.jpg';
import smartphoneScreen3 from '../../assets/images/blog/2023/03/Screenshot_20230314_152504_Nextcloud-Notes.jpg';
import shareIcon from '../../assets/images/blog/2022/12/iconmonstr-share-2-240.png';
import thumbUpIcon from '../../assets/images/blog/2023/03/iconmonstr-thumb-10-240.png';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Nextcloud Notes
 */
const post = {
  id: '11440',
  slug: 'nextcloud-notes',
  
  // Base data - same regardless of language
  date: "2023-03-19T18:22:43.000Z",
  readTime: 12,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 7,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Nextcloud Notes",
      formattedDate: "19. März 2023",
      excerpt: "Mit Nextcloud Notes hast du deine Notizen immer zur Hand, egal ob auf dem Smartphone oder über das Nextcloud-Webinterface. In diesem Blogbeitrag zeigen wir dir, wie du die App einrichten und nutzen kannst.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Notes</h1>
          
          <p>Mit Nextcloud Notes hast du deine Notizen immer zur Hand, egal ob auf dem Smartphone oder über die Nextcloud-Weboberfläche. In diesem Blogbeitrag zeigen wir dir, wie du die App einrichten und nutzen kannst.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={noteIcon} alt="Notiz Icon" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Installation</BlogHeading>
          
          <p>Du kannst die App ganz einfach über das Webinterface von Nextcloud installieren. Melde dich dazu mit einem Administrator-Account an, klicke auf den Button oben rechts und wähle "+ Apps".<br />Es öffnet sich ein neues Fenster mit einer Übersicht der Apps. Klicke hier auf "Büro und Text" und wähle die App "Notes" aus. Klicke auf "Herunterladen und aktivieren".</p>
          
          <BlogImage src={appInstallImage} alt="App Installation" size="medium" className="mx-auto my-6" />
          
          <p>Damit man auch unterwegs auf seine Notizen zugreifen und sich Notizen machen kann, gibt es die entsprechende App für Smartphones.</p>
          
          <p>
            Android:<br />
            <a href="https://play.google.com/store/apps/details?id=it.niedermann.owncloud.notes&hl=de&gl=US" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://play.google.com/store/apps/details?id=it.niedermann.owncloud.notes&hl=de&gl=US
            </a>
          </p>
          
          <p>
            <a href="https://f-droid.org/de/packages/it.niedermann.owncloud.notes/" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://f-droid.org/de/packages/it.niedermann.owncloud.notes/
            </a>
          </p>
          
          <p>
            iOS:<br />
            <a href="https://apps.apple.com/us/app/nextcloud-notes/id813973264" target="_blank" rel="noopener" className="text-primary hover:underline">
              https://apps.apple.com/us/app/nextcloud-notes/id813973264
            </a>
          </p>
          
          <BlogHeading level={3}>Einrichtung in der Weboberfläche</BlogHeading>
          
          <p>Nach der Installation der App erscheint in der oberen Menüleiste ein neues Symbol mit einem Stift. Wenn du die App zum ersten Mal öffnest, erscheinen einige Erklärungen zu den einzelnen Funktionen.</p>
          
          <BlogImage src={appFirstOpenImage} alt="Erstmalige Nutzung der App" size="large" className="my-6" />
          
          <p>Klicke auf "Neue Notiz", um deine erste Notiz zu erstellen. Das Wort, das du in die erste Zeile schreibst, wird als Name für die Notiz verwendet. Wenn du auf das Bild-Symbol klickst, kannst du Bilder zu deinen Notizen hinzufügen. Wenn du viele Notizen hast und den Überblick nicht verlieren willst, kannst du sie in Kategorien einteilen. Klicke dazu in der Notiz auf die drei Punkte neben dem Bildsymbol – Details – und gib der Kategorie einen Namen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={categoriesImage} alt="Kategorien" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={categoriesImage} alt="Kategorien" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Einrichtung am Smartphone</BlogHeading>
          
          <p>Wenn du bereits in der Nextcloud App auf deinem Smartphone eingeloggt bist, kannst du nach dem Öffnen der Notes App einfach auf "Anmelden" tippen und dein bestehendes Konto auswählen.<br />Nach dem Login hast du deine Notizen auch auf deinem Smartphone.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/3">
              <BlogImage src={smartphoneScreen1} alt="Smartphone Anmeldung" size="medium" />
            </div>
            <div className="w-full md:w-1/3">
              <BlogImage src={smartphoneScreen2} alt="Kontoauswahl" size="medium" />
            </div>
            <div className="w-full md:w-1/3">
              <BlogImage src={smartphoneScreen3} alt="Notizen am Smartphone" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Teilen</BlogHeading>
          
          <p>Du kannst deine Notizen auch mit anderen teilen. Klicke dazu auf die drei Punkte neben einer Notiz und dann auf "Teilen". Auf der rechten Seite öffnet sich die gewohnte "Teilen"-Funktion.</p>
          
          <p>Auf dem Smartphone öffnest du einfach eine Notiz, tippst auf die drei Punkte oben rechts und wählst "Teilen".</p>
          
          <BlogImage src={shareIcon} alt="Teilen Icon" size="small" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Vorteile</BlogHeading>
          
          <p>Mit Nextcloud Notes hast du deine Notizen auf allen Geräten immer dabei. Du kannst zum Beispiel eine Checkliste für deinen nächsten Ausflug erstellen und diese mit deinen Freunden teilen. Die Synchronisation erfolgt über deine eigene Cloud und ist sicher und privat.</p>
          
          <BlogImage src={thumbUpIcon} alt="Daumen hoch" size="small" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Nextcloud Notes",
      formattedDate: "19. März 2023",
      excerpt: "Mit Nextcloud Notes hast du deine Notizen immer zur Hand, egal ob auf dem Smartphone oder über das Nextcloud-Webinterface. In diesem Blogbeitrag zeigen wir dir, wie du die App einrichten und nutzen kannst.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "Nextcloud Notes",
      formattedDate: "March 19, 2023",
      excerpt: "With Nextcloud Notes, you always have your notes at hand, whether on your smartphone or through the Nextcloud web interface. In this blog post, we show you how to set up and use the app.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Notes</h1>
          
          <p>With Nextcloud Notes, you always have your notes at hand, whether on your smartphone or through the Nextcloud web interface. In this blog post, we show you how to set up and use the app.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "Nextcloud Notes",
      formattedDate: "19 March 2023",
      excerpt: "With Nextcloud Notes, you always have your notes at hand, whether on your smartphone or through the Nextcloud web interface. In this blog post, we show you how to set up and use the app.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;