// src/data/blogPosts/organic-maps.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about Organic Maps
 */
const post = {
  id: '19647',
  slug: 'organic-maps',
  
  // Base data - same regardless of language
  date: "2024-04-09T07:51:48.000Z",
  readTime: 12,
  categories: ["Empfehlungen", "Open Source Software"],
  thumbnail: "../images/blog/organic-maps-featured.png",
  thumbnailUrl: "../images/blog/organic-maps-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 31,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Organic Maps",
      formattedDate: "9. April 2024",
      excerpt: "Die Open Source Navigation App zeigt dir auch offline den richtigen Weg für deine nächste Wanderung, Radtour oder Autofahrt. In diesem Artikel zeigen wir dir die Grundkonfiguration und du erfährst, was Organic Maps so besonders macht.",
      content: () => (
        <BlogContent>
          <p>Die Open Source Navigation App zeigt dir auch offline den richtigen Weg für deine nächste Wanderung, Radtour oder Autofahrt. In diesem Artikel zeigen wir dir die Grundkonfiguration und du erfährst, was Organic Maps so besonders macht.</p>
          
          <div className="flex justify-center items-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 192 192" fill="none" className="mx-auto">
              <path d="M142.07 46.129c5.265-3.387 20.107-6.337 24.539 2.417 4.433 8.268-5.427 14.978-15.24 21.198-8.221 5.557-16.062 8.81-23.778 13.532-7.046-7.025-18.528-5.021-25.629.828-8.983 6.65-15.848 15.812-25.36 21.809 12.522 10.193 32.174 12.691 45.335 2.244 7.46-5.283 12.071-16.873 5.724-24.725m-81.55 27.92c-5.265 3.387-17.535 7.623-21.967-.81-3.79-8.91 4.462-14.334 13.632-20.876 8.221-5.558 16.062-8.81 23.778-13.532 7.045 7.024 18.528 5.02 25.629-.829 8.982-6.65 15.848-15.812 25.36-21.809-12.522-10.193-32.174-12.69-45.335-2.243-7.46 5.283-12.071 16.873-5.724 24.725M94.218 22.01c-25.433-.535-49.944 18.01-55.391 43.022-3.294 13.583-1.059 28.205 6.059 40.23C57.005 129.747 74.968 150.826 94.219 170c20.368-18.544 39.793-39.311 51.27-64.662 3.97-8.554 6.45-18.898 6.32-27.734.01-6.628-1.505-15.028-4.575-21.661-8.904-20.6-30.684-34.165-53.015-33.931z" stroke="#000" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Open Street Map</BlogHeading>
          
          <p>Organic Maps verwendet die freie Weltkarte „Open Street Map" zur Darstellung der Navigation. Dies ist ein internationales Projekt, bei dem jeder Daten zu Straßen, Wegen, Eisenbahnen, Flüssen, Wäldern, Häusern und allem, was auf einer Karte dargestellt werden kann, beisteuern kann.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/04/Openstreetmap_logo.svg" 
              alt="OpenStreetMap Logo" 
              size="small"
            />
          </div>
          
          <BlogHeading>Besonderheiten</BlogHeading>
          
          <ul className="list-disc pl-6 space-y-2 my-4">
            <li>
              <p>Detaillierte Offline-Karten mit Orten, die es auf anderen Karten nicht gibt, dank <a href="https://www.openstreetmap.org/about" target="_blank" rel="noopener">OpenStreetMap</a></p>
            </li>
            <li>
              <p>Rad-, Wander- und Spazierwege</p>
            </li>
            <li>
              <p>Höhenlinien, Höhenprofile, Bergspitzen und Steigungen</p>
            </li>
            <li>
              <p>Schritt-für-Schritt-Navigation für Fußgänger, Radfahrer und Autofahrer mit Sprachführung, Android Auto</p>
            </li>
            <li>
              <p>Schnelle Offline-Suche von Orten auf der Karte</p>
            </li>
            <li>
              <p>Export und Import von Lesezeichen im KML/KMZ-Format, Import GPX</p>
            </li>
            <li>
              <p>Dunkler Modus zum Schutz deiner Augen</p>
            </li>
            <li>
              <p>Länder und Regionen nehmen nicht viel Speicherplatz in Anspruch</p>
            </li>
            <li>
              <p>Kostenlos und Open-Source</p>
            </li>
            <li>
              <p>Respektiert deine Privatsphäre</p>
            </li>
            <li>
              <p>Akkuschonend</p>
            </li>
          </ul>
          
          <BlogHeading>Organic Maps installieren</BlogHeading>
          
          <p>Die App ist sowohl für Android als auch für iOS verfügbar und kann kostenlos heruntergeladen werden.</p>
          
          <ul className="list-disc pl-6 space-y-2 my-4">
            <li>
              <a href="https://apps.apple.com/app/organic-maps/id1567437057" target="_blank" rel="noopener">App Store</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=app.organicmaps&hl=de" target="_blank" rel="noopener">Play Store</a>
            </li>
            <li>
              <a href="https://f-droid.org/de/packages/app.organicmaps" target="_blank" rel="noopener">F-Droid</a>
            </li>
          </ul>
          
          <BlogHeading>Grundkonfiguration</BlogHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            <div>
              <h3 className="text-lg font-semibold underline mb-2">Karten heruntergeladen</h3>
              <p>Um die Offline-Karten nutzen zu können, müssen diese zunächst heruntergeladen werden. Tippen Sie dazu auf die drei Querstriche und dann auf „Karten herunterladen".</p>
            </div>
            
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/04/Einstellungen-Teil1.jpg" 
                alt="Organic Map Einstellungen Teil 1" 
                size="medium"
              />
            </div>
          </div>
          
          <div className="flex justify-center my-4">
            <BlogImage 
              src="../images/blog/2024/04/Karten-herunterladen.jpg" 
              alt="Organic Map Karten herunterladen" 
              size="medium"
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            <div>
              <h3 className="text-lg font-semibold underline mb-2">Kartenstile und Ebene</h3>
              <p>Je nachdem, welche Tour du gerade fährst, kannst du durch Tippen auf das Symbol in der linken oberen Ecke zusätzliche Ebenen für Wandern, Gelände und U-Bahn aktivieren. Dadurch werden zusätzliche Informationen wie Aussichtspunkte und Höhenmeter angezeigt.</p>
            </div>
            
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/04/Kartenstil-und-Ebene-1.jpg" 
                alt="Organic Map Kartenstil und Ebene" 
                size="medium"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            <div>
              <h3 className="text-lg font-semibold underline mb-2">Einstellungen</h3>
              <p>Es gibt einige Einstellungen, die du in der App vornehmen kannst, um die Bedienung und das Aussehen an deine Bedürfnisse anzupassen. Diese Einstellungen haben sich in unseren Tests am besten bewährt:</p>
            </div>
            
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/04/Einstellungen-Teil3.jpg" 
                alt="Organic Map Einstellungen Teil 3" 
                size="medium"
              />
            </div>
          </div>
          
          <div className="flex justify-center my-4">
            <BlogImage 
              src="../images/blog/2024/04/Einstellungen-Teil4.jpg" 
              alt="Organic Map Einstellungen Teil 4" 
              size="medium"
            />
          </div>
          
          <BlogHeading>Spenden</BlogHeading>
          
          <p>Organic Maps steht allen kostenlos zur Verfügung. Wenn dir die App gefällt, kannst du einen beliebigen Betrag spenden.</p>
          <p><a href="https://organicmaps.app/de/donate/" target="_blank" rel="noopener">https://organicmaps.app/de/donate/</a></p>
          
          <p>Mit deiner Spende finanzierst du die Server, von denen die Karten heruntergeladen werden können, und du hilfst mit, den Benutzersupport zu finanzieren, Fehler zu beheben und die Stabilität der App zu verbessern.</p>
          
          <BlogHeading>Zusammenfassung</BlogHeading>
          
          <p>Mit Organic Maps bekommst du eine tolle Open Source Navigation App, die dir mit den Karten von Open Street Map viele Informationen zu deiner Route liefert. Die App ist Open Source, respektiert deine Privatsphäre, kann offline genutzt werden und verbraucht wenig Akku.</p>
          
          <div className="flex justify-center items-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 192 192" fill="none" className="mx-auto">
              <path d="M142.07 46.129c5.265-3.387 20.107-6.337 24.539 2.417 4.433 8.268-5.427 14.978-15.24 21.198-8.221 5.557-16.062 8.81-23.778 13.532-7.046-7.025-18.528-5.021-25.629.828-8.983 6.65-15.848 15.812-25.36 21.809 12.522 10.193 32.174 12.691 45.335 2.244 7.46-5.283 12.071-16.873 5.724-24.725m-81.55 27.92c-5.265 3.387-17.535 7.623-21.967-.81-3.79-8.91 4.462-14.334 13.632-20.876 8.221-5.558 16.062-8.81 23.778-13.532 7.045 7.024 18.528 5.02 25.629-.829 8.982-6.65 15.848-15.812 25.36-21.809-12.522-10.193-32.174-12.69-45.335-2.243-7.46 5.283-12.071 16.873-5.724 24.725M94.218 22.01c-25.433-.535-49.944 18.01-55.391 43.022-3.294 13.583-1.059 28.205 6.059 40.23C57.005 129.747 74.968 150.826 94.219 170c20.368-18.544 39.793-39.311 51.27-64.662 3.97-8.554 6.45-18.898 6.32-27.734.01-6.628-1.505-15.028-4.575-21.661-8.904-20.6-30.684-34.165-53.015-33.931z" stroke="#000" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Organic Maps",
      formattedDate: "9. April 2024",
      excerpt: "Die Open Source Navigation App zeigt dir auch offline den richtigen Weg für deine nächste Wanderung, Radtour oder Autofahrt. In diesem Artikel zeigen wir dir die Grundkonfiguration und du erfährst, was Organic Maps so besonders macht.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Organic Maps",
      formattedDate: "April 9, 2024",
      excerpt: "This open source navigation app shows you the right way for your next hike, bike tour, or car ride, even offline. In this article, we'll show you the basic configuration and tell you what makes Organic Maps so special.",
      content: () => (
        <BlogContent>
          <p>This open source navigation app shows you the right way for your next hike, bike tour, or car ride, even offline. In this article, we'll show you the basic configuration and tell you what makes Organic Maps so special.</p>
          
          <div className="flex justify-center items-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 192 192" fill="none" className="mx-auto">
              <path d="M142.07 46.129c5.265-3.387 20.107-6.337 24.539 2.417 4.433 8.268-5.427 14.978-15.24 21.198-8.221 5.557-16.062 8.81-23.778 13.532-7.046-7.025-18.528-5.021-25.629.828-8.983 6.65-15.848 15.812-25.36 21.809 12.522 10.193 32.174 12.691 45.335 2.244 7.46-5.283 12.071-16.873 5.724-24.725m-81.55 27.92c-5.265 3.387-17.535 7.623-21.967-.81-3.79-8.91 4.462-14.334 13.632-20.876 8.221-5.558 16.062-8.81 23.778-13.532 7.045 7.024 18.528 5.02 25.629-.829 8.982-6.65 15.848-15.812 25.36-21.809-12.522-10.193-32.174-12.69-45.335-2.243-7.46 5.283-12.071 16.873-5.724 24.725M94.218 22.01c-25.433-.535-49.944 18.01-55.391 43.022-3.294 13.583-1.059 28.205 6.059 40.23C57.005 129.747 74.968 150.826 94.219 170c20.368-18.544 39.793-39.311 51.27-64.662 3.97-8.554 6.45-18.898 6.32-27.734.01-6.628-1.505-15.028-4.575-21.661-8.904-20.6-30.684-34.165-53.015-33.931z" stroke="#000" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>OpenStreetMap</BlogHeading>
          
          <p>Organic Maps uses the free world map "OpenStreetMap" for navigation display. This is an international project where anyone can contribute data on roads, paths, railways, rivers, forests, houses, and anything else that can be represented on a map.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/04/Openstreetmap_logo.svg" 
              alt="OpenStreetMap Logo" 
              size="small"
            />
          </div>
          
          <BlogHeading>Special Features</BlogHeading>
          
          <ul className="list-disc pl-6 space-y-2 my-4">
            <li>
              <p>Detailed offline maps with places that don't exist on other maps, thanks to <a href="https://www.openstreetmap.org/about" target="_blank" rel="noopener">OpenStreetMap</a></p>
            </li>
            <li>
              <p>Cycling, hiking, and walking paths</p>
            </li>
            <li>
              <p>Contour lines, elevation profiles, mountain peaks, and slopes</p>
            </li>
            <li>
              <p>Turn-by-turn navigation for pedestrians, cyclists, and drivers with voice guidance, Android Auto</p>
            </li>
            <li>
              <p>Fast offline search for places on the map</p>
            </li>
            <li>
              <p>Export and import of bookmarks in KML/KMZ format, import GPX</p>
            </li>
            <li>
              <p>Dark mode to protect your eyes</p>
            </li>
            <li>
              <p>Countries and regions don't take up much storage space</p>
            </li>
            <li>
              <p>Free and open source</p>
            </li>
            <li>
              <p>Respects your privacy</p>
            </li>
            <li>
              <p>Battery-friendly</p>
            </li>
          </ul>
          
          <BlogHeading>Installing Organic Maps</BlogHeading>
          
          <p>The app is available for both Android and iOS and can be downloaded for free.</p>
          
          <ul className="list-disc pl-6 space-y-2 my-4">
            <li>
              <a href="https://apps.apple.com/app/organic-maps/id1567437057" target="_blank" rel="noopener">App Store</a>
            </li>
            <li>
              <a href="https://play.google.com/store/apps/details?id=app.organicmaps&hl=de" target="_blank" rel="noopener">Play Store</a>
            </li>
            <li>
              <a href="https://f-droid.org/de/packages/app.organicmaps" target="_blank" rel="noopener">F-Droid</a>
            </li>
          </ul>
          
          <BlogHeading>Basic Configuration</BlogHeading>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            <div>
              <h3 className="text-lg font-semibold underline mb-2">Downloading Maps</h3>
              <p>To use the offline maps, you first need to download them. Tap on the three horizontal lines and then on "Download Maps".</p>
            </div>
            
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/04/Einstellungen-Teil1.jpg" 
                alt="Organic Map Settings Part 1" 
                size="medium"
              />
            </div>
          </div>
          
          <div className="flex justify-center my-4">
            <BlogImage 
              src="../images/blog/2024/04/Karten-herunterladen.jpg" 
              alt="Organic Map Download Maps" 
              size="medium"
            />
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            <div>
              <h3 className="text-lg font-semibold underline mb-2">Map Styles and Layers</h3>
              <p>Depending on the tour you're currently on, you can activate additional layers for hiking, terrain, and subway by tapping the icon in the upper left corner. This will display additional information such as viewpoints and elevation.</p>
            </div>
            
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/04/Kartenstil-und-Ebene-1.jpg" 
                alt="Organic Map Style and Layer" 
                size="medium"
              />
            </div>
          </div>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-8">
            <div>
              <h3 className="text-lg font-semibold underline mb-2">Settings</h3>
              <p>There are several settings you can adjust in the app to customize the operation and appearance to your needs. These settings have proven to be the best in our tests:</p>
            </div>
            
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/04/Einstellungen-Teil3.jpg" 
                alt="Organic Map Settings Part 3" 
                size="medium"
              />
            </div>
          </div>
          
          <div className="flex justify-center my-4">
            <BlogImage 
              src="../images/blog/2024/04/Einstellungen-Teil4.jpg" 
              alt="Organic Map Settings Part 4" 
              size="medium"
            />
          </div>
          
          <BlogHeading>Donations</BlogHeading>
          
          <p>Organic Maps is available to everyone for free. If you like the app, you can donate any amount.</p>
          <p><a href="https://organicmaps.app/de/donate/" target="_blank" rel="noopener">https://organicmaps.app/de/donate/</a></p>
          
          <p>With your donation, you help finance the servers from which the maps can be downloaded, and you help fund user support, fix bugs, and improve the stability of the app.</p>
          
          <BlogHeading>Summary</BlogHeading>
          
          <p>With Organic Maps, you get a great open source navigation app that provides you with a lot of information about your route using OpenStreetMap maps. The app is open source, respects your privacy, can be used offline, and uses little battery.</p>
          
          <div className="flex justify-center items-center my-6">
            <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 192 192" fill="none" className="mx-auto">
              <path d="M142.07 46.129c5.265-3.387 20.107-6.337 24.539 2.417 4.433 8.268-5.427 14.978-15.24 21.198-8.221 5.557-16.062 8.81-23.778 13.532-7.046-7.025-18.528-5.021-25.629.828-8.983 6.65-15.848 15.812-25.36 21.809 12.522 10.193 32.174 12.691 45.335 2.244 7.46-5.283 12.071-16.873 5.724-24.725m-81.55 27.92c-5.265 3.387-17.535 7.623-21.967-.81-3.79-8.91 4.462-14.334 13.632-20.876 8.221-5.558 16.062-8.81 23.778-13.532 7.045 7.024 18.528 5.02 25.629-.829 8.982-6.65 15.848-15.812 25.36-21.809-12.522-10.193-32.174-12.69-45.335-2.243-7.46 5.283-12.071 16.873-5.724 24.725M94.218 22.01c-25.433-.535-49.944 18.01-55.391 43.022-3.294 13.583-1.059 28.205 6.059 40.23C57.005 129.747 74.968 150.826 94.219 170c20.368-18.544 39.793-39.311 51.27-64.662 3.97-8.554 6.45-18.898 6.32-27.734.01-6.628-1.505-15.028-4.575-21.661-8.904-20.6-30.684-34.165-53.015-33.931z" stroke="#000" strokeWidth="12" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
          </div>
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offering includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-round service that includes installation, customization, and updates, allowing you to focus fully on your projects.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Organic Maps",
      formattedDate: "9 April 2024",
      excerpt: "This open source navigation app shows you the right way for your next hike, bike tour, or car ride, even offline. In this article, we'll show you the basic configuration and tell you what makes Organic Maps so special.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;