import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloud,
  faFileAlt,
  faVideo,
  faGlobe,
  faNetworkWired,
  faClock,
  faFileInvoice,
  faEnvelope,
  faKey,
  faUsers,
  faBook,
  faProjectDiagram,
  faMailBulk
} from '@fortawesome/free-solid-svg-icons';
import Container from '../components/container';

const SoftwareSection = () => {
  const { t } = useTranslation('home');
  const [activeCategory, setActiveCategory] = useState('all');
  
  // Software category filters - more specific and meaningful categories
  const categories = [
    { id: 'all', name: 'Alle' },
    { id: 'productivity', name: 'Produktivität & Zusammenarbeit' },
    { id: 'document', name: 'Dokumentenmanagement' },
    { id: 'infrastructure', name: 'Infrastruktur & Systeme' },
    { id: 'identity', name: 'Identität & Zugriff' }
  ];
  
  // Get software list from translation
  const softwareList = t('c5.list', { returnObjects: true }) || [];
  
  // Map icons by index (position in the list) rather than by title
  // This way, changing the titles in translation won't break the icons
  const icons = [
    faCloud,        // Nextcloud (0)
    faFileAlt,      // Collabora Online (1)
    faVideo,        // Nextcloud Talk (2)
    faGlobe,        // WordPress (3)
    faNetworkWired, // Netbox (4)
    faClock,        // Kimai (5)
    faFileInvoice,  // Paperless-ngx (6)
    faMailBulk,     // Mailcow (7)
    faKey,          // Keycloak (8)
    faUsers,        // openLDAP (9)
    faBook,         // XWIKI (10)
    faProjectDiagram // OpenProject (11)
  ];
  
  // Define categories by index instead of by title
  // This way, if titles change in the translation, categorization still works
  const softwareCategoriesByIndex = [
    'productivity',  // Nextcloud (0)
    'productivity',  // Collabora Online (1)
    'productivity',  // Nextcloud Talk (2)
    'infrastructure', // WordPress (3)
    'infrastructure', // Netbox (4)
    'productivity',  // Kimai (5)
    'document',      // Paperless-ngx (6)
    'infrastructure', // Mailcow (7)
    'identity',      // Keycloak (8)
    'identity',      // openLDAP (9)
    'document',      // XWIKI (10)
    'productivity'   // OpenProject (11)
  ];
  
  // Filter software by category
  const filteredSoftware = activeCategory === 'all' 
    ? softwareList 
    : softwareList.filter((_, index) => softwareCategoriesByIndex[index] === activeCategory);
  
  return (
    <Container>
      <div className="w-full">
        <h2 className="text-3xl font-bold text-center text-cyan-400 mb-2">{t('c5.title')}</h2>
        <p className="text-center max-w-2xl mx-auto mb-8">
          Wir setzen auf bewährte Open-Source-Lösungen, die wir für Sie optimal konfigurieren und betreiben.
        </p>
        
        {/* Category filters */}
        <div className="flex flex-wrap justify-center mb-8 gap-2">
          {categories.map(category => (
            <button
              key={category.id}
              className={`px-4 py-2 rounded-full text-sm font-medium transition-all duration-300
                ${activeCategory === category.id 
                  ? 'bg-cyan-500 text-white shadow-md' 
                  : 'bg-gray-200 text-gray-700 dark:bg-gray-700 dark:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600'}`}
              onClick={() => setActiveCategory(category.id)}
            >
              {category.name}
            </button>
          ))}
        </div>
        
        {/* Software grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5">
          {filteredSoftware.map((software, index) => (
            <div 
              key={index}
              className="group relative overflow-hidden bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg border border-gray-700 shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1"
            >
              {/* Software icon - large, partially transparent background accent */}
              <div className="absolute right-0 bottom-0 opacity-10 text-cyan-400 transform translate-x-4 translate-y-4">
                <FontAwesomeIcon 
                  icon={icons[index] || faCloud} 
                  className="text-7xl transition-transform duration-300 group-hover:scale-110" 
                />
              </div>
              
              <div className="p-5 h-full flex flex-col">
                {/* Title with icon */}
                <div className="flex items-center mb-2">
                  <div className="w-10 h-10 rounded-full bg-cyan-500 bg-opacity-20 flex items-center justify-center mr-3">
                    <FontAwesomeIcon 
                      icon={icons[index] || faCloud} 
                      className="text-cyan-400 text-lg" 
                    />
                  </div>
                  <h3 className="text-xl font-bold text-cyan-400">{software.title}</h3>
                </div>
                
                {/* Subtitle - properly using the one from the translation */}
                <div className="text-sm font-medium text-gray-400 mb-3">{software.subtitle}</div>
                
                {/* Description */}
                <p className="text-sm text-gray-300">{software.description}</p>
                
                {/* Animated border on hover */}
                <div className="absolute inset-0 border-2 border-transparent group-hover:border-cyan-500 rounded-lg transition-all duration-300"></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default SoftwareSection;