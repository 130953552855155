// src/pages/blog.jsx with fixed search functionality for React component blog posts
import React, { useState, useEffect } from 'react';
import { Link, useSearchParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Container from '../components/container';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarAlt, 
  faClock, 
  faSearch,
  faFilter,
  faTimes,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';
import BlogService from '../services/blogService';

// Improved category filter component
const CategoryFilterList = ({ categories, activeFilter, handleCategoryFilter, t }) => {
  return (
    <div className="mb-4">
      <h3 className="font-semibold text-white dark:text-white mb-2 font-heading">
        {t('categories', 'Kategorien')}
      </h3>
      <div className="flex flex-col gap-2">
        <button
          onClick={() => handleCategoryFilter('all')}
          className={`text-left px-3 py-2 rounded text-sm transition-colors ${
            activeFilter === 'all'
              ? 'bg-primary text-white'
              : 'bg-gray-700 dark:bg-gray-700 text-gray-300 dark:text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-600'
          }`}
        >
          {t('all-posts', 'Alle Beiträge')}
        </button>
        
        <div className="max-h-60 overflow-y-auto pr-1 space-y-1 scrollbar-thin scrollbar-thumb-gray-600">
          {categories.map(category => (
            <button
              key={category}
              onClick={() => handleCategoryFilter(category)}
              className={`text-left px-3 py-2 rounded text-sm transition-colors ${
                activeFilter === category
                  ? 'bg-primary text-white'
                  : 'bg-gray-700 dark:bg-gray-700 text-gray-300 dark:text-gray-300 hover:bg-gray-600 dark:hover:bg-gray-600'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

const Blog = () => {
  const { t, i18n } = useTranslation('blog');
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  
  // Get current language and handle both formats
  const getCurrentLanguage = () => {
    // Get the full locale from i18n
    const fullLang = i18n.language || localStorage.getItem('i18nextLng') || 'de_DE';
    
    // For backward compatibility with existing posts that use simple codes
    const simpleLang = fullLang.split('_')[0];
    
    return { fullLang, simpleLang };
  };
  
  const [posts, setPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [activeFilter, setActiveFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPosts, setFilteredPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showFilters, setShowFilters] = useState(false);
  
  // Format date for display
  const formatDate = (dateString) => {
    if (!dateString) return '';
    
    try {
      const date = new Date(dateString);
      return date.toLocaleDateString(i18n.language || navigator.language || 'de-DE', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    } catch (e) {
      return dateString;
    }
  };
  
  // Handle category filter
  const handleCategoryFilter = (category) => {
    setActiveFilter(category);
    applyFilters(searchTerm, category, posts);
    
    // Update URL parameter
    const params = new URLSearchParams(searchParams);
    if (category === 'all') {
      params.delete('category');
    } else {
      params.set('category', category);
    }
    setSearchParams(params);
  };

  // Handle search
  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    applyFilters(term, activeFilter, posts);
    
    // Update URL parameter
    const params = new URLSearchParams(searchParams);
    if (term === '') {
      params.delete('search');
    } else {
      params.set('search', term);
    }
    setSearchParams(params);
  };
  
  // Reset all filters
  const resetFilters = () => {
    setSearchTerm('');
    setActiveFilter('all');
    setFilteredPosts([...posts]); // Show all posts
    navigate('/blog');
  };

  // Apply filters to posts - Updated to handle both old and new format
  const applyFilters = (term, category, postsToFilter) => {
    // Make a copy to avoid modifying the original
    let filtered = [...postsToFilter];
    
    // Apply category filter
    if (category !== 'all') {
      filtered = filtered.filter(post => 
        post.categories.includes(category)
      );
    }
    
    // Apply search term filter
    if (term && term.trim() !== '') {
      filtered = filtered.filter(post => {
        const { fullLang, simpleLang } = getCurrentLanguage();
        
        // Try to get content in the current language (try both formats)
        const translations = post.translations || {};
        let title, excerpt, searchableContent;
        
        // Try full locale first, then simple language code, then fall back to default
        if (translations[fullLang]) {
          title = translations[fullLang].title || '';
          excerpt = translations[fullLang].excerpt || '';
          // Use searchableContent property if available (for new format)
          searchableContent = translations[fullLang].searchableContent || '';
          
          // If searchableContent is not available, but content is a string, use that
          if (!searchableContent && typeof translations[fullLang].content === 'string') {
            searchableContent = translations[fullLang].content;
          }
        } else if (translations[simpleLang]) {
          title = translations[simpleLang].title || '';
          excerpt = translations[simpleLang].excerpt || '';
          // Use searchableContent property if available (for new format)
          searchableContent = translations[simpleLang].searchableContent || '';
          
          // If searchableContent is not available, but content is a string, use that
          if (!searchableContent && typeof translations[simpleLang].content === 'string') {
            searchableContent = translations[simpleLang].content;
          }
        } else {
          title = post.title || '';
          excerpt = post.excerpt || '';
          // Use searchableContent property if available (for new format)
          searchableContent = post.searchableContent || '';
          
          // If searchableContent is not available, but content is a string, use that
          if (!searchableContent && typeof post.content === 'string') {
            searchableContent = post.content;
          }
        }
        
        return title.toLowerCase().includes(term) || 
               (excerpt && excerpt.toLowerCase().includes(term)) ||
               (searchableContent && searchableContent.toLowerCase().includes(term));
      });
    }
    
    setFilteredPosts(filtered);
  };
  
  // Fetch posts from service and handle URL parameters
  useEffect(() => {
    const fetchPosts = async () => {
      try {
        setLoading(true);
        const allPosts = await BlogService.getAllPosts();
        setPosts(allPosts);
        
        // Extract unique categories
        const allCategories = allPosts.flatMap(post => post.categories);
        const uniqueCategories = [...new Set(allCategories)].sort();
        setCategories(uniqueCategories);
        
        // Check URL parameters
        const categoryParam = searchParams.get('category');
        const searchParam = searchParams.get('search');
        
        // Set filter states
        const activeCategory = categoryParam || 'all';
        const activeSearch = searchParam || '';
        
        setActiveFilter(activeCategory);
        setSearchTerm(activeSearch);
        
        // Apply filters using the retrieved posts directly
        // This ensures we don't use stale state
        applyFilters(activeSearch, activeCategory, allPosts);
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
        setLoading(false);
      }
    };
    
    fetchPosts();
  }, [searchParams, i18n.language]); // Re-fetch when language or search params change

  // Get translated content for a post
  const getTranslatedContent = (post) => {
    const { fullLang, simpleLang } = getCurrentLanguage();
    
    // Try full locale format first (e.g., de_DE)
    if (post.translations && post.translations[fullLang]) {
      return {
        title: post.translations[fullLang].title,
        excerpt: post.translations[fullLang].excerpt,
        formattedDate: post.translations[fullLang].formattedDate
      };
    }
    
    // Try simple language code (e.g., de)
    if (post.translations && post.translations[simpleLang]) {
      return {
        title: post.translations[simpleLang].title,
        excerpt: post.translations[simpleLang].excerpt,
        formattedDate: post.translations[simpleLang].formattedDate
      };
    }
    
    // Fallback to default properties if translations not available
    return {
      title: post.title,
      excerpt: post.excerpt,
      formattedDate: post.formattedDate
    };
  };

  // Show loading state
  if (loading) {
    return (
      <Container>
        <div className="flex justify-center items-center h-64">
          <div className="text-primary">{t('loading', 'Loading...')}</div>
        </div>
      </Container>
    );
  }

  return (
    <Container>
      <div className="max-w-6xl mx-auto py-8">
        <div className="flex flex-col md:flex-row gap-8">
          {/* Sidebar with filters */}
          <div className="w-full md:w-1/4">
            <div className="bg-gray-800 dark:bg-gray-800 rounded-lg shadow-md p-4 sticky top-24">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold text-white dark:text-white font-heading">
                  {t('filters', 'Filter')}
                </h2>
                <button 
                  onClick={() => setShowFilters(!showFilters)}
                  className="md:hidden text-gray-300 dark:text-gray-300 focus:outline-none"
                  aria-label={showFilters ? 'Hide filters' : 'Show filters'}
                >
                  <FontAwesomeIcon icon={showFilters ? faTimes : faFilter} />
                </button>
              </div>
              
              <div className={`${showFilters ? 'block' : 'hidden'} md:block`}>
                {/* Search bar */}
                <div className="mb-6 relative">
                  <div className="relative">
                    <input
                      type="text"
                      value={searchTerm}
                      onChange={handleSearch}
                      placeholder={t('search-blog', 'Blog durchsuchen...')}
                      className="w-full px-4 py-2 pl-10 bg-gray-700 dark:bg-gray-700 border border-gray-600 dark:border-gray-600 rounded-md text-white dark:text-white focus:border-primary focus:outline-none"
                    />
                    <FontAwesomeIcon 
                      icon={faSearch} 
                      className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                    />
                  </div>
                </div>
                
                {/* Category filters - using the new component */}
                <CategoryFilterList 
                  categories={categories} 
                  activeFilter={activeFilter} 
                  handleCategoryFilter={handleCategoryFilter} 
                  t={t} 
                />
                
                {/* Reset filters button */}
                {(activeFilter !== 'all' || searchTerm) && (
                  <button
                    onClick={resetFilters}
                    className="w-full px-4 py-2 bg-gray-700 dark:bg-gray-700 text-gray-300 dark:text-gray-300 rounded hover:bg-gray-600 dark:hover:bg-gray-600 transition-colors"
                  >
                    {t('reset-filters', 'Filter zurücksetzen')}
                  </button>
                )}
              </div>
            </div>
          </div>
          
          {/* Blog posts list */}
          <div className="w-full md:w-3/4">
            {filteredPosts.length === 0 ? (
              <div className="text-center py-12 bg-gray-800 dark:bg-gray-800 rounded-lg shadow-md">
                <p className="text-gray-300 dark:text-gray-300 text-lg">
                  {t('no-posts-found', 'Keine Beiträge gefunden, die Ihren Kriterien entsprechen')}
                </p>
              </div>
            ) : (
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {filteredPosts.map(post => {
                  // Get translated content
                  const translatedContent = getTranslatedContent(post);
                  
                  return (
                    <div key={post.id} className="bg-gray-800 dark:bg-gray-800 rounded-lg shadow-md overflow-hidden hover:shadow-lg transition-shadow">
                      {/* Featured image */}
                      {post.thumbnailUrl && (
                        <div className="h-48 overflow-hidden">
                          <img 
                            src={post.thumbnailUrl} 
                            alt={translatedContent.title}
                            className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-105"
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = 'https://via.placeholder.com/600x300?text=PathConnect';
                            }}
                          />
                        </div>
                      )}
                      
                      <div className="p-6">
                        {/* Meta information */}
                        <div className="flex flex-wrap text-sm text-gray-400 dark:text-gray-400 mb-2">
                          <div className="flex items-center mr-4 mb-2">
                            <FontAwesomeIcon icon={faCalendarAlt} className="mr-1" />
                            {translatedContent.formattedDate || formatDate(post.date)}
                          </div>
                          
                          {post.readTime && (
                            <div className="flex items-center mb-2">
                              <FontAwesomeIcon icon={faClock} className="mr-1" />
                              {post.readTime} min {t('read-time', 'read')}
                            </div>
                          )}
                        </div>
                        
                        {/* Title */}
                        <h2 className="text-xl font-bold text-white dark:text-white mb-3 hover:text-primary transition-colors font-heading">
                          <Link to={`/blog/${post.slug}`}>
                            {translatedContent.title}
                          </Link>
                        </h2>
                        
                        {/* Categories */}
                        <div className="flex flex-wrap mb-4 gap-2">
                          {post.categories.map(category => (
                            <span 
                              key={category}
                              className="text-xs px-3 py-1 bg-gray-700 dark:bg-gray-700 text-primary rounded-full hover:bg-gray-600 dark:hover:bg-gray-600 transition-colors cursor-pointer"
                              onClick={(e) => {
                                e.preventDefault();
                                handleCategoryFilter(category);
                              }}
                            >
                              {category}
                            </span>
                          ))}
                        </div>
                        
                        {/* Excerpt */}
                        {translatedContent.excerpt && (
                          <p className="text-gray-300 dark:text-gray-300 mb-4 line-clamp-3">
                            {translatedContent.excerpt}
                          </p>
                        )}
                        
                        {/* Read more link */}
                        <Link 
                          to={`/blog/${post.slug}`}
                          className="inline-flex items-center text-primary font-medium hover:text-button-dark"
                        >
                          {t('read-more', 'Weiterlesen')}
                          <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                        </Link>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Blog;