// src/data/blogPosts/wechsel-zu-pathconnect.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading,
  BlogImage 
} from '../../components/blog';

// Import images
import thumbnailImage from '../../assets/images/blog/wechsel-zu-pathconnect-featured.png';
import pathConnectLogo from '../../assets/images/blog/2020/03/PathConnectLogo.png';
import speechBubbleIcon from '../../assets/images/blog/2023/08/iconmonstr-speech-bubble-26-240.png';
import keyIcon from '../../assets/images/blog/2022/01/key.png';
import syncIcon from '../../assets/images/blog/2022/03/sync.png';
import userIcon from '../../assets/images/blog/2022/01/Verbraucher.png';

/**
 * Blog post about switching to PathConnect
 */
const post = {
  id: '13022',
  slug: 'wechsel-zu-pathconnect',
  
  // Base data - same regardless of language
  date: "2023-09-27T08:41:13.000Z",
  readTime: 7,
  categories: ["Informationen"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 17,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Wechsel zu PathConnect",
      formattedDate: "27. September 2023",
      excerpt: "In unserem letzten Blogbeitrag haben wir beschrieben, wie du mit der App \"User Migration\" deine Daten von einer bisherigen Nextcloud auf eine neue Nextcloud umziehen kannst. Heute geht es darum wie ein Wechsel zu PathConnect funktioniert.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">Nextcloud Wechsel zu PathConnect</h1>
          
          <p>In unserem letzten Blogbeitrag haben wir beschrieben, wie du mit der App "<a href="https://pathconnect.de/blog/nextcloud-user-migration-app/" target="_blank" rel="noopener">User Migration</a>" deine Daten von einer bisherigen Nextcloud auf eine neue Nextcloud umziehen kannst. Heute geht es darum wie ein Wechsel zu PathConnect funktioniert. Wir zeigen dir welche Möglichkeiten du hast und wie wir dich dabei unterstützen können.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={pathConnectLogo} alt="Logo PathConnect" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Bestandsaufnahme</BlogHeading>
          
          <p>Es gibt verschiedene Möglichkeiten mit deiner alten Nextcloud zu PathConnect zu wechseln. Welche am besten geeignet ist, hängt von deinen Zugriffsrechten auf die alte Nextcloud-Instanz und der Menge der vorhandenen Daten ab.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={speechBubbleIcon} alt="Sprechblase Icon" size="small" />
          </div>
          
          <BlogHeading>Du hast einen SSH-Zugang</BlogHeading>
          
          <p>Mit einem SSH-Zugang (secure shell) können wir uns auf den Server verbinden, auf dem deine bisherige Nextcloud-Instanz läuft und deine Daten inklusive Datenbank auf den neuen Nextcloud-Server bei PathConnect kopieren. Der Vorteil hierbei ist, dass du nicht alles runter- und wieder hochladen musst, durch den Export der Datenbank können sich die Nutzer direkt wieder mit ihrem bisherigen Passwort anmelden. Profileinstellungen, Freigaben, Kalender und Kontakte werden ebenfalls übernommen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={keyIcon} alt="Schlüssel Icon" size="small" />
          </div>
          
          <BlogHeading>Du hast keinen SSH-Zugang</BlogHeading>
          
          <p>Wenn du keinen SSH-Zugang zu deinem alten Nextcloud-Server hast, ist das auch kein Problem. In diesem Fall empfehlen wir dir, zunächst mit der App „User Migration" einen Export deiner Profilinformationen, Kalender und Kontakte zu erstellen und in deine neue Nextcloud zu importieren. Anschließend richtest du den Nextcloud Client auf deinem Computer zusätzlich mit deinen neuen Cloud-Zugangsdaten ein. Du bist dann sowohl mit deiner alten Nextcloud als auch mit der neuen Nextcloud verbunden. Jetzt kannst du einfach deine synchronisierten Ordner aus deiner alten Nextcloud in deine neue Nextcloud synchronisieren.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={syncIcon} alt="Synchronisierungs Icon" size="small" />
          </div>
          
          <BlogHeading>Wir helfen dir</BlogHeading>
          
          <p>Egal für welche Möglichkeit du dich entscheidest, wir unterstützen dich natürlich dabei. Wenn du es erst einmal alleine versuchen möchtest und bei einem Schritt nicht weiterkommst, schreibe uns einfach an <a href="mailto:info@pathconnect.de" target="_blank" rel="noopener">info@pathconnect.de.</a></p>
          
          <p>Gerne können wir auch einen Termin vereinbaren, bei dem wir dich aktiv beim Umzug deiner Nextcloud untersützen.</p>
          
          <div className="flex justify-center my-4">
            <BlogImage src={userIcon} alt="Verbraucher Icon" size="small" />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "Wechsel zu PathConnect",
      formattedDate: "27. September 2023",
      excerpt: "In unserem letzten Blogbeitrag haben wir beschrieben, wie du mit der App \"User Migration\" deine Daten von einer bisherigen Nextcloud auf eine neue Nextcloud umziehen kannst. Heute geht es darum wie ein Wechsel zu PathConnect funktioniert.",
      get content() { return post.translations.de.content; }
    }
  },
  
  // Default getters for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;