// src/data/blogPosts/fairemail.js

// Import images
import thumbnailImage from '../../assets/images/blog/fairemail-featured.png';
import fairEmailLogo from '../../assets/images/blog/2023/03/FairEmail.png';
import paperPlaneIcon from '../../assets/images/blog/2023/03/iconmonstr-paper-plane-8-240.png';
import lockIcon from '../../assets/images/blog/2022/01/lock.png';
import smartphoneIcon from '../../assets/images/blog/2022/03/smartphone.png';
import setupImage01 from '../../assets/images/blog/2023/03/FairEmail-Setup01.jpg';
import setupImage02 from '../../assets/images/blog/2023/03/FairEmail-Setup02.jpg';
import displayImage01 from '../../assets/images/blog/2023/03/FairEmail-Darstellung01.jpg';
import displayImage02 from '../../assets/images/blog/2023/03/FairEmail-Darstellung02.jpg';
import basicFunction01 from '../../assets/images/blog/2023/03/FairEmail-Grundfunktion01.jpg';
import basicFunction02 from '../../assets/images/blog/2023/03/FairEmail-Grundfunktion02.jpg';

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogGallery,
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about FairEmail
 */
const post = {
  id: '11314',
  slug: 'fairemail',
  
  // Base data - same regardless of language
  date: "2023-03-09T16:02:51.000Z",
  readTime: 13,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: thumbnailImage,
  thumbnailUrl: thumbnailImage,
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 25,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "FairEmail",
      formattedDate: "9. März 2023",
      excerpt: "Mit der Android-App FairEmail kannst du deine E-Mail-Konten verwalten sowie E-Mails senden und empfangen. Warum wir dir diese App empfehlen, erfährst du in diesem Artikel.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">FairEmail</h1>
          
          <p>Mit der Android-App FairEmail kannst du deine E-Mail-Konten verwalten sowie E-Mails senden und empfangen. Warum wir dir diese App empfehlen, erfährst du in diesem Artikel.</p>
          
          <div className="flex flex-col md:flex-row justify-between items-center my-6 gap-4">
            <div className="w-full md:w-1/2 text-center">
              <BlogImage src={fairEmailLogo} alt="FairEmail Logo" size="medium" />
            </div>
            
          </div>
          
          <BlogHeading>Über die App</BlogHeading>
          
          <p>FairEmail ist ausschließlich für Android verfügbar. Die App ist Open Source und sowohl als kostenlose als auch als kostenpflichtige Version im PlayStore und F-Droid erhältlich.<br />Es können alle „normalen" E-Mail-Anbieter wie Gmail, GMX etc. eingebunden werden. Auch datenschutzfreundliche Provider wie Posteo und Startmail funktionieren problemlos. Wenn du eine eigene E-Mail Adresse über eine Domain hast, werden die Einstellungen auch hier einwandfrei erkannt. <br />Die App schützt deine Privatsphäre, verbraucht wenig Akku und ist einfach zu bedienen.</p>
          
          <BlogImage src={paperPlaneIcon} alt="Papierflieger Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Installation & Einrichtung</BlogHeading>
          
          <p>Zur Installation einfach die App aus dem PlayStore oder F-Droid herunterladen.</p>
          <p><a href="https://play.google.com/store/apps/details?id=eu.faircode.email&hl=de&gl=US" target="_blank" rel="noopener">https://play.google.com/store/apps/details?id=eu.faircode.email&hl=de&gl=US</a></p>
          <p><a href="https://f-droid.org/de/packages/eu.faircode.email/" target="_blank" rel="noopener">https://f-droid.org/de/packages/eu.faircode.email/</a></p>
          <p>Anschließend kannst du dein erstes E-Mail-Konto einrichten. Dazu tippst du im Bereich „Konten hinzufügen oder ändern" auf „Assistent" und wählst einen Anbieter aus der Liste aus. Falls dein Anbieter hier nicht aufgeführt ist, tippe auf „Anderer Anbieter".</p>
          <p>Als anderen Anbieter können wir dir zum Beispiel <a href="https://posteo.de/de" target="_blank" rel="noopener">posteo</a> empfehlen. Gib im nächsten Fenster deinen Namen, deine E-Mail-Adresse und dein Passwort ein und tippe auf „Prüfen".</p>
          <p>FairEmail erkennt nun die passenden Servereinstellungen und schon bist du verbunden. Für weitere E-Mail-Konten kannst du die Schritte einfach wiederholen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={setupImage01} alt="FairEmail Setup Schritt 1" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={setupImage02} alt="FairEmail Setup Schritt 2" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Einstellungen</BlogHeading>
          
          <p>Um zu den Einstellungen zu gelangen, tippe auf die drei Striche neben „Unterhaltungen" und wähle dann den Punkt „Einstellungen".<br />Eine Erklärung aller Einstellungsmöglichkeiten würde den Rahmen dieses Artikels sprengen und die meisten Einstellungen kannst du in den Standardeinstellungen belassen.<br />Für die Nutzer wirklich relevant ist der Punkt „Darstellung", hier kannst du das Design der App und der E-Mails nach Belieben anpassen.<br />Um die E-Mails zu verschlüsseln, kannst du unter dem Menüpunkt „Verschlüsselung" z.B. deinen PGP-Schlüssel hinterlegen.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={displayImage01} alt="FairEmail Darstellung Einstellungen 1" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={displayImage02} alt="FairEmail Darstellung Einstellungen 2" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Grundfunktionen</BlogHeading>
          
          <p>Nachdem du dein Konto eingerichtet hast, siehst du in deinem Posteingang die E-Mails, die du erhalten hast. Um eine neue E-Mail zu schreiben, klicke auf den Button mit dem Stift unten links. Wenn du mehrere Konten verbunden hast, wirst du gefragt, von welchem Konto die E-Mail gesendet werden soll. Um auf eine E-Mail zu antworten, tippe auf den Button mit dem Pfeil links unten in der Ecke.</p>
          
          <div className="flex flex-col md:flex-row gap-4 my-6">
            <div className="w-full md:w-1/2">
              <BlogImage src={basicFunction02} alt="FairEmail Grundfunktion 1" size="medium" />
            </div>
            <div className="w-full md:w-1/2">
              <BlogImage src={basicFunction01} alt="FairEmail Grundfunktion 2" size="medium" />
            </div>
          </div>
          
          <BlogHeading level={3}>Privatsphäre & Sicherheit</BlogHeading>
          
          <p>Die App selbst sammelt keine Daten und ist Open Source. Mit FairEmail kannst du Nachrichten ver- und entschlüsseln, Bilder werden standardmäßig nicht angezeigt, um Tracking zu verhindern. Wenn eine E-Mail mit Links kommt, müssen diese erst nach dem Klick bestätigt werden, außerdem siehst du die genaue Zieladresse. Zusätzlich wirst du gewarnt, wenn eine Nachricht nicht authentifiziert werden konnte.</p>
          
          <BlogImage src={lockIcon} alt="Schloss Icon" size="icon" className="mx-auto my-6" />
          
          <BlogHeading level={3}>Pro Funktionen</BlogHeading>
          
          <p>Die kostenpflichtige Version der App schaltet einige Pro-Funktionen frei. Nachrichten können zu einem späteren Zeitpunkt verschickt werden, Konten können mit Farben versehen werden, zusätzliche Filterregeln sind möglich, etc. Aber auch ohne diese Zusatzfunktionen lohnt es sich, etwas Geld für die App auszugeben, um die Entwicklung zu unterstützen.</p>
          
          <BlogImage src={smartphoneIcon} alt="Smartphone Icon" size="icon" className="mx-auto my-6" />
          
          <BlogSection title="Open Source Software bei PathConnect">
            <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
            
            
          </BlogSection>
        </BlogContent>
      )
    },
    
    // Same content for full locale format
    de_DE: {
      title: "FairEmail",
      formattedDate: "9. März 2023",
      excerpt: "Mit der Android-App FairEmail kannst du deine E-Mail-Konten verwalten sowie E-Mails senden und empfangen. Warum wir dir diese App empfehlen, erfährst du in diesem Artikel.",
      // Reference the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English translation (placeholder)
    en: {
      title: "FairEmail",
      formattedDate: "March 9, 2023",
      excerpt: "With the Android app FairEmail, you can manage your email accounts as well as send and receive emails. Find out why we recommend this app in this article.",
      content: () => (
        <BlogContent>
          <h1 className="text-3xl md:text-4xl font-bold mb-4">FairEmail</h1>
          
          <p>With the Android app FairEmail, you can manage your email accounts as well as send and receive emails. Find out why we recommend this app in this article.</p>
          
          {/* English content would continue here - this is just a placeholder */}
        </BlogContent>
      )
    },
    
    en_GB: {
      title: "FairEmail",
      formattedDate: "9 March 2023",
      excerpt: "With the Android app FairEmail, you can manage your email accounts as well as send and receive emails. Find out why we recommend this app in this article.",
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;