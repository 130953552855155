// src/data/blogPosts/prime-mover-wordpress-einfach-umziehen.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection,
  BlogGallery
} from '../../components/blog';

/**
 * Multilingual blog post about migrating WordPress with Prime Mover
 */
const post = {
  id: '18755',
  slug: 'prime-mover-wordpress-einfach-umziehen',
  
  // Base data - same regardless of language
  date: "2024-03-13T15:22:09.000Z",
  readTime: 6,
  categories: ["Anleitungen", "Open Source Software"],
  thumbnail: "../images/blog/prime-mover-wordpress-einfach-umziehen-featured.png",
  thumbnailUrl: "../images/blog/prime-mover-wordpress-einfach-umziehen-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 2,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "Prime Mover – WordPress einfach umziehen",
      formattedDate: "13. März 2024",
      excerpt: "Mit dem PlugIn Prime Mover kannst du deine WordPress-Seite ganz einfach zu einem anderen Hoster umziehen. In diesem Artikel stellen wir dir das Plugin vor und erklären die einzelnen Schritte eines WordPress Umzugs.",
      content: () => (
        <BlogContent>
          <p>Mit dem PlugIn Prime Mover kannst du deine WordPress-Seite ganz einfach zu einem anderen Hoster umziehen. In diesem Artikel stellen wir dir das Plugin vor und erklären die einzelnen Schritte eines WordPress Umzugs.</p>
          
          <BlogHeading>Prime Mover</BlogHeading>
          
          <p>Prime Mover ist ein Plugin, das du in deiner WordPress Instanz installieren kannst. Mit diesem Tool kannst du deine gesamte WordPress Installation sichern und auf einem neuen System wiederherstellen. Egal ob es sich um eine Single-Site oder Multi-Site Instanz handelt, der Export ist einfach. Wir zeigen dir die Vorgehensweise Schritt für Schritt.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/01/WordPress-logotype-wordmark-white.png" 
              alt="WordPress Logo" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Sicherung mit Prime Mover erstellen</BlogHeading>
          
          <p>Bevor du anfängst, deaktiviere WordPress-Sicherheits-PlugIns wie Solid-Security, AIO oder Wordfence, um Komplikationen zu vermeiden.<br />
          Nachdem du das PlugIn heruntergeladen und in WordPress installiert hast, findest du im linken Menü einen neuen Punkt „Prime Mover". Klicke hier auf „Go to Migration Tools" und dann auf „Export".</p>
          
          <p>Es werden einige Exportoptionen angeboten. Für einen kompletten Umzug wähle den letzten Punkt mit "Export database, media files, plugins and themes". Als Exporttyp kannst du zwischen Single-Site und Multi-Site wählen. Für einen normalen Umzug auf eine neue Instanz wähle „Export to single-site format".<br />
          Sobald der Export erfolgreich abgeschlossen ist, kannst du den Download der Datei starten.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-6">
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/03/Export-Options.png" 
                alt="Export Options in Prime Mover" 
                size="medium" 
              />
            </div>
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/03/Download-package.png" 
                alt="Download Package in Prime Mover" 
                size="medium" 
              />
            </div>
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/03/Running-Export.png" 
                alt="Running Export in Prime Mover" 
                size="medium" 
              />
            </div>
          </div>
          
          <BlogHeading>Neue WordPress Instanz starten</BlogHeading>
          
          <p>Wenn du zu einem neuen Hostinganbieter wechselst, erhältst du in der Regel einen Administratorzugang zu einer WordPress-Instanz. Logge dich in die neue Instanz ein und installiere das „Prime Mover"-Plugin erneut. Klicke diesmal auf „Go to Migration Tools" – „RESTORE".<br />
          Hier wählst du den soeben erstellten und heruntergeladenen Export aus. Anschließend wird die Datei hochgeladen und der Import gestartet. Sobald der Import erfolgreich abgeschlossen ist, bietet dir Prime Mover an, die Linkstruktur anzupassen, falls deine Website vorher unter einer anderen Adresse lief.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/03/restore.png" 
              alt="Restore Process in Prime Mover" 
              size="large" 
            />
          </div>
          
          <BlogHeading>WordPress bei PathConnect</BlogHeading>
          
          <p>Wir bieten Dir ein schnelles und stabiles WordPress Hosting. Egal ob für einen Blog, eine Website oder einen Onlineshop. Die Performance passt sich Deinen Anforderungen und der Auslastung der Seite an.<br />
          Den Umzug der WordPress Instanz übernehmen wir kostenlos, die Elementor Pro Lizenz, WordPress Updates und persönlicher Support bei Fragen sind ebenfalls im Service enthalten.</p>
          
          <div className="flex justify-center my-4">
            <a href="/shop/" className="px-6 py-2 bg-primary text-white rounded hover:bg-primary-dark transition duration-300">
              WordPress Hosting
            </a>
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "Prime Mover – WordPress einfach umziehen",
      formattedDate: "13. März 2024",
      excerpt: "Mit dem PlugIn Prime Mover kannst du deine WordPress-Seite ganz einfach zu einem anderen Hoster umziehen. In diesem Artikel stellen wir dir das Plugin vor und erklären die einzelnen Schritte eines WordPress Umzugs.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "Prime Mover – Easily Migrate WordPress",
      formattedDate: "March 13, 2024",
      excerpt: "With the Prime Mover plugin, you can easily migrate your WordPress site to another host. In this article, we introduce the plugin and explain the individual steps of a WordPress migration.",
      content: () => (
        <BlogContent>
          <p>With the Prime Mover plugin, you can easily migrate your WordPress site to another host. In this article, we introduce the plugin and explain the individual steps of a WordPress migration.</p>
          
          <BlogHeading>Prime Mover</BlogHeading>
          
          <p>Prime Mover is a plugin that you can install in your WordPress instance. With this tool, you can back up your entire WordPress installation and restore it on a new system. Whether it's a single-site or multi-site instance, the export is simple. We'll show you the process step by step.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/01/WordPress-logotype-wordmark-white.png" 
              alt="WordPress Logo" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Creating a Backup with Prime Mover</BlogHeading>
          
          <p>Before you begin, deactivate WordPress security plugins like Solid Security, AIO, or Wordfence to avoid complications.<br />
          After you've downloaded and installed the plugin in WordPress, you'll find a new item called "Prime Mover" in the left menu. Click here on "Go to Migration Tools" and then on "Export".</p>
          
          <p>Several export options are offered. For a complete migration, select the last option with "Export database, media files, plugins and themes". As an export type, you can choose between Single-Site and Multi-Site. For a normal migration to a new instance, select "Export to single-site format".<br />
          Once the export is successfully completed, you can start downloading the file.</p>
          
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-6">
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/03/Export-Options.png" 
                alt="Export Options in Prime Mover" 
                size="medium" 
              />
            </div>
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/03/Download-package.png" 
                alt="Download Package in Prime Mover" 
                size="medium" 
              />
            </div>
            <div className="flex justify-center">
              <BlogImage 
                src="../images/blog/2024/03/Running-Export.png" 
                alt="Running Export in Prime Mover" 
                size="medium" 
              />
            </div>
          </div>
          
          <BlogHeading>Starting a New WordPress Instance</BlogHeading>
          
          <p>When you switch to a new hosting provider, you typically receive administrator access to a WordPress instance. Log in to the new instance and install the "Prime Mover" plugin again. This time, click on "Go to Migration Tools" – "RESTORE".<br />
          Here, you select the export you just created and downloaded. The file will then be uploaded and the import will start. Once the import is successfully completed, Prime Mover offers to adjust the link structure if your website previously ran under a different address.</p>
          
          <div className="flex justify-center my-6">
            <BlogImage 
              src="../images/blog/2024/03/restore.png" 
              alt="Restore Process in Prime Mover" 
              size="large" 
            />
          </div>
          
          <BlogHeading>WordPress at PathConnect</BlogHeading>
          
          <p>We offer you fast and stable WordPress hosting. Whether for a blog, a website, or an online shop. The performance adapts to your requirements and the load on the site.<br />
          We handle the migration of the WordPress instance free of charge, and the Elementor Pro license, WordPress updates, and personal support for questions are also included in the service.</p>
          
          <div className="flex justify-center my-4">
            <a href="/shop/" className="px-6 py-2 bg-primary text-white rounded hover:bg-primary-dark transition duration-300">
              WordPress Hosting
            </a>
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "Prime Mover – Easily Migrate WordPress",
      formattedDate: "13 March 2024",
      excerpt: "With the Prime Mover plugin, you can easily migrate your WordPress site to another host. In this article, we introduce the plugin and explain the individual steps of a WordPress migration.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;