// src/data/blogPosts/pathconnect-und-nextcloud.js

// Import blog components
import { 
  BlogContent, 
  BlogHeading, 
  BlogImage, 
  BlogSection
} from '../../components/blog';

/**
 * Multilingual blog post about PathConnect and Nextcloud
 */
const post = {
  id: '5672',
  slug: 'pathconnect-und-nextcloud',
  
  // Base data - same regardless of language
  date: "2022-12-21T12:26:19.000Z",
  readTime: 7,
  categories: ["Informationen"],
  thumbnail: "../images/blog/pathconnect-und-nextcloud-featured.png",
  thumbnailUrl: "../images/blog/pathconnect-und-nextcloud-featured.png",
  thumbnailSize: 'medium',
  showInPostHeader: true,
  likes: 19,
  comments: [],
  
  // Language-specific data
  translations: {
    // German content
    de: {
      title: "PathConnect und Nextcloud",
      formattedDate: "21. Dezember 2022",
      excerpt: "Was genau macht PathConnect und wie hängt das mit Nextcloud zusammen? Darum geht es in diesem Blogartikel.",
      content: () => (
        <BlogContent>
          <p>Was genau macht PathConnect und wie hängt das mit Nextcloud zusammen? Darum geht es in diesem Blogartikel.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/03/PathConnectLogo.png" 
              alt="PathConnect Logo" 
              size="large" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Nextcloud</BlogHeading>
          
          <p>Nextcloud ist eine Open Source Software, die es dir ermöglicht auf deine persönlichen Daten, Dokumente, Fotos, Kontakte und Kalender von jedem Gerät aus zuzugreifen, zu teilen und zu synchronisieren. Die eigene Nextcloud kann entweder selbst oder von einem Hosting Anbieter betrieben werden.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2020/08/Nextcloud_Logo.svg.png" 
              alt="Nextcloud Logo" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Nextcloud Office und Talk</BlogHeading>
          
          <p>Mit Nextcloud Office (Collabora Online) kannst du Dokumente und Präsentationen in der Cloud erstellen, diese mit anderen Leuten teilen und gemeinsam bearbeiten.<br />Text- und Videochats können mit Nextcloud Talk sowohl zu zweit als auch in der Gruppe abgehalten werden.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/09/collabora-online-1024x471.png" 
              alt="Collabora Online in Nextcloud" 
              size="large" 
            />
          </div>
          
          <BlogHeading>PathConnect</BlogHeading>
          
          <p>PathConnect kümmert sich um die Installation, Bereitstellung und Wartung deiner Nextcloud.<br />Wir sorgen dafür das alles reibungslos funktioniert, installieren Updates, testen neue Funktionen, lösen auftretende Probleme und kümmern uns um die Sicherheit und Verfügbarkeit der Server.</p>
          
          <p>Wir helfen dir von deinem alten Cloudanbieter zu wechseln und steht dir bei Fragen und Problemen immer gerne zur Seite.</p>
          
          <p>Mit PathConnect kannst du alle Funktionen und Vorteile von Nextcloud nutzen ohne dir Gedanken über die Installation, Serverwartung, Verfügbarkeit und Sicherheit zu machen.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2020/03/PathConnectLogo.png" 
              alt="PathConnect Logo" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Open Source Software bei PathConnect</BlogHeading>
          
          <p>Einfach auswählen und loslegen. Wir passen die Software genau an deine Bedürfnisse an, kümmern uns um die Wartung und stehen dir mit persönlichem Support zur Seite. Unser Angebot umfasst eine Vielzahl von Open-Source-Lösungen, die perfekt auf deine Anforderungen zugeschnitten werden können. Genieße den Komfort eines Rundum-Services, der Installation, Anpassungen und Updates beinhaltet, damit du dich voll und ganz auf deine Projekte konzentrieren kannst.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    de_DE: {
      title: "PathConnect und Nextcloud",
      formattedDate: "21. Dezember 2022",
      excerpt: "Was genau macht PathConnect und wie hängt das mit Nextcloud zusammen? Darum geht es in diesem Blogartikel.",
      // This references the same content as 'de' to avoid duplication
      get content() { return post.translations.de.content; }
    },
    
    // English content
    en: {
      title: "PathConnect and Nextcloud",
      formattedDate: "December 21, 2022",
      excerpt: "What exactly does PathConnect do and how is it related to Nextcloud? That's what this blog article is about.",
      content: () => (
        <BlogContent>
          <p>What exactly does PathConnect do and how is it related to Nextcloud? That's what this blog article is about.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2024/03/PathConnectLogo.png" 
              alt="PathConnect Logo" 
              size="large" 
            />
          </div>
          
          <div className="flex justify-center my-4">
            
          </div>
          
          <BlogHeading>Nextcloud</BlogHeading>
          
          <p>Nextcloud is an open source software that allows you to access, share, and synchronize your personal data, documents, photos, contacts, and calendars from any device. Your own Nextcloud can either be operated by yourself or through a hosting provider.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2020/08/Nextcloud_Logo.svg.png" 
              alt="Nextcloud Logo" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Nextcloud Office and Talk</BlogHeading>
          
          <p>With Nextcloud Office (Collabora Online), you can create documents and presentations in the cloud, share them with others, and collaboratively edit them.<br />Text and video chats can be conducted with Nextcloud Talk, both one-on-one and in groups.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2022/09/collabora-online-1024x471.png" 
              alt="Collabora Online in Nextcloud" 
              size="large" 
            />
          </div>
          
          <BlogHeading>PathConnect</BlogHeading>
          
          <p>PathConnect takes care of the installation, provision, and maintenance of your Nextcloud.<br />We ensure that everything works smoothly, install updates, test new features, resolve any issues that arise, and take care of the security and availability of the servers.</p>
          
          <p>We help you switch from your old cloud provider and are always available to assist you with questions and problems.</p>
          
          <p>With PathConnect, you can use all the features and benefits of Nextcloud without having to worry about installation, server maintenance, availability, and security.</p>
          
          <div className="flex justify-center items-center my-6">
            <BlogImage 
              src="../images/blog/2020/03/PathConnectLogo.png" 
              alt="PathConnect Logo" 
              size="large" 
            />
          </div>
          
          <BlogHeading>Open Source Software at PathConnect</BlogHeading>
          
          <p>Simply select and get started. We customize the software exactly to your needs, take care of maintenance, and provide you with personal support. Our offering includes a variety of open source solutions that can be perfectly tailored to your requirements. Enjoy the comfort of an all-round service that includes installation, customization, and updates, allowing you to focus fully on your projects.</p>
          
          <div className="flex justify-center my-4">
            
          </div>
        </BlogContent>
      )
    },
    
    // Make the same content available in the full locale format
    en_GB: {
      title: "PathConnect and Nextcloud",
      formattedDate: "21 December 2022",
      excerpt: "What exactly does PathConnect do and how is it related to Nextcloud? That's what this blog article is about.",
      // This references the same content as 'en' to avoid duplication
      get content() { return post.translations.en.content; }
    }
  },
  
  // Default getter methods for convenience
  get title() {
    return this.translations.de.title;
  },
  
  get formattedDate() {
    return this.translations.de.formattedDate;
  },
  
  get excerpt() {
    return this.translations.de.excerpt;
  },
  
  get content() {
    return this.translations.de.content;
  }
};

export default post;