// src/components/blog/BlogContent.js
import React from 'react';

/**
 * Main wrapper for blog post content with consistent styling
 * Improved for better mobile width usage
 */
const BlogContent = ({ children, className = '' }) => {
  return (
    <div className={`blog-content w-full px-2 sm:px-4 md:px-6 max-w-none md:max-w-4xl mx-auto prose prose-lg dark:prose-invert prose-headings:text-primary prose-a:text-primary prose-img:rounded-md prose-img:shadow-md ${className}`}>
      {children}
    </div>
  );
};

export default BlogContent;